import React from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as candidatesIcon } from '../assets/icons/candidates-icon.svg';
import { ReactComponent as clientsIcon } from '../assets/icons/clients-icon.svg';
import { ReactComponent as dashboardIcon } from '../assets/icons/dashboard-icon.svg';
import { ReactComponent as eventsIcon } from '../assets/icons/events-icon.svg';
import { ReactComponent as profileIcon } from '../assets/icons/profile-icon.svg';
import { ReactComponent as projectsIcon } from '../assets/icons/projects-icon.svg';
import { ReactComponent as reporstIcon } from '../assets/icons/reports-icon.svg';
import { ReactComponent as settingsIcon } from '../assets/icons/settings-icon.svg';
import logo from '../assets/img/logo-1.png';
import Subtitle from './SubTitle';

import { LAYOUT_DRAWER_WIDTH } from '../constants/layout';

const drawerWidth = LAYOUT_DRAWER_WIDTH;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const primaryMenuItems = [
  { title: 'Dashboard', url: '/', icon: dashboardIcon },
  { title: 'Candidates', url: '/candidates', icon: candidatesIcon },
  { title: 'Clients', url: '/clients', icon: clientsIcon },
  { title: 'Projects', url: '/projects', icon: projectsIcon },
];

const secondaryMenuItems = [
  { title: 'Profile', url: '/', icon: profileIcon },
  { title: 'Events', url: '/events', icon: eventsIcon },
  { title: 'Settings', url: '/settings', icon: settingsIcon },
];

const Layout = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const showTopBar = useMediaQuery('(min-width:663px)');

  const drawer = (
    <div>
      <List sx={{ marginLeft: 1 }}>
        {primaryMenuItems.map((item) => (
          <ListItem button key={item.title} sx={{ color: 'white' }}>
            <ListItemIcon sx={{ color: 'white', minWidth: 20, marginRight: 2 }}>
              <SvgIcon component={item.icon} inheritViewBox sx={{ fontSize: 18 }}></SvgIcon>
            </ListItemIcon>
            <Link
              href={item.url}
              sx={{
                textDecoration: 'none',
              }}
            >
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              />
            </Link>
          </ListItem>
        ))}
        <Accordion sx={{ backgroundColor: 'transparent' }}>
          <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ color: 'white', height: 15 }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
            }}
          >
            <SvgIcon
              component={reporstIcon}
              inheritViewBox
              color="white"
              sx={{ color: 'white', marginRight: 2, fontSize: 18 }}
            ></SvgIcon>
            <Typography
              sx={{
                color: 'white',
                textDecoration: 'none',
                fontSize: 15,
                fontWeight: 'bold',
              }}
            >
              Reports
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginLeft: 3 }}>
            <List>
              <ListItem>
                <Link
                  href="#"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Create A Submission Report
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="#"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Create A Weekly Report
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="#"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: 10,
                    fontWeight: 'bold',
                  }}
                >
                  View All Reports
                </Link>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
      <Divider sx={{ borderColor: 'white', marginX: 3 }} />
      <Typography
        sx={{
          color: 'white',
          textDecoration: 'none',
          fontSize: 13,
          fontWeight: 'bold',
          textAlign: 'left',
          marginLeft: 3,
          marginTop: 3,
        }}
      >
        Account
      </Typography>
      <List sx={{ marginLeft: 1 }}>
        {secondaryMenuItems.map((item) => (
          <ListItem button key={item.title} sx={{ color: 'white' }}>
            <ListItemIcon sx={{ color: 'white', minWidth: 25, marginRight: 2 }}>
              <SvgIcon component={item.icon} inheritViewBox color="white" sx={{ fontSize: 18 }}></SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                color: 'white',
                textDecoration: 'none',
                fontSize: 15,
                fontWeight: 'bold',
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: 'white',
        }}
      >
        <Toolbar sx={{ marginX: 2, height: '64px' }} disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' }, color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>

          <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
            <PeopleAltIcon sx={{ color: 'nexxusBlue.main', marginRight: 1, width: 18 }} />
            <Typography sx={{ color: 'nexxusGray.main', fontSize: 14 }}>Welcome,&nbsp;</Typography>
            <Link
              href="/login"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: 14,
              }}
              sx={{ color: 'nexxusBlue.main' }}
            >
              Andrea Gomez
            </Link>{' '}
            {showTopBar ? (
              <>
                <Typography sx={{ color: 'nexxusGray.main', fontSize: 14 }}>&nbsp;- last login session:</Typography>
                <Typography
                  sx={{
                    color: 'nexxusGray.main',
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                >
                  &nbsp; 08/25/1991-17:76:21 Hrs
                </Typography>
              </>
            ) : null}
            <Chip
              label="Log out"
              icon={<PowerSettingsNewIcon />}
              sx={{
                backgroundColor: 'nexxusBlue.main',
                color: 'white',
                height: 28,
                marginLeft: 1,
                '& .MuiChip-icon': {
                  color: 'white',
                  width: 20,
                  marginLeft: 1,
                },
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: 'linear-gradient(0deg, #004dd3 0%, #011c2e 100%)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 1,
              marginBottom: 4,
            }}
          >
            <img src={logo} alt="" className="" width={200} />
          </Box>
          {drawer}
        </Drawer>
        {/* Full width drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: 'linear-gradient(0deg, #004dd3 0%, #011c2e 100%)',
            },
          }}
          open
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 1,
              marginBottom: 4,
            }}
          >
            <img src={logo} alt="" className="" width={200} />
          </Box>
          {drawer}
        </Drawer>
      </Box>
      <Main open={mobileOpen}>
        <Subtitle subtitle="Find the" subtitleFocus="Perfect Candidate" />
        {props.children}
      </Main>
    </Box>
  );
};

export default Layout;
