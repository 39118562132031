import { Box, Link, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { capitalizeString } from '@nexxus/utils';
import { useGetAuditTrailsQuery } from 'api/api';
import { Outlet } from 'react-router-dom';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    editable: false,
    width: 50,
  },
  {
    field: 'User.full_name',
    headerName: 'User',
    editable: false,
    width: 240,
    valueGetter: (params) => {
      return params.row.User.full_name;
    },
  },
  {
    field: 'User.email',
    headerName: 'Email',
    editable: false,
    width: 220,
    valueGetter: (params) => {
      return params.row.User.email;
    },
  },
  {
    field: 'activity',
    headerName: 'Activity',
    editable: false,
    width: 200,
    valueGetter: (params) => {
      return capitalizeString(params.row.activity);
    },
  },
  {
    field: 'createdAtDate',
    headerName: 'Date',
    editable: false,
    width: 160,
    valueGetter: (params) => {
      return moment(params.row.createdAt).format('MM/DD/YYYY');
    },
  },
  {
    field: 'createdAtTime',
    headerName: 'Time',
    editable: false,
    width: 160,
    valueGetter: (params) => {
      return moment(params.row.createdAt).format('hh:mm A');
    },
  },
  {
    field: 'details',
    headerName: 'Details',
    editable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <Link href={`/audittrails/${params.row.id}`}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Link>
      );
    },
  },
];

const LogListing = () => {
  const { data: logs } = useGetAuditTrailsQuery();

  return (
    <>
      {logs ? (
        <Box height={900} width="100%">
          <DataGrid
            rows={logs}
            columns={columns}
            pageSize={16}
            rowCount={16}
            density="compact"
            rowsPerPageOptions={[50]}
            disableSelectionOnClick
          />
          <Outlet />
        </Box>
      ) : null}
    </>
  );
};

export default LogListing;
