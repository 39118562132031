import { Typography, Box } from '@mui/material';
import { PaperTermsConditions } from '@nexxus/components';
import { useGetCurrentUserQuery } from 'api/api';
import MainLayout from 'components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

const CustomerTermsConditions = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser} = useGetCurrentUserQuery();


  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout
      {...props}
      current="settings"
      avatarTopBar={currentUser?.Client?.logoUrl ?? ''}
    >
      <Box>
        <Typography my="20px" variant="h1" color="primary">
          Terms and Conditions
        </Typography>
      </Box>
      <PaperTermsConditions />
    </MainLayout>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTermsConditions);
