import { FormControlLabel, Grid, useTheme } from '@mui/material';
import { InputCheckbox } from '@nexxus/components';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from 'constants/users';
import usePreventLocation from 'hooks/usePreventLocation';
import _ from 'lodash';
import { UserFields, usersInitialState } from 'pages/userAccessControl/userFields';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import UsersService from 'services/UsersService';
import { hookFormTrimValues } from 'utils/utils';

const AddUsersModal = ({ openModal, setOpenModal, roles, clients, onHandleSaved, userSelected, setUserSelected }) => {
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();
  const { fields, handleSave, errors, handleSubmit, validSubmit, resetForm, setValues, setInitialStateRequest } =
    UserFields(usersInitialState);
  const theme = useTheme();
  useEffect(() => {
    if (!openModal) {
      setHasChanged(false);
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        const trimmedFields = hookFormTrimValues(fields);
        if (!_.isEmpty(userSelected))
          await UsersService.updateUser(userSelected.id, {
            ...trimmedFields,
            clientId: ![ROLE_CUSTOMER, ROLE_SUPERCUSTOMER].includes(fields.role) ? '' : fields.clientId,
          });
        else await UsersService.createNewUser(trimmedFields);

        toast.success('User saved succesfully');
        onHandleSaved();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  useEffect(() => {
    if (fields.role !== '') {
      setValues((prev) => ({ ...prev, clientId: fields.clientId }));
    }
  }, [fields.clientId, fields.role, setValues]);

  useEffect(() => {
    if (!_.isEmpty(userSelected)) {
      const clientId = userSelected.Clients?.length > 0 ? userSelected.Clients[0].id : '';
      const { first_name, last_name, email, role, phone,isStaff } = userSelected;
      setValues((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
        isStaff
      }));
      setInitialStateRequest((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
        isStaff
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const handleCloseModal = useCallback(() => {
    if (hasChanged) {
      if (window.confirm(LEAVE_RESTRICTION_MESSAGE)) {
        setUserSelected({});
        setOpenModal(false);
        return true;
      } else {
        return false;
      }
    } else {
      setUserSelected({});
      setOpenModal(false);
    }
  }, [LEAVE_RESTRICTION_MESSAGE, hasChanged, setOpenModal, setUserSelected]);

  const handleChecked = useCallback(
    (id, checked) => {
      setHasChanged(true);
      handleSave({ target: { name: id, value: checked } });
    },
    [handleSave, setHasChanged]
  );
  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value !== fields[e.target.name]) {
        setHasChanged(true);
        handleSave(e);
      }
    },
    [fields, handleSave, setHasChanged]
  );

  const addNewUser = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="first_name"
            label="First Name"
            placeholder=""
            onChangeInput={handleInputChange}
            initialValue={fields?.first_name || ''}
            error={errors.first_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="last_name"
            label="Last Name"
            placeholder=""
            onChangeInput={handleInputChange}
            initialValue={fields?.last_name || ''}
            error={errors?.last_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="email"
            label="Email"
            type="email"
            onChangeInput={handleInputChange}
            error={errors.email?.txt}
            initialValue={fields?.email || ''}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <InputAutoCompleteV2
            id="role"
            label="Select Role"
            placeholder="Select Role"
            opts={roles}
            initialValue={fields.role}
            onChangeInput={handleInputChange}
            error={errors.role?.txt}
            defaultOption={false}
          />
        </Grid>

        {[ROLE_SUPERCUSTOMER, ROLE_CUSTOMER].includes(fields.role) && (
          <Grid item xs={12} sx={{}}>
            <InputAutoCompleteV2
              id="clientId"
              label="Select Client to Be Linked"
              placeholder="Select Client"
              opts={clients?.map((cl) => ({ _id: cl.id, label: cl.companyName }))}
              onChangeInput={handleInputChange}
              value={fields.clientId}
              initialValue={fields.clientId}
              error={errors.clientId?.txt}
              defaultOption={false}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={<InputCheckbox element={'isStaff'} isChecked={fields.isStaff} onChecked={handleChecked} />}
            label={'Is Sonatafy Staff ?'}
            sx={{}}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalFeedback
      variant="general"
      headerText={'Add New User'}
      headerBarColor={theme.palette.primary.main}
      open={openModal}
      onAccept={() => handleSubmit(fields)}
      onCancel={() => {}}
      onClose={handleCloseModal}
      disableCloseOnAccept={true}
    >
      {addNewUser()}
    </ModalFeedback>
  );
};

export default AddUsersModal;
