import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles({
  default: {
    color: '#FAFAFA',
    backgrounColor: '#63a4ff',
    backgroundImage: 'linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)',
  },
  info: {
    color: '#FAFAFA',
    backgrounColor: '#045de9',
    backgroundImage: 'linear-gradient(315deg, #045DE9 0%, #09C6F9 74%)',
  },
  primary: {
    color: '#FAFAFA',
    backgroundColor: '#2a2a72',
    backgroundImage: 'linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)',
  },
  error: {
    color: '#FAFAFA',
    backgrounColor: '#FF5050',
    backgroundImage: 'linear-gradient(315deg, #FF5050 0%, #ff6347 50%)',
  },
  warning: {
    color: '#FAFAFA',
    backgrounColor: '#ff9f43',
    backgroundImage: 'linear-gradient(315deg, #ff9f43 0%, #ffc371 74%)',
  },
  success: {
    color: '#FAFAFA',
    backgrounColor: '#00d084',
    backgroundImage: 'linear-gradient(315deg, #00d084 0%, #9edc82 74%)',
  },
});

export default function useBackgroundColor() {
  return styles();
}
