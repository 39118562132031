import { Autocomplete, Checkbox, TextField, Chip, FormHelperText, FormControl, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'utils/utils';
import { makeStyles } from '@mui/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const equalsCheck = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const stylesInputAutoCompleteV2 =  makeStyles({
  root: {
    '& > div > div.MuiInputBase-root.Mui-disabled': {
      background: '#dadada',
      '& input': { color: '#979797' },
    },
    '& > div > div.MuiInputBase-root.Mui-disabled fieldset': {

      borderWidth: '1px!important',
      borderColor: 'rgba(0, 0, 0, 0.26)!important',
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #FF5050!important',
    },
    '& .MuiChip-filled.Mui-disabled': { background: '#979797', opacity: '0.6' },
    '& .MuiChip-filled.Mui-disabled svg': { display: 'none' },
  },
});

const InputAutoCompleteV2 = ({
  id,
  label,
  tooltip,
  size,
  opts,
  initialValue,
  multiple = false,
  checkbox = false,
  autocomplete = true,
  error,
  placeholder,
  onChangeInput,
  isDisabled,
  styleLabel,
  sx,
  sxContainer,
  fullWidth,
  defaultOption = false,
  styleError,
  inputProps = {},
  sortOpts = true,
  disableClearable = false,
  typeRenderOptions = null,
  limitResults = false,
}) => {
  const [value, setValue] = useState(multiple ? [] : '');
  const [inputValue, setInputValue] = useState('');
  const [full, setFull] = useState(null);
  const [options, setOptions] = useState([]);
  const [placeholderVal, setPlaceholderVal] = useState(placeholder);
  const [limitLengthTags, setLimitLengthTags] = useState(25);
  const classes = stylesInputAutoCompleteV2();

  let inputRef = useRef();
  let autocompleteRef = useRef();
  const prevValue = usePrevious(value);

  useMemo(() => {
    if (options.length > 0 && initialValue && initialValue !== value) {
      if (!multiple) {
        const find = [...opts].find((option) => option === value);
        setValue(opts.find((option) => option._id === initialValue));
        setInputValue(find?.label || '');
        return;
      }

      if (multiple) {
        if (initialValue.length === 0) return setValue([]);
        // @ts-ignore
        else return setValue(options.filter((op) => initialValue.includes(op._id)));
      }
    } else {
      setValue(multiple ? [] : '');
      setInputValue('');
    }
  }, [options, initialValue, multiple, opts]);

  useEffect(() => {
    if (typeof prevValue !== 'undefined') {
      let obj;
      if (!multiple) obj = { target: { name: id, value: value?._id ?? '' } };
      else if (multiple && value.length === 0) obj = { target: { name: id, value: [] } };
      else obj = { target: { name: id, value: value?.map((val) => val._id) } };
      // prevent re render on capture initial value from request
      if (
        (!multiple && initialValue === value?._id) ||
        (multiple &&
          equalsCheck(
            options?.filter((op) => initialValue?.includes(op._id)),
            value
          ))
      ) {
        return;
      }
      onChangeInput(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useMemo(() => {
    if (typeof fullWidth !== 'undefined' && fullWidth === false) {
      setFull(false);
    } else {
      setFull(true);
    }
  }, [fullWidth]);

  useEffect(() => {
    if (limitResults) {
      if (inputRef?.current?.clientWidth > 300) setLimitLengthTags(25);
      else if (inputRef?.current?.clientWidth > 260) setLimitLengthTags(20);
      else if (inputRef?.current?.clientWidth > 210) setLimitLengthTags(15);
      else setLimitLengthTags(6); // < 155
    }
  }, [limitResults]);

  useMemo(() => {
    if (sortOpts === true) {
      opts.sort((a, b) => {
        if (b.label.includes('<')) {
          return 1;
        }
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    }
    setOptions(opts);

    if (opts && defaultOption === true) {
      const noSelectionOption = { _id: '', label: placeholder ?? 'No Selection' };
      setOptions([noSelectionOption, ...opts]);
    }
  }, [opts, defaultOption, sortOpts, placeholder]);

  const renderCheckBoxInput = useCallback(
    (props, option, { selected }) => {
      if (!checkbox) return null;
      return (
        <li {...props} key={option._id}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          {option.label}
        </li>
      );
    },
    [checkbox]
  );
  const renderOptions = useCallback((props, option) => {
    return (
      <li {...props} key={option._id}>
        {option.label}
      </li>
    );
  }, []);

  const handleRenderTags = useCallback(
    (values, getTagProps) => {
      if (typeRenderOptions === 'checkbox') {
        if (checkbox && values.length >= 2) {
          return `${values.length} selected`;
        }
        let displayList = values.map((item, index) => item.label).join(', ');
        return <span>{displayList}</span>;
      } else if (typeRenderOptions === 'tags') {
        return values.map((option, index) => (
          <Chip key={option._id} color="primary" label={option.label} {...getTagProps({ index })} />
        ));
      } else if (values.length === 1) return `${values[0].label}`;
      else {
        const joinValues = values.map((val) => val.label).join(', ');
        if (!limitResults) return joinValues;
        else return joinValues.substring(0, limitLengthTags) + '...';
      }
    },
    [checkbox, typeRenderOptions, limitLengthTags]
  );

  useMemo(() => {
    if (typeof value === 'object' && value.length === 0) return setPlaceholderVal(placeholder);
    else if (multiple && value.length > 0) setPlaceholderVal('');
    else setPlaceholderVal(placeholder);
  }, [multiple, placeholder, value]);

  const handleChange = (event, inputs, reason) => {
    if ('clear' === reason) {
      return setValue(multiple ? [] : '');
    } else {
      setValue(inputs);
    }
  };

  return (
    <FormControl
      fullWidth={full}
      sx={{ textAlign: 'left', justifyContent: 'space-between', ...sxContainer }}
      disabled={isDisabled}
    >
      {label && label !== '' && (
        <FormLabel error={!!error && error !== ''} htmlFor={id} sx={{ ...styleLabel, marginTop: 'auto' }}>
          {label} {tooltip}
        </FormLabel>
      )}
      <Autocomplete
        multiple={multiple}
        ref={autocompleteRef}
        id={id}
        options={options}
        getOptionDisabled={(option) => option.disabled}
        disableCloseOnSelect={multiple}
        classes={classes}
        sx={{
          ...sx,
          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FF5050!important',
          },
        }}
        disabled={isDisabled}
        disableClearable={disableClearable}
        // getOptionLabel={(option) => option?._id?.toString() || ''}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option._id == value._id}
        // isOptionEqualToValue={(option, value) => option.label == value.label}
        value={value || null}
        renderOption={checkbox ? renderCheckBoxInput : renderOptions}
        fullWidth={true}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            InputProps={{ ...inputProps, ...params.InputProps }}
            error={!!error}
            placeholder={placeholderVal}
            ref={inputRef}
          />
        )}
        onChange={handleChange}
        renderTags={handleRenderTags}
      />
      {error && (
        <FormHelperText error id={id} sx={{ styleError }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

InputAutoCompleteV2.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  label: PropTypes.string,
  tooltip: PropTypes.element,
  size: PropTypes.string,
  sx: PropTypes.object,
  sxContainer: PropTypes.object,
  autocomplete: PropTypes.bool,
  styleLabel: PropTypes.object,
  inputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  opts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  renderValue: PropTypes.func,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  onChangeInput: PropTypes.func,
  styleError: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default InputAutoCompleteV2;
