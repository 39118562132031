import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_HUMAN_RESOURCES,
  ROLE_SUPERCUSTOMER,
  ROLE_SUPERTAS,
  ROLE_TAS,
} from 'constants/users';
import Joi from 'joi';
import userService from 'services/userService';
import { setValidationMessages } from 'utils/validationMessages';

const methodEmailValid = async (value, helpers) => {
  if (helpers.prefs.initialStateRequest.email !== value) {
    try {
      await userService.validateNewEmail(value);
    } catch (e) {
      throw Object.assign(new Error('Email is already taken!'), {
        details: [
          {
            context: {
              label: 'email',
              key: 'email',
            },
            code: 422,
            type: 'any.invalid',
            path: ['email'],
            message: 'Email is already taken',
          },
        ],
      });
    }
  }
  return true;
};

export const schemaUser = {
  first_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  last_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  role: Joi.string()
    .required()
    .valid(
      ROLE_CUSTOMER,
      ROLE_ADMIN,
      ROLE_TAS,
      ROLE_SUPERTAS,
      ROLE_SUPERCUSTOMER,
      ROLE_ACCOUNT_MANAGER,
      ROLE_HUMAN_RESOURCES
    )
    .messages({ ...setValidationMessages(['string', 'required']), 'any.only': 'Field required' }),
  clientId: Joi.when('role', {
    // is: Joi.string(),
    is: Joi.valid(ROLE_CUSTOMER, ROLE_SUPERCUSTOMER),
    then: Joi.number().integer(),
    otherwise: Joi.any().optional().allow(''),
  }).messages(setValidationMessages(['number', 'required'])),
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .external(methodEmailValid, 'email validation')
    .messages(setValidationMessages(['string', 'required'])),
  phone: Joi.string().optional().allow(''),
  isStaff: Joi.bool(),
};
