import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import { styled, useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const MainModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#ffffff',
  textAlign: 'center',
  borderRadius: '12px',
  overflow: 'hidden',
});

const ModalFeedback = ({
  open,
  onClose,
  onCancel,
  onAccept,
  variant,
  headerText,
  titleText,
  bodyText,
  children,
  iconVariant,
  iconColor,
  headerBarColor,
  btnConfirmText,
  btnCancelText,
  disableBtnConfirm,
  disableBtnCancel,
  disableCloseOnAccept = false,
  modalWidth,
  buttonsDirection,
  variantButtonConfirm,
  variantButtonCancel,
  iconConfirmBtn,
  iconCancelBtn,
  buttonColorConfirm,
  buttonColorCancel,
  disabledDivider,
  sx,
}) => {
  const theme = useTheme();

  const handleCancel = (e) => {
    onCancel();
    onClose(e, 'cancelButton');
  };
  const handleAccept = (e) => {
    onAccept();
    if (!disableCloseOnAccept) onClose(e, 'acceptButton');
  };
  const handleCloseIcon = (e) => {
    onClose(e, 'closeIcon');
  };

  const finalModalWidth = useMemo(() => {
    return modalWidth ? modalWidth : 450;
  }, [modalWidth]);

  const finaVariantType = useMemo(() => {
    const typeData =
      (variant === 'success' && {
        icon: <CheckCircleIcon sx={{ fontSize: '4em', color: 'success.main' }} />,
        color: 'success.main',
      }) ||
      (variant === 'info' && {
        icon: <ErrorOutlineRoundedIcon sx={{ fontSize: '4em', color: 'info.main' }} />,
        color: 'info.main',
      }) ||
      (variant === 'warning' && {
        icon: <NewReleasesRoundedIcon sx={{ fontSize: '4em', color: 'nexxusYellow.main' }} />,
        color: 'nexxusYellow.main',
      }) ||
      (variant === 'error' && {
        icon: <CancelRoundedIcon sx={{ fontSize: '4em', color: 'error.main' }} />,
        color: 'error.main',
      }) ||
      (variant === 'general' && {
        color: headerBarColor ?? '#ffffff',
      });
    return typeData;
  }, [variant, headerBarColor]);

  const finalColorIcon = useMemo(() => {
    const variantsData = {
      success: {
        icon: <CheckCircleIcon sx={{ fontSize: '4em', color: iconColor ? iconColor : 'success.main' }} />,
        color: 'success.main',
      },
      info: {
        icon: <ErrorOutlineRoundedIcon sx={{ fontSize: '4em', color: iconColor ? iconColor : 'info.main' }} />,
        color: 'info.main',
      },
      warning: {
        icon: <NewReleasesRoundedIcon sx={{ fontSize: '4em', color: iconColor ? iconColor : 'nexxusYellow.main' }} />,
        color: 'nexxusYellow.main',
      },
      error: {
        icon: <CancelRoundedIcon sx={{ fontSize: '4em', color: iconColor ? iconColor : 'error.main' }} />,
        color: 'error.main',
      },
    };
    return variantsData;
  }, [iconColor]);

  return (
    <div>
      <Modal
        sx={sx}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainModalBox sx={{ width: finalModalWidth, maxWidth: '90%' }}>
          <Box
            sx={{
              backgroundColor: finaVariantType.color,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingY: 1,
              paddingX: 2,
              minHeight: '52px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                flex: 1,
                color: variant === 'general' ? theme.palette.getContrastText(finaVariantType.color) : 'white',
                fontWeight: 'bolder',
                textAlign: 'center',
                margin: 'auto',
                overflowWrap: 'break-word',
                maxWidth: 'calc(100% - 2.25rem)',
              }}
            >
              {headerText}
            </Typography>
            <IconButton color="primary" sx={{ position: 'absolute', right: '.5rem' }} onClick={handleCloseIcon}>
              <CloseIcon
                sx={{
                  color: variant === 'general' ? theme.palette.getContrastText(finaVariantType.color) : 'white',
                  fontSize: '1.5rem',
                }}
              />
            </IconButton>
          </Box>
          <Box sx={{ p: 4, py: 2, maxHeight: '80vh', overflow: 'auto' }}>
            {(variant !== 'general' || (variant === 'general' && iconVariant)) && (
              <Stack justifyContent="center" alignItems="center" sx={{ pb: 3 }}>
                {variant === 'general' ? finalColorIcon[iconVariant].icon : finaVariantType.icon}
              </Stack>
            )}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  flex: 1,
                  color: 'black',
                  fontWeight: 'bolder',
                  textAlign: 'center',
                  overflowWrap: 'break-word',
                }}
              >
                {titleText}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  flex: 1,
                  color: 'black',
                  fontWeight: 400,
                  textAlign: 'center',
                  overflowWrap: 'break-word',
                }}
              >
                {bodyText}
              </Typography>
              {variant === 'general' && children}
            </Box>
          </Box>
          {(!disableBtnCancel || !disableBtnConfirm) && (
            <>
              {!disabledDivider && <Divider variant="middle" sx={{ width: '100%', margin: 'auto' }} />}
              <Grid sx={{ pb: 1.5, px: 1.5 }}>
                <Stack
                  direction="row"
                  sx={{
                    textAlign: 'left',
                    pt: 1.5,
                    gap: '1em',
                    justifyContent: buttonsDirection ?? 'flex-end',
                    width: '100%',
                  }}
                >
                  {!disableBtnCancel && (
                    <Button
                      sx={{ borderRadius: 0, px: 0 }}
                      variant={variantButtonCancel ?? 'text'}
                      color={buttonColorCancel ?? 'warning'}
                      onClick={handleCancel}
                    >
                      <Typography
                        sx={{
                          fontSize: '15px',
                          fontWeight: 'bold',
                          paddingRight: 1,
                          paddingLeft: 1,
                        }}
                      >
                        {btnCancelText ? btnCancelText : 'CANCEL'}
                      </Typography>
                    </Button>
                  )}
                  {!disableBtnConfirm && (
                    <Button
                      className="confirm"
                      sx={{ borderRadius: 0, px: 3 }}
                      variant={variantButtonConfirm ?? 'contained'}
                      color={buttonColorConfirm ?? 'success'}
                      onClick={handleAccept}
                    >
                      {iconConfirmBtn}
                      <Typography
                        sx={{
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                      >
                        {btnConfirmText ? btnConfirmText : 'OK'}
                      </Typography>
                    </Button>
                  )}
                </Stack>
              </Grid>
            </>
          )}
        </MainModalBox>
      </Modal>
    </div>
  );
};

ModalFeedback.defaultProps = {
  modalWidth: 450,
  disabledDivider: false,
};

ModalFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'info', 'error', 'general']),
  headerText: PropTypes.string,
  titleText: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.node,
  iconVariant: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
  iconColor: PropTypes.string,
  headerBarColor: PropTypes.string,
  btnConfirmText: PropTypes.string,
  btnCancelText: PropTypes.string,
  disableBtnConfirm: PropTypes.bool,
  disableBtnCancel: PropTypes.bool,
  disableCloseOnAccept: PropTypes.bool,
  modalWidth: PropTypes.number,
  buttonsDirection: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  variantButtonConfirm: PropTypes.oneOf(['text', 'contained', 'outlined']),
  variantButtonCancel: PropTypes.oneOf(['text', 'contained', 'outlined']),
  iconConfirmBtn: PropTypes.node,
  iconCancelBtn: PropTypes.node,
  buttonColorConfirm: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning', 'string']),
  buttonColorCancel: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning', 'string']),
  disabledDivider: PropTypes.bool,
  sx: PropTypes.object,
};

export default ModalFeedback;
