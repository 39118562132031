import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_API_BACKEND;

export default axios.create({
  baseURL: apiEndpoint,
});

export const axiosPrivate = axios.create({
  baseURL: apiEndpoint,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
