import { useState } from 'react';
import axios, { axiosPrivate } from '../api/axios';

const useSignedS3Url = () => {
  const [isUploading, setIsUploading] = useState(false);

  /**
   *
   * @function
   * @param {Object} params
   * @param {File | string} params.file
   * @param {String} params.contentType
   * @param {String} params.path
   * @returns {Promise}
   */
  const handleFileUpload = ({ file, contentType, path = '' }) => {
    setIsUploading(true);
    return new Promise((resolve, reject) => {
      axiosPrivate
        .put('/v1/signed-s3-url/upload', {
          contentType: file.type,
          filename: file.name,
          path: path,
        })
        .then(({ data: { url } }) => {
          if (url) {
            uploadToS3(url, file, contentType)
              .then((response) => {
                setIsUploading(false);
                const object_url = url.split('?')[0];
                resolve({ ...response, object_url, originalName: file.name });
              })
              .catch((error) => {
                setIsUploading(false);
                reject(error);
              });
          }
        })
        .catch((error) => {
          // console.log('err', error);
        });
    });
  };

  const uploadToS3 = (url, file, contentType) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('Content-Type', contentType);
      formData.append('file', file);

      axios
        .put(url, file, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(({ data }) => {
          resolve({ url, file, ...data });
        })
        .catch((error) => {
          // console.log('error', error);
          reject(error);
        });
    });
  };

  const getSignedDownloadUrl = async (url, keepOriginalName, originalName) => {
    const body = { url };
    if (keepOriginalName) {
      body.keepOriginalName = true;
      body.originalName = originalName;
    }
    return new Promise((resolve, reject) => {
      axiosPrivate
        .put('/v1/signed-s3-url/download', body)
        .then(({ data }) => {
          resolve(data.url);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return { handleFileUpload, isUploading, getSignedDownloadUrl };
};
export default useSignedS3Url;
