import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import SkillPills from '../../../SkillPills/SkillPills';

const CandidateSkills = (props) => {
  const [candSkills, setCandSkills] = useState([]);

  useEffect(() => {
    setCandSkills(props.candidate.Skills.slice(0, 5));
  }, [props.candidate.Skills]);

  return (
    <Box sx={{ width: 182, mx: 'auto' }}>
      {candSkills &&
        candSkills.map((_, index) => {
          const { name: candidateName, id: skillId } = props.candidate.Skills[index];
          const { rate: candidateRate } =
            props.candidate.Candidate_Skill?.filter((skill) => skill.SkillId === skillId)[0] ||
            props.candidate.Candidate_Skills?.filter((skill) => skill.SkillId === skillId)[0] ||
            0;
          return (
            <Stack direction="column" justifyContent="center" key={index} sx={{ mt: -0.5, mb: 1.8 }}>
              <Stack direction="column" justifyContent="space-evenly">
                <Box>
                  <Typography
                    sx={{
                      ml: 0.25,
                      textAlign: 'left',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: 12,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {candidateName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <SkillPills
                    rate={candidateRate}
                    stylePill={{
                      height: 5,
                      width: 34,
                      marginLeft: 2,
                    }}
                    disabled={true}
                    labelPill={false}
                  />
                </Box>
              </Stack>
            </Stack>
          );
        })}
    </Box>
  );
};

export default CandidateSkills;
