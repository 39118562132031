import http from './httpService';

function getTags() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/tags/all');
}

function getTagsWithCount() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/tags/all-with-use-count');
}

function createTag(tag) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/tags', tag);
}

function deleteTag(tagId) {
  return http.delete(process.env.REACT_APP_API_BACKEND + '/v1/tags/' + tagId);
}

function getSkill(skill) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/skills/by/${skill}`);
}
function getSkillsQuery(params) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/skills/`, { params });
}

function createSkill(skill) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/skills', skill);
}

function updateSkill(skillId, body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/skills/${skillId}`, body);
}

const AdministrationService = {
  getTags,
  createTag,
  deleteTag,
  getTagsWithCount,
  getSkill,
  createSkill,
  updateSkill,
  getSkillsQuery,
};

export default AdministrationService;
