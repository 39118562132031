import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import SocialMediaLinks from 'components/Auth/SocialMedaLinks';
import forbes from '../../assets/img/forbes.png';
import logo from '../../assets/img/logo-1.png';
import video from '../../assets/img/nexxus.mp4';

const AuthSkeleton = (props) => {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ textAlign: 'center', background: theme.palette.gradients.dark.tb, minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ marginTop: 4, marginBottom: 4, display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="" className="" width={320} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ minWidth: 400, maxWidth: 450 }}>
              <CardContent>{props.children}</CardContent>
            </Card>
          </Box>
          <Box sx={{ marginTop: 4, marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
            <img src={forbes} alt="" className="" width={270} />
          </Box>
          <SocialMediaLinks />
          <Typography variant="body2" color="white" textTransform="uppercase" align="center" my={2}>
            COPYRIGHT @2022 SONATAFY TECHNOLOGY, LLC. ALL RIGHTS RESERVED.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <video autoPlay muted loop id="login-video">
              <source src={video} type="video/mp4" />
            </video>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthSkeleton;
