import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

const ProjectPopover = ({ project }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="text" onClick={handleClick} sx={{ color: theme.palette.primary.lighter }}>
        {'VIEW ALL'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: 0,
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ width: '300px', p: 1 }}>
          <Typography sx={{ textAlign: 'center', color: '#B2B2B2', fontSize: '10px', fontWeight: 'bold' }}>
            PROJECT DESIRED SKILLS
          </Typography>
          {project.Skills.map((skill, index) => (
            <Chip
              key={index}
              sx={{ marginLeft: 1, marginTop: 1 }}
              label={`${skill.name} (${skill.Project_Skill.year_experience})`}
              variant="select-tag"
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default ProjectPopover;
