import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

//-----------------------------------------------------------------------------

const BreadcrumbsContext = createContext({
  breadcrumbs: [],
  setBreadcrumbs: (arg) => arg,
});

//-----------------------------------------------------------------------------

const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const value = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);
  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
};

export { BreadcrumbsContext, BreadcrumbsProvider };
