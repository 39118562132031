import { CANDIDATEPROJECT_STATUS } from './candidate_project';

const initialStateFeedback = {
  ARCHIVED: {},
  PANEL_INTERVIEW: {},
  PANEL_INTERVIEW_REJECTED: {},
  SKILLS_ASSESSMENT: {},
  SKILLS_ASSESSMENT_REJECTED: {},
  SUBMITTED_CUSTOMER: {},
  SUBMITTED_CUSTOMER_REJECTED: {},
  CUSTOMER_INTERVIEW: {},
  CUSTOMER_INTERVIEW_REJECTED: {},
  OFFER_EXTENDED: {},
  OFFER_ACCEPTED: {},
  OFFER_REJECTION: {},
  HIRED: {},
  DROPPED_OUT: {},
};

const GROUP_PROJECT_STATUS_REJECTED = [
  CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.DROPPED_OUT,
  CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT_REJECTED,
  CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED,
  CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
];

const excludeForTotalCounters = [
  CANDIDATEPROJECT_STATUS.ARCHIVED,
  CANDIDATEPROJECT_STATUS.OFFER_EXTENDED,
  CANDIDATEPROJECT_STATUS.OFFER_ACCEPTED,
  CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
];

export { initialStateFeedback, excludeForTotalCounters, GROUP_PROJECT_STATUS_REJECTED };
