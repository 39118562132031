import Box from '@mui/material/Box';
import MainLayout from 'components/MainLayout/MainLayout';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ProjectFormView from 'components/Projects/Mode/ProjectFormView';
import ProjectHeaderCards from 'components/Projects/Mode/ProjectHeaderCards';
import ProjectHeaderCustomer from 'components/Projects/Mode/ProjectHeaderCustomer';
import projectService from 'services/projectService';
import { PROJECT_STATUS } from 'utils/staticData';
import { useGetCustomersQuery, useGetRecruitersQuery } from 'api/api';

const formattedDate = (date) => moment(date).format('MM/DD/YYYY'); // eslint-disable-line

const Project = (props) => {
  const history = useNavigate();

  const { data: recruiters } = useGetRecruitersQuery();
  const { data: customers } = useGetCustomersQuery();

  const [candidatesCountStatus, setCandidatesCountStatus] = useState({
    ARCHIVED: 0,
    CUSTOMER_INTERVIEW: 0,
    HIRED: 0,
    PANEL_INTERVIEW: 0,
    REJECTED_POST_INTERVIEW: 0,
    REJECTED_PRE_INTERVIEW: 0,
    SKILLS_ASSESSMENT: 0,
    SUBMITTED_CUSTOMER: 0,
  });

  const [clientSelected, setClientSelected] = useState({});
  const { ProjectId } = useParams();

  const [project, setProject] = useState({});

  // init hook
  useEffect(() => {
    (async () => {})();
  }, []);

  // project hook
  useEffect(() => {
    (async () => {
      if (ProjectId) {
        try {
          const { data } = await projectService.getProject(ProjectId);
          if (!data) history('/404');
          const { data: countsStatus } = await projectService.getProjectCandidatesCount(ProjectId);
          setProject(data);
          setCandidatesCountStatus(countsStatus);
        } catch (e) {
          history('/404');
        }
      }
    })();
  }, [ProjectId]); // eslint-disable-line

  // hoook set client for project
  useEffect(() => {
    (async () => {
      if (project.ClientId !== '') {
        const clientSelected = customers.find((client) => client.id === project.ClientId);
        setClientSelected(clientSelected);
      }
    })();
  }, [project.ClientId]); // eslint-disable-line

  return (
    <MainLayout {...props} current="projects">
      <Box>
        <Grid container>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            {clientSelected && <ProjectHeaderCustomer client={clientSelected} />}
          </Grid>
          <Grid item xs={8}>
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'nexxusGreenMid.main',
                }}
              >
                STATUS:{' '}
                {project.status &&
                  Object.keys(PROJECT_STATUS)
                    .find((key) => PROJECT_STATUS[key] === project.status)
                    .replace(/_/g, ' ')}
              </Typography>
            </Stack>
            <ProjectHeaderCards candidatesCountStatus={candidatesCountStatus} />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 1, marginTop: 3 }} />
        <ProjectFormView recruiters={recruiters} projectDB={project}></ProjectFormView>
        <Stack>
          <Button
            component={Link}
            to={`/accountmanager/projects`}
            variant="text"
            sx={{ mt: 2, '&:hover': { background: 'none', textDecoration: 'underline' } }}
          >
            <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '1.5em', color: '#009eff' }}>
              RETURN TO PROJECTS
            </Typography>
          </Button>
        </Stack>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
