import { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { ENG_ASSESSMENT_STATUS } from 'constants/candidate';
import { useInitEmmersionSyncMutation, useGetCandidateByIdQuery } from 'api/api';
import MoreUploadsTab from 'components/Candidates/UploadsCandidateTab/MoreUploadsTab';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import AdditionalCertificates from '../Candidates/UploadsCandidateTab/AdditionalCertificates';
import CandidateAudios from './CandidateAudios';
import DocumentFiles from './DocumentFiles';
import { LoadingButton } from '@mui/lab';

const InterviewUploads = ({ candidateId, isDisabled, setHasChanged, hasChanged }) => {
  const [syncRetries, setSyncRetries] = useState(0);

  const {
    data: candidate,
    status: candidateStatus,
    refetch: candidateRefetch,
  } = useGetCandidateByIdQuery(candidateId, { skip: !candidateId });

  const [isEmmersionSynced, setIsEmmersionSynced] = useState(false);
  const [emmersionSyncMutation] = useInitEmmersionSyncMutation();
  const [timeOut, setTimeOut] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, [timeOut]);

  const checkEmmersionSyncProgress = () => {
    const timeout = setTimeout(() => {
      candidateRefetch();
    }, 10000);
    setTimeOut(timeout);
  };

  const handleEmmersionSync = () => {
    setIsEmmersionSynced(true);
    emmersionSyncMutation({
      candidateId,
    })
      .unwrap()
      .then((res) => {
        checkEmmersionSyncProgress();
      })
      .catch((err) => {
        if (err.status === 404) {
          toast.error('No assessment available.');
        } else {
          const { data } = err;
          if (data?.message) toast.error(data?.message);
          else toast.error('Emmersion sync failed');
        }
        setIsEmmersionSynced(false);
      });
  };

  useEffect(() => {
    if (candidateStatus === 'fulfilled' && isEmmersionSynced) {
      console.log('eng_assessment_status', candidate?.eng_assessment_status);
      if (candidate?.eng_assessment_status === ENG_ASSESSMENT_STATUS.PROCESSING && syncRetries < 4) {
        console.log('fullfilled and new sync');
        setSyncRetries(syncRetries + 1);
        checkEmmersionSyncProgress();
      }

      if (syncRetries === 4) {
        setIsEmmersionSynced(false);
        setSyncRetries(0);
      }

      if (candidate?.eng_assessment_status === ENG_ASSESSMENT_STATUS.PROCESSED) {
        setIsEmmersionSynced(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateStatus]);

  useEffect(() => {
    candidateRefetch();
  }, [hasChanged, candidateRefetch]);

  // Emmersion Modal
  const [openConfirmEmmersion, setOpenConfirmEmmersion] = useState(false);
  const onCloseEmmersionModal = () => {};
  const onAcceptEmmersion = () => {
    setOpenConfirmEmmersion(false);
    handleEmmersionSync();
  };

  return (
    <Grid container spacing={2}>
      <ModalFeedback
        key="emmersionSync"
        variant="warning"
        headerText="Sync with Emmersion"
        titleText="Are you sure you want to Sync with Emmersion?"
        open={openConfirmEmmersion}
        onCancel={() => setOpenConfirmEmmersion(false)}
        onClose={onCloseEmmersionModal}
        onAccept={onAcceptEmmersion}
      />
      <Grid item container xs={12} md={6} display="flex" direction="column" justifyContent="center">
        <Typography variant="subtitle" fontSize={18} align="center" marginBottom={2}>
          DOCUMENTATION
        </Typography>
        {candidate ? <DocumentFiles candidate={candidate} isDisabled={isDisabled} /> : null}
        <LoadingButton
          onClick={() => setOpenConfirmEmmersion(true)}
          variant="contained"
          sx={{
            marginTop: 4,
            marginX: 'auto',
            width: 220,
          }}
          loading={isEmmersionSynced}
          disabled={isEmmersionSynced}
        >
          {!isEmmersionSynced ? 'Sync with Emmersion' : 'Sync in progress'}
        </LoadingButton>
        {isEmmersionSynced && (
          <Typography variant="caption" textAlign="center" color="grey">
            Process will take a few seconds
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {candidate ? <CandidateAudios candidate={candidate} /> : null}
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={4} direction={'row'} alignItems="stretch">
          <Grid container sx={{ alignSelf: 'flex-start' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
                NEW ATTACHMENT TO CANDIDATE
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MoreUploadsTab isDisabled={isDisabled} setHasChanged={setHasChanged}></MoreUploadsTab>
            </Grid>
          </Grid>
          <Box
            sx={{ height: { xs: '2px', md: 'inherit' }, width: { xs: '100%', md: '2px' }, backgroundColor: 'grey.main' }}
          />
          <Grid container sx={{ alignSelf: 'flex-start' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
                ADDITIONAL PROFESSIONAL CERTIFICATES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AdditionalCertificates isDisabled={isDisabled} setHasChanged={setHasChanged}></AdditionalCertificates>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default InterviewUploads;
