import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as bubble } from '../../assets/icons/bubble-icon.svg';
import { ReactComponent as download } from '../../assets/icons/download.svg';
import { ReactComponent as lens } from '../../assets/icons/lens-icon.svg';
import { ReactComponent as pdf } from '../../assets/icons/pdf-icon.svg';
import { ReactComponent as remove } from '../../assets/icons/remove-icon.svg';
import { ReactComponent as skillAssessment } from '../../assets/icons/skill_assessment.svg';
import { ReactComponent as skillAssessmentSmall } from '../../assets/icons/skill_assessment_small.svg';
import { ReactComponent as hired } from '../../assets/icons/hired.svg';
import { ReactComponent as hiredSmall } from '../../assets/icons/hired_small.svg';
import { ReactComponent as interview } from '../../assets/icons/interview.svg';
import { ReactComponent as interviewSmall } from '../../assets/icons/interview_small.svg';
import { ReactComponent as qualificationInterview } from '../../assets/icons/qualification_interview.svg';
import { ReactComponent as qualificationInterviewSmall } from '../../assets/icons/qualification_interview_small.svg';
import { ReactComponent as rejected } from '../../assets/icons/reject.svg';
import { ReactComponent as rejectedSmall } from '../../assets/icons/rejected_small.svg';
import { ReactComponent as submitted } from '../../assets/icons/submitted.svg';
import { ReactComponent as submittedSmall } from '../../assets/icons/submitted_small.svg';
import { ReactComponent as chevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as view } from '../../assets/icons/view.svg';
import { ReactComponent as share } from '../../assets/icons/share.svg';
import { ReactComponent as kebab } from '../../assets/icons/kebab.svg';

export const LensIcon = (props) => <SvgIcon {...props} component={lens} inheritViewBox />;
export const BubbleIcon = (props) => <SvgIcon {...props} component={bubble} inheritViewBox />;
export const RemoveIcon = (props) => <SvgIcon {...props} component={remove} inheritViewBox />;
export const PdfIcon = (props) => <SvgIcon {...props} component={pdf} inheritViewBox />;
export const DownloadIcon = (props) => <SvgIcon {...props} component={download} inheritViewBox />;
export const SkillAssessmentIcon = (props) => (
  <SvgIcon {...props} component={props?.small ? skillAssessmentSmall : skillAssessment} inheritViewBox />
);
export const HiredIcon = (props) => (
  <SvgIcon
    {...props}
    className={props?.small ? 'small' : 'normal'}
    sx={{ '&.small path:not(:first-child)': { stroke: 'rgb(152, 252, 158)', color: 'rgb(152, 252, 158)' } }}
    component={props?.small ? hiredSmall : hired}
    inheritViewBox
  />
);
export const InterviewIcon = (props) => (
  <SvgIcon {...props} component={props?.small ? interviewSmall : interview} inheritViewBox />
);
export const QualificationInterviewIcon = (props) => (
  <SvgIcon {...props} component={props?.small ? qualificationInterviewSmall : qualificationInterview} inheritViewBox />
);
export const RejectIcon = (props) => (
  <SvgIcon {...props} component={props?.small ? rejectedSmall : rejected} inheritViewBox />
);
export const SubmitIcon = (props) => (
  <SvgIcon {...props} component={props?.small ? submittedSmall : submitted} inheritViewBox />
);
export const RightArrow = (props) => <SvgIcon {...props} component={chevronRight} inheritViewBox />;
export const ViewIcon = (props) => <SvgIcon {...props} component={view} inheritViewBox />;
export const ShareIcon = (props) => <SvgIcon {...props} component={share} inheritViewBox />;
export const KebabIcon = (props) => <SvgIcon {...props} component={kebab} inheritViewBox />;
