import {
  Table,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  TableBody,
  Divider,
  TableSortLabel,
  styled,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { withStyles } from '@mui/styles';
import { useCallback } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

const TableIconSorting = styled(KeyboardArrowUpIcon)(({ theme }) => ({
  color: `${theme.palette.nexxusGray.main} !important`,
}));

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ManageCustomerTable = ({ children, user, sorting, setSorting, setCustomLoader }) => {
  const createSortHandler = useCallback(
    (fieldSort) => {
      setCustomLoader(true);
      if (sorting.sort !== fieldSort) setSorting({ sort: fieldSort, order: 'asc' });
      else setSorting({ sort: fieldSort, order: sorting.order === 'asc' ? 'desc' : 'asc' });
    },
    [setCustomLoader, setSorting, sorting]
  );

  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '250px' }}>
              <TableSortLabel
                active={sorting.sort === 'companyName'}
                direction={sorting.sort === 'companyName' ? sorting.order : 'asc'}
                onClick={() => createSortHandler('companyName')}
                IconComponent={TableIconSorting}
                sx={{
                  textAlign: 'left',
                  '& svg': { opacity: 0.3, '&:hover': { opacity: 0.3 } },
                  transition: 'transform 3.3s',
                }}
              >
                <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                  COMPANY
                </Typography>
                {sorting.sort === 'companyName' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sorting.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: '250px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                WEBSITE{' '}
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1, width: '85px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                OFFERED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1, width: '85px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                ACCEPTED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1, width: '85px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                DENIED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1, textAlign: 'center' }}>
              <TableSortLabel
                active={sorting.sort === 'createdAt'}
                direction={sorting.sort === 'createdAt' ? sorting.order : 'asc'}
                onClick={() => createSortHandler('createdAt')}
                IconComponent={TableIconSorting}
                sx={{ textAlign: 'center', '& svg': { opacity: 0.3, '&:hover': { opacity: 0.3 } } }}
              >
                <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                  DATE CREATED
                </Typography>
                {sorting.sort === 'createdAt' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sorting.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ paddingX: 1, textAlign: 'center' }}>
              <TableSortLabel
                active={sorting.sort === 'updatedAt'}
                direction={sorting.sort === 'updatedAt' ? sorting.order : 'asc'}
                onClick={() => createSortHandler('updatedAt')}
                IconComponent={TableIconSorting}
                sx={{ textAlign: 'center', '& svg': { opacity: 0.3, '&:hover': { opacity: 0.3 } } }}
              >
                <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                  LAST UPDATED
                </Typography>
                {sorting.sort === 'updatedAt' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sorting.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ paddingX: 1, width: '80px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                {user.role !== 'tas' ? 'EDIT' : ''}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={8}>
              <Divider sx={{ flexGrow: 1, borderBottomWidth: '2px', marginBottom: 3 }} />
            </TableCell>
          </TableRow>
          {children?.map((child) => child)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomerTable);
