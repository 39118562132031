import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { Fab, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { HubspotContactForm } from '@nexxus/components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

const WrapperCustomer = ({ modalHubspot, setModalHubspot, ...restProps }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [modalHubspot2, setModalHubspot2] = useState(false);
  const isMobileViewPort = useMediaQuery(theme.breakpoints.down('sm'));
  const isInContactSupportPage = window.location.pathname.includes('contact-form');

  useEffect(() => {
    if (modalHubspot || modalHubspot2) return setOpenModal(true);
    setOpenModal(false);
  }, [modalHubspot, modalHubspot2]);

  const handleClose = () => {
    setModalHubspot(false);
    setModalHubspot2(false);
  };

  const FloatingIconButton = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(12),
    right: theme.spacing(2),
  }));
  return (
    <>
      <ModalFeedback
        variant="general"
        headerText="Contact Form Support"
        disableBtnCancel={true}
        disableBtnConfirm={true}
        headerBarColor={theme.palette.primary.main}
        modalWidth={isMobileViewPort ? 0 : 800}
        open={openModal}
        onClose={handleClose}
        onCancel={handleClose}
      >
        <HubspotContactForm />
      </ModalFeedback>
      {!isMobileViewPort && !isInContactSupportPage && (
        <FloatingIconButton color="primary" aria-label="support" size="medium" onClick={() => setModalHubspot2(true)}>
          <ContactSupportOutlinedIcon sx={{ width: '1.3em', height: '1.3em' }} />
        </FloatingIconButton>
      )}
      {restProps.children}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperCustomer);
