import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import useIsMounted from '../../hooks/useIsMounted';

/**
 * Simple wrapper for dynamic SVG import hook. You can implement your own wrapper,
 * or even use the hook directly in your components.
 */
const SvgItem = ({ name, ...rest }) => {
  const [dataSrc, setDataSrc] = useState('');
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const importIcon = async () => {
      const data = await import(`../../assets/icons/${name}.svg`);
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setDataSrc(data.default);
      }
    };
    importIcon();
  }, [isMountedRef, name]);

  // @ts-ignore
  return <SVG src={dataSrc} fill="currentColor" {...rest} />;
};

export default SvgItem;
