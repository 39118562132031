import { Container } from '@mui/material';
import { styled } from '@mui/system';

const MainContainerStyled = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0px 10px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px 0px',
  },
}));

const MainContainer = ({ children }) => {
  return (
    <MainContainerStyled maxWidth="sxl" disableGutters>
      {children}
    </MainContainerStyled>
  );
};

export default MainContainer;
