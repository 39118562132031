import { CircularProgress, Stack, Typography, styled } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

const TitleText = styled(Typography)({
  fontSize: '20px',
  fontWeight: '600',
  paddingBottom: 0,
});

const CustomText = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
});

const FileUploader = ({
  openModalUploader,
  setOpenModalUploader,
  progressStatus,
  setFile,
  setFileType,
  setProgressStatus,
}) => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const cvRef = useRef();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();

    setDragActive(false);
    if (
      [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(e?.dataTransfer?.files?.[0]?.type)
    ) {
      handleNewFile(e.dataTransfer.files[0]);
    }
  };

  const handleNewFile = (file) => {
    setProgressStatus('in-progress');
    setFile(file);
    setFileType('pdf');
  };

  const cvChangeHandler = async (event) => {
    if (
      [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(event?.dataTransfer?.files?.[0]?.type)
    ) {
      toast.error('Please upload a valid PDF file');
      return false;
    }
    handleNewFile(event.target.files[0]);
  };

  const handleClickUpload = () => {
    cvRef.current.click();
  };

  const renderView = useMemo(() => {
    if (progressStatus === 'initial') {
      setDisabledBtn(false);
      return (
        <>
          <InsertDriveFileOutlinedIcon sx={{ width: '96px', height: '96px' }} htmlColor="#0242D1" />
          <TitleText>UPLOAD RESUME</TitleText>
          <CustomText>Upload or drag and drop</CustomText>
          <CustomText>PDF (prefered), DOCX, DOC</CustomText>
        </>
      );
    }
    if (progressStatus === 'in-progress') {
      setDisabledBtn(true);
      return (
        <>
          <CircularProgress sx={{ marginBottom: '59px' }} />
          <TitleText>Validating File</TitleText>
        </>
      );
    }
    if (progressStatus === 'success') {
      setDisabledBtn(true);
      return (
        <>
          <InsertDriveFileOutlinedIcon sx={{ width: '96px', height: '96px', marginBottom: '52px' }} htmlColor="#0242D1" />
          <TitleText>RESUME UPLOADED SUCCESFULLY!</TitleText>
        </>
      );
    }
    if (progressStatus === 'failed') {
      setDisabledBtn(true);
    }
  }, [progressStatus]);

  return (
    <ModalFeedback
      open={openModalUploader}
      disableBtnCancel
      disableBtnConfirm={disabledBtn}
      onAccept={handleClickUpload}
      onClose={() => {
        setOpenModalUploader(false);
      }}
      sx={{
        '& .MuiButtonBase-root': {
          '&.confirm': { border: '2px solid #0242D1', color: '#0242D1', display: 'flex', gap: '8px', marginBottom: '20px' },
        },
      }}
      buttonsDirection="center"
      variantButtonConfirm="outlined"
      iconConfirmBtn={<UploadOutlinedIcon />}
      buttonColorConfirm="inherit"
      variant="general"
      disableCloseOnAccept
      disabledDivider
      btnConfirmText="UPLOAD RESUME"
    >
      <Stack
        alignItems="center"
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          // Add styles for the drop area
          border: dragActive ? '2px dashed #0242D1' : 'none',
          borderRadius: '8px',
        }}
      >
        <input
          type="file"
          hidden
          onChange={cvChangeHandler}
          ref={cvRef}
          accept={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ].join(',')}
        />
        {renderView}
      </Stack>
    </ModalFeedback>
  );
};

FileUploader.propTypes = {
  progressStatus: PropTypes.oneOf(['initial', 'stopped', 'in-progress', 'success', 'failed']),
};
export default FileUploader;
