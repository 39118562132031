import { connect } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from '../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { useGetCurrentUserQuery } from 'api/api';
import { Widget404 } from '@nexxus/components';

const Error404 = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: '404',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="404" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      <Box>
        <Grid container mt={'20px'} spacing={2}>
          <Grid item xs={12}>
            <Widget404 />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
