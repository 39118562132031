import { Autocomplete, Button, Chip, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useCallback, useMemo, useState } from 'react';
import { capitalizeString } from 'utils/utils';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const ModalTags = (props) => {
  const theme = useTheme();
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [value, setValue] = useState({ static: [], state: [] });

  useMemo(() => {
    if (props.initialValue && props.initialValue.length > 0) {
      const newArray = props.initialValue.map((obj) => {
        return _.omit(obj, ['Candidate_Tag']);
      });
      setValue((prev) => ({ ...prev, static: newArray, state: newArray }));
    }
  }, [props.initialValue]);

  useMemo(() => {
    if (props.reset === true) setValue((prev) => ({ ...prev, state: prev.static }));
  }, [props.reset]);

  const handleOptionDisabled = useCallback(() => {
    return value.state.length >= 5;
  }, [value.state.length]);

  const handleChange = useCallback(
    (ev, valueAutocomplete) => {
      if (valueAutocomplete !== value.state) {
        props.setResetTags(false);
        setValue((prev) => ({ ...prev, state: valueAutocomplete }));
        props.onHandleTags(valueAutocomplete);
      }
    },
    [props, value]
  );

  return (
    <Stack alignItems="flex-end">
      <Stack direction="row" spacing={2}>
        {value?.state.map((tag) => (
          <Chip key={`tags_candidate_${uuidv4()}`} label={capitalizeString(tag.name)} variant="tag" color="success" />
        ))}
      </Stack>
      {!props.editing && (
        <Button
          variant="text"
          onClick={() => setTagModalOpen(true)}
          sx={{ color: props?.error && props.error.txt ? '#d32f2f' : theme.palette.primary.main }}
        >
          Manage Tags
        </Button>
      )}
      <Typography variant="caption" color="error">
        {props?.error && props.error.txt}
      </Typography>
      <ModalFeedback
        variant="general"
        headerText="Manage Candidate Tags"
        headerBarColor={theme.palette.primary.main}
        open={tagModalOpen}
        btnCancelText="Reset"
        btnConfirmText="Done"
        modalWidth={640}
        onAccept={() => {
          setTagModalOpen(false);
        }}
        onClose={() => setTagModalOpen(false)}
        onCancel={() => {
          setTagModalOpen(false);
          setValue((prev) => {
            props.onHandleTags(prev.static);
            return { ...prev, state: prev.static };
          });
        }}
      >
        <Autocomplete
          multiple
          id="tags"
          limitTags={5}
          options={props.tags ?? []}
          value={value.state}
          getOptionLabel={(option) => capitalizeString(option.name)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => {
            return <TextField {...params} placeholder={value.state.length ? '' : 'Type to search Tags'} />;
          }}
          onChange={handleChange}
          renderTags={(_values, getTagProps) => {
            return _values.map((option, index) => (
              <Chip
                key={option.id}
                label={capitalizeString(option.name)}
                {...getTagProps({ index })}
                variant="select-tag"
                deleteIcon={<ClearIcon />}
              />
            ));
          }}
          getOptionDisabled={handleOptionDisabled}
        />
      </ModalFeedback>
    </Stack>
  );
};

export default ModalTags;
