import { Button, Chip, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useState } from 'react';

const SkillChip = styled(Chip)({
  '&': {
    height: 'auto',
  },
  '& .MuiChip-label': {
    textAlign: 'center',
    padding: '2px 6px',
    width: '100%',
    fontWeight: 'bold',
    textShadow: '0px 0px 1px rgba(100, 100, 100, 0.5)',
    whiteSpace: 'normal',
    maxWidth: 140,
  },
});

export default function SkillsPopover({ label, skills }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const getColor = (value) => {
    if (value < 2) {
      return theme.palette.error.main;
    } else if (value < 4) {
      return theme.palette.nexxusYellow.main;
    } else {
      return theme.palette.success.main;
    }
  };

  let primarySkill = [];
  let secondarySkills = [];

  skills.forEach((skill) => {
    if (skill.Project_Skill.skill_type === 2) {
      secondarySkills.push(skill);
    } else {
      primarySkill.push(skill);
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="text" onClick={handleClick} sx={{ color: theme.palette.primary.lighter }}>
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            borderRadius: 0,
            p: 0.75,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack spacing={0.5} minWidth={140}>
          <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="bold" color="GrayText">
            Primary Skills
          </Typography>
          {primarySkill.map((skill) => (
            <SkillChip
              variant="compact"
              align="center"
              key={skill.id}
              label={`${skill.name} (${skill.Project_Skill.year_experience})`}
              sx={{
                backgroundColor: getColor(skill.Project_Skill.year_experience),
              }}
            />
          ))}
          {secondarySkills && (
            <>
              <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="bold" color="GrayText">
                Optional Skills
              </Typography>
              {secondarySkills.map((skill) => (
                <SkillChip
                  variant="compact"
                  align="center"
                  key={skill.id}
                  label={`${skill.name} (${skill.Project_Skill.year_experience})`}
                  sx={{
                    backgroundColor: getColor(skill.Project_Skill.year_experience),
                  }}
                />
              ))}
            </>
          )}
        </Stack>
      </Popover>
    </div>
  );
}
