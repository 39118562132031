import UploadPdf from './UploadPDF';
import candidateService from '../../services/candidateService';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';
import useSignedS3Url from '../../hooks/useSignedS3Url';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';

const DocumentFiles = ({ candidate, isDisabled }) => {
  const [formFile, setFormFile] = useState('');
  const [cvFile, setCvFile] = useState('');
  const [interviewURL, setInterviewURL] = useState('');
  const [cvURL, setCvURL] = useState('');
  const [formFileUploading, setFormFileUploading] = useState(false);
  const [cvFileUploading, setCvFileUploading] = useState(false);
  const intertviewRef = useRef();
  const cvRef = useRef();

  const { handleFileUpload } = useSignedS3Url();

  const cvChangeHandler = async (event) => {
    //validate file is pdf format
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf') {
      toast.error('Please upload a valid PDF file');
      return false;
    }
    setCvFileUploading(true);
    try {
      const s3File = await handleFileUpload({
        file: event.target.files[0],
        contentType: 'application/pdf',
        path: UPLOAD_LOCATIONS.CANIDATE_CV,
      });

      if (!s3File) {
        setCvFileUploading(false);
        setCvFile('');
        setCvURL('');
        toast.error('Something went wrong');
        cvRef.current.value = '';
        return false;
      }

      const body = {
        cv_file_name: event.target.files[0].name,
        cv_file_url: s3File?.object_url,
      };

      await candidateService.updateCandidateSignedFile(candidate.id, 'cv', body);

      setCvFile(event.target.files[0].name);
      setCvURL(s3File?.object_url);
      setCvFileUploading(false);
      cvRef.current.value = '';
    } catch (error) {
      setCvFileUploading(false);
      setCvFile('');
      setCvURL('');
      toast.error('Something went wrong');
      cvRef.current.value = '';
    }
  };

  const formChangeHandler = async (event) => {
    //validate file is pdf format
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf') {
      toast.error('Please upload a valid PDF file');
      return false;
    }
    setFormFileUploading(true);
    try {
      const s3File = await handleFileUpload({
        file: event.target.files[0],
        contentType: 'application/pdf',
        path: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
      });

      if (!s3File) {
        setFormFileUploading(false);
        setFormFile('');
        setInterviewURL('');
        toast.error('Something went wrong');
        intertviewRef.current.value = '';
        return false;
      }

      const body = {
        interview_file_name: event.target.files[0].name,
        interview_file_url: s3File?.object_url,
      };

      await candidateService.updateCandidateSignedFile(candidate.id, 'interview', body);

      setFormFile(event.target.files[0].name);
      setInterviewURL(s3File?.object_url);
      setFormFileUploading(false);
    } catch (error) {
      setFormFileUploading(false);
      toast.error('Something went wrong');
      intertviewRef.current.value = '';
    }
  };

  const handleDelete = async (fileType) => {
    try {
      await candidateService.deleteCandidateFile(candidate.id, fileType);
      switch (fileType) {
        case 'cv':
          setCvFile('');
          setCvURL('');
          cvRef.current.value = '';
          break;
        case 'interview':
          setFormFile('');
          setInterviewURL('');
          intertviewRef.current.value = '';
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setInterviewURL(candidate?.interview_file_url);
  }, [candidate?.interview_file_url]);

  useEffect(() => {
    setCvURL(candidate?.cv_file_url);
  }, [candidate?.cv_file_url]);

  return (
    <Stack direction="row" spacing={2} justifyContent="center" alignItems="start">
      <UploadPdf
        key="cert"
        label="ENGLISH CERT"
        filetype="interview"
        filename={formFile}
        fileurl={interviewURL}
        handleChange={formChangeHandler}
        handleDelete={() => handleDelete('interview')}
        uploading={formFileUploading}
        inputRef={intertviewRef}
        isDisabled={isDisabled}
      />

      <UploadPdf
        key="cv"
        label="CV/RESUME"
        filetype="cv"
        filename={cvFile}
        fileurl={cvURL}
        handleChange={cvChangeHandler}
        handleDelete={() => handleDelete('cv')}
        uploading={cvFileUploading}
        inputRef={cvRef}
        isDisabled={isDisabled}
      />
    </Stack>
  );
};

export default DocumentFiles;
