import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Avatar, Box, Divider, Grid, Link, Stack, Switch, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link as LinkRouterDom } from 'react-router-dom';

import DashboardCard from '../../../../components/Dashboard/DashboardCard';
import ProjectList from './ProjectList';

import { DirectionsRun, Inventory, PriorityHigh } from '@mui/icons-material';

import useManageProjects from 'hooks/useManageProjects';
import { connect } from 'react-redux';
import MainLayout from '../../../../components/MainLayout/MainLayout';
import { loginExpire } from '../../../../store/auth';
import { PROJECT_STATUS } from 'constants/project';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { ROLE_SUPERTAS } from '../../../../constants/users';
import { FormControlLabel } from '@mui/material';
import _ from 'lodash';

const ManageProjects = (props) => {
  const { client, customers, totalSize, countProjects, projects, projectsIsLoading, filters, setFilters } =
    useManageProjects();

  const handleSwitch = () => {
    setFilters((prev) => ({ ...prev, hideArchiveCustomers: !prev.hideArchiveCustomers }));
  };

  const optionsStatusProject = useMemo(() => {
    const options = _.omitBy(PROJECT_STATUS, (value, key) => ['DRAFT'].includes(key));
    return Object.entries(options).map(([key, value]) => {
      return { _id: value, label: key.replace('_', ' '), disabled: ['INTAKE'].includes(key) };
    });
  }, []);

  return (
    <MainLayout {...props} current="projects">
      <Box>
        <Stack direction="row" spacing={2} justifyContent="flex-end" marginBottom={2}>
          &nbsp;
          <Typography variant="body2">
            <strong>{client.companyName}</strong>
          </Typography>
          <Typography variant="body2">
            {client.address} {client.phone}
          </Typography>
          {client?.websiteUrl && (
            <Link component="a" href={client.websiteUrl} target="_blank">
              <Typography variant="body2"> VISIT WEBSITE</Typography>
            </Link>
          )}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
          <DashboardCard bgcolor="red-card" totalSize={50} width={200} total={countProjects.URGENT}>
            <Typography>
              <PriorityHigh />
            </Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>URGENT</Typography>
            <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
          </DashboardCard>
          <DashboardCard bgcolor="green-card" totalSize={50} width={200} total={countProjects.ACTIVE}>
            <Typography>
              <DirectionsRun />
            </Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>ACTIVE</Typography>
            <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
          </DashboardCard>
          <DashboardCard bgcolor="blue-card" totalSize={60} width={200} total={countProjects.ARCHIVED}>
            <Typography>
              <Inventory />
            </Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>ARCHIVED</Typography>
            <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
          </DashboardCard>
          {/* Client Logo */}
          <Box sx={{ marginLeft: 'auto!important' }}>
            <Avatar
              sx={{
                width: totalSize,
                height: totalSize,
              }}
              src={client?.logoUrl}
            >
              <ApartmentRoundedIcon sx={{ fontSize: 56 }} />
            </Avatar>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
          {/* <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>SELECT CUSTOMER</Typography> */}
          {/* Client Selection */}
          <Box sx={{ width: '200px' }}>
            {projects && customers && (
              <InputAutoCompleteV2
                id="client"
                initialValue={filters.client}
                opts={customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
                placeholder="CUSTOMER"
                onChangeInput={(ev) => {
                  setFilters((prev) => ({ ...prev, client: ev.target.value }));
                }}
                size="small"
              />
            )}
          </Box>
          <Box sx={{ width: '200px' }}>
            {projects && customers && (
              <InputAutoCompleteV2
                id="ProjectStatus"
                label=""
                size={'normal'}
                opts={optionsStatusProject}
                error={''}
                initialValue={''}
                placeholder={'POSITION STATUS'}
                onChangeInput={(e) => {
                  setFilters((prev) => ({ ...prev, status: e.target.value !== '' ? [e.target.value] : [] }));
                }}
                sortOpts={false}
                isDisabled={false}
              />
            )}
          </Box>
          <FormControlLabel
            control={<Switch onChange={handleSwitch} checked={filters.hideArchiveCustomers} disabled={false} />}
            label="Hide Customers Archived"
            labelPlacement="start"
          />
          {/* Counter Projects */}
          <Stack direction="row" spacing={2} justifyContent="space-between"></Stack>
        </Stack>

        <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 2 }} />
        <Grid container>
          <Grid item xs={4}>
            {[ROLE_SUPERTAS].includes(props.auth.user.role) && (
              <LinkRouterDom to="/tas/projects/create">
                <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '16px', color: 'green' }}>
                  + ADD A NEW POSITION
                </Typography>
              </LinkRouterDom>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'nexxusGrayMid.main', textAlign: 'center' }}>
              CUSTOMERS POSITIONS
            </Typography>
          </Grid>
        </Grid>
        <ProjectList
          projects={projects}
          initialOrder={'status'}
          isLoading={projectsIsLoading}
          type={['active', 'onhold', 'urgent', 're_enable', 'intake', 'archived']}
        />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProjects);
