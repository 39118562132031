import { useState } from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { POSITIONS } from '@nexxus/constants';
import InputSelectV2 from 'components/FormElement/InputSelectV2';

export default function FiltersDashboard(props) {
  const [skillValue, setSkillValue] = useState(null);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Refine Results:</Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <InputSelectV2
          id="client"
          label="Customer"
          initialValue={props.filters.client}
          opts={props.customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
          placeholder="No Selection"
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, client: ev.target.value }));
          }}
        >
          {(
            <MenuItem key={0} value={''}>
              No Selection
            </MenuItem>
          ) &&
            props.customers.forEach((customer, index) => {
              return (
                <MenuItem key={`customer-${customer.id}-${index}`} value={customer.id}>
                  {customer.companyName}
                </MenuItem>
              );
            })}
        </InputSelectV2>
        <InputSelectV2
          id="recruiters"
          label="Recruiter"
          initialValue={props.filters.recruiters}
          placeholder="No Selection"
          multiple
          renderValue={() => {
            if (props.filters.recruiters?.length === 1) {
              const selectedRecruiter = props.recruiters.rows.find(
                (recruiter) => recruiter.id === props.filters.recruiters[0]
              );
              return selectedRecruiter?.full_name;
            }

            return `${props.filters.recruiters.length} Selected`;
          }}
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, recruiters: ev.target.value }));
          }}
        >
          {props.recruiters.rows.map((recruiter, index) => {
            return (
              <MenuItem key={`recruiter-${recruiter.id}-${index}`} value={recruiter.id}>
                <Checkbox checked={props.filters.recruiters.includes(recruiter.id)} />
                <ListItemText primary={recruiter.full_name} />
              </MenuItem>
            );
          })}
        </InputSelectV2>
        <InputSelectV2
          id="position"
          label="Title"
          opts={Object.values(POSITIONS)}
          placeholder="No Selection"
          initialValue={props.filters.position}
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, position: ev.target.value }));
          }}
        />
        <FormControl fullWidth>
          <FormLabel>Main Technology</FormLabel>
          <Autocomplete
            id="skill"
            name="skill"
            placeholder="Search Skills"
            onChange={(item) => {
              setSkillValue(item);
              props.setFilters((prev) => ({ ...prev, technology: item?.id || '' }));
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={props.skills.rows}
            renderInput={(params) => <TextField {...params} placeholder="No Selection" />}
            value={skillValue}
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <FormLabel>&nbsp;</FormLabel>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.handleFilterProjects}
            sx={{
              height: '54px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: 17,
              pt: 1,
              borderRadius: 0,
            }}
          >
            FILTER RESULTS
          </Button>
        </FormControl>
      </Stack>
    </>
  );
}
