import http from './httpService';

function updateMarkAsRead(notificationId, body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/notifications/${notificationId}`, body);
}

function updateBulkToggleAsRead(body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/notifications/bulk-mark-as-read`, body);
}

const NotificationService = {
  updateMarkAsRead,
  updateBulkToggleAsRead
};

export default NotificationService;