import { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';

import { toast } from 'react-toastify';
import { useDeleteCandidateAudioMutation, useUpdateCandidateFileMutation } from 'api/api';
import useSignedS3Url from '../../hooks/useSignedS3Url';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';

const AudioFile = ({ candidateId, audio, questionId, isDisabled = false }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [updateFileMutation] = useUpdateCandidateFileMutation();
  const [isUploading, setIsUploading] = useState(false);

  const onCloseModal = () => setOpen(false);
  const handleOpenModal = useCallback(() => setOpen(true), []);
  const onAccept = () => {
    handleDelete();
    setOpen(false);
  };

  const [audioSrc, setAudioSrc] = useState(null);

  const { handleFileUpload, getSignedDownloadUrl } = useSignedS3Url();
  const [deleteAudio] = useDeleteCandidateAudioMutation();

  const handleDelete = async () => {
    deleteAudio({ audioId: audio.id })
      .unwrap()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdatefile = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);

    const payload = {
      question_text: `Interview Question #${questionId}`,
      question_id: questionId,
      audio_file_name: file.name,
    };

    const s3File = await handleFileUpload({
      file: file,
      contentType: 'audio/mpeg',
      path: UPLOAD_LOCATIONS.CANDIDATE_AUDIOS,
    });

    if (!s3File) {
      setIsUploading(false);
      toast.error('Error uploading file');
      return false;
    }

    payload.audio_file_url = s3File?.object_url;

    updateFileMutation({ candidateId, file_type: 'audios', data: payload })
      .unwrap()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        e.target.value = null;
        setIsUploading(false);
      });
  };

  useEffect(() => {
    if (audio?.audio_file_url) {
      if (audio?.audio_file_url !== audioSrc?.split('?')?.[0]) {
        getSignedDownloadUrl(audio?.audio_file_url)
          .then((url) => {
            setAudioSrc(url);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setAudioSrc('');
    }
  }, [audio?.audio_file_url, audioSrc, getSignedDownloadUrl]);

  return (
    <>
      <ModalFeedback
        key="audioDelete"
        variant="warning"
        headerText="Delete Audio"
        titleText="Delete audio permanently?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <Box sx={{ marginTop: 5 }}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {audio?.id ? `Interview Question #${questionId} - Audio` : ''}
        </Typography>
        <Paper
          elevation={0}
          sx={{
            borderColor: theme.variables.inputs.borderColor,
            borderWidth: theme.variables.inputs.borderWidth,
            borderStyle: 'solid',
            display: 'flex',
            padding: '0.1rem 0',
            borderRadius: theme.variables.inputs.borderRadius,
          }}
        >
          <audio controls src={audioSrc}>
            Your browser does not support the audio element.
          </audio>
        </Paper>
        <Box sx={{ display: 'flex', marginTop: 1 }}>
          <div className="file-input-small">
            <input
              type="file"
              name={`audio-${questionId}-file`}
              id={`audio-${questionId}-file`}
              className="file"
              accept="audio/mpeg"
              onChange={onUpdatefile}
              disabled={isDisabled}
            />
            {!isDisabled && (
              <label
                className={isDisabled ? 'disabled' : ''}
                style={{ opacity: isDisabled ? '0.5' : '1', cursor: 'text!important' }}
                htmlFor={`audio-${questionId}-file`}
              >
                UPDATE AUDIO
              </label>
            )}
          </div>
          {isUploading && <CircularProgress size={20} />}
          {audio?.id && (
            <Button variant="text" onClick={handleOpenModal} sx={{ textTransform: 'none' }}>
              <Typography sx={{ fontSize: 13, color: 'red', fontWeight: 'bold' }}>DELETE AUDIO</Typography>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AudioFile;
