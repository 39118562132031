import Joi from 'joi';
import candidateService from 'services/candidateService';
import { setValidationMessages } from '../utils/validationMessages';
import { tlds } from '@hapi/tlds';

const methodEmail = async (value, helpers) => {
  try {
    if (helpers.prefs.initialStateRequest.email !== value) await candidateService.getEmailValid(value);
  } catch (e) {
    throw Object.assign(new Error('Email is already taken!'), {
      details: [
        {
          context: {
            label: 'email',
            key: 'email',
          },
          code: 422,
          type: 'any.invalid',
          path: ['email'],
          message: 'Email is already taken!',
        },
      ],
    });
  }
  return true;
};

export const schemaWorkExperience = {
  we_isValid: Joi.boolean().invalid(false).messages({
    'any.invalid': 'Work experience must be validated for user',
  }),
  we_startDate: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  we_endDate: Joi.string()
    .optional()
    .allow('', ' ')
    .messages(setValidationMessages(['string'])),
  we_position: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  otherwise: Joi.string().allow('', ' '),
  we_location: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  we_company: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  we_description: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaCvTAS = {
  birthdayCV: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  yearsOfExperienceCV: Joi.when('resumeRequired', {
    is: Joi.boolean().valid(true),
    then: Joi.number()
      .integer()
      .messages(setValidationMessages(['number'])),
    otherwise: Joi.number().integer().allow('', ' '),
  }),
  positionCV: Joi.when('resumeRequired', {
    is: Joi.boolean().valid(true),
    then: Joi.string()
      .required()
      .messages(setValidationMessages(['string', 'required'])),
    otherwise: Joi.string().allow('', ' '),
  }),

  certificationsCV: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  certificationsCV_valid: Joi.when('certificationsCV', {
    is: Joi.string().min(3),
    then: Joi.boolean().valid(true),
    otherwise: Joi.boolean(),
  }),
  // certificationsCV_valid: Joi.boolean().valid(true),
  // skillsCV_valid: Joi.boolean().valid(true),
  skillsCV: Joi.when('resumeRequired', {
    is: true,
    then: Joi.array()
      .items(
        Joi.object().keys({
          value: Joi.string().required(),
          validated: Joi.valid(true),
        })
      )
      .min(1)
      .messages(setValidationMessages(['array'])),
    otherwise: Joi.array().items().min(0),
  }),
  languagesCV: Joi.when('resumeRequired', {
    is: true,
    then: Joi.array()
      .items().min(1)
      .messages(setValidationMessages(['array'])),
    otherwise: Joi.array().items().min(0),
  }),
  industry_experienceCV: Joi.when('resumeRequired', {
    is: false,
    then: Joi.array().optional(),
    otherwise: Joi.array().optional(),
  }),
  // languagesCV_valid: Joi.boolean().valid(true),
  languagesCV_valid: Joi.when('resumeRequired', {
    is: true,
    then: Joi.boolean().valid(true),
    otherwise: Joi.boolean(),
  }),

  workExperienceCV: Joi.when('resumeRequired', {
    is: Joi.boolean().valid(true),
    then: Joi.array()
      .items(Joi.object().keys(schemaWorkExperience))
      .min(1)
      .messages(setValidationMessages(['array'])),
    otherwise: Joi.array()
      .items(
        Joi.object().keys({
          we_isValid: Joi.boolean(),
          we_startDate: Joi.string().optional().allow('', ' '),
          we_endDate: Joi.string()
            .optional()
            .allow('', ' ')
            .messages(setValidationMessages(['string'])),
          we_position: Joi.string().allow('', ' '),
          we_location: Joi.string().allow('', ' '),
          we_company: Joi.string().allow('', ' '),
          we_description: Joi.string().allow('', ' '),
        })
      )
      .min(0),
  }),
};

export const schemaVisa = {
  visa_required: Joi.boolean(),
  visa_issue_date: Joi.string()
    .when('visa_required', {
      is: Joi.boolean().valid(true),
      then: Joi.required(),
      otherwise: Joi.allow('', ' '),
    })
    .messages(setValidationMessages(['string', 'required'])),

  visa_expiration_date: Joi.string()
    .when('visa_required', {
      is: Joi.boolean().valid(true),
      then: Joi.required(),
      otherwise: Joi.allow('', ' '),
    })
    .messages(setValidationMessages(['string', 'required'])),
  visa_type: Joi.when('visa_required', {
    is: Joi.boolean().valid(true),
    then: Joi.number().integer(),
    otherwise: Joi.allow('', ' '),
  }).messages(setValidationMessages(['number', 'required'])),

  visa_nationality: Joi.when('visa_required', {
    is: Joi.boolean().valid(true),
    then: Joi.number().integer(),
    otherwise: Joi.allow('', ' '),
  }).messages(setValidationMessages(['number', 'required'])),
  visa_status: Joi.when('visa_required', {
    is: Joi.boolean().valid(true),
    then: Joi.number().integer(),
    otherwise: Joi.allow('', ' '),
  }).messages(setValidationMessages(['number', 'required'])),
  visa_type_other: Joi.when('visa_type', {
    is: Joi.valid(3),
    then: Joi.string()
      .required()
      .messages(setValidationMessages(['string', 'required'])),
    otherwise: Joi.string().allow('', ' '),
  }),
  visa_file: Joi.string()
    .when('visa_required', {
      is: Joi.boolean().valid(true),
      then: Joi.required(),
      otherwise: Joi.allow('', ' '),
    })
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaTAS = {
  candidate_id: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required'])),
  first_name: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  last_name: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  phone_number: Joi.string()
    .min(11)
    .max(15)
    .optional()
    .allow('')
    .messages({
      ...setValidationMessages(['string', 'required']),
      'string.pattern.base': 'E.g. +1(123)123-1234',
    }),

  //  .pattern(/^\+(\d{1,4})((\(?\d{1,4}\)?))*?\d{3,4}-?\d{3,4}$/)
  //  .pattern(/^\+(\d{1,4})((\(?\d{1,4}\)?))?\d{3,4}-?\d{3,4}$/)

  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: tlds } })
    .external(methodEmail, 'custom validation')
    .messages(setValidationMessages(['string', 'required'])),
  company_email: Joi.string()
    .optional()
    .allow('', ' ', null)
    .email({ minDomainSegments: 2, tlds: { allow: tlds } })
    .messages(setValidationMessages(['string', 'required'])),
  status: Joi.number()
    .integer()
    .valid(5, 10)
    .messages(setValidationMessages(['number', 'required'])),
  blacklisted: Joi.boolean(),
  staff: Joi.boolean(),
  availability: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  availability_interview: Joi.string()
    .min(3)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  LocationCountryId: Joi.number().allow('', null),
  developer_title: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  developer_seniority: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  employment_type: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  salary_currency: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  salary_expectation: Joi.number()
    .integer()
    .positive()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  timezone: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  linkedin: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  github_profile: Joi.string()
    .uri()
    .required()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  candidate_code_score: Joi.number()
    .integer()
    .positive()
    .optional()
    .max(100)
    .allow('', 0)
    .messages({ ...setValidationMessages(['number']), 'number.max': `Field must be max 100` }),
  candidate_result_link: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  portfolio_link: Joi.string()
    .uri()
    .required()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  info_additional: Joi.string()
    .optional()
    .allow('', ' ')
    .messages(setValidationMessages(['string'])),
  tags: Joi.array()
    .min(1)
    .messages(setValidationMessages(['array'])),
  rate_customer: Joi.number()
    .positive()
    .integer()
    .messages({ ...setValidationMessages(['number']), 'number.positive': `Field must be greater than 0` }),
  rate_per_hour: Joi.number()
    .positive()
    .integer()
    .messages({ ...setValidationMessages(['number']), 'number.positive': `Field must be greater than 0` }),
  ...schemaCvTAS,
  ...schemaVisa,
};
