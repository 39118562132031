import { useState, useEffect, useCallback } from 'react';
import history from 'services/history';

const LEAVE_RESTRICTION_MESSAGE = `Are you sure you want to leave? You may lose your changes`;

const usePreventLocation = () => {
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    let unblock;
    if (hasChanged === true) {
      unblock = history.block((tx) => {
        if (window.confirm(LEAVE_RESTRICTION_MESSAGE)) {
          unblock();
          tx.retry();
        }
      });
    } else unblock = null;
    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [hasChanged]);

  const addBeforeUnloadEvent = useCallback(
    (event) => {
      if (hasChanged) {
        event.returnValue = LEAVE_RESTRICTION_MESSAGE;
        return LEAVE_RESTRICTION_MESSAGE;
      }
    },
    [hasChanged]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', addBeforeUnloadEvent);
    return () => {
      window.removeEventListener('beforeunload', addBeforeUnloadEvent);
    };
  }, [addBeforeUnloadEvent]);

  return {
    setHasChanged,
    hasChanged,
    LEAVE_RESTRICTION_MESSAGE,
  };
};

export default usePreventLocation;
