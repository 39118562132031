import { ROLE_ADMIN, ROLE_SUPERTAS } from 'constants/users';
import Candidates from 'pages/import/Candidates/index';
import DuplicateCandidates from 'pages/import/DuplicateCandidates/index';
import InvalidCandidates from 'pages/import/InvalidCandidates/index';
import { Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';

const importRoutes = [
  {
    name: 'Candidate Import',
    path: '/import/candidates',
    element: <Candidates />,
    authorization: [ROLE_SUPERTAS, ROLE_ADMIN],
  },
  {
    name: 'Duplicate Candidates',
    path: '/import/candidates/duplicates',
    element: <DuplicateCandidates />,
    authorization: [ROLE_SUPERTAS, ROLE_ADMIN],
  },
  {
    name: 'Invalid Candidates',
    path: '/import/candidates/invalid',
    element: <InvalidCandidates />,
    authorization: [ROLE_SUPERTAS, ROLE_ADMIN],
  },
];

export const ImportRoutes = () => {
  return importRoutes.map((route, index) => {
    return (
      <Route
        key={`import-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
