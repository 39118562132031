import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { loginExpire, logout } from '../store/auth';

const WithAuthorization = (props) => {
  const location = useLocation();
  if (!['/404'].includes(location.pathname)) {
    if (!props['roles']) return <Navigate to={'/404'} replace={true}></Navigate>;

    if (!props.roles?.includes(props.auth?.user?.role)) return <Navigate to={'/403'} replace={true}></Navigate>;
  }

  return props.children;
};

const PrivateRoute = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = props.auth?.isAuthenticated;

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/logout') {
      if (
        ((location.search && location.search !== '') || location.pathname !== '/') &&
        !['/404', '/403'].includes(location.pathname)
      ) {
        const uriWord = `${location.pathname}${location.search}`;
        const encodedUriWord = encodeURIComponent(uriWord);
        props.logout();
        navigate(`/login?returnUrl=${encodedUriWord}`, { replace: true });
      } else {
        navigate('/logout', { replace: true });
      }
    }
  }, [isAuthenticated, location, navigate, props]);

  return props.auth.isAuthenticated ? WithAuthorization(props) : <></>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
