import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';

const HeaderProjectsList = ({ items }) => {
  return (
    <>
      {items.map((item, index) => {
        const gridColumns = () => {
          if (items.length > 6) return 1;
          if (index === 5) return 2;
          return 1;
        };
        return (
          <Fragment key={index}>
            <Grid key={index} item xs={gridColumns()}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: 'nexxusGray.main',
                  textAlign: index === 6 ? 'center' : 'left',
                  marginBottom: 1,
                }}
              >
                {item.title}
              </Typography>
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export default HeaderProjectsList;
