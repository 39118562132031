import { Box, Button, FormControl, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const RepeaterControl = ({
  elements,
  maxElements,
  labelRemove,
  labelAdd,
  onHandleIncrement,
  onHandleDecrement,
  styleControl = {},
}) => {
  return (
    <FormControl fullWidth sx={{ textAlign: 'left', ...styleControl }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          mb: 0,
          mx: 0,
          marginTop: '3px',
          gap: '.5em',
        }}
      >
        {elements?.length > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 1 }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'nexxusGrayMid.main',
                lineHeight: 1,
                marginRight: 1,
              }}
            >
              {labelRemove}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                minWidth: '10px',
                paddingX: '5px',
                paddingY: '3px',
                borderRadius: '50%',
                borderColor: 'nexxusGrayMid.main',
              }}
              onClick={onHandleDecrement}
              data-cy="Remove"
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '900',
                  textAlign: 'right',
                  color: 'nexxusGrayMid.main',
                  lineHeight: 0.6,
                  pt: '0px',
                }}
              >
                -
              </Typography>
            </Button>
          </Box>
        )}

        {elements?.length < maxElements && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 1 }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'nexxusGrayMid.main',
                lineHeight: 1,
                marginRight: 1,
              }}
            >
              {labelAdd}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                minWidth: '10px',
                paddingX: '5px',
                paddingY: '3px',
                borderRadius: '50%',
                borderColor: 'nexxusGrayMid.main',
              }}
              onClick={onHandleIncrement}
              data-cy="Add"
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  textAlign: 'right',
                  color: 'nexxusGrayMid.main',
                  lineHeight: 0.6,
                  pt: '3px',
                }}
              >
                +
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

RepeaterControl.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object),
  maxElements: PropTypes.number.isRequired,
  labelRemove: PropTypes.string.isRequired,
  labelAdd: PropTypes.string.isRequired,
  onHandleIncrement: PropTypes.func,
  onHandleDecrement: PropTypes.func,
};

export default RepeaterControl;
