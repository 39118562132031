import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { CustomSelect, SearchInput } from '@nexxus/components';
import NCSWidget from './NCSWidget';

const CandidateHeader = ({
  logoUrl,
  positions,
  onHandlePosition,
  lifetimeTotals,
  candidatesNames,
  handleCandidateSelected,
  projectSelected,
  clearFilters = () => {},
  initialValueSearch = '',
}) => {
  const [resetSearch, setResetSearch] = useState(false);
  const isMobile = useMediaQuery('(max-width:700px)');
  const isNcsBreakpoint = useMediaQuery('(min-width:985px)');

  const handleClear = () => {
    clearFilters();
    setResetSearch(true);
  };

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={isNcsBreakpoint ? { padding: '16px', background: 'white', borderRadius: '5px' } : {}}
    >
      {!isMobile && (
        <Grid item md={isNcsBreakpoint ? 9 : 12} sx={!isNcsBreakpoint ? { width: '100%' } : {}}>
          <Grid
            container
            spacing={isNcsBreakpoint ? 2 : 0}
            sx={!isNcsBreakpoint ? { background: 'white', borderRadius: '5px', padding: '16px' } : {}}
          >
            <Grid item sm={5}>
              <CustomSelect
                options={positions ?? []}
                onChangeInput={onHandlePosition}
                selectText="Select a position"
                variant={'variant-1'}
                defaultOption={false}
                MenuProps={{
                  PaperProps: {
                    sx: { minWidth: '270px' },
                  },
                }}
                width="100%"
                initialValue={projectSelected}
              />
            </Grid>
            {/* Hide Search Option and Clear Search Button NEX-1158 */}
            {/* <Grid item sm={3} sx={!isNcsBreakpoint ? { marginLeft: '16px' } : {}}>
              <SearchInput
                resetSearch={resetSearch}
                options={candidatesNames}
                onSearch={handleCandidateSelected}
                label="Search Candidate"
                width="100%"
                initialValue={initialValueSearch}
              />
            </Grid>
            <Grid
              item
              sm={3}
              sx={!isNcsBreakpoint ? { marginLeft: '16px', maxWidth: '28% !important', flexBasis: '28% !important' } : {}}
            >
              <Button variant="outlined" fullWidth onClick={handleClear}>
                Clear Search
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      )}

      {!isMobile && isNcsBreakpoint && <NCSWidget lifetimeTotals={lifetimeTotals} logoUrl={logoUrl} />}
    </Grid>
  );
};

export default CandidateHeader;
