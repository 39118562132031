import { Box, Divider, IconButton, Stack, Typography, Avatar, CircularProgress, Paper } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';

import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { v4 as uuidv4 } from 'uuid';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import _ from 'lodash';
import { stringAvatar } from 'utils/utils';
import { capitalizeString } from '../../../utils/utils';
import { POSITIONS } from '@nexxus/constants';

const CustomMobileStepper = ({ activeStep, steps, onStepClick }) => {
  const theme = useTheme();
  return (
    <StepsContainer>
      {Array.from(Array(steps).keys()).map((step) => {
        return (
          <Step
            key={step}
            onClick={() => onStepClick(step)}
            sx={{ backgroundColor: activeStep === step ? theme.palette.primary.main : theme.palette.grey[400] }}
          />
        );
      })}
    </StepsContainer>
  );
};
const StepsContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: 'transparent',
  justifyContent: 'center',
  // @ts-ignore
  marginTop: theme.spacing(3),
  flexDirection: 'row',
  gap: 0,
  padding: 0,
}));

const Step = styled(Paper)(({ theme }) => ({
  '-webkit-transition': 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: '50%',
  width: '14px',
  height: '14px',
  margin: '0 2px',
  cursor: 'pointer',
}));

const WidgetIntake = ({ customer, candidates, loading, skills, showmatch = false }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [activeStep2, setActiveStep2] = useState(0);

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const handleChangeIndex2 = (index) => {
    setActiveStep2(index);
  };

  const CustomAvatarWrapper = styled(Stack)(({ theme }) => ({
    gap: '12px',
    margin: '0 auto',
    maxWidth: '285px',
  }));

  const CustomAvatar = styled(Avatar)(({ theme }) => ({
    width: '75px',
    height: '75px',
    // background: theme.palette.grey[300],
    '& svg': {
      fontSize: '2.7rem',
      color: '#000',
    },
  }));

  const CustomDivider = styled(Divider)(({ theme }) => ({
    margin: '32px auto !important',
    borderBottomWidth: 'medium',
    width: '100%',
  }));

  const avatarProps = (cand) => {
    const strAvatar = stringAvatar(cand?.full_name ?? 'CA');
    if (strAvatar.children && strAvatar.children.length > 2)
      strAvatar.children = `${cand?.first_name?.split('')[0]}${cand?.last_name?.split('')[0]}`;
    return strAvatar;
  };

  const setPositionCandidate = (cand) => {
    const position = Object.values(POSITIONS).find((ti) => ti._id === cand.developer_title).label || '';
    return `${capitalizeString(position)} - ${capitalizeString(cand.developer_seniority)}`;
  };

  const cleanSkills = skills.filter((sk) => sk.SkillId !== '');

  useEffect(() => {
    setActiveStep(0);
    setActiveStep2(0);
  }, [candidates]);

  return (
    <Stack
      direction="column"
      spacing={4}
      p={1}
      sx={{
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('xl')]: { p: 0 },
      }}
    >
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Avatar src={customer?.logoUrl}>
          <ApartmentRoundedIcon />
        </Avatar>
      </Stack>
      <CustomDivider />
      {loading && (
        <Stack
          id="widget-loader"
          direction={'column'}
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ px: 4, mt: '0px!important', minHeight: '302px', [theme.breakpoints.down('xl')]: { px: 1 } }}
        >
          <CircularProgress size={80} thickness={6} />
        </Stack>
      )}
      {/* Widget One */}
      {!loading && (candidates.length > 36 || candidates.length === 0) && (
        <Stack
          id="widget-1"
          direction={'column'}
          spacing={2}
          justifyContent={'center'}
          sx={{
            px: 4,
            mt: '0px!important',
            minHeight: '302px',
            [theme.breakpoints.down('xl')]: { px: 1 },
            [theme.breakpoints.down('sm')]: { mt: '0!important' },
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              color: theme.palette.grey[600],
              textAlign: 'center',
              visibility: showmatch ? 'visible' : 'hidden',
            }}
          >
            Match Found
          </Typography>
          <Typography
            sx={{
              fontSize: '17px',
              color: theme.palette.primary.main,
              fontWeight: 900,
              textAlign: 'center',
              visibility: showmatch ? 'visible' : 'hidden',
            }}
          >
            {candidates?.length ?? '0'} Candidates
          </Typography>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            sx={{ [theme.breakpoints.down('lg')]: { justifyContent: 'center' } }}
          >
            <IconButton color="primary" sx={{}}>
              <ErrorIcon></ErrorIcon>
            </IconButton>
            <Typography
              sx={{
                fontSize: '16px',
                color: theme.palette.grey[600],
                textAlign: 'left',
              }}
            >
              Want a more accurate result? Help us narrow it down by filling all the fields
            </Typography>
          </Stack>
        </Stack>
      )}
      {/* Widget Two */}
      {!loading &&
        ((cleanSkills.length === 0 && candidates.length <= 36) ||
          (cleanSkills.length >= 1 && candidates.length > 6 && candidates.length <= 36)) &&
        candidates.length !== 0 && (
          <Stack
            id="widget-2"
            direction={'column'}
            spacing={2}
            sx={{
              minHeight: '270px',
              px: 2,
              [theme.breakpoints.down('xl')]: { px: 0 },
              [theme.breakpoints.down('sm')]: { mt: '0!important' },
            }}
          >
            <Typography sx={{ fontSize: '20px', color: theme.palette.grey[600], textAlign: 'center' }}>
              Match Found
            </Typography>
            <Typography sx={{ fontSize: '17px', color: theme.palette.primary.main, fontWeight: 900, textAlign: 'center' }}>
              {candidates.length}
              {' Candidates'}
            </Typography>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleChangeIndex}
              animateHeight
              enableMouseEvents
            >
              {_.chunk(candidates, 6).map((rows) => {
                return (
                  <CustomAvatarWrapper
                    direction={'row'}
                    spacing={2}
                    alignItems={'flex-start'}
                    flexWrap={'wrap'}
                    justifyContent="center"
                    useFlexGap
                    key={uuidv4()}
                    sx={{ minHeight: '165px' }}
                  >
                    {rows.map((cand) => (
                      <CustomAvatar
                        key={uuidv4()}
                        {...avatarProps(cand)}
                        alt="Remy Sharp"
                        src={cand?.avatar}
                        variant="circular"
                      >
                        {/* <PersonIcon></PersonIcon> */}
                      </CustomAvatar>
                    ))}
                  </CustomAvatarWrapper>
                );
              })}
            </SwipeableViews>
            <CustomMobileStepper
              steps={_.chunk(candidates, 6).length}
              activeStep={activeStep}
              onStepClick={(currentStep) => handleChangeIndex(currentStep)}
            />
          </Stack>
        )}
      {!loading && candidates.length <= 6 && cleanSkills.length > 0 && candidates.length > 0 && (
        <Stack
          id="widget-3"
          direction={'column'}
          spacing={2}
          sx={{
            width: '100%',
            minHeight: '270px',
            px: 2,
            [theme.breakpoints.down('xl')]: { px: 0 },
            [theme.breakpoints.down('sm')]: { mt: '0!important' },
          }}
        >
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep2}
            onChangeIndex={handleChangeIndex2}
            animateHeight
            enableMouseEvents
            style={{ minHeight: '200px' }}
          >
            {candidates.map((cand) => (
              <Box key={uuidv4()} sx={{ position: 'relative', width: '100%' }}>
                <Avatar
                  src={cand?.avatar}
                  variant="circular"
                  sx={{
                    width: '87px',
                    height: '87px',
                    fontSize: '2.1rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: 'rgba(255, 255, 255, .6)',
                    border: 'none',
                    bgcolor: 'nexxusGrayLight.main',
                    margin: '0px auto 24px',
                  }}
                />
                <Typography
                  textTransform="uppercase"
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: '600',
                    fontSize: '19px',
                    textAlign: 'center',
                  }}
                >
                  {setPositionCandidate(cand)}
                </Typography>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 200, textAlign: 'center', marginBottom: '5px', marginTop: '2px' }}
                >
                  {cand.full_name}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: '600',
                    fontSize: '19px',
                    textAlign: 'center',
                  }}
                >
                  MATCH:{' '}
                  <Typography
                    component={'span'}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: '600',
                      fontSize: '19px',
                      textAlign: 'center',
                    }}
                  >
                    {cand.totalMatchCandidate}%
                  </Typography>
                </Typography>
              </Box>
            ))}
          </SwipeableViews>
          <CustomMobileStepper
            steps={candidates.length}
            activeStep={activeStep2}
            onStepClick={(currentStep) => handleChangeIndex2(currentStep)}
          />
        </Stack>
      )}
      <CustomDivider />
      <Stack
        sx={{ width: '100%', [theme.breakpoints.down('lg')]: { mt: '0!important' } }}
        direction={'column'}
        spacing={2}
        justifyContent={'center'}
        alignItems={{ lg: 'center', sm: 'center' }}
        flexWrap={'wrap'}
      >
        <Typography
          textTransform="uppercase"
          sx={{
            color: '#000',
            fontWeight: '600',
            fontSize: '19px',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: { textAlign: 'center' },
          }}
        >
          {customer?.companyName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default WidgetIntake;
