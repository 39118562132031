import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { FormHook } from 'hooks/form/Form';
import Joi from 'joi';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import http from 'services/httpService';

const dataSourceOptions = [
  { _id: 'calendly', label: 'Calendly' },
  { _id: 'never-bounce', label: 'Never Bounce' },
  { _id: 'nexxus-group', label: 'Nexxus Group' },
  { _id: 'linkedin-talent', label: 'LinkedIn Talent' },
  { _id: 'nexxus-site-jobs', label: 'Nexxus Site Jobs' },
  { _id: 'candidate-referral-form', label: 'Canndidate Referal Form' },
  { _id: 'hireline', label: 'Hireline' },
];

const useImportCandidateStats = () => {
  const initialState = {
    candidates: 0,
    draftCandidates: 0,
    invalidDraftCandidates: 0,
    duplicates: 0,
  };
  const [stats, setStats] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await http.get(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/stats');
      setStats(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, []);

  const refetch = useCallback(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (!hasFetched) {
      fetch();
      setHasFetched(true);
    }
  }, [fetch, hasFetched]);

  return { stats, loading, error, refetch };
};

const ImportForm = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState(null);
  const [reset, setReset] = useState(false);
  const theme = useTheme();

  const { stats, loading, refetch } = useImportCandidateStats();

  const schema = {
    dataSource: Joi.string().required(),
    file_name: Joi.string().required(),
  };

  const { fields, handleSave, errors, handleSubmit, validSubmit, resetForm, setValues } = FormHook(
    { dataSource: '', file_name: '' },
    schema
  );

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValues({ ...fields, file_name: file.name });
      setFile(file);
    }
  };

  //useeffect with async
  useEffect(() => {
    if (validSubmit && !submitting) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      setSubmitting(true);
      http
        .post(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/' + fields.dataSource, formData)
        .then(({ data }) => {
          resetForm();
          setReset(true);
          setSubmitting(false);

          refetch();
          toast.success('Uploaded, please wait for the data to be processed.');
          processAll();
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.dataSource, file, refetch, resetForm, submitting, validSubmit]);

  useEffect(() => {
    (async () => {
      if (reset) {
        resetForm();
        setFile('');
        setReset(false);
      }
    })();
  }, [reset, resetForm]);

  const processAll = async () => {
    try {
      setProcessing(true);
      await http.get(process.env.REACT_APP_API_BACKEND + '/v1/import/process/all');
      setProcessing(false);
      refetch();
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
  };

  const BoxStyled = styled(Box)(({ theme }) => ({
    padding: '10px 15px',
    minWidth: 168,
    color: 'white',
    textTransform: 'uppercase',
  }));

  return (
    <>
      <Stack spacing={2} direction="row" mt={4}>
        <BoxStyled sx={{ background: theme.palette.gradients.success }}>
          <Typography variant="body1">Total</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.candidates}</strong>
          </Typography>
        </BoxStyled>
        <BoxStyled sx={{ background: theme.palette.gradients.success }}>
          <Typography variant="body1">Scraped</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.scraped}</strong>
          </Typography>
        </BoxStyled>
        <BoxStyled position="relative" sx={{ background: theme.palette.gradients.primary }}>
          <Typography variant="body1">Scrape Ready</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.readyForScraping}</strong>
          </Typography>
        </BoxStyled>
        <BoxStyled sx={{ background: theme.palette.gradients.info }}>
          <Typography variant="body1">Draft</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.draftCandidates}</strong>
          </Typography>
        </BoxStyled>
        <BoxStyled sx={{ background: theme.palette.gradients.warning }}>
          <Typography variant="body1">Invalid</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.invalidDraftCandidates}</strong>
          </Typography>
        </BoxStyled>
        <BoxStyled sx={{ background: theme.palette.gradients.default }}>
          <Typography variant="body1">Duplicates</Typography>
          <Typography variant="h4" textAlign="right">
            <strong>{loading ? '– –' : stats.duplicates}</strong>
          </Typography>
        </BoxStyled>
      </Stack>
      <form onSubmit={onSubmit}>
        <Stack mt={5} direction="row" maxWidth={720} spacing={3} alignItems="end" mb={4}>
          <InputSelectV2
            id="dataSource"
            size={'normal'}
            label="Data Source"
            opts={dataSourceOptions}
            error={errors.dataSource?.txt}
            onChangeInput={(e) => {
              handleSave(e);
            }}
            variant="standard"
            placeholder="Select Data Source"
            defaultOption={true}
            initialValue={fields.dataSource}
            backgroundColor="nexxusGrayExtraLight.main"
            isDisabled={submitting}
          />

          <InputFileWithText
            accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            id={'Sheet'}
            label={'Spreadsheet'}
            onFileChange={onFileChange}
            reset={reset}
          />

          <LoadingButton fullWidth sx={{ height: 56, width: 200 }} loading={submitting} variant="contained" type="submit">
            Upload
          </LoadingButton>
        </Stack>
      </form>
      {stats.draftCandidates > 0 && (
        <LoadingButton loading={processing} variant="contained" onClick={processAll}>
          Process All
        </LoadingButton>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportForm);
