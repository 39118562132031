import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SvgItem from '../../../Media/SvgItem';

const AccordionSideBar = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  position: 'unset',
  margin: 0,
  marginBottom: theme.spacing(2),
  '& .MuiPaper-root': {
    margin: 0,
  },
  '&.Mui-expanded': {
    margin: 0,
    transition: 'all 0.1s ease 0s',
  },
  '&.Mui-expanded:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const AccordionSummarySideBar = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.Mui-expanded': {
    transition: 'all 0.1s ease 0s',
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
  '.MuiTypography-root': {
    borderBottom: theme.variables.sidebar.border,
    transition: theme.variables.sidebar.bordertransition,
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '.MuiTypography-root': {
      borderBottom: theme.variables.sidebar.bordertransition,
      transition: theme.variables.sidebar.bordertransition,
    },
  },
}));

const AccordionListSideBarItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
  // @ts-ignore
})(({ theme, active }) => ({
  ...{
    transition: 'all 0.1s ease',
    '& a': { borderBottom: theme.variables.sidebar.border },
    '& a:hover': {
      textDecoration: 'none',
      borderBottom: theme.variables.sidebar.borderOnHover,
      transition: theme.variables.sidebar.borderTransition,
    },
  },
  ...(active === true && {
    '& a': { borderBottom: theme.variables.sidebar.borderOnActive },
  }),
}));

const AccordionListSideBarlink = styled(Link)(({ theme }) => ({
  color: theme.variables.sidebar.itemColor,
  textDecoration: 'none',
  fontSize: theme.variables.sidebar.secondaryItemFontSize,
  letterSpacing: theme.variables.sidebar.letterSpacing,
  fontWeight: theme.variables.sidebar.itemFontWeight,
}));

const AccordionDetailsSideBar = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  paddingRop: theme.spacing(0),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const AccordionDetailsListSideBar = styled(List)(({ theme }) => ({
  padding: 0,
}));

const AccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.variables.sidebar.itemColor,
  textDecoration: 'none',
  fontSize: theme.variables.sidebar.itemFontSize,
  letterSpacing: '1px',
  fontWeight: theme.variables.sidebar.itemFontWeight,
}));

const ItemIcon = styled(Box)(({ theme }) => ({
  color: theme.variables.sidebar.itemColor,
  minWidth: 18,
  marginRight: theme.spacing(2),
}));

const ListItemSidebar = ({ item, expanded, handleChangeExpanded, pathname }) => {
  return (
    <>
      <AccordionSideBar expanded={item.expandedName === expanded} onChange={handleChangeExpanded(item.expandedName)}>
        <AccordionSummarySideBar
          expandIcon={<ArrowForwardIosSharpIcon sx={{ color: 'white', height: 15 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ItemIcon>
            <SvgItem name={item.iconName} width="1em" height="1em" style={{ fontSize: 18 }} />
          </ItemIcon>
          <AccordionTitle>{item.title}</AccordionTitle>
        </AccordionSummarySideBar>
        <AccordionDetailsSideBar>
          <AccordionDetailsListSideBar>
            {item.submenuItems &&
              item.submenuItems.map((sub, index) => {
                return (
                  <AccordionListSideBarItem
                    // @ts-ignore
                    active={sub.url === pathname}
                    key={index}
                  >
                    <AccordionListSideBarlink href={sub.url}>{sub.title}</AccordionListSideBarlink>
                  </AccordionListSideBarItem>
                );
              })}
          </AccordionDetailsListSideBar>
        </AccordionDetailsSideBar>
      </AccordionSideBar>
    </>
  );
};

export default ListItemSidebar;
