import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import MainLayout from 'components/MainLayout/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import LogListing from './LogListing';

const LogDashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Audit Trails & Activity Log',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="import">
      <Box pt={2} pb={2}>
        <Typography variant="h4" textTransform="uppercase" mb={2}>
          Audit Trails & Activity Log
        </Typography>
        <LogListing />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogDashboard);
