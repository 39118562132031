import { Box, Typography } from '@mui/material';

import cancelLogo from 'assets/icons/cancel-icon.gif';
import likeLogo from 'assets/icons/like-icon.gif';
import submitLogo from 'assets/icons/submitted-icon.gif';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

const ProjectHeaderCards = ({ candidatesCountStatus }) => {
  const {
    PANEL_INTERVIEW,
    SKILLS_ASSESSMENT,
    SUBMITTED_CUSTOMER,
    CUSTOMER_INTERVIEW,
    OFFER_EXTENDED,
    OFFER_ACCEPTED,
    PANEL_INTERVIEW_REJECTED,
    SKILLS_ASSESSMENT_REJECTED,
    SUBMITTED_CUSTOMER_REJECTED,
    CUSTOMER_INTERVIEW_REJECTED,
    OFFER_REJECTION,
    DROPPED_OUT,
  } = candidatesCountStatus;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      {/* PIPELINE CARD */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }} className="blue-card">
        <Box
          sx={{
            borderRadius: '50%',
            color: 'common.white',
            border: 4,
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 5,
          }}
        >
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'white',
            }}
          >
            {PANEL_INTERVIEW + SKILLS_ASSESSMENT + SUBMITTED_CUSTOMER + CUSTOMER_INTERVIEW + OFFER_EXTENDED + OFFER_ACCEPTED}
          </Typography>
        </Box>
        <Box>
          <img src={submitLogo} alt="" className="" height={24} style={{ marginLeft: 'auto' }} />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
              marginY: 1,
            }}
          >
            CANDIDATES
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
            }}
          >
            IN PIPELINE
          </Typography>
        </Box>
      </Box>
      {/* HIRED CARD*/}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, marginLeft: 1 }} className="green-card">
        <Box
          sx={{
            borderRadius: '50%',
            color: 'common.white',
            border: 4,
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 5,
          }}
        >
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'white',
            }}
          >
            {candidatesCountStatus.HIRED}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <img src={likeLogo} alt="" className="" height={24} style={{ marginLeft: 'auto' }} />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
              marginY: 1,
            }}
          >
            CANDIDATES
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
            }}
          >
            HIRED
          </Typography>
        </Box>
      </Box>
      {/* REJECTED Card */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, marginLeft: 1, backgroundColor: '#FF5050' }}>
        <Box
          sx={{
            borderRadius: '50%',
            color: 'common.white',
            border: 4,
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 5,
          }}
        >
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'white',
            }}
          >
            {PANEL_INTERVIEW_REJECTED +
              SKILLS_ASSESSMENT_REJECTED +
              SUBMITTED_CUSTOMER_REJECTED +
              CUSTOMER_INTERVIEW_REJECTED +
              OFFER_REJECTION +
              DROPPED_OUT}
          </Typography>
        </Box>
        <Box>
          <img src={cancelLogo} alt="" className="" height={24} style={{ marginLeft: 'auto' }} />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
              marginY: 1,
            }}
          >
            CANDIDATES
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'right',
              color: 'white',
              lineHeight: 1,
            }}
          >
            REJECTED
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeaderCards);
