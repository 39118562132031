import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowRightIcon sx={{ fontSize: '2rem', color: '#b2b2b2' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .05)',
  flexDirection: 'row',
  padding: 0,
  justifyContent: 'flex-start',
  borderBottom: ' 1px solid rgba(0, 0, 0, .125)',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiAccordionSummary-content': {
    borderTop: 'none',
    flexGrow: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export const AccordionWrapper = ({ children, accordionSummary, handleOnChange, open }) => {
  const handleChange = (e, expanded) => {
    handleOnChange(expanded);
  };

  return (
    <Accordion defaultExpanded={false} expanded={open} onChange={handleChange} sx={{ pt: 2, pb: 4 }}>
      <AccordionSummary aria-controls={`panel${0}d-content`} id={`panel${0}d-header`}>
        <Typography sx={{ fontWeight: 900, fontSize: 18 }}>{accordionSummary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
