import { createSlice } from '@reduxjs/toolkit';

let user = JSON.parse(localStorage.getItem('currentUser'));
let tokens = JSON.parse(localStorage.getItem('tokens')) || {};
let lastLogin = localStorage.getItem('lastLogin');

const initialState = { isAuthenticated: false };

const localStorageState = { loggedIn: true, user, tokens, lastLogin, isAuthenticated: true };

const authSlice = createSlice({
  name: 'auth',
  initialState: user ? localStorageState : initialState,
  reducers: {
    loginRequest: (state, action) => {
      return {
        ...state,
        loggingIn: true,
        user: action.payload.user,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        loggingIn: true,
        user: action.payload.user,
        tokens: action.payload.tokens,
        lastLogin: action.payload.lastLogin,
      };
    },
    loginExpire: (state, action) => {
      return initialState;
    },
    loginFailure: () => {
      return initialState;
    },
    logout: () => {
      return initialState;
    },
    signout: (state, action) => {
      return initialState;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, loginExpire, logout, signout } = authSlice.actions;
export default authSlice.reducer;
