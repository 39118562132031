import { Box, Divider, List, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import * as sidebarMenuItems from '../../../constants/sidebarMenuItems';
import ListItemSidebar from './ListItems/ListItemSidebar';

const SidebarMenuContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
}));

const ListSectionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'margintop',
  // @ts-ignore
})(({ theme, margintop }) => {
  return {
    ...{
      color: theme.palette.common.white,
      textDecoration: 'none',
      fontSize: 13,
      fontWeight: 'bold',
      textAlign: 'left',
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    ...(margintop === false && {
      marginTop: theme.spacing(0.5),
    }),
  };
});

const SideBarMenu = (props) => {
  const { current } = props;
  const currentUser = useSelector((state) => state.auth.user);
  const [expanded, setExpanded] = useState(current);
  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const menuItemsArr = [
    {
      title: 'Primary Menu Items',
      showSectionTitle: false,
      showBottomDivider: true,
      sectionType: 'primary',
      items: sidebarMenuItems[`${currentUser?.role.toUpperCase()}_SIDEBAR`],
    },
    {
      title: 'Account',
      showSectionTitle: true,
      sectionType: 'primary',
      items: [
        { id: 1, title: 'Profile', url: '/profile', iconName: 'profile-icon' },
        { id: 3, title: 'Settings', url: '/settings', iconName: 'settings-icon' },
      ],
    },
  ];

  return (
    <Scrollbar style={{ width: '100%', height: 'auto' }}>
      <SidebarMenuContainer>
        {/* Draw all the list sections */}
        {menuItemsArr.map((parentMenuItem, index, menuItemsObj) => {
          /* Draw A Parent List Section Item */
          return (
            <Fragment key={parentMenuItem.title}>
              {/* Show the section title if available and enabled */}
              {parentMenuItem.showSectionTitle && parentMenuItem.title && (
                <ListSectionTitle
                  // @ts-ignore
                  margintop={menuItemsObj[index - 1].showBottomDivider}
                >
                  {parentMenuItem.title}
                </ListSectionTitle>
              )}

              {/* Draw the section items */}
              <List sx={{ marginLeft: 1 }}>
                {parentMenuItem.items?.map((item) => {
                  return (
                    <ListItemSidebar
                      key={item.id}
                      item={item}
                      expanded={expanded}
                      handleChangeExpanded={handleChangeExpanded}
                    ></ListItemSidebar>
                  );
                })}
              </List>

              {/* Draw a divider if it is not the last section in the list */}
              {menuItemsObj.length - 1 !== index && parentMenuItem.showBottomDivider !== false && (
                <Divider sx={{ borderColor: 'white', marginX: 3, mt: 2 }} />
              )}
            </Fragment>
          );
        })}
      </SidebarMenuContainer>
    </Scrollbar>
  );
};

export default SideBarMenu;
