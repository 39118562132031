import { connect } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import MainLayoutV1 from 'components/MainLayout/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

import OnboardingForm from 'pages/tas/candidateForm/onboarding/OnboardingForm';
import { useParams } from 'react-router-dom';

const Onboarding = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  const { positionId, candidateId } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  const renderContent = () => (
    <Box>
      <Typography my="20px" variant="h1" color="primary" fontSize={props.isTasAdmin ? '2em' : ''}>
        Onboarding Request Format
      </Typography>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: '16px', background: 'white', borderRadius: '5px' }}
      >
        {positionId && candidateId && (
          <OnboardingForm projectId={positionId} candidateId={candidateId ?? ''} disabled={true}></OnboardingForm>
        )}
      </Stack>
    </Box>
  )

  if (props.isTasAdmin) {
    return (
      <MainLayoutV1 {...props} current="dashboard">
        {renderContent()}
      </MainLayoutV1>
    )
  }

  return (
    <MainLayout {...props} current="dashboard">
      {renderContent()}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
