import { useState } from 'react';

import { Button, Link, Modal, Stack } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'rgb(0, 0, 0, 0.8)',
  border: '1px solid #ccc',
  color: '#FAFAFA',
  fontWeight: 600,
  marginLeft: { md: 17.5 },
};

const bodyStyle = {
  fontSize: 22,
  minHeight: 400,
  pt: 4,
  pb: 2,
};

export const useModalState = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    setOpen,
    handleOpen,
    handleClose,
  };
};

export const ModalHeader = (props) => {
  const headerStyle = {
    fontSize: 24,
    bgcolor: '#ff5b5b',
    padding: '1rem 1rem 0.5rem 1rem',
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={headerStyle}>
      {props.children}
    </Stack>
  );
};

export const ModalBody = (props) => {
  return (
    <Stack sx={bodyStyle} justifyContent="space-between" alignItems="center" align="center">
      {props.children}
    </Stack>
  );
};

export const ModalConfirmButton = (props) => {
  const buttonStyle = {
    fontSize: 35,
    fontWeight: 600,
    padding: '0.5rem 3rem 0 3rem',
  };

  return (
    <Button variant="contained" color="nexxusRed" sx={buttonStyle} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export const ModalCancelButton = (props) => {
  const buttonStyle = {
    fontSize: 15,
    color: '#fafafa',
  };

  return (
    <Link
      href="#"
      sx={buttonStyle}
      onClick={(ev) => {
        ev.preventDefault();
        props.onClose();
      }}
    >
      {props.children}
    </Link>
  );
};

export default function ModalConfirmation(props) {
  return (
    <>
      <Modal hideBackdrop open={props.open} onClose={props.onClose}>
        <Stack sx={modalStyle}>{props.children}</Stack>
      </Modal>
    </>
  );
}
