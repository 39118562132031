import Joi from 'joi';
import { setValidationMessages } from '../utils/validationMessages';

export const schemaCandidateGrade = {
  professionalism: Joi.number()
    .integer()
    .min(1)
    .max(5)
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  english_proficiency: Joi.number()
    .integer()
    .min(1)
    .max(5)
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  articulation: Joi.number()
    .integer()
    .min(1)
    .max(5)
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  experience: Joi.number()
    .integer()
    .min(1)
    .max(5)
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  cultural_match: Joi.number()
    .integer()
    .min(1)
    .max(5)
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  engineering_interview: Joi.number().integer().min(1).max(5).optional().allow('', 0),
};
