import { Box, Button, FormLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useTabInterviewState } from 'contexts/TabInterviewContext';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import AddNoteModal from './AddNoteModal';
import { useGetCandidateNotesQuery } from 'api/api';

const Note = (props) => {
  const theme = useTheme();
  const note = useMemo(() => props.note, [props.note]);
  const onSelectedNote = useMemo(() => props.onSelectedNote, [props.onSelectedNote]);

  const handleSelectedNote = () => {
    onSelectedNote(note);
  };

  return (
    <Box
      sx={{
        bgcolor: props.index % 2 ? theme.palette.nexxusGrayLight.main : theme.palette.nexxusGrayExtraLight.main,
        p: 1,
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={handleSelectedNote}
    >
      <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
        {note.NoteTaker.full_name} | {moment(note.updatedAt).format('MM/DD/YYYY | h:mm a')} PST
      </Typography>
    </Box>
  );
};

const ManageCandidateNotes = (props) => {
  const theme = useTheme();
  const currentIsDisabled = useMemo(() => props.isDisabled, [props.isDisabled]);
  const { currentCandidateId, setHasChanged } = useTabInterviewState();
  const height = useMemo(() => props.height, [props.height]);
  const projectId = useMemo(() => props.projectId, [props.projectId]);
  const scope = useMemo(() => props.scope, [props.scope]);
  const label = useMemo(() => props.label, [props.label]);
  const [open, setOpen] = useState(false);
  const [noteModal, setNoteModal] = useState(null);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [noteModalAction, setNoteModalAction] = useState('add');

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const handleOpenNoteModal = () => {
    setNoteModal(null);
    setNoteModalAction('add');
    setOpenNoteModal(true);
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const handleAccept = () => {
    setHasChanged(false);
  };

  const { data: candidateNotes, refetch } = useGetCandidateNotesQuery({ candidateId: currentCandidateId, scope, projectId });

  return (
    <Box sx={{ position: 'relative' }}>
      <ModalFeedback
        variant="info"
        headerText="Interview Notes"
        titleText="Do you want to add a new note?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
      />
      <FormLabel disabled={currentIsDisabled}>{label}</FormLabel>
      <Box
        sx={{
          border: 2,
          borderColor: currentIsDisabled ? theme.variables.inputs.borderColorDisabled : theme.variables.inputs.borderColor,
          p: 1,
          width: '100%',
          height: height,
          mt: '5px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            height: height - 76,
          }}
        >
          {candidateNotes &&
            candidateNotes.map((note, index) => {
              return (
                <Note
                  key={note.id}
                  index={index}
                  note={note}
                  onSelectedNote={(note) => {
                    const unescapedNote = { ...note, note: _.unescape(note.note) };
                    setNoteModal(unescapedNote);
                    setOpenNoteModal(true);
                    setNoteModalAction('view');
                  }}
                />
              );
            })}
        </Box>
      </Box>

      <Button variant="contained" onClick={handleOpenNoteModal} disabled={currentIsDisabled} sx={{ mt: 2 }}>
        Add New Note
      </Button>

      <AddNoteModal
        key={`note-${noteModalAction}-${noteModal?.id}`}
        candidateId={currentCandidateId}
        projectId={projectId}
        scope={scope}
        disabled={currentIsDisabled}
        note={noteModal}
        open={openNoteModal}
        action={noteModalAction}
        onSuccess={() => {
          setOpenNoteModal(false);
          refetch();
          handleAccept();
        }}
        onModeChange={(action) => {
          setNoteModalAction(action);
        }}
        onClose={handleCloseNoteModal}
      />
    </Box>
  );
};

export default ManageCandidateNotes;
