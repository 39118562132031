import { createSlice } from '@reduxjs/toolkit';

const initialState = { locations: [], candidateOpen: false, candidateInfo: {}, candidate: {}, controlViewer: { pdfSrc: '', source: '' } };

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    setLocations: (state, action) => {
      return {
        ...state,
        locations: action?.payload?.locations,
      };
    },
    setCandidateInfo: (state, action) => {
      return {
        ...state,
        candidateInfo: !state.candidateOpen ? action.payload.candidateInfo : {},
      };
    },
    setCandidateOpen: (state, action) => {
      const newState = {
        ...state,
        candidateOpen: !state.candidateOpen,
        candidate: !state.candidateOpen
          ? {
              id: action.payload.CandidateId,
              position: action.payload.ProjectId,
              pipeline: action.payload.pipeline,
              tabDefault: action.payload.tabDefault,
              target: action.payload.target,
              controlViewer: action?.payload?.controlViewer ?? { pdfSrc: '', source: '' },
            }
          : {},
      };

      if (!state.candidateOpen) newState.candidateInfo = {};

      return newState;
    },
  },
});

export const { setCandidateInfo, setCandidateOpen, setLocations } = customerSlice.actions;
export default customerSlice.reducer;
