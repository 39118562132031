import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Stack } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useState } from 'react';

const RenderActions = (props) => {
  const { row, setOpenModalUser, setUserSelected } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleEdit = (ev) => {
    ev.preventDefault();
    Promise.all([setUserSelected(row), setOpenModalUser(true)]);
  };

  return (
    <>
      <ModalFeedback
        variant="warning"
        open={openDeleteModal}
        headerText={'Delete Current User'}
        titleText={'Do you want to delete this user?'}
        onCancel={() => setOpenDeleteModal(false)}
        onAccept={() => props.onHandleDeleteUser()}
        onClose={() => setOpenDeleteModal(false)}
      />
      <Stack direction="row" spacing={1}>
        <IconButton onClick={handleEdit} size="small">
          <EditIcon color={'nexxusBlack'} />
        </IconButton>
        <IconButton onClick={() => setOpenDeleteModal(true)} size="medium" sx={{ mx: 4 }}>
          <HighlightOffIcon color={'error'} />
        </IconButton>
      </Stack>
    </>
  );
};

export default RenderActions;
