import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Stack, Divider, Typography, Button } from '@mui/material';

import { Cached, ThumbUpAlt, NotInterested } from '@mui/icons-material';

import ClientInfo from './ClientInfo';
import DashboardCard from '../../../components/Dashboard/DashboardCard';
import CandidatePipeline from './CandidatePipeline';

const ActiveProjectsHeader = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();

  const handleEditProject = (projectId) => {
    navigate(`/tas/dashboard/active_projects/${customerId}/${projectId}`);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <ClientInfo />
        <Stack justifyContent="space-around" sx={{ width: '60%' }} spacing={1}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Typography sx={{ fontSize: 12, color: 'green' }}>STATUS: ACTIVE</Typography>
            <Button
              variant="outlined"
              color="error"
              sx={{ height: '1rem', fontSize: 10, fontWeight: 'bold', p: 1 }}
              onClick={() => {
                handleEditProject('project-001');
              }}
            >
              EDIT PROJECT
            </Button>
            <Button variant="outlined" color="error" sx={{ height: '1rem', fontSize: 10, fontWeight: 'bold', p: 1 }}>
              NOTIFY CUSTOMER
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <DashboardCard bgcolor="blue-card" totalSize={60}>
              <Typography>
                <Cached />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>CANDIDATES</Typography>
              <Typography sx={{ fontSize: 15 }}>IN PIPELINE</Typography>
            </DashboardCard>
            <DashboardCard bgcolor="green-card" totalSize={50}>
              <Typography>
                <ThumbUpAlt />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>CANDIDATES</Typography>
              <Typography sx={{ fontSize: 15 }}>SUBMITTED</Typography>
            </DashboardCard>
            <DashboardCard bgcolor="red-card" totalSize={50}>
              <Typography>
                <NotInterested />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>CANDIDATES</Typography>
              <Typography sx={{ fontSize: 15 }}>REJECTED</Typography>
            </DashboardCard>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default function ActiveProjects() {
  return (
    <>
      <ActiveProjectsHeader />

      <Divider sx={{ mt: 10 }} />
      <h3>%FORM - EDIT PROJECT%</h3>
      <Divider sx={{ mb: 10 }} />

      <CandidatePipeline />
    </>
  );
}
