import { Box, Grid } from '@mui/material';
import { WidgetErrorPage, WidgetErrorBody } from '@nexxus/components';
import { useGetCurrentUserQuery } from 'api/api';
import MainLayout from 'components/MainLayout/MainLayout';
import MainLayoutNew from 'components/MainLayoutRedesign/MainLayout';
import { PATH_ROLE, ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from 'constants/users';

import { connect } from 'react-redux';

import { loginExpire } from 'store/auth';

const UserMainLayout = (props) => {
  return [ROLE_SUPERCUSTOMER, ROLE_CUSTOMER].includes(props.auth.user?.role) ? (
    <MainLayoutNew {...props} avatarTopBar={props.user?.Client?.logoUrl ?? ''}>
      {props.children}
    </MainLayoutNew>
  ) : (
    <MainLayout {...props}></MainLayout>
  );
};

const Page403 = (props) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  return (
    <UserMainLayout user={currentUser} {...props}>
      <Box>
        <Grid container mt={'20px'} spacing={2}>
          <Grid item xs={12}>
            {[ROLE_SUPERCUSTOMER, ROLE_CUSTOMER].includes(props.auth.user?.role) ? (
              <WidgetErrorPage
                code={403}
                legend={'PERMISSION DENIED'}
                path={`/${PATH_ROLE(props.auth.user?.role)}/dashboard`}
              ></WidgetErrorPage>
            ) : (
              <WidgetErrorBody
                code={403}
                legend={'PERMISSION DENIED'}
                path={`/${PATH_ROLE(props.auth.user?.role)}/dashboard`}
              ></WidgetErrorBody>
            )}
          </Grid>
        </Grid>
      </Box>
    </UserMainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page403);
