import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import SvgItem from '../../../Media/SvgItem';

const ListSideBarItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
  // @ts-ignore
})(({ theme, active }) => ({
  ...{
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    color: theme.variables.sidebar.itemColor,
    minHeight: '48px',
    padding: '0px 16px',
    '.MuiListItemText-primary': {
      borderBottom: theme.variables.sidebar.border,
      transition: theme.variables.sidebar.bordertransition,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '.MuiListItemText-primary': {
        borderBottom: theme.variables.sidebar.borderOnHover,
        transition: theme.variables.sidebar.bordertransition,
      },
    },
  },
  ...(active === true && {
    '.MuiListItemText-primary': {
      borderBottom: theme.variables.sidebar.borderOnActive,
    },
  }),
}));

const ListSideBarItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.variables.sidebar.itemColor,
  minWidth: 18,
  marginRight: theme.spacing(2),
}));

const ListSideBarlink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  transition: 'all 0.1s ease',
  '&:hover': {
    textDecoration: 'none',
    // backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const ListSideBarItemText = styled(ListItemText)(({ theme }) => ({
  margin: '0px',
  '.MuiListItemText-primary': {
    color: theme.variables.sidebar.itemColor,
    transition: theme.variables.sidebar.bordertransition,
    textDecoration: 'none',
    fontSize: theme.variables.sidebar.itemFontSize,
    letterSpacing: theme.variables.sidebar.letterSpacing,
    fontWeight: theme.variables.sidebar.itemFontWeight,
    margin: '0px',
  },
}));

const SingleListItem = ({ item, pathname }) => {
  return (
    <>
      <ListSideBarItem
        // @ts-ignore
        active={item.url === pathname}
        key={item.title}
      >
        <ListSideBarlink href={item.url}>
          <ListSideBarItemIcon>
            <SvgItem name={item.iconName} width="1em" height="1em" style={{ fontSize: 18 }} />
          </ListSideBarItemIcon>
          <ListSideBarItemText primary={item.title} />
        </ListSideBarlink>
      </ListSideBarItem>
    </>
  );
};

export default SingleListItem;
