import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import { excludeForTotalCounters, GROUP_PROJECT_STATUS_REJECTED } from 'constants/customer';
import customerService from 'services/customerService';

import { useCallback, useEffect, useState } from 'react';
import { default as candidateService } from 'services/candidateService';

const useCustomerProjectCandidates = () => {
  const initialCounters = {
    PANEL_INTERVIEW: {
      orderTab: 1,
      count: 0,
      key: 'PANEL_INTERVIEW',
      label: 'qualification interview',
      cssClass: '',
      projectStatus: 'PANEL_INTERVIEW',
      in: ['tas', 'customer'],
    },
    SKILLS_ASSESSMENT: {
      orderTab: 2,
      count: 0,
      key: 'SKILLS_ASSESSMENT',
      label: 'skills assessment',
      cssClass: '',
      projectStatus: 'SKILLS_ASSESSMENT',
    },
    SUBMITTED_CUSTOMER: {
      orderTab: 3,
      count: 0,
      key: 'SUBMITTED_CUSTOMER',
      label: 'submitted',
      cssClass: '',
      projectStatus: 'SUBMITTED_CUSTOMER',
    },
    CUSTOMER_INTERVIEW: {
      orderTab: 4,
      count: 0,
      key: 'CUSTOMER_INTERVIEW',
      label: 'interview',
      cssClass: '',
      projectStatus: 'CUSTOMER_INTERVIEW',
    },
    HIRED: {
      orderTab: 5,
      count: 0,
      key: 'HIRED',
      label: 'hired',
      cssClass: 'hired',
      projectStatus: 'HIRED',
      in: ['tas', 'customer'],
    },
    REJECTED: {
      orderTab: 6,
      count: 0,
      key: 'REJECTED',
      label: 'rejected',
      cssClass: 'rejected',
      projectStatus: 'REJECTED',
      in: ['tas', 'customer'],
    },
    ARCHIVED: {
      count: 0,
    },
  };

  const [projectId, setProjectId] = useState('');
  const [customer, setCustomer] = useState({});
  const [statusSelected, setStatusSelected] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [candidateSelected, setCandidateSelected] = useState('');
  const [loading, setLoading] = useState(false);

  const [candidatesCountStatus, setCandidatesCountStatus] = useState(initialCounters);
  const sortBy = 'id';

  const getStatusArr = useCallback(() => {
    let status = [];
    let statusProject;
    if (statusSelected === 'REJECTED') {
      status = [CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED, CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED];
      statusProject = 'REJECTED';
    } else {
      status.push(CANDIDATEPROJECT_STATUS[statusSelected]);
      statusProject = statusSelected;
    }

    return { statusArr: status, statusTab: statusProject };
  }, [statusSelected]);

  const fetchCandidates = useCallback(async () => {
    const tab = getStatusArr();
    let params = {
      status: tab.statusArr,
      sortBy: sortBy,
      includeLogs: false,
      excludeRelationsCandidate: true,
    };
    if (candidateSelected !== '') params['candidateId'] = candidateSelected;

    let src;
    if (projectId && projectId !== '') {
      const { data } = await candidateService.getCandidatesByProjectStatus(projectId, params);
      src = data;
    }

    return src ?? [];
  }, [candidateSelected, getStatusArr, projectId]);

  const setSourceAndCounters = useCallback(async () => {
    if (customer && projectId) {
      const { data: countsStatus } = await customerService.getCustomerCounters(
        customer.id,
        projectId,
        {
          excludeForTotal: excludeForTotalCounters,
        },
        true
      );
      Promise.all([
        setCandidatesCountStatus((prev) => ({
          ...prev,
          PANEL_INTERVIEW: { ...prev['PANEL_INTERVIEW'], count: countsStatus?.COUNTER_PANEL_INTERVIEW ?? 0 },
          SKILLS_ASSESSMENT: { ...prev['SKILLS_ASSESSMENT'], count: countsStatus?.COUNTER_SKILLS_ASSESSMENT ?? 0 },
          CUSTOMER_INTERVIEW: { ...prev['CUSTOMER_INTERVIEW'], count: countsStatus?.COUNTER_CUSTOMER_INTERVIEW ?? 0 },
          SUBMITTED_CUSTOMER: { ...prev['SUBMITTED_CUSTOMER'], count: countsStatus?.COUNTER_SUBMITTED_CUSTOMER ?? 0 },
          HIRED: { ...prev['HIRED'], count: countsStatus?.COUNTER_HIRED ?? 0 },
          REJECTED: {
            ...prev['REJECTED'],
            count:
              // Number(countsStatus?.COUNTER_PANEL_INTERVIEW_REJECTED ?? 0) +
              // Number(countsStatus?.COUNTER_SKILLS_ASSESSMENT_REJECTED ?? 0) +
              Number(countsStatus?.COUNTER_CUSTOMER_INTERVIEW_REJECTED ?? 0) +
              Number(countsStatus?.COUNTER_SUBMITTED_CUSTOMER_REJECTED ?? 0)
              // Number(countsStatus?.COUNTER_OFFER_REJECTION ?? 0) +
              // Number(countsStatus?.COUNTER_DROPPED_OUT ?? 0),
          },
          ARCHIVED: { ...prev['ARCHIVED'], count: countsStatus?.COUNTER_ARCHIVED ?? 0 },
        })),
      ]);
    }
  }, [customer, projectId]);

  useEffect(() => {
    (async () => {
      if (statusSelected !== '') {
        const candidates = await fetchCandidates();
        setLoading(true);
        setSourceAndCounters();
        setCandidates(candidates);
      }
    })();
  }, [fetchCandidates, setSourceAndCounters, statusSelected]);

  return {
    initialCounters,
    candidates,
    projectId,
    candidatesCountStatus,
    statusSelected,
    customer,
    candidateSelected,
    loading,
    setCandidateSelected,
    setStatusSelected,
    setProjectId,
    setCandidates,
    setCustomer,
    setLoading,
    fetchCandidates
  };
};

export default useCustomerProjectCandidates;
