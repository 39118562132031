import { Grid, Button, FormLabel, FormControl, FormHelperText, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useTheme } from '@mui/styles';
import { useAddTagMutation } from 'api/api';

import { OutlinedInput } from '@nexxus/components';

const CreateTagForm = ({ onSuccess, onError }) => {
  const theme = useTheme();
  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().label('Tag Name'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const [addTag] = useAddTagMutation();

  const onSubmit = async (data) => {
    console.log(data);

    addTag(data)
      .unwrap()
      .then((response) => {
        console.log(response);
        onSuccess(response.data);
        reset();
      })
      .catch((error) => {
        console.log(error);
        onError(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" textTransform="uppercase" mb={3}>
        Manage Tags
      </Typography>
      <Grid container spacing={2} mb={4} sx={{ maxWidth: 600 }}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <Controller name="name" control={control} render={({ field }) => <OutlinedInput fullWidth {...field} />} />
            <FormHelperText sx={{ color: theme.palette.warning.main }}>{errors.name && errors.name.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl>
            <FormLabel>&nbsp;</FormLabel>
            <Button type="submit" variant="contained" size="large" color="primary">
              Add New Tag
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateTagForm;
