import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useState } from 'react';
import parse from 'html-react-parser';
import _ from 'lodash';

const Note = ({ note, index }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        bgcolor: index % 2 ? theme.palette.nexxusGrayLight.main : theme.palette.nexxusGrayExtraLight.main,
        p: 1,
        mb: 1,
        '& > p > ul > li': {
          listStyle: 'disc',
          marginLeft: '20px',
          marginBottom: '10px',
        },
      }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
        {parse(_.unescape(note.note))}
      </Typography>
      <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
        {note.NoteTaker.full_name} | {moment(note.createdAt).format('MM/DD/YYYY | h:mm a')} PST
      </Typography>
    </Box>
  );
};

export default function CandidateProjectNotesPopover({ label, notes }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="text" onClick={handleClick} sx={{ color: theme.palette.primary.lighter }}>
        {label}
      </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            elevation: 1,
            sx: {
              borderRadius: 0,
              p: 0.75,
              minWidth: 100,
              minHeight: 40,
              width: notes.length ? 480 : 'auto',
              height: notes.length ? 240 : 'auto',
              overflowY: 'auto',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Stack spacing={0.5}>
            {notes?.map((note, index) => (
              <Note key={note.id} index={index} note={note} />
            ))}

            {notes?.length === 0 && (
              <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="bold">
                No Notes
              </Typography>
            )}
          </Stack>
        </Popover>
    </div>
  );
}
