import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { publish } from 'events.js';
import { useEffect, useMemo, useState } from 'react';
import { usePrevious, capitalizeString } from 'utils/utils';

import _ from 'lodash';
import { styled } from '@mui/system';
import { useGetSkillsQuery, useGetTagsQuery, useGetManagedCountriesQuery } from 'api/api';
import { seniority as seniorityOptions, timezoneOptsCand } from 'utils/staticData';
import { POSITIONS } from '@nexxus/constants';
import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';

const gridItemStyles = {
  xs: 3,
};

const IncludeLabel = styled(FormLabel)({
  marginBottom: 0,
  marginRight: '0.74rem',
});

const initialFilterState = {
  position: '',
  seniority: '',
  skills: [],
  tags: [],
  location: [],
  LocationCountryId: [],
  timezone: [],
  show_blacklisted: false,
  show_archived: false,
  pipeline_status: '',
  name: '',
  email: '',
};

const CandidateTableFilters = ({ showIncludeBox }) => {
  const { data: skills, isSuccess: skillsIsSuccess } = useGetSkillsQuery();
  const [skillValue, setSkillValue] = useState([]);
  const { data: tags, isSuccess: tagsIsSuccess } = useGetTagsQuery();
  const [tagValue, setTagValue] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState(initialFilterState);
  const [disableBtnFilter, setDisableBtnFilter] = useState(true);
  const previousFilters = usePrevious(filters);

  const { data: managedCountries } = useGetManagedCountriesQuery();
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    if (managedCountries) {
      setLocationOptions(managedCountries.map((country) => ({ _id: country.id, label: country.name })));
    }
  }, [managedCountries]);

  useMemo(() => {
    const isFieldEmpty = (value) => {
      return value === '' || value.length === 0;
    };
    let disabled = true;
    Object.keys(filters).forEach((key) => {
      const filterValue = filters[key];
      if (!isFieldEmpty(filterValue)) {
        disabled = false;
      }
    });
    setDisableBtnFilter(disabled);
  }, [filters]);

  useMemo(() => {
    if (resetFilter && previousFilters !== filters) {
      publish('CandidateSearchFilterUpdate', filters);
    }
    setResetFilter(false);
  }, [resetFilter]);

  const FilterButton = styled(Button)({
    fontSize: 18,
    fontWeight: 'bold',
  });
  const showIncludeInputBox = showIncludeBox === undefined ? true : showIncludeBox;

  const filterSearch = () => {
    setFilters(initialFilterState);
    setSkillValue([]);
    setTagValue([]);
    setResetFilter(true);
  };

  const skillOptions = useMemo(() => {
    return skills?.filter((sk) => sk.isActive) || [];
  }, [skills]);

  return (
    <>
      {showIncludeInputBox ? (
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" mb={2}>
          <Button
            variant="contained"
            color={'warning'}
            disabled={disableBtnFilter}
            sx={{ marginRight: '1em' }}
            onClick={filterSearch}
          >
            Clear Filters
          </Button>
          <FormControl>
            <IncludeLabel>INCLUDE:</IncludeLabel>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={filters.show_blacklisted}
                  onChange={(_e, checked) => setFilters((prev) => ({ ...prev, show_blacklisted: checked }))}
                />
              }
              label={<Typography variant="caption">BLACKLISTED</Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={filters.show_archived}
                  onChange={(_e, checked) => setFilters((prev) => ({ ...prev, show_archived: checked }))}
                />
              }
              label={<Typography variant="caption">ARCHIVED</Typography>}
            />
          </FormControl>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" mb={2}>
          <Button
            variant="contained"
            color={'warning'}
            disabled={disableBtnFilter}
            sx={{ marginRight: '1em' }}
            onClick={filterSearch}
          >
            Clear Filters
          </Button>
        </Stack>
      )}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <InputLabel htmlFor="name">Name</InputLabel>
          <OutlinedInput
            id="name"
            onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
            value={filters.name}
            placeholder="Filter by name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            onChange={(e) => setFilters((prev) => ({ ...prev, email: e.target.value }))}
            value={filters.email}
            placeholder="Filter by email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputAutoCompleteV2
            id="position"
            label="Position"
            placeholder="No Selection"
            opts={Object.values(POSITIONS)}
            onChangeInput={(e) => setFilters((prev) => ({ ...prev, position: e.target.value }))}
            initialValue={filters.position}
            defaultOption={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputAutoCompleteV2
            label="Seniority"
            id="seniority"
            placeholder="No Selection"
            opts={seniorityOptions}
            onChangeInput={(e) => setFilters((prev) => ({ ...prev, seniority: e.target.value }))}
            initialValue={filters.seniority}
            defaultOption={true}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="skills">Skills</FormLabel>
            {skillsIsSuccess && (
              <Autocomplete
                multiple
                id="skills"
                limitTags={5}
                options={skillOptions}
                value={skillValue}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return <TextField {...params} placeholder={skillValue.length ? '' : 'Type to search Skills'} />;
                }}
                onChange={(ev, value) => {
                  setSkillValue(value);
                  setFilters((prev) => ({ ...prev, skills: value.map((skill) => skill.id) }));
                }}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="tags">Tags</FormLabel>
            {tagsIsSuccess && (
              <Autocomplete
                multiple
                id="tags"
                limitTags={5}
                options={tags}
                value={tagValue}
                getOptionLabel={(option) => capitalizeString(option.name)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return <TextField {...params} placeholder={tagValue.length ? '' : 'Type to search Tags'} />;
                }}
                onChange={(ev, value) => {
                  setTagValue(value);
                  setFilters((prev) => ({ ...prev, tags: value.map((tag) => tag.id) }));
                }}
                getOptionDisabled={(option) => (tagValue.length >= 3 || tagValue.includes(option) ? true : false)}
              />
            )}
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputAutoCompleteV2
            label="Location"
            id="LocationCountryId"
            placeholder="No Selection"
            opts={locationOptions}
            onChangeInput={(e) => {
              setFilters((prev) => ({ ...prev, LocationCountryId: e.target.value }));
            }}
            initialValue={filters.LocationCountryId}
            multiple={true}
            defaultOption={false}
            limitResults={true}
          />
        </Grid>
        <Grid item xs={3}>
          <InputAutoCompleteV2
            label="Timezone"
            id="timezone"
            placeholder="No Selection"
            opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
            onChangeInput={(e) => {
              setFilters((prev) => ({ ...prev, timezone: e.target.value }));
            }}
            initialValue={filters.timezone}
            multiple={true}
            defaultOption={false}
            limitResults={true}
          />
        </Grid>
        <Grid item {...gridItemStyles}>
          <InputAutoCompleteV2
            id="ProjectStatus"
            label="Project Stage"
            size={'normal'}
            opts={Object.keys(CANDIDATEPROJECT_STATUS).map((key, value) => {
              return { _id: key.toLowerCase(), label: key.replaceAll('_', ' ') };
            })}
            initialValue={filters.pipeline_status}
            placeholder={'No Selection'}
            onChangeInput={(e) => setFilters((prev) => ({ ...prev, pipeline_status: e.target.value }))}
            defaultOption={true}
          />
        </Grid>
        <Grid item {...gridItemStyles}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>&nbsp;</FormLabel>
            <FilterButton
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                if (previousFilters !== filters) {
                  publish('CandidateSearchFilterUpdate', filters);
                }
              }}
            >
              FILTER RESULTS
            </FilterButton>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CandidateTableFilters;
