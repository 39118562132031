import { useCallback } from 'react';
import { Grid, Typography, Link, FormControlLabel, Switch } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ROLE_TAS } from 'constants/users';

const ManageClientsHeader = ({ role, hideArchiveCustomers, setHideArchiveCustomers }) => {
  const handleSwitch = useCallback((_e, checked) => {
    setHideArchiveCustomers(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={2} sx={{ marginY: 4 }}>
      <Grid container alignItems="center" item sm={6}>
        <Typography align="left" sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', px: 4 }}>
          ALL CUSTOMERS
        </Typography>
        {![ROLE_TAS].includes(role) && (
          <Link
            component={RouterLink}
            to="/tas/customer/create"
            sx={{
              '&:hover': {
                textDecoration: 'none',
              },
            }}
          >
            <Typography
              display="flex"
              sx={{
                fontWeight: 'bold',
                px: 1,
                py: '8px',
                fontSize: '12px',
                color: 'white',
                backgroundColor: 'nexxusGreenDark.main',
              }}
            >
              + ADD A NEW CUSTOMER
            </Typography>
          </Link>
        )}
        <FormControlLabel
          control={<Switch onChange={handleSwitch} checked={hideArchiveCustomers} disabled={false} />}
          label="Hide Archived"
          labelPlacement="start"
        />
      </Grid>
    </Grid>
  );
};

export default ManageClientsHeader;
