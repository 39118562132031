import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { useGetCurrentUserQuery } from 'api/api';
import { HubspotContactForm } from '@nexxus/components';

const HelpContactForm = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Request Support',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="Help" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      <Box>
        <Grid container spacing={2} display='flex' justifyContent='center'>
          <Grid item xs={12} md={10}>
            <Typography my="20px" variant="h1" color="primary">
              Request Support
            </Typography>
          </Grid>
        </Grid>
        
        <Grid container spacing={2} display='flex' justifyContent='center'>
          <Grid item xs={12} md={10}>
            <HubspotContactForm />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpContactForm);
