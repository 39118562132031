import moment from 'moment';
import http from './httpService';
import axios from 'api/axios';

export async function login(user) {
  const body = { ...user };

  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.interceptors.request.use(null, (error) => {
    Promise.reject(error);
  });
  axios.interceptors.response.use((response) => {
    return response;
  });

  const { data } = await axios.post(
    process.env.REACT_APP_API_BACKEND + '/v1/auth/login',
    {
      email: body.email,
      password: body.password,
      gather: body.gather,
    },
    {
      withCredentials: true,
    }
  );

  if (data.tokens) http.setTokens(data.tokens);
  localStorage.setItem('currentUser', JSON.stringify(data.user));
  localStorage.setItem('lastLogin', moment(new Date()).format('DD/MM/yyyy h:mm:ss a'));

  if (localStorage.getItem('remember') && localStorage.getItem('remember') === body.email) {
    if (body.remember === true) localStorage.setItem('remember', body.email);
    else localStorage.removeItem('remember');
  } else {
    if (body.remember === true) localStorage.setItem('remember', body.email);
  }

  return data;
}

export async function logout() {
  await axios
    .post(`${process.env.REACT_APP_API_BACKEND}/v1/auth/logout`, null, {
      withCredentials: true,
    })
    .catch((error) => {
      console.log(error);
    });

  localStorage.removeItem('tokens');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('lastLogin');
}

export async function getCurrentUser() {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/users/me`);
}

export function getAllTokens() {
  return localStorage.getItem(http.apiAllTokens);
}

export async function getRememberUser() {
  return localStorage.getItem('remember');
}

export async function forgotPassword(email) {
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const { data } = await axios.post(process.env.REACT_APP_API_BACKEND + '/v1/auth/forgot-password', {
    email: email,
  });

  return data;
}

export async function resetPassword(password, token) {
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const { data } = await axios.post(
    process.env.REACT_APP_API_BACKEND + '/v1/auth/reset-password',
    { password: password },
    {
      params: {
        token: token,
      },
    }
  );

  return data;
}

export async function checkExpToken(token) {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const { data } = await axios.post(process.env.REACT_APP_API_BACKEND + '/v1/auth/check-token', {
    token: token,
  });
  return data;
}

export default {
  login,
  logout,
  getCurrentUser,
  getAllTokens,
  forgotPassword,
  resetPassword,
  checkExpToken,
  getRememberUser,
};
