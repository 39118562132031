import FieldSkills from 'pages/tas/projects/EditProject/fieldSkills';
import { useCallback } from 'react';
import { SKILL_TYPE } from 'utils/staticData';
import {
  schemaRepeaterSkill,
  schemaRepeaterSkillOptional,
  schemaRepeaterSkillYear,
  schemaRepeaterSkillYearOptional,
} from 'validations/projects/projectRegister';

const FieldsNewAdittionalSkills = ({
  keyField,
  fields,
  errors,
  skillsSearch,
  reset,
  setValues,
  handleSaveRepeaterProp,
  setStopNavigation,
}) => {
  const handleOnChangeInputSelect = useCallback(
    (evt, schema) => {
      const element = evt.target.name.split('_');
      if (evt.target.value !== fields[element[1]][element[2]]) {
        handleSaveRepeaterProp(evt, schema);
        setStopNavigation(true);
      }
    },
    [fields, handleSaveRepeaterProp, setStopNavigation]
  );

  return (
    <FieldSkills
      skills={fields}
      errors={errors}
      skillsSearch={skillsSearch}
      reset={reset}
      keyField={keyField}
      addMoreSkill={() => {
        const newFields = [...fields];
        newFields.push({ SkillId: '', skill_type: SKILL_TYPE.MUST_HAVE, yearExperience: '' });
        setValues((prev) => ({ ...prev, [keyField]: newFields }));
      }}
      removeMoreSkill={() => {
        const newFields = [...fields];
        setValues((prev) => ({ ...prev, [keyField]: newFields.slice(0, -1) }));
      }}
      onUpdateSkill={handleOnChangeInputSelect}
      schemas={{
        newSkill: { skill: schemaRepeaterSkill, year: schemaRepeaterSkillYear },
        adittionalSkill: { skill: schemaRepeaterSkillOptional, year: schemaRepeaterSkillYearOptional },
      }}
    />
  );
};

export default FieldsNewAdittionalSkills;
