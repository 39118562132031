import { ROLE_ACCOUNT_MANAGER } from 'constants/users';
import Projects from 'pages/accountManager/Projects';
import ViewProject from 'pages/accountManager/projects/ViewProject';
import { Route } from 'react-router-dom';
import Dashboard from '../pages/accountManager/Dashboard';
import PrivateRoute from './privateRouter';

const accountManagagerRoutes = [
  {
    name: 'Dashboard Account Manager',
    path: '/accountmanager/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },

  {
    name: 'View Projects',
    path: '/accountmanager/projects',
    element: <Projects />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'View Project',
    path: '/accountmanager/projects/:ProjectId/view',
    element: <ViewProject />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  }
];

export const AccountManagagerRoutes = ({ user }) => {
  return accountManagagerRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
