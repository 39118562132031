import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeContext, CookiesFooter } from '@nexxus/components';
import MainContainer from 'components/MainLayout/MainContainer';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/auth';
import Breadcrumbs from './Breadcrumbs';
import SideBar from './Sidebar/SideBar';
import Topbar from './topbar';
import { useSetLastUserActivityMutation } from '../../api/api';

import { useIdleTimer } from 'react-idle-timer';

const drawerWidth = 350;

const timeout_minutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?? '15';
const session_idle_timeout = 1000 * 60 * parseInt(timeout_minutes);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  maxWidth: '100%',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(12, 2, 3, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 3, 3, 3),
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MainBarebone = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  backgroundColor: '#ECECEC',
  width: '100%',
  minHeight: 'calc(100vh)',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 0, 3, 0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0, 3, 0),
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MainLayout = (props) => {
  const history = useNavigate();
  const { window, current, variant = 'old' } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { setThemeName, themeName } = useContext(ThemeContext);

  const [setLastUserActivity] = useSetLastUserActivityMutation();

  useIdleTimer({
    timeout: session_idle_timeout,
    throttle: 1000 * 30,
    onIdle: () => history('/logout'),
    onAction: () => setLastUserActivity(),
  });

  useEffect(() => {
    setThemeName('old');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    history('/logout');
  };

  const BreadcrumbsStyled = styled(Breadcrumbs)({
    paddingLeft: '40px',
  });

  return (
    <Box sx={{ display: 'flex' }}>
      {themeName === 'old' && props?.auth?.user && (
        <Topbar {...props} handleSidebarOpen={() => setMobileOpen(!mobileOpen)} onHandleLogout={handleLogout}></Topbar>
      )}
      <Box component="nav" sx={{ width: { lg1: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        {themeName === 'old' && props?.auth?.user && (
          <SideBar
            {...props}
            window={window}
            mobileOpen={mobileOpen}
            handleSidebarOpen={(val) => setMobileOpen(val)}
            current={current}
            drawerWidth={drawerWidth}
          />
        )}
      </Box>
      {variant !== 'transition' ? (
        <Main open={mobileOpen}>
          <Container maxWidth="lg2" sx={{ mb: 2 }}>
            <BreadcrumbsStyled maxWidth="lg2" />
          </Container>
          <MainContainer>{props.children}</MainContainer>
        </Main>
      ) : (
        <MainBarebone open={mobileOpen}>{props.children}</MainBarebone>
      )}
      <CookiesFooter />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
