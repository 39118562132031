import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, CircularProgress, FormControl, Grid, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SvgButton from 'components/Candidates/InterviewCandidateTab/Elements/SvgButton';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';
import useSignedS3Url from '../../../hooks/useSignedS3Url';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';
import ModalPdfViewer from 'components/Generic/Modal/ModalPdfViewer';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

const MoreUploadsTab = ({ isDisabled, setHasChanged }) => {
  const [attachments, setAttachments] = useState([]);
  const [attachment, setAttachment] = useState({});
  const [files, setFiles] = useState('');
  const [saving, setSaving] = useState(false);
  const [fileViewer, setFileViewer] = useState('');
  let formRef = useRef();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const schema = Joi.object({
    title_upload: Joi.string().required().messages({
      'string.empty': 'Field required',
      'any.invalid': 'Field required',
    }),
    url: Joi.string().required().allow(' ').messages({
      'string.empty': 'Field required',
      'any.invalid': 'Field required',
    }),
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      title_upload: '',
      url: '',
    },
  });

  const handleFileChange = ({ target }) => {
    setFiles(target.files?.[0] || []);
    setValue('url', target.files?.[0] ? ' ' : '');
    trigger('url');
    handleChange();
  };
  const theme = useTheme();
  let { CandidateId } = useParams();
  const { handleFileUpload, getSignedDownloadUrl } = useSignedS3Url();

  const columns = [
    {
      field: 'title',
      headerName: 'TITLE',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Actions',
      editable: false,
      sortable: false,
      width: 140,
      renderCell: ({ row }) => {
        return (
          <>
            <SvgButton
              fontSize="28px"
              color="nexxusBlack"
              iconName="pdf-icon"
              label=""
              onClick={async () => {
                const signedUrl = await getSignedDownloadUrl(row.url);
                setFileViewer(signedUrl);
              }}
            />
            <SvgButton
              LinkComponent="a"
              disableRipple
              target="_blank"
              isDisabled={isDisabled}
              href={row.url}
              fontSize="28px"
              iconName="remove-icon"
              label=""
              onClick={() => {
                setAttachment(row);
                setOpenDeleteModal(true);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const {
        data: { attachments },
      } = await candidateService.getCandidateAttachments(CandidateId);
      if (attachments) setAttachments(attachments);
    })();
  }, []); // eslint-disable-line

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpenDeleteModal(false);
  };

  const onAcceptDelete = async () => {
    const newAt = [...attachments].filter((at) => at !== attachment);
    Promise.all([
      await candidateService.updateCandidateAttachment(CandidateId, { type: 'attachments', attachments: newAt }),
      await candidateService.updateCandidateActivity(CandidateId),
      setAttachments(newAt),
    ]);
  };

  const handleChange = () => {
    setHasChanged(true);
  };

  const onSubmit = () => {
    (async () => {
      try {
        if (!saving) setSaving(true);
        const payload = {
          id: 'attachment',
          dest: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
          title: getValues('title_upload'),
        };

        const s3Upload = await handleFileUpload({
          file: files,
          contentType: 'application/pdf',
          path: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
        });

        if (s3Upload) {
          payload.url = s3Upload.object_url;
        }
        const { data } = await candidateService.uploadCandidateSignedFile(CandidateId, payload);
        await candidateService.updateCandidateActivity(CandidateId);
        setSaving(false);
        setFiles('');
        setAttachments(data.meta_uploads.attachments);
        toast.success('New attachment saved successfully!');
        reset();
      } catch (error) {
        setSaving(false);
        let err = '';
        if (typeof error?.response?.data?.message === 'string') {
          err = error.response.data.message;
          return toast.error(err);
        } else if (error.message) {
          err = error.message;
        } else {
          const objError = JSON.parse(error.response.data.message);
          err = objError.message;
        }
        return toast.error(err);
      }
    })();
  };

  return (
    <Grid container spacing={2} component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <ModalPdfViewer fileViewer={fileViewer} onClose={() => setFileViewer('')} />
      <ModalFeedback
        variant="warning"
        headerText="Delete Test Result"
        titleText="Are you sure you want to delete this test result?"
        open={openDeleteModal}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptDelete}
      />
      <Grid item sm={12}>
        <InputAutoCompleteV2
          id="title_upload"
          size={'small'}
          isDisabled={isDisabled}
          opts={[
            { _id: 'background check', label: 'Background Check' },
            { _id: 'economical offer', label: 'Economical Offer' },
            { _id: 'phone screen', label: 'Phone Screen' },
            { _id: 'portfolio', label: 'Portfolio' },
            { _id: 'onboarding format', label: 'Onboarding Format' },
            { _id: 'recommendation letter', label: 'Recommendation Letter' },
            { _id: 'other', label: 'Other' },
          ]}
          error={!!errors && errors?.title_upload?.message}
          initialValue={getValues('title_upload')}
          placeholder={'Attachment Title'}
          label="Attachment TITLE"
          onChangeInput={(e) => {
            if (e.target.value !== getValues('title_upload')) {
              setValue('title_upload', e.target.value);
              trigger('title_upload');
              handleChange();
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputFileWithText
          accept={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          id={'uploadFile'}
          label={'File (pdf, word)'}
          isDisabled={isDisabled}
          onFileChange={handleFileChange}
          reset={!saving}
          error={errors.url?.message}
        ></InputFileWithText>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%', mt: '22px', [theme.breakpoints.down('md')]: { mt: 0 } }}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            startIcon={
              saving ? (
                <CircularProgress style={{ color: 'white', marginRight: 16 }} size="0.8rem" thickness={8} />
              ) : (
                <CheckCircleIcon />
              )
            }
            sx={{ width: '100%' }}
            disabled={isDisabled}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
          CANDIDATE ATTACHMENTS
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <DataGrid
          sx={{ mt: 1, px: 1 }}
          headerHeight={50}
          rows={attachments || []}
          columns={columns}
          autoHeight
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          disableSelectionOnClick
          disableColumnMenu
          pageSize={10}
          // onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          getRowId={(row) => row.title + row.url}
        />
      </Grid>
    </Grid>
  );
};

export default MoreUploadsTab;
