import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { useGetLogsQuery } from 'api/api';

const formattedDate = (date) => moment(date).format('MM/DD/YYYY');

export default function CandidateLog(props) {
  const swiperSlide = useSwiperSlide();
  const { data: logs, refetch } = useGetLogsQuery({
    type: 'project_candidate',
    candidateId: props.candidate.id,
  });

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (swiperSlide.isActive && !fetched) {
      refetch();
      setFetched(true);
    }
  }, [swiperSlide.isActive, fetched, refetch]);

  return (
    <Box sx={{ maxHeight: '150px' }}>
      <Box
        sx={{
          overflowY: 'hidden',
          marginBottom: 3,
        }}
      >
        {logs?.map((log) => {
          return (
            <Typography key={log.id} sx={{ fontSize: 10, mb: 1 }} align="left">
              <em>{formattedDate(log.createdAt)}</em>
              <br /> {log.meta.message}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
