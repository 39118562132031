import { Box, Typography } from '@mui/material';
import ImportForm from 'pages/import/Candidates/ImportForm';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import MainLayout from '../../../components/MainLayout/MainLayout';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { loginExpire } from '../../../store/auth';

const Candidates = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Import Candidates',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="import">
      <Box pt={2} pb={2}>
        <Typography variant="h4" textTransform="uppercase">
          Import <strong>Candidates</strong>
        </Typography>

        <ImportForm />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
