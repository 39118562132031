import { useEffect, useMemo, useState } from 'react';
import { Box, Button, FormHelperText, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { setValidationMessages } from 'utils/validationMessages';
import { useTheme } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import ReactQuill from 'react-quill';
import candidateService from 'services/candidateService';
import { toast } from 'react-toastify';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';
import { capitalize } from 'lodash';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const AddNoteModal = (props) => {
  const theme = useTheme();
  const open = useMemo(() => props.open, [props.open]);
  const note = useMemo(() => props.note, [props.note]);
  const action = useMemo(() => props.action, [props.action]);
  const [title, setTitle] = useState('');

  const schema = Joi.object({
    id: Joi.number(),
    note: Joi.string()
      .trim()
      .required()
      .messages(setValidationMessages(['string', 'required'])),
    ProjectId: Joi.alternatives().conditional('scope', {
      is: CANDIDATE_NOTE_SCOPE.PROJECT,
      then: Joi.number()
        .required()
        .messages(setValidationMessages(['number', 'required'])),
      otherwise: Joi.any().allow(null),
    }),
    scope: Joi.string().required().valid(CANDIDATE_NOTE_SCOPE.GLOBAL, CANDIDATE_NOTE_SCOPE.PROJECT),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      id: note?.id,
      note: note?.note,
      ProjectId: parseInt(props.projectId),
      scope: props.scope,
    },
  });

  const onSubmit = async (data) => {
    if (action === 'add') {
      candidateService
        .addCandidateNote(props.candidateId, data)
        .then((response) => {
          toast.success('Note added successfully');
          props.onSuccess();
          reset();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (action === 'edit') {
      candidateService
        .updateCandidateNotes(note.id, data)
        .then((response) => {
          toast.success('Note updated successfully');
          props.onSuccess();
          reset();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setValue('ProjectId', parseInt(props.projectId));
    setValue('scope', props.scope);
  }, [props.projectId, props.scope, setValue]);

  useEffect(() => {
    const title = capitalize(`${action} Note`);
    setTitle(title);
  }, [action]);

  const handleModeChange = () => {
    if (action === 'view') {
      props.onModeChange('edit');
    } else {
      props.onModeChange('view');
    }
  };

  return (
    <Stack mt={1} justifyContent="flex-end" direction="row">
      <ModalFeedback
        variant="general"
        headerText={title}
        headerBarColor={theme.palette.primary.main}
        open={open}
        disableBtnConfirm
        disableBtnCancel
        modalWidth={980}
        onClose={props.onClose}
        onCancel={props.onClose}
      >
        <Box
          sx={{
            '> .quill > .ql-container': { height: '400px', display: 'flex', flex: 1, flexDirection: 'column' },
            '> .quill > .ql-container > ql-editor': { flex: 1 },
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder={`Type a note`}
                {...field}
                readOnly={action === 'view'}
              />
            )}
          />
          <FormHelperText sx={{ color: theme.palette.warning.main }}>{errors.note && errors.note.message}</FormHelperText>
          <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
            {action !== 'add' && (
              <Button variant="contained" onClick={handleModeChange} color={action === 'edit' ? 'info' : 'primary'}>
                {action === 'view' ? 'Edit' : 'Cancel'}
              </Button>
            )}
            {action !== 'view' && (
              <Button type="submit" variant="contained">
                {action === 'add' ? 'Add' : 'Save'}
              </Button>
            )}
          </Stack>
        </Box>
      </ModalFeedback>
    </Stack>
  );
};

export default AddNoteModal;
