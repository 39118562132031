import { Typography, useTheme, Grid } from '@mui/material';
import { useState, useCallback, useMemo } from 'react';
import Fuse from 'fuse.js';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import RowSkillCv from './rowSkillCv';

const ModalValidationSkills = ({
  openModalSkills,
  setOpenModalSkills,
  skills,
  skillsOptions,
  handleValidate,
  onHandleDeleteSkill,
}) => {
  const theme = useTheme();
  const [arrSkills, setArrSkills] = useState([]);

  const handleCloseModal = useCallback(() => {
    setOpenModalSkills(false);
  }, [setOpenModalSkills]);

  const searchSimmilarity = useCallback((word, arr) => {
    /*function excludeWordsFromString(inputString, wordsToExclude) {
      const regexPattern = new RegExp(wordsToExclude.join('|'), 'gi');
      const resultString = inputString.replace(regexPattern, '');
      return resultString;
    }
    function extractWordsInsideParentheses(inputString) {
      const regexPattern = /\(([^)]+)\)/g;
      const matches = inputString.match(regexPattern);
      const extractedWords = matches ? matches.map((match) => match.replace(/[()]/g, '').trim()) : [];
      return extractedWords;
    }*/
    function extractWordsOutsideParentheses(inputString) {
      const regexPattern = /\([^)]+\)|\s+/g;
      const partsOutsideParentheses = inputString.split(regexPattern).filter(Boolean);
      return partsOutsideParentheses;
    }

    const fuse = new Fuse(arr, {
      isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 3,
    });

    const matches = fuse.search(word);
    if (matches.length > 0) {
      const str = matches[0].item;
      return str;
    } else {
      const outside = extractWordsOutsideParentheses(word);
      const outsideMatches = fuse.search(outside.join(' '));
      if (outsideMatches.length > 0) {
        const str = outsideMatches[0].item;
        return str;
      }
    }
    return '';
  }, []);

  const skillsOptionsSelect = useMemo(() => {
    return skillsOptions.map((sk) => sk.label);
  }, [skillsOptions]);

  useMemo(async () => {
    if (skills?.length > 0 && skillsOptionsSelect?.length > 0) {
      const arr = await Promise.all(
        skills.map(async (sk) => ({
          skill: sk.value,
          validated: sk.validated,
          hint: sk.validated ? '' : await searchSimmilarity(sk.value, skillsOptionsSelect),
          editing: false,
        }))
      );

      setArrSkills(arr);
    }
  }, [searchSimmilarity, skills, skillsOptionsSelect]);

  const handleDeleleSkill = useCallback(
    (index) => {
      const copySkill = [...arrSkills];
      copySkill.splice(index, 1);
      setArrSkills(copySkill);
      onHandleDeleteSkill(index);
    },
    [arrSkills, onHandleDeleteSkill]
  );

  return (
    <ModalFeedback
      variant="general"
      headerText={'Skills Validation'}
      headerBarColor={theme.palette.primary.main}
      open={openModalSkills}
      onCancel={handleCloseModal}
      onClose={handleCloseModal}
      disableCloseOnAccept={true}
      btnCancelText="Close"
      disableBtnConfirm={true}
      sx={{}}
      modalWidth={1200}
    >
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={4} sx={{ textAlign: 'left', py: '7px!important' }}>
          <Typography>Skills</Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'left', py: '7px!important' }}>
          <Typography>Did you mean</Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        {openModalSkills &&
          arrSkills?.map((sk, index) => {
            return (
              <RowSkillCv
                key={sk.skill}
                {...sk}
                skillsOptions={skillsOptions}
                deleteSkillCV={() => handleDeleleSkill(index)}
                onHandleValidate={handleValidate}
              />
            );
          })}
      </Grid>
    </ModalFeedback>
  );
};

export default ModalValidationSkills;
