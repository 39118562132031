import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducer';
import { api } from '../api/api';

// const loggerMiddleware = createLogger();

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredPaths: ["customer.candidate.target"],
    ignoredActionPaths: ['payload.target'],
  }
};

export default function () {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddlewareConfig).concat(thunk, api.middleware),
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, loggerMiddleware, api.middleware),
  });
}
