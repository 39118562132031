import { Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { PasswordFields, passwordInitialState } from 'pages/me/profileFields';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import userService from 'services/userService';
import { loginSuccess } from 'store/auth';

const MePasswordForm = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { fields, handleSave, errors, handleSubmit, validSubmit } = PasswordFields(passwordInitialState);

  const CustomButton = styled(Button)({
    padding: '10px 30px',
  });

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        await userService.updateMePassword(
          Object.assign({}, { old_password: fields.old_password, password: fields.password })
        );
        setOpen(false);
        toast.success('User password updated');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  return (
    <>
      <CustomButton variant="contained" onClick={() => setOpen(true)} sx={{ padding: '10px 30px' }}>
        Change Password
      </CustomButton>
      <ModalFeedback
        variant="general"
        headerText={'Change Password'}
        headerBarColor={theme.palette.primary.main}
        open={open}
        onAccept={() => handleSubmit(fields)}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        disableCloseOnAccept={true}
      >
        <Box>
          <InputTextOutlinedV2
            id="old_password"
            label="Current Password"
            type="password"
            onChangeInput={handleSave}
            initialValue={''}
            error={errors.old_password?.txt}
            variant="standard"
            styleControl={{ marginTop: '1.5em' }}
          />
          <InputTextOutlinedV2
            id="password"
            label="New Password"
            type="password"
            variant="standard"
            onChangeInput={handleSave}
            initialValue={''}
            error={errors.password?.txt}
            styleControl={{ marginTop: '1.5em' }}
          />
          <InputTextOutlinedV2
            id="password_confirm"
            label="Confirm New Password"
            type="password"
            variant="standard"
            onChangeInput={handleSave}
            initialValue={''}
            error={errors.password_confirm?.txt}
            styleControl={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          />
        </Box>
      </ModalFeedback>
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (user, tokens) => {
      dispatch(loginSuccess(user, tokens));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MePasswordForm);
