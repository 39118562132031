import { Box, Drawer, Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import NexusLogo from '../../../assets/img/logo-1.png';
import { ReactComponent as sonatafyLogoSmall } from '../../../assets/img/sonatafy-logo-small.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import SideBarMenu from './SideBarMenu';

const CustomizedDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    display: 'grid',
    gridTemplateRows: 'auto 1fr 80px',
    boxSizing: 'border-box',
    background: theme.palette.gradients.dark.tb,
  },
}));

const DrawerTopBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 30,
  marginBottom: 30,
}));

const SideBar = (props) => {
  const { drawerWidth, mobileOpen, handleSidebarOpen, current, auth } = props;
  const [width] = useWindowSize();
  const [mobileSideBarVersion, setMobileSideBarVersion] = useState(true);
  const { lastLogin } = props.auth;

  useEffect(() => {
    setMobileSideBarVersion(width <= 1180);
  }, [width]);

  return (
    <>
      {/* Drawer For Mobile and Desktop Version */}
      <CustomizedDrawer
        variant={mobileSideBarVersion ? 'temporary' : 'permanent'}
        open={mobileOpen}
        onClose={() => handleSidebarOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      >
        {/* Top Sidebar Image Logo */}
        <DrawerTopBox>
          <Box component="img" alt="Nexus Logo" src={NexusLogo} width={200} />
        </DrawerTopBox>

        {/* Sidebarmenu */}
        <SideBarMenu current={current} auth={auth} />

        {/* Draw bottom sidebar section */}
        <Stack sx={{ textAlign: 'center', position: 'absolute', bottom: '20px', ml: '30px' }}>
          <Typography variant="caption" color="primary.contrastText">
            Last Login: {lastLogin}
          </Typography>
          <Typography variant="caption" color="primary.contrastText">
            <SvgIcon
              component={sonatafyLogoSmall}
              inheritViewBox
              fontSize="small"
              sx={{ mr: 0.5, verticalAlign: 'bottom' }}
            />{' '}
            POWERED BY <strong>SONATAFY TECHNOLOGY</strong>
          </Typography>
        </Stack>
      </CustomizedDrawer>
    </>
  );
};

export default SideBar;
