import { Grid, Divider, useTheme } from '@mui/material';
import { InputTextOutlined } from '@nexxus/components';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import InputFileDragDrop from 'components/FormElement/InputFileDragDrop';
import {
  PROJECT_HARDWARE,
  PROJECT_HOLIDAYS,
  PROJECT_SENIORITY,
  PROJECT_TIMEZONES,
  PROJECT_WORKING_HOURS,
} from 'constants/project';
import { POSITIONS } from '@nexxus/constants';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const PositionInfo = ({ values, setValue, errors, disabled, initialState, setModified, onFileHandler, uploads }) => {
  const [snapshot, setSnapshot] = useState(initialState);

  const theme = useTheme();

  useMemo(() => {
    setSnapshot(values);
  }, [values]);

  const setValueField = useCallback(
    (fieldTargetName, value) => {
      let newVal = value;
      setSnapshot((prev) => ({ ...prev, [fieldTargetName]: value }));
      setValue(fieldTargetName, newVal);
      setModified(true);
    },
    [setModified, setValue]
  );

  const handleOnChangeInputSelect = (evt) => {
    if (evt.target.value !== _.get(snapshot, evt.target.name)) {
      let value = evt.target.value;
      if (evt.target.name === 'intake_hardware_needs') console.log('value hn:', value);
      setValueField(evt.target.name, value);
      setModified(true);
    }
  };

  return (
    <Grid container spacing={2}>
      {/* intake_position_title */}
      <Grid item xs={12} md={6} lg={3}>
        <InputAutoCompleteV2
          id="intake_position_title"
          size={'medium'}
          opts={Object.values(POSITIONS)}
          error={errors.intake_position_title?.message}
          initialValue={_.get(snapshot, 'intake_position_title')}
          placeholder={'Position Title'}
          onChangeInput={(e) => handleOnChangeInputSelect(e)}
          isDisabled={disabled}
          disableClearable={true}
        />
      </Grid>
      {/* intake_seniority */}
      <Grid item xs={12} md={6} lg={3}>
        <InputAutoCompleteV2
          id="intake_seniority"
          size={'medium'}
          opts={PROJECT_SENIORITY}
          error={errors.intake_seniority?.message}
          initialValue={_.get(snapshot, 'intake_seniority')}
          placeholder={'Position Seniority'}
          onChangeInput={(e) => handleOnChangeInputSelect(e)}
          isDisabled={disabled}
          sortOpts={false}
          disableClearable={true}
        />
      </Grid>
      {/* intake_headcount */}
      <Grid item xs={12} md={6} lg={3}>
        <InputTextOutlined
          id="intake_headcount"
          type="number"
          placeholder="Headcount"
          size="medium"
          error={errors?.intake_headcount?.message}
          initialValue={_.get(snapshot, 'intake_headcount')}
          onChangeInput={({ target }) => {
            setValueField('intake_headcount', target.value);
          }}
          isDisabled={disabled}
        />
      </Grid>
      {/* intake_maxrate_usd */}
      <Grid item xs={12} md={6} lg={3}>
        <InputTextOutlined
          id="intake_maxrate_usd"
          type="number"
          placeholder="Max Rate (USD)"
          size="medium"
          error={errors?.intake_maxrate_usd?.message}
          initialValue={_.get(snapshot, 'intake_maxrate_usd')}
          onChangeInput={({ target }) => {
            setValueField('intake_maxrate_usd', target.value);
          }}
          isDisabled={disabled}
        />
      </Grid>
      {/* intake_description */}
      <Grid item xs={12} md={12} lg={6}>
        <InputTextOutlined
          id={'intake_description'}
          type="text"
          size={'medium'}
          placeholder="Project Description"
          error={errors.intake_description?.message}
          initialValue={_.get(snapshot, 'intake_description')}
          onChangeInput={({ target }) => {
            setValueField('intake_description', target.value);
          }}
          styleInput={{ mr: 1, minHeight: '128px' }}
          multiline
          rowsMultiline={4}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid item container xs={12} spacing={2}>
          {/* intake_timezone */}
          <Grid item xs={12} md={6}>
            <InputAutoCompleteV2
              id="intake_timezone"
              size={'medium'}
              opts={PROJECT_TIMEZONES}
              error={errors.intake_timezone?.message}
              initialValue={_.get(snapshot, 'intake_timezone')}
              placeholder={'Timezone'}
              onChangeInput={(e) => handleOnChangeInputSelect(e)}
              isDisabled={disabled}
              disableClearable={true}
            />
          </Grid>
          {/* intake_holidays  */}
          <Grid item xs={12} md={6}>
            <InputAutoCompleteV2
              id="intake_holidays"
              size={'medium'}
              opts={PROJECT_HOLIDAYS}
              error={errors.intake_holidays?.message}
              initialValue={_.get(snapshot, 'intake_holidays')}
              placeholder={'Holidays'}
              onChangeInput={(e) => handleOnChangeInputSelect(e)}
              isDisabled={disabled}
              disableClearable={true}
            />
          </Grid>
          {/* intake_working_hours_open */}
          <Grid item xs={12} md={6}>
            <InputAutoCompleteV2
              id="intake_working_hours_open"
              size={'medium'}
              opts={PROJECT_WORKING_HOURS()}
              error={errors.intake_working_hours_open?.message}
              initialValue={_.get(snapshot, 'intake_working_hours_open')}
              placeholder={'Working Hours (Open)'}
              onChangeInput={(e) => handleOnChangeInputSelect(e)}
              isDisabled={disabled}
              disableClearable={true}
              sortOpts={false}
            />
          </Grid>
          {/* intake_working_hours_close */}
          <Grid item xs={12} md={6}>
            <InputAutoCompleteV2
              id="intake_working_hours_close"
              size={'medium'}
              opts={PROJECT_WORKING_HOURS()}
              error={errors.intake_working_hours_close?.message}
              initialValue={_.get(snapshot, 'intake_working_hours_close')}
              placeholder={'Working Hours (Close)'}
              onChangeInput={(e) => handleOnChangeInputSelect(e)}
              isDisabled={disabled}
              disableClearable={true}
              sortOpts={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 1 }} />
      </Grid>
      {/* intake_job_description intake_codetest_link intake_hardware_needs*/}
      <Grid item xs={12} lg={6}>
        <Grid item container xs={12} spacing={2}>
          {/* intake_job_description */}
          <Grid item xs={12}>
            <InputTextOutlined
              id={'intake_job_description'}
              type="text"
              size={'medium'}
              placeholder="Job Description"
              error={errors.intake_job_description?.message}
              initialValue={_.get(snapshot, 'intake_job_description')}
              onChangeInput={({ target }) => {
                setValueField('intake_job_description', target.value);
              }}
              isDisabled={disabled}
            />
          </Grid>
          {/* intake_codetest_link intake_hardware_needs*/}
          <Grid item container xs={12} md={6} spacing={2}>
            {/* intake_codetest_link*/}
            <Grid item xs={12}>
              <InputTextOutlined
                id={'intake_codetest_link'}
                type="text"
                size={'medium'}
                placeholder="Code Test (Link)"
                error={errors.intake_codetest_link?.message}
                initialValue={_.get(snapshot, 'intake_codetest_link')}
                onChangeInput={({ target }) => {
                  setValueField('intake_codetest_link', target.value);
                }}
                isDisabled={disabled}
              />
            </Grid>
            {/* intake_hardware_needs */}
            <Grid item xs={12}>
              <InputAutoCompleteV2
                id="intake_hardware_needs"
                size={'normal'}
                initialValue={_.get(snapshot, 'intake_hardware_needs')}
                error={errors.intake_hardware_needs?.message}
                opts={PROJECT_HARDWARE || []}
                onChangeInput={handleOnChangeInputSelect}
                placeholder="Hardware Needs"
                disableClearable={true}
                isDisabled={disabled}
              />
            </Grid>
          </Grid>
          {/* intake_codetest_file */}
          <Grid item xs={12} md={6}>
            <InputFileDragDrop
              body={'Upload Code Test File'}
              caption={'(.xls, .xlsx., .doc. docx, .pdf)'}
              iconUpload={<FileUploadOutlinedIcon sx={{ width: '40px', height: '40px' }} htmlColor="#0242D1" />}
              id={'intake_codetest_file'}
              mimeTypes={[
                'application/pdf',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
              onFileHandler={onFileHandler}
              sxAreaDragDrop={{
                maxHeight: '130px',
                [theme.breakpoints.down('md')]: { minHeight: '140px' },
                '.MuiTypography-body1': {
                  color: 'rgba(0, 0, 0, 0.23)',
                  fontWeight: '400',
                },
                '.MuiTypography-caption': {
                  color: 'rgba(0, 0, 0, 0.23)',
                  fontWeight: '400',
                },
              }}
              urlDownload={_.get(snapshot, 'intake_codetest_file')}
              isDisabled={disabled}
              signDownload={true}
              settingsDownload={{
                url: _.get(snapshot, 'intake_codetest_file') ?? '',
                originalName: uploads?.originalName ?? '',
              }}
              error={errors?.intake_codetest_file?.message}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* intake_interview_process */}
      <Grid item xs={12} md={12} lg={6}>
        <InputTextOutlined
          id={'intake_interview_process'}
          type="text"
          size={'medium'}
          placeholder="Interview Process And Additional Requirements"
          error={errors.intake_interview_process?.message}
          initialValue={_.get(snapshot, 'intake_interview_process')}
          onChangeInput={({ target }) => {
            setValueField('intake_interview_process', target.value);
          }}
          styleInput={{ mr: 1, minHeight: '200px' }}
          multiline
          rowsMultiline={7}
          isDisabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default PositionInfo;
