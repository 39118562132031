import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useTabInterviewState } from 'contexts/TabInterviewContext';
import { useCallback, useEffect } from 'react';
import candidateService from 'services/candidateService';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';
import ManageCandidateNotes from './Elements/ManageCandidateNotes';
import Title from './Elements/Title';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { toast } from 'react-toastify';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(min(200px,50%),1fr))',
  gridGap: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

const LeftInterviewTab = ({ isSaved, setHasChanged }) => {
  const {
    currentCandidateId,
    currentIsDisabled,
    globalDataUpdateDone,
    setGlobalDataUpdateDone,
    gradeOptions,
    fields,
    setValues,
    handleSave,
    errors,
    validSubmit,
  } = useTabInterviewState();
  const handleOnChangeInputSelect = useCallback(
    (evt) => {
      if (evt.target.name === 'professionalism') console.log(evt.target);
      if (evt.target.value !== fields[evt.target.name]) {
        handleSave(evt);
        setHasChanged(true);
      }
    },
    [fields, handleSave, setHasChanged]
  );

  // Get All Candidate Code Tests
  useEffect(() => {
    (async () => {
      if (currentCandidateId) {
        const res = await candidateService.getCandidateGrade(currentCandidateId);
        const { data } = res;
        if (res.status === 200) {
          setValues({
            ...fields,
            professionalism: data.professionalism || '',
            english_proficiency: data.english_proficiency || '',
            articulation: data.articulation || '',
            experience: data.experience || '',
            cultural_match: data.cultural_match || '',
            engineering_interview: data?.engineering_interview || '',
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCandidateId]);

  // Update Candidate Grade
  useEffect(() => {
    (async () => {
      setGlobalDataUpdateDone(false);
      if (currentCandidateId && validSubmit && !globalDataUpdateDone) {
        try {
          await candidateService.updateCandidateActivity(currentCandidateId);
          await candidateService.updateCandidateGrade(currentCandidateId, fields);
          setGlobalDataUpdateDone(true);
        } catch (err) {
          if (err?.response?.data?.message) toast.error(err?.response?.data?.message);
          setGlobalDataUpdateDone(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  useEffect(() => {
    if (isSaved) {
      setHasChanged(false);
    }
  }, [isSaved, setHasChanged]);

  return (
    <Box sx={{ mb: 5 }}>
      <Title>GRADE BASED ON TAS PANEL INTERVIEW</Title>

      <GridContainer sx={{ marginTop: 3, marginBottom: 3 }}>
        <InputAutoCompleteV2
          id={'professionalism'}
          label="PROFESSIONALISM"
          autocomplete={false}
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.professionalism}
          error={errors.professionalism && errors.professionalism.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={handleOnChangeInputSelect}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id={'english_proficiency'}
          label="ENGLISH PROFICIENCY"
          autocomplete={false}
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.english_proficiency}
          error={errors.english_proficiency && errors.english_proficiency.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleOnChangeInputSelect(e, fields.english_proficiency)}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id={'articulation'}
          label="ARTICULATION CLARITY"
          autocomplete={false}
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.articulation}
          error={errors.articulation && errors.articulation.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleOnChangeInputSelect(e, fields.articulation)}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id={'cultural_match'}
          label="CULTURAL MATCH"
          autocomplete={false}
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.cultural_match}
          error={errors.cultural_match && errors.cultural_match.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleOnChangeInputSelect(e, fields.cultural_match)}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id={'experience'}
          label="EXPERIENCE/JOB HISTORY"
          autocomplete={false}
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.experience}
          error={errors.experience && errors.experience.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleOnChangeInputSelect(e, fields.experience)}
          disableClearable={true}
        />
      </GridContainer>

      {currentCandidateId && (
        <ManageCandidateNotes
          isDisabled={currentIsDisabled}
          scope={CANDIDATE_NOTE_SCOPE.GLOBAL}
          projectId={null}
          candidateId={currentCandidateId}
          height={280}
          label="INTERVIEW NOTES"
        />
      )}
    </Box>
  );
};

export default LeftInterviewTab;
