import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import LeftInterviewTab from 'components/Candidates/InterviewCandidateTab/LeftInterviewTab';
import RightInterviewTab from 'components/Candidates/InterviewCandidateTab/RightInterviewTab';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { TabInterviewProvider, useTabInterviewState } from 'contexts/TabInterviewContext';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { api } from 'api/api';
import { useDispatch } from 'react-redux';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 2px auto',
  gridTemplateColumns: '1fr',
  gridGap: theme.spacing(4),
  [theme.breakpoints.up('xl')]: {
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'calc(50% - 25px) 2px calc(50% - 25px)',
    gridGap: `0px ${theme.spacing(3)}`,
  },
}));

const NewTabInterview = ({ isDisabled, candidateId, setHasChanged }) => {
  const currentCandidateId = useMemo(() => candidateId, [candidateId]);
  const currentIsDisabled = useMemo(() => isDisabled, [isDisabled]);
  return (
    <TabInterviewProvider>
      <NewTabInterviewLayout setHasChanged={setHasChanged} isDisabled={currentIsDisabled} candidateId={currentCandidateId} />
    </TabInterviewProvider>
  );
};

NewTabInterview.propTypes = {
  candidateId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

const NewTabInterviewLayout = ({ isDisabled, candidateId, setHasChanged }) => {
  const { setCurrentCandidateId, setCurrentIsDisabled, handleSubmit, updateDone } = useTabInterviewState();
  const [open, setOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    handleSubmit(e);
    setOpen(false);
    setIsSaved(true);
  };

  useEffect(() => {
    setCurrentCandidateId(candidateId);
    setCurrentIsDisabled(isDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, isDisabled]);

  useEffect(() => {
    (() => {
      if (updateDone) {
        dispatch(api.util.invalidateTags(['Candidates']));
        toast.success('Candidate Grades Updated');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDone]);

  return (
    <>
      <ModalFeedback
        variant="info"
        headerText="Grades"
        titleText="Do you want to update the grades?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <GridContainer>
        <LeftInterviewTab setHasChanged={setHasChanged} isSaved={isSaved} />
        <Box sx={{ height: { xs: '2px', xl: '100%' }, width: { xs: '100%', xl: '2px' }, backgroundColor: 'grey.main' }} />
        <RightInterviewTab setHasChanged={setHasChanged} />
        {/* <BottomCandidateResults /> */}
        <Grid
          container
          component="form"
          onSubmit={handleOpenModal}
          sx={{ mt: { xl: 3 }, gridColumn: { xl: 'span 3 / span 3' } }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1,
              }}
            >
              <Button variant="contained" type="submit" startIcon={<CheckCircleIcon />} disabled={isDisabled}>
                Update Grades
              </Button>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  );
};

NewTabInterviewLayout.propTypes = {
  candidateId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

export default NewTabInterview;
