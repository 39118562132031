// @ts-ignore
import { TabsCandidatesV2, MobileHeaderFilters, TooltipHelper } from '@nexxus/components';
import CandidateHeader from 'pages/customer/candidateOverview/candidateHeader';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import { useGetCurrentUserQuery, useGetCustomersPendingFeedbackQuery, useGetManagedCountriesQuery } from 'api/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import customerService from 'services/customerService';
import { capitalizeString } from 'utils/utils';
import projectService from '../../services/projectService';
import { loginExpire } from '../../store/auth';
// @ts-ignore
import useCustomerProjectCandidates from 'hooks/customer/useCustomerProjectCandidates';
import { CANDIDATEPROJECT_STATUS, GROUP_PROJECT_STATUS_REJECTED } from 'constants/candidate_project';
import { Badge, Stack, Typography, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { PROJECT_STATUS } from '../../constants/project';
import { POSITIONS } from '@nexxus/constants';
import usePreventLocation from 'hooks/usePreventLocation';
import NCSWidget from './candidateOverview/NCSWidget';
import useSignedS3Url from 'hooks/useSignedS3Url';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { setCandidateOpen, setLocations } from 'store/customer';
import _ from 'lodash';

const Candidates = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // @ts-ignore
  const [projects, setProjects] = useState([]);
  const [candidateSearch, setCandidateSearch] = useState([]);
  const isMobile = useMediaQuery('(max-width:700px)');
  const isNcsBreakpoint = useMediaQuery('(min-width:985px)');
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();
  const { getSignedDownloadUrl } = useSignedS3Url();

  let pjId = searchParams.get('ProjectId');
  
  /* eslint-disable */
  // @ts-ignore
  const tabSelected = searchParams.get('Tab');
  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();
  const { data: customerPendingFeedback } = useGetCustomersPendingFeedbackQuery(
    {
      customerId: currentUser?.Client?.id,
    },
    {
      skip: !isSuccess,
    }
  );

  const { data: locationData } = useGetManagedCountriesQuery(null, {
    skip: !isSuccess,
  });

  const {
    // @ts-ignore
    candidates,
    projectId,
    candidatesCountStatus,
    customer,
    // @ts-ignore
    statusSelected,
    candidateSelected,
    // @ts-ignore
    loading,
    setStatusSelected,
    setProjectId,
    setCustomer,
    // @ts-ignore
    setCandidateSelected,
    setLoading,
    setCandidates
  } = useCustomerProjectCandidates();

  const handleSearch = async (_searchTerm, value, _reason) => onHandleSearch(value.key);

  const onHandleSearch = useCallback(async (CandidateId) => {
    setLoading(true);
    setStatusSelected('');
    setCandidateSelected(CandidateId);
  }, []);

  useMemo(() => {
    if (pjId) {
      if (!projects.find((pj) => pj._id === parseInt(pjId))) return navigate('/404');
      setProjectId(pjId ?? '')
    }
  }, [projects, pjId]);

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        let status = 'PANEL_INTERVIEW';
        if (tabSelected) status = pjId === projectId ? tabSelected : 'PANEL_INTERVIEW';
        setLoading(true);
        setCandidateSelected('');
        setStatusSelected(status);
      }
    })();
  }, [projectId, pjId, tabSelected]);

  //init Hook
  useMemo(() => {
    if (currentUser) setCustomer(currentUser.Client);
  }, [currentUser]);

  //init Hook
  useMemo(() => {
    (async () => {
      if (currentUser) {
        const { data } = await projectService.getProjectsByClient(currentUser.Client.id, {
          excludeStatus: [
            PROJECT_STATUS.ARCHIVED,
            PROJECT_STATUS.ON_HOLD,
            PROJECT_STATUS.RE_ENABLE,
            PROJECT_STATUS.DRAFT,
            PROJECT_STATUS.INTAKE,
          ],
        });

        const projs = data.map((pj) => {
          const position = Object.values(POSITIONS).find((ti) => ti._id === pj.position).label || '';
          let label = `${capitalizeString(position)} - ${capitalizeString(pj.seniority)}`;

          const exists = customerPendingFeedback?.projectsWithCandidates.find((pjFeed) => pj.id === pjFeed.ProjectId);

          label = (
            <Stack direction="row" spacing="2" justifyContent="space-between" sx={{ width: '100%' }}>
              <Typography>{`${capitalizeString(position)} ${capitalizeString(pj.seniority)}`} </Typography>
              {exists && (
                <Badge
                  badgeContent={exists.Candidates?.length}
                  color="error"
                  sx={{
                    display: 'block',
                    position: 'relative',
                    top: '8px',
                    right: '10px',
                    width: '20px',
                  }}
                />
              )}
            </Stack>
          );

          return {
            ...pj,
            _id: pj.id,
            label,
          };
        });
        setProjects(projs);
      }
    })();
  }, [currentUser, customerPendingFeedback]);

  // populate candidate search
  useEffect(() => {
    (async () => {
      if (currentUser) {
        // populate candidate search
        if (projectId !== '') {
          const { data: allCandidates } = await customerService.getCustomerCandidates(currentUser.Client.id, {
            // @ts-ignore
            ProjectId: projectId !== '' && projectId !== 0 ? projectId : null,
            includes: false,
            status: [
              CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW,
              CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT,
              CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER,
              CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW,
              CANDIDATEPROJECT_STATUS.HIRED,
              CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED,
              CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED,
              CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
              CANDIDATEPROJECT_STATUS.DROPPED_OUT,
            ],
            includeBlacklisted: true,
          });
          setCandidateSearch(allCandidates);
        }
      }
    })();
  }, [currentUser, projectId, setCandidateSearch]);

  useEffect(() => {
    (async () => {
      if (candidateSelected !== '') {
        const candidate = candidateSearch.find((cand) => cand.id == candidateSelected);
        if (candidate) {
          const keyStatus = Object.entries(CANDIDATEPROJECT_STATUS).find(
            ([key, _value]) => CANDIDATEPROJECT_STATUS[key] === candidate.Projects?.[0]?.Project_Candidate?.['status']
          );

          setStatusSelected(GROUP_PROJECT_STATUS_REJECTED.includes(keyStatus[1]) ? 'REJECTED' : keyStatus[0]);
        }
      }
    })();
  }, [candidateSelected, candidateSearch, setStatusSelected]);

  // @ts-ignore
  const handlePosition = (PjId) => {
    if (PjId !== projectId) {
      setCandidateSelected('');
      setStatusSelected('');
      setProjectId(PjId);
    }
  };

  const handleScheduledInterviewCandidate = async (candidate) => {
    await customerService.saveCustomerFeedback({
      candidateId: candidate,
      projectId: Number(projectId),
      scheduled: true,
    });
    toast.success('Interview request sent!');
  };

  const candidatesToOpts = () => {
    return candidateSearch.map((cand, index) => ({
      key: cand.id,
      label: `${cand.full_name}`,
    }));
  };

  const clearFilters = () => {
    if (candidateSelected !== '') {
      setLoading(true);
      setCandidateSelected('');
      setStatusSelected('PANEL_INTERVIEW');
    }
  };

  const candidatesForTabs = useMemo(() => {
    return Object.assign(
      {},
      ...Object.entries({ ...candidatesCountStatus })
        .filter(([key, val]) => val.key)
        .map(([k, v]) => {
          return { [k]: v };
        })
    );
  }, [candidatesCountStatus]);

  useEffect(() => {
    if(locationData?.length > 0) {
      props.setLocations({locations: locationData})
    }
  }, [locationData]);

  const refetchOverview = useCallback((candidate, feedback) => {

    const candidatesCloned = _.cloneDeep(candidates);
    candidatesCloned.find((cand) => cand.id === candidate).Projects[0].Project_Candidate.customer_feedback[statusSelected].feedback = feedback;
    setCandidates(candidatesCloned);
  }, [candidates])

  const riseHandleFeedbackMobile = useCallback(async (feedback, candidate) => {
    await customerService.saveCustomerFeedback({
      feedback: feedback,
      candidateId: candidate,
      projectId: Number(candidate),
    });

    toast.success('Feedback sent!');
    refetchOverview(candidate, feedback);
  }, [refetchOverview]);

  return (
    <MainLayout
      {...props}
      avatarTopBar={customer?.logoUrl || ''}
      subtitle="Candidate"
      subtitleFocus="Overview"
      current="projects"
      refetchOverview={refetchOverview}
    >
      <Stack spacing={2}>
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          mt="20px!important"
          mb="4px!important"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Candidate Overview
          <TooltipHelper
            textTip={'This section summarizes the current candidates and the status of their submission process. '}
            placement={isMobile ? 'top' : 'top-end'}
          >
            <QuestionMarkOutlinedIcon sx={{ color: '#fff', width: '0.5em', height: '0.5em', fontSize: '1.6rem' }} />
          </TooltipHelper>
        </Typography>
        {!isMobile ? (
          <CandidateHeader
            logoUrl={customer?.logoUrl || ''}
            candidatesNames={candidatesToOpts()}
            positions={projects}
            onHandlePosition={handlePosition}
            lifetimeTotals={candidatesCountStatus}
            handleCandidateSelected={handleSearch}
            projectSelected={projectId}
            clearFilters={clearFilters}
            initialValueSearch={candidateSelected}
          />
        ) : (
          <MobileHeaderFilters
            positions={projects}
            onHandlePosition={handlePosition}
            optsCandidates={candidatesToOpts()}
            handleCandidateSelected={handleSearch}
          />
        )}
        <TabsCandidatesV2
          hasChanged={hasChanged}
          setHasChanged={setHasChanged}
          leaveMessage={LEAVE_RESTRICTION_MESSAGE}
          project={projectId}
          countersCandidate={candidatesForTabs}
          allCandidates={candidates}
          setStatusSelected={setStatusSelected}
          statusSelected={statusSelected}
          loading={loading}
          setLoading={setLoading}
          onHandleFeedback={isMobile ? riseHandleFeedbackMobile : refetchOverview}
          handleScheduledInterview={handleScheduledInterviewCandidate}
          getSignedDownloadUrl={getSignedDownloadUrl}
          catalogLocations={locationData}
          setCandidateOpen={props.setCandidateOpen}
        />
        {!isNcsBreakpoint && <NCSWidget lifetimeTotals={candidatesCountStatus} logoUrl={customer?.logoUrl || ''} />}
      </Stack>
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.auth, customerStore: state.customer };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    setCandidateOpen: (payload) => {
      dispatch(setCandidateOpen(payload));
    },
    setLocations: (payload) => {
      dispatch(setLocations(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
