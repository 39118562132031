import { ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from 'constants/users';
import CandidateOverview from 'pages/customer/CandidateOverview';
// import ProjectOverview from 'pages/customer/ProjectOverview';
// import Reports from 'pages/customer/Reports';
import WrapperCustomer from 'pages/customer/WrapperCustomer';
import { Route } from 'react-router-dom';
import Dashboard from '../pages/customer/Dashboard';
import PrivateRoute from './privateRouter';
import Help from 'pages/customer/Help';
import ProjectArchived from 'pages/customer/ProjectArchived';
import UserAccessControlCustomer from 'pages/userAccessControlCustomer/UserAccessControlCustomer';
import IntakeForm from 'pages/customer/IntakeForm';
import IntakeList from 'pages/customer/IntakeList';
import TermsConditions from 'pages/customer/TermsConditions';
import NotificationCenter from 'pages/customer/NotificationCenter';
import HelpContactForm from 'pages/customer/HelpContactForm';

const customerRoutes = [
  {
    name: 'Dashboard Customer',
    path: '/customer/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Help',
    path: '/customer/help',
    element: <Help />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Customer Overview',
    path: '/customer/projects/overview',
    element: <CandidateOverview />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Customer Archived',
    path: '/customer/projects/archived',
    element: <ProjectArchived />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'User Access Customer Super',
    path: '/admincustomer/users',
    element: <UserAccessControlCustomer />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Intake',
    path: '/customer/projects/intake',
    element: <IntakeForm editing={true} key="create-intake" />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Intake Edit',
    path: '/customer/projects/intake/:ProjectId/edit',
    element: <IntakeForm editing={true} key="intake-edit-:ProjectId" />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Intake Edit',
    path: '/customer/projects/intake/:ProjectId/view',
    element: <IntakeForm editing={false} key="intake-view-:ProjectId" />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Intake Saved',
    path: '/customer/projects/intake/list',
    element: <IntakeList />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Request Support',
    path: '/customer/contact-form',
    element: <HelpContactForm />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  // {
  //   name: 'Project Overview Edit',
  //   path: '/customer/projects/intake/:ProjectId/edit',
  //   element: <ProjectOverview key="view:editRole" />,
  //   authorization: [ROLE_SUPERCUSTOMER],
  // },
  // {
  {
    name: 'Terms and Consitions',
    path: '/customer/terms-conditions',
    element: <TermsConditions />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Notifications Center',
    path: '/customer/notifications',
    element: <NotificationCenter />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
];

export const CustomerRoutes = () => {
  return customerRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={
          <PrivateRoute roles={route.authorization}>
            <WrapperCustomer>{route.element}</WrapperCustomer>
          </PrivateRoute>
        }
      />
    );
  });
};
