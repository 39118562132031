import { Avatar, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import { CustomButton, CustomPopover, LifeTimeProjects, NcsPopover, PopoverNcs } from '@nexxus/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef, useState } from 'react';

const NCSWidget = ({ lifetimeTotals, logoUrl }) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const isNcsBreakpoint = useMediaQuery('(min-width:985px)');

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNCS, setAnchorElNCS] = useState(null);
  const [anchorElLifetimeModal, setAnchorElLifetimeModal] = useState(null);

  const myRef = useRef(null);

  const handleLearnMoreBtn = () => {
    setAnchorElNCS(myRef.current);
  };

  return (
    <>
      <Grid
        item
        sx={
          !isNcsBreakpoint
            ? { background: 'white', borderRadius: '5px', padding: '16px', width: '100%', marginTop: '16px' }
            : {}
        }
      >
        <Stack
          direction="row"
          gap={1}
          sx={{
            justifyContent: !isNcsBreakpoint && !isMobile ? 'flex-end' : 'space-between',
            width: isMobile ? '100%' : 'inherit',
          }}
        >
          <CustomButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ paddingRight: '8px', paddingLeft: '8px', width: '132px', height: '36px' }}
          >
            What is NCS?{' '}
          </CustomButton>

          <Stack direction="row" gap={1}>
            <Avatar src={`${logoUrl}`} sx={{ width: 36, height: 36 }} />
            <IconButton
              color="primary"
              component="label"
              sx={{ height: '33px' }}
              onClick={(e) => setAnchorElLifetimeModal(e.currentTarget)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Stack>

          <CustomPopover
            sx={{ boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.2)', border: 'none', borderRadius: '5px' }}
            backdropShadow={false}
            anchorEl={anchorElLifetimeModal}
            setAnchorEl={setAnchorElLifetimeModal}
          >
            <LifeTimeProjects
              candidateSubmissions={lifetimeTotals?.SUBMITTED_CUSTOMER.count || 0}
              codeTesting={lifetimeTotals?.SKILLS_ASSESSMENT.count || 0}
              panelInterview={lifetimeTotals?.PANEL_INTERVIEW.count || 0}
              customerInterview={lifetimeTotals?.CUSTOMER_INTERVIEW.count || 0}
              hired={lifetimeTotals?.HIRED.count || 0}
              archived={lifetimeTotals?.ARCHIVED.count || 0}
            />
          </CustomPopover>
        </Stack>
      </Grid>
      <NcsPopover onClickLearnMore={handleLearnMoreBtn} buttonRef={myRef} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <PopoverNcs
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorElNCS}
        setAnchorEl={setAnchorElNCS}
      />
    </>
  );
};

export default NCSWidget;
