import React, { Fragment } from 'react';
import InterviewUploads from '../../../components/InterviewUploads';

const TabUploads = ({ candidateId, isDisabled, setHasChanged, hasChanged }) => {
  if (!candidateId) return null;

  return (
    <Fragment>
      <InterviewUploads
        setHasChanged={setHasChanged}
        candidateId={candidateId}
        isDisabled={isDisabled}
        hasChanged={hasChanged}
      />
    </Fragment>
  );
};

export default TabUploads;
