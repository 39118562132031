import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import MainLayout from '../../../components/MainLayout/MainLayout';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { loginExpire } from '../../../store/auth';
import CandidateResultContent from './CandidateResultContent';

const CandidateSearch = (props) => {
  const only_blacklisted = props.only_blacklisted ?? false;
  const only_staff = props.only_staff ?? false;
  const { setBreadcrumbs } = useBreadcrumbs();

  const title = props.title;

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Candidates',
      },
    ]);
  }, [setBreadcrumbs]);

  const CustomTitle = props.showIncludeBox
    ? styled(Typography)({
        textTransform: 'uppercase',
      })
    : styled(Typography)({
        textTransform: 'uppercase',
        marginBottom: '24px',
      });

  return (
    <>
      <MainLayout {...props} current="candidates">
        <CustomTitle variant="h4" align="left">
          {title}
        </CustomTitle>
        <CandidateResultContent {...props} only_blacklisted={only_blacklisted} only_staff={only_staff} />
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

CandidateSearch.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showIncludeBox: PropTypes.bool,
  only_blacklisted: PropTypes.bool,
  only_staff: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSearch);
