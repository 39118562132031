import { FormHelperText, IconButton, Input, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DialogConfirmation } from '@nexxus/components';
import useSignedS3Url from 'hooks/useSignedS3Url';
import { makeStyles } from '@mui/styles';

const stylesInputFileDragDrop = makeStyles(() => ({
  stack: {
    borderRadius: '8px',
    // height: '100%',
    position: 'relative',
    padding: 2,
    width: 'calc(100%)',
    height: 'calc(100% - 23px)',
  },
  muiDisabled: {
    // opacity: 0.5,
    pointerEvents: 'none',
    color: '#979797!important',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.26)!important',
  },
}));

const InputFileDragDrop = ({
  id,
  iconUpload,
  title = '',
  body = '',
  caption = '',
  isDisabled = false,
  reset = false,
  mimeTypes = ['application/pdf'],
  onFileHandler,
  sxAreaDragDrop = {},
  urlDownload = '',
  error,
  signDownload = false,
  settingsDownload,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [modalFile, setModalFile] = useState(false);

  const [file, setFile] = useState('');
  const cvRef = useRef(null);
  const theme = useTheme();
  const { getSignedDownloadUrl } = useSignedS3Url();
  const classes = stylesInputFileDragDrop();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = function (e) {
    e.preventDefault();
    setIsDragging(false);
    fileHandler(e?.dataTransfer?.files);
  };

  function formatFileName(originalFileName, maxLength = 15) {
    const fileExtension = originalFileName.split('.').pop();
    const fileNameWithoutExtension = originalFileName.replace(/\.[^/.]+$/, '');
    let trimmedFileName = fileNameWithoutExtension.slice(0, maxLength);
    if (fileNameWithoutExtension.length > 14) trimmedFileName = trimmedFileName.concat('...');
    const formattedFileName = `${trimmedFileName}.${fileExtension}`;

    return formattedFileName;
  }

  const onCloseModalFile = useCallback(() => {
    setModalFile(false);
  }, []);

  const fileHandler = useCallback(
    (files) => {
      if (mimeTypes.includes(files?.[0]?.type)) {
        const file = files[0];
        setFile(file.name);
        onFileHandler(files);
      }
    },
    [mimeTypes, onFileHandler]
  );

  const fileReset = useCallback(
    (e) => {
      e.stopPropagation();
      cvRef.current.children[0].value = '';
      setFile('');
      onFileHandler([]);
    },
    [onFileHandler]
  );

  useMemo(() => {
    if (reset) fileReset();
  }, [reset, fileReset]);

  const onAcceptModalFile = useCallback(
    (e) => {
      fileReset(e);
      setModalFile(false);
    },
    [fileReset]
  );

  const handleDownload = async (e) => {
    e.stopPropagation();
    if (['', '#'].includes(file) && ['', '#'].includes(settingsDownload.url)) return;
    if (!signDownload) {
      return window.open(urlDownload, '_blank');
    }
    if (settingsDownload.url.startsWith('http')) {
      const signedUrl = await getSignedDownloadUrl(settingsDownload.url, true, settingsDownload.originalName);
      return window.open(signedUrl, '_blank');
    }
  };

  const handleClickDropArea = useCallback(() => {
    return file === '' ? cvRef.current.children[0].click() : null;
  }, [file]);

  const getFileName = useCallback(() => {
    if (file === '') return settingsDownload.originalName;
    else if (file !== '') return file;
    else return '';
  }, [file, settingsDownload.originalName]);

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent={'center'}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          border: !isDragging ? '2px dashed rgba(0, 0, 0, 0.23)' : '2px dashed rgba(2, 66, 209, 0.5)',
          background: isDisabled ? '#dadada' : 'inherit',
          ...sxAreaDragDrop,
        }}
        className={`${classes.stack} ${isDisabled ? classes.muiDisabled : ''}`}
        onClick={handleClickDropArea}
      >
        <Input
          id={id}
          type="file"
          ref={cvRef}
          sx={{ display: 'none' }}
          inputProps={{
            accept: mimeTypes.join(','),
          }}
          disabled={isDisabled}
          onChange={(e) => {
            // @ts-ignore
            if (e?.target?.files) fileHandler(e.target.files);
          }}
        />

        {file !== '' || settingsDownload?.url !== '' ? (
          <>
            <IconButton
              type="button"
              disabled={isDisabled}
              sx={{
                padding: 0,
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1),
                zIndex: 2,
                display: isDisabled ? 'none' : 'block',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log('file: ', file);
                setModalFile(true);
              }}
            >
              <HighlightOffIcon htmlColor="#d32f2f" />
            </IconButton>
            <IconButton type="button" sx={{ padding: 0 }} onClick={handleDownload}>
              <InsertDriveFileOutlinedIcon htmlColor="#0242D1" sx={{ width: '40px', height: '40px' }} />
            </IconButton>
            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
              {settingsDownload.url.startsWith('http')
                ? formatFileName(settingsDownload.originalName)
                : formatFileName(settingsDownload.url)}
            </Typography>
          </>
        ) : (
          <>
            {iconUpload}
            {title && (
              <Typography variant="h3" classes={{}}>
                {title}
              </Typography>
            )}
            {body && <Typography variant="body1">{body}</Typography>}
            {caption && <Typography variant="caption">{caption}</Typography>}
          </>
        )}
      </Stack>
      <DialogConfirmation
        title={'delete uploaded file?'}
        body={`Are you sure you want to delete\nfile "${getFileName()}"?\n\nYou can re-upload a new file`}
        open={modalFile}
        onClose={onCloseModalFile}
        onAccept={onAcceptModalFile}
      ></DialogConfirmation>
      {error && (
        <FormHelperText id={id} error>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default InputFileDragDrop;
