import { Grid, useTheme } from '@mui/material';
import { ExtendsValidationHandler } from '@nexxus/components';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import usePreventLocation from 'hooks/usePreventLocation';
import Joi from 'joi';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import adminService from 'services/administrationService';
import { hookFormTrimValues } from 'utils/utils';
import { setValidationMessages } from 'utils/validationMessages';

const customSkillValidation = async (value) => {
  const { data } = await adminService.getSkill(value);
  if (data)
    throw Object.assign(new Error('Skill already exists'), {
      details: [
        {
          context: {
            label: 'Skill Name',
            key: 'skillName',
          },
          code: 422,
          type: 'any.invalid',
          path: ['skillName'],
          message: 'Skill already exists',
        },
      ],
    });
  return true;
};

const AddSkillModal = ({ openModal, setOpenModal, onHandleSaved, skillSelected }) => {
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();
  const formRef = useRef();
  const { errors, setValue, getValues, reset, handleSubmit } = ExtendsValidationHandler({
    schemaData: Joi.object({
      skillName: Joi.string()
        .required()
        .external(customSkillValidation, 'custom validation')
        .messages(setValidationMessages(['string', 'required'])),
    }),
    defaultValues: { skillName: '' },
  });

  const theme = useTheme();
  useEffect(() => {
    if (!openModal) {
      setHasChanged(false);
      reset({ skillName: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useMemo(() => {
    reset((prev) => ({
      ...prev,
      skillName: !_.isEmpty(skillSelected) && skillSelected?.name !== '' ? skillSelected.name : '',
    }));
  }, [reset, skillSelected]);

  const handleCloseModal = useCallback(() => {
    reset({ skillName: '' });
    if (hasChanged) {
      if (window.confirm(LEAVE_RESTRICTION_MESSAGE)) {
        setOpenModal(false);
        return true;
      } else {
        return false;
      }
    } else {
      setOpenModal(false);
    }
  }, [LEAVE_RESTRICTION_MESSAGE, hasChanged, reset, setOpenModal]);

  const handleOnChangeInput = (evt) => {
    if (evt.target.value !== getValues(evt.target.name)) {
      setValue(evt.target.name, evt.target.value);
    }
  };

  const onSubmit = useCallback(async () => {
    const trimmedFields = hookFormTrimValues(getValues());
    try {
      if (!_.isEmpty(skillSelected) && skillSelected.name !== '') {
        await adminService.updateSkill(skillSelected.id, {
          ...trimmedFields,
        });
      } else {
        await adminService.createSkill(trimmedFields);
      }
    } catch (e) {
      console.log('error: ', e?.response?.data);
      let err='';
      if (typeof e?.response?.data?.message === 'string') {
        err = e.response.data.message;
      } else{
        err = 'Something went wrong saving skill!';
      }
        toast.error(err);
        return;

    }

    toast.success('Skill saved succesfully');
    onHandleSaved();
  }, [getValues, onHandleSaved, skillSelected]);

  const addNewSkill = () => {
    return (
      <Grid container spacing={2} component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="skillName"
            label="Skill Name"
            placeholder=""
            onChangeInput={handleOnChangeInput}
            initialValue={getValues('skillName')}
            error={errors.skillName?.message}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalFeedback
      variant="general"
      headerText={`${skillSelected?.name !== '' ? 'Edit' : 'Add new'} Skill`}
      headerBarColor={theme.palette.primary.main}
      open={openModal}
      onAccept={() => {
        handleSubmit(onSubmit)();
      }}
      onCancel={() => {}}
      onClose={handleCloseModal}
      disableCloseOnAccept={true}
    >
      {addNewSkill()}
    </ModalFeedback>
  );
};

AddSkillModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onHandleSaved: PropTypes.func,
};

export default AddSkillModal;
