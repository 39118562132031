import Box from '@mui/material/Box';
import MainLayout from 'components/MainLayout/MainLayout';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import EditIcon from '@mui/icons-material/Edit';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { Button, CircularProgress, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as pdfIcon } from 'assets/icons/pdf-icon.svg';
import { ReactComponent as removeIcon } from 'assets/icons/remove-icon.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { styled } from '@mui/system';
import CandidateProjectNotesPopover from 'components/Candidates/CandidateProjectNotesPopover';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import ProjectFormView from 'components/Projects/Mode/ProjectFormView';
import ProjectHeaderCustomer from 'components/Projects/Mode/ProjectHeaderCustomer';
import { ROLE_SUPERTAS } from 'constants/users';
import candidateService from 'services/candidateService';
import projectService from 'services/projectService';

import { CANDIDATEPROJECT_COUNTERS, CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import ProjectPipelineCounters from 'components/Projects/ProjectPipelineCounters';
import SideBarProjectTable from './SideBarProjectTable';
import { useGetCustomersQuery, useGetRecruitersQuery } from 'api/api';
import { PROJECT_STATUS } from 'constants/project';
import useSignedS3Url from 'hooks/useSignedS3Url';

const formattedDate = (date) => moment(date).format('MM/DD/YYYY');

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        0 Candidates Found in this stage
      </Typography>
    </Stack>
  );
};

const LoadingOverlay = () => {
  return (
    <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
      <CircularProgress sx={{ width: '175px!important', height: '175px!important' }} />
    </Stack>
  );
};

const StatusTypo = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
}));

const Project = (props) => {
  const theme = useTheme();
  const history = useNavigate();
  const { ProjectId } = useParams();
  const [open, setOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const [project, setProject] = useState({});
  const [candidatesCountStatus, setCandidatesCountStatus] = useState({
    ...CANDIDATEPROJECT_COUNTERS,
  });

  const { data: recruiters } = useGetRecruitersQuery();
  const { data: customers, isSuccess: isSuccessCustomers } = useGetCustomersQuery();

  const [candidatesByStatus, setCandidatesByStatus] = useState([]);
  const [statusOfProject, setStatusOfProject] = useState([]);

  const [clientSelected, setClientSelected] = useState({});

  const [candidateSelected, setCandidateSelected] = useState('');
  const [colorStatus, setColorStatus] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const { getSignedDownloadUrl } = useSignedS3Url();

  let columns = [
    {
      field: 'full_name',
      headerName: 'NAME',
      minWidth: 280,
      flex: 2,
      sortable: false,
      headerClassName: 'first-column-header',
      renderCell: ({ row }) => {
        return (
          <Button
            LinkComponent="a"
            sx={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'flex-start' }}
            target="_blank"
            href={`/tas/candidates/${row?.id}`}
          >
            {row?.full_name}
          </Button>
        );
      },
    },
    {
      field: 'stage',
      headerName: 'STAGE',
      headerAlign: 'left',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Typography variant="caption" fontWeight="bold">
            {_.findKey(CANDIDATEPROJECT_STATUS, function (key) {
              return key === row.Projects[0].Project_Candidate.status;
            }).replaceAll('_', ' ')}
          </Typography>
        );
      },
    },
    {
      field: 'updatedAt',
      align: 'center',
      headerAlign: 'center',
      headerName: 'LAST EDIT',
      sortable: false,
      width: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" fontWeight="bold">
          {formattedDate(row.updatedAt)}
        </Typography>
      ),
    },
    {
      field: 'notes',
      headerName: 'NOTES',
      width: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        return <CandidateProjectNotesPopover label="VIEW ALL" notes={row.CandidateNotes}/>;
      },
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      editable: false,
      sortable: false,
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <IconButton
              disableRipple
              disabled={!row.cv_file_url}
              onClick={async () => {
                const signedUrl = await getSignedDownloadUrl(row.cv_file_url);
                window.open(signedUrl, '_blank');
              }}
            >
              <SvgIcon component={pdfIcon} inheritViewBox sx={{ fontSize: 22 }}></SvgIcon>
            </IconButton>
            <IconButton color={'nexxusBlack'} component={Link} to={`/tas/candidates/${row.id}`}>
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => setCandidateSelected(row.id)}>
              <SvgIcon component={removeIcon} inheritViewBox sx={{ fontSize: 22 }}></SvgIcon>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  // project hook
  useEffect(() => {
    (async () => {
      if (ProjectId) {
        try {
          const { data } = await projectService.getProject(ProjectId);
          if (!data) history('/404');
          const { data: countsStatus } = await projectService.getProjectCandidatesCount(ProjectId, {includeBlacklisted: true});
          setProject(data);
          setCandidatesCountStatus(countsStatus);
        } catch (e) {
          history('/404');
        }
      }
    })();
  }, [ProjectId]); // eslint-disable-line

  // state hook
  useEffect(() => {
    (async () => {
      if (statusOfProject.length > 0) {
        const candidates = await refetchCandidatesByStatus();
        setCandidatesByStatus(candidates);
        setLoading(false)
      }
    })();
  }, [statusOfProject]); // eslint-disable-line

  useEffect(() => {
    if (typeof project.status == 'number') {
      const stat = Object.keys(PROJECT_STATUS).find((key) => PROJECT_STATUS[key] === project.status);
      setProjectStatus(stat);
      if (stat === 'ARCHIVED') {
        setColorStatus(theme.palette.archived.main);
        return 0;
      }
      if (stat === 'ACTIVE') {
        setColorStatus(theme.palette.active.main);
        return 0;
      }
      if (stat === 'ON_HOLD') {
        setColorStatus(theme.palette.onhold.main);
        return 0;
      }
      if (stat === 'URGENT') {
        setColorStatus(theme.palette.urgent.main);
        return 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    (async () => {
      if (candidateSelected !== '') setOpen(true);
    })();
  }, [candidateSelected]); // eslint-disable-line

  // hoook set client for project
  useEffect(() => {
    (async () => {
      if (project.ClientId !== '' && isSuccessCustomers) {
        const clientSelected = customers.find((client) => client.id === project.ClientId);
        setClientSelected(clientSelected);
      }
    })();
  }, [project.ClientId]); // eslint-disable-line

  const onCloseModal = (event) => {
    event?.preventDefault();
    setOpen(false);
    setCandidateSelected('');
  };

  const onAccept = async () => {
    await projectService.candidateEnrollment(candidateSelected.toString(), ProjectId, 'ARCHIVED');
    const { data: countsStatus } = await projectService.getProjectCandidatesCount(ProjectId, {includeBlacklisted: true});
    setCandidatesByStatus(await refetchCandidatesByStatus());
    setCandidatesCountStatus(countsStatus);
    setOpen(false);
  };

  const refetchCandidatesByStatus = async () => {
    let params = {
      status: statusOfProject,
      sortBy: 'id',
      excludeRelationsCandidate: true,
      includeNotes: true
    };
    const { data } = await candidateService.getCandidatesByProjectStatus(ProjectId, params);
    return data.map((candidate) => {
      return { ...candidate, ncs: candidate?.candidate_ncs + '%', score: candidate?.candidate_score + '%' };
    });
  };

  return (
    <MainLayout {...props} current="projects">
      <Box>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            {clientSelected && <ProjectHeaderCustomer client={clientSelected} />}
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
              <StatusTypo sx={{ color: 'nexxusGray.main' }}>
                STATUS:
                <span
                  style={{
                    color: colorStatus,
                  }}
                >
                  {' '}
                  {projectStatus.replaceAll('_', ' ')}
                </span>
              </StatusTypo>

              {[ROLE_SUPERTAS].includes(props.auth.user.role) && (
                <Button
                  variant="action"
                  size="small"
                  color="error"
                  component={Link}
                  to={`/tas/projects/${ProjectId}/edit`}
                  startIcon={<EditIcon />}
                >
                  edit project
                </Button>
              )}
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ProjectPipelineCounters type="small" candidatesCountStatus={candidatesCountStatus} />
            </Box>
            {/* <ProjectHeaderCards candidatesCountStatus={candidatesCountStatus} /> */}
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 1, marginTop: 3 }} />
        <ProjectFormView recruiters={recruiters} projectDB={project}></ProjectFormView>
        <Typography
          variant="subtitle"
          align="center"
          sx={{
            mt: 2,
            mb: 4,
          }}
        >
          {project.name && project.name.toUpperCase()} CANDIDATE PIPELINE
        </Typography>

        <SideBarProjectTable
          candidateCountStatus={candidatesCountStatus}
          statusOfProject={statusOfProject}
          setStatusOfProject={setStatusOfProject}
          setIsSideBarOpen={setIsSideBarOpen}
          setLoading={setLoading}
        >
          {loading ? (
            <LoadingOverlay />
          ) : (
            <DataGrid
              rows={candidatesByStatus}
              columns={columns}
              disableColumnMenu
              className={isSideBarOpen ? 'open' : 'close'}
              sx={{
                marginLeft: '0px!important',
                borderLeft: '0px!important',
                borderTopLeftRadius: '0px!important',
                borderTopRightRadius: '0px!important',
                '& .first-column-header': {
                  paddingLeft: '16px',
                },
                '&.open': {
                  borderBottomLeftRadius: '0px',
                },
                '&.close': {
                  borderBottomLeftRadius: '16px',
                },
              }}
              autoHeight
              hideFooter
              components={{
                ...theme.components.MuiDataGrid.defaultProps.components,
                ...{
                  NoRowsOverlay: () => <NoDataOverlay />,
                  NoResultsOverlay: () => <NoDataOverlay />,
                },
              }}
            />
          )}
        </SideBarProjectTable>
      </Box>
      <ModalFeedback
        variant="warning"
        headerText="Remove Candidate"
        titleText="Do you really want to remove this candidate from current project?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
