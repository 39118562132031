import axios, { axiosPrivate } from 'api/axios';
import auth from './authService';

axiosPrivate.interceptors.request.use(
  (config) => {
    const tokens = JSON.parse(auth.getAllTokens());
    if (!tokens) return Promise.reject();

    config.headers['Authorization'] = `Bearer ${tokens.access.token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//create interceptor to refresh token
axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      error._retry = true;

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BACKEND}/v1/auth/refresh-tokens`, null, {
          withCredentials: true,
        });

        setTokens(response.data);
        const instance = await axiosPrivate(originalRequest);
        instance.config.headers['Authorization'] = `Bearer ${response.data?.access?.token}`;
        return instance;
      } catch (error) {
        console.log('final error');
      }
    }
    return Promise.reject(error);
  }
);

axiosPrivate.defaults.headers.common['Content-Type'] = 'application/json';
const apiAllTokens = 'tokens';

export function setTokens(tokens) {
  return localStorage.setItem('tokens', JSON.stringify(tokens));
}

export default {
  get: axiosPrivate.get,
  post: axiosPrivate.post,
  put: axiosPrivate.put,
  patch: axiosPrivate.patch,
  delete: axiosPrivate.delete,
  setTokens,
  apiAllTokens,
};
