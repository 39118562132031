import { CallMerge as CallMergeIcon, LinkedIn as LinkedInIcon, Person as PersonIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DuplicateMergeForm from 'pages/import/DuplicateCandidates/DuplicateMergeForm';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';
import http from 'services/httpService';

const useDuplicateCandidates = () => {
  const [duplicates, setDuplicates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await http.get(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/duplicates');
      setDuplicates(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  }, []);

  const refetch = useCallback(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (!hasFetched) {
      fetch();
      setHasFetched(true);
    }
  }, [fetch, hasFetched]);

  return { duplicates, loading, error, refetch };
};

const DuplicateCandidatesGrid = () => {
  const { duplicates, loading: loadingDuplicates, refetch: refetchDuplicates } = useDuplicateCandidates();
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [candidatesToMerge, setCandidatesToMerge] = useState([]);

  const mergeCandidateInfo = (params) => {
    candidateService.getDuplicatesByLinkedinHandle(params.row.linkedin_handle).then(({ data }) => {
      setCandidatesToMerge(data);
      setModalOpen(true);
    });
  };

  const clearLinkedInProfile = (params) => {
    candidateService
      .clearLinkedInProfile(params.id)
      .then((res) => {
        refetchDuplicates();
        toast.success('LinkedIn profile cleared');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCandidate = (params) => {
    candidateService
      .deleteCandidate(params.id)
      .then((res) => {
        refetchDuplicates();
        toast.success('Duplicate Candidate Deleted');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error deleting duplicate candidate');
      });
  };

  const duplicatesColumns = [
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'import_source', headerName: 'Import Source', flex: 1 },
    { field: 'linkedin_handle', headerName: 'LinkedIn Handle', flex: 2 },
    { field: 'linkedin', headerName: 'LinkedIn', flex: 2 },
    {
      field: 'projects',
      headerName: 'Project Count',
      width: 90,
      renderCell: (params) => {
        return params.row.Projects.length;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: (params) => [
        <GridActionsCellItem
          key="merge"
          icon={<CallMergeIcon />}
          onClick={() => mergeCandidateInfo(params)}
          label="Merge Info"
          showInMenu
        />,
        <GridActionsCellItem
          key="clear"
          icon={<LinkedInIcon />}
          onClick={() => clearLinkedInProfile(params)}
          label="Clear"
          showInMenu
        />,
        <GridActionsCellItem
          key="delete"
          icon={<PersonIcon />}
          onClick={() => deleteCandidate(params)}
          label="Delete"
          showInMenu
        />,
      ],
    },
  ];

  return (
    <>
      <Typography
        variant="subtitle"
        align="center"
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        Duplicate Candidates
      </Typography>
      <DataGrid
        headerHeight={40}
        autoHeight
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        disableSelectionOnClick
        disableColumnMenu
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        rows={duplicates}
        loading={loadingDuplicates}
        columns={duplicatesColumns}
      />
      <Stack mt={5} direction="row" spacing={3} justifyContent="end" mb={4}>
        <LoadingButton
          loading={loadingDuplicates}
          variant="contained"
          onClick={() => {
            refetchDuplicates();
          }}
        >
          Refresh duplicates
        </LoadingButton>
      </Stack>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Stack height="100%" justifyContent="center" alignItems="center">
          <Box width="1100px" padding={4} borderRadius={1} sx={{ backgroundColor: '#efefef' }}>
            <Typography variant="h4" textTransform="uppercase" mb={2}>
              <strong>Merge</strong> Candidate Info
            </Typography>
            <DuplicateMergeForm
              candidatesToMerge={candidatesToMerge}
              onClose={() => {
                setModalOpen(false);
                refetchDuplicates();
                toast.success('Candidate Info Merged');
              }}
              onCancel={() => setModalOpen(false)}
            />
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

export default DuplicateCandidatesGrid;
