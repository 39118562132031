import { FormHook } from 'hooks/form/Form';
import { schemaUser } from 'validations/users/schemaUser';
export const usersInitialState = {
  first_name: '',
  last_name: '',
  email: '',
  role: 'customer',
  phone: '',
};

const schemaUserNoClientId = {
  first_name: schemaUser.first_name,
  last_name: schemaUser.last_name,
  email: schemaUser.email,
  role: schemaUser.role,
  phone: schemaUser.phone,
};

export function UserFields(data = usersInitialState) {
  return FormHook(data, schemaUserNoClientId, false);
}
