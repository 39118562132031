import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import reportService from 'services/reportService';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import _ from 'lodash';
import { capitalizeString } from 'utils/utils';
import { useGetCustomersQuery, useGetRecruitersQuery } from 'api/api';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};
const FilterButton = styled(Button)({
  fontSize: 18,
  fontWeight: 'bold',
});

const HistoricalView = (props) => {
  const maxWidth = 'xl';

  const initialFilters = {
    ProjectId: '',
    customers: [],
    RecruiterId: '',
    startDate: moment().weekday(1).format('YYYY-MM-DD'),
    finishDate: moment().weekday(5).format('YYYY-MM-DD'),
    status: 'sent',
  };
  const theme = useTheme();
  const { setBreadcrumbs } = useBreadcrumbs();

  const [projectsOptions, setProjectsOptions] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [customer, setCustomer] = useState('');
  const [recruitersOptions, setRecruitersOptions] = useState([]);
  const [reports, setReports] = useState([]);
  const [showingResults, setShowingResults] = useState(0);
  const [filters, setFilters] = useState({ ...initialFilters });
  const [reset, setReset] = useState(false);
  const { data: customers } = useGetCustomersQuery();
  const { data: recruiters } = useGetRecruitersQuery();

  const columns = [
    {
      field: 'companyName',
      headerName: 'Customer',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => row.Client?.companyName || '',
    },
    {
      field: 'position',
      headerName: 'POSITION',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => capitalizeString(row.Project.position) || '',
    },
    {
      field: 'reportGeneratedAt',
      headerName: 'SENT ON',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => moment(row.generatedAt).format('MMMM Do, YYYY'),
    },
    {
      field: 'recruiter',
      headerName: 'Recruiter',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => row.Project.Recruiter.full_name,
    },
    {
      field: 'edit',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,

      renderCell: ({ row }) => {
        return <RenderActions row={row} />;
      },
    },
  ];

  const RenderActions = (props) => {
    const { row } = props;
    const navigate = useNavigate();
    const handleViewReport = (ev) => {
      ev.preventDefault();
      navigate(`/tas/reports/${row.id}/view`);
    };

    return (
      <Stack direction="row" spacing={1}>
        <IconButton onClick={handleViewReport} size="small">
          <VisibilityIcon color={'nexxusBlack'} />
        </IconButton>
      </Stack>
    );
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Search Reports',
      },
    ]);
  }, [setBreadcrumbs]);

  //init Hook
  useEffect(() => {
    (async () => {
      const { data: reportsDB } = await reportService.getAllReports(filters);
      setReports(reportsDB);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    setShowingResults(reports.length);
  }, [reports]);

  useEffect(() => {
    if (customer === '') return;

    const recArr = [];
    const recProjectIds = [];
    const recProject = [];

    reports.forEach((rep) => {
      if (rep.Client.id === customer && !recArr.includes(rep.Project.Recruiter.id)) {
        recArr.push(rep.Project.Recruiter.id);
      }
      if (rep.Client.id === customer && !recProjectIds.includes(rep.Project.id)) {
        recProjectIds.push(rep.Project.id);
        recProject.push(rep.Project);
      }
    });

    const recruiterOpts = recruiters.filter((rec) => recArr.includes(rec.id));
    setRecruitersOptions(recruiterOpts);
    setProjectsOptions(recProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleFilter = useCallback(async () => {
    const { data: reportsDB } = await reportService.getAllReports(filters);
    setReports(reportsDB);
    setReset(false);
  }, [filters]);

  useMemo(() => {
    if (reset === true) {
      handleFilter();
    }
  }, [handleFilter, reset]);

  const getDataValue = (customer) => (customer.companyName ? capitalizeString(customer.companyName) : '');

  return (
    <MainLayout {...props} current="reports">
      <Box>
        <Container maxWidth={maxWidth}>
          <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
            Search <strong>Reports</strong>
          </Typography>
          <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />

          <Grid container spacing={2} sx={{ marginBottom: '2em', justifyContent: 'space-between' }}>
            <Grid item xs={4} sx={{ display: 'grid', alignItems: 'center' }}>
              <Stack direction="column" spacing={2} justifyContent="flex-end" alignItems="flex-start">
                <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>
                  SELECT CUSTOMER
                </Typography>
                {/* Client Selection */}
                <Box sx={{ width: '100%' }}>
                  <InputAutoCompleteV2
                    id="client"
                    initialValue={filters.customers[0]}
                    opts={
                      customers?.map((customer) => ({
                        _id: customer.id,
                        label: getDataValue(customer),
                      })) || []
                    }
                    placeholder="No Selection"
                    onChangeInput={(ev) => {
                      setCustomer(ev.target.value);
                      setFilters((prev) => ({ ...prev, customers: [ev.target.value] }));
                    }}
                    defaultOption={false}
                    fullWidth={true}
                    sortOpts={false}
                    disableClearable={true}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ display: 'grid', alignItems: 'center' }}>
              <Stack direction="column" spacing={2} justifyContent="flex-end" alignItems="flex-start">
                <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>
                  SELECT RECRUITER
                </Typography>
                {/* Recruiter Selection */}
                <Box sx={{ width: '100%' }}>
                  <InputAutoCompleteV2
                    id="recruiter"
                    opts={
                      recruitersOptions?.map((rec) => ({
                        _id: rec.id,
                        label: capitalizeString(rec.full_name),
                      })) || []
                    }
                    isDisabled={recruitersOptions.length > 0 ? false : true}
                    placeholder={recruitersOptions.length > 0 ? `Select option` : 'No options available'}
                    onChangeInput={(ev) => {
                      setFilters((prev) => ({ ...prev, RecruiterId: ev.target.value }));
                    }}
                    initialValue={filters.RecruiterId}
                    defaultOption={false}
                    sortOpts={false}
                    disableClearable={true}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ display: 'grid', alignItems: 'center' }}>
              <Stack direction="column" spacing={2} justifyContent="flex-end" alignItems="flex-start">
                <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>
                  SELECT PROJECT
                </Typography>
                {/* Recruiter Selection */}
                <Box sx={{ width: '100%' }}>
                  <InputAutoCompleteV2
                    id="project"
                    opts={
                      projectsOptions?.map((pj) => ({
                        _id: pj.id,
                        label: `${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
                      })) || []
                    }
                    isDisabled={projectsOptions.length > 0 ? false : true}
                    placeholder={projectsOptions.length > 0 ? `Select option` : 'No options available'}
                    onChangeInput={(ev) => {
                      setFilters((prev) => ({ ...prev, ProjectId: ev.target.value }));
                    }}
                    initialValue={filters.ProjectId}
                    defaultOption={false}
                    sortOpts={false}
                    disableClearable={true}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={'auto'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={moment(filters.startDate).format('MM/DD/YYYY')}
                  onChange={(newValue) => {
                    if (newValue === null) return;
                    setFilters((prev) => ({ ...prev, startDate: moment(moment(newValue.$d)).format('YYYY-MM-DD') }));
                  }}
                  renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={'auto'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={moment(filters.finishDate).format('MM/DD/YYYY')}
                  onChange={(newValue) => {
                    if (newValue === null) return;
                    setFilters((prev) => ({ ...prev, finishDate: moment(moment(newValue.$d)).format('YYYY-MM-DD') }));
                  }}
                  renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4} sx={{ display: 'grid', alignItems: 'center', marginLeft: 'auto' }}>
              <FilterButton variant="contained" color="secondary" size="large" onClick={handleFilter}>
                Filter Results
              </FilterButton>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: 'center!important', paddingTop: '1.5em' }}>
            <Grid item xs={'auto'}>
              <Button
                size="small"
                sx={{
                  color: 'rgba(0,0,0,0.6)',
                  fontWeight: '700',
                  textTransform: 'lowercase',
                  cursor: 'default',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
              >
                showing {showingResults} results
              </Button>
            </Grid>
            {!_.isEqual(filters, initialFilters) && (
              <>
                <Typography
                  sx={{
                    fontSize: '0.8125rem',
                    textTransform: 'uppercase',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '.2em',
                  }}
                >
                  |
                </Typography>
                <Grid item xs={'auto'}>
                  <Button
                    size="small"
                    sx={{ color: 'rgba(0,0,0,0.6)', fontWeight: '700', textTransform: 'lowercase' }}
                    onClick={() => {
                      setFilters(initialFilters);
                      setReset(true);
                    }}
                  >
                    CLEAR FILTERS
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          <DataGrid
            sx={{ marginTop: '1.5em' }}
            headerHeight={50}
            rows={reports || []}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            components={{
              ...theme.components.MuiDataGrid.defaultProps.components,
              ...{
                NoRowsOverlay: () => <NoDataOverlay />,
                NoResultsOverlay: () => <NoDataOverlay />,
              },
            }}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};
export default HistoricalView;
