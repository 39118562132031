import { ROLE_HUMAN_RESOURCES } from 'constants/users';
import { Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';
import Dashboard from 'pages/humanResources/Dashboard';
import Onboarding from 'pages/humanResources/Onboarding';

const humanResourcesRoutes = [
  {
    name: 'Dashboard Human Resources',
    path: '/hr/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_HUMAN_RESOURCES],
  },
  {
    name: 'Dashboard Human Resources',
    path: '/hr/onboarding/position/:positionId/candidate/:candidateId/',
    element: <Onboarding />,
    authorization: [ROLE_HUMAN_RESOURCES],
  },
];

export const HumanResourcesRoutes = () => {
  return humanResourcesRoutes.map((route, index) => {
    return (
      <Route
        key={`hr-${index.toString()}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
