import { Box, Typography, Grid, Stack, Popover, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useGetCandidateFeedbackQuery } from 'api/api';
import { DataGrid } from '@mui/x-data-grid';
import NoDataOverlay from 'components/NoDataOverlay';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { FiberManualRecord } from '@mui/icons-material';
import { PROJECT_SENIORITY } from 'constants/project';
import { POSITIONS } from '@nexxus/constants';
import { amber } from '@mui/material/colors';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { capitalizeString } from 'utils/utils';

const Entry = styled(Typography)(({ theme }) => ({
  margin: '5px 0',
  padding: '2px 5px',
}));
const ItemListFeedback = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
const formattedDate = (date) => moment(date).format('MM/DD/YYYY');

const NoData = () => <NoDataOverlay title="NO DATA" subtitle="0 records found" />;

const RenderActions = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const togglePopOver = (ev) => {
    ev.preventDefault();
    setAnchorEl(ev.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={togglePopOver} size="large">
        <PageviewOutlinedIcon />
      </IconButton>
      <Popover
        id={`popover-${row.position}-${row.senority}-${row.date}`}
        open={open}
        anchorEl={anchorEl}
        onClose={togglePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Entry>
            <em>{formattedDate(row.dateFeed)}</em>: <b>{row.customer}</b> - <i>{capitalizeString(row.position)}</i>
          </Entry>
          <Typography>Name: {row.feedback.customer_feedback_name}</Typography>
          <Typography>Title: {row.feedback.customer_feedback_title}</Typography>
          <Typography>Email: {row.feedback.customer_feedback_email}</Typography>
          {/* // replace _ with spaces */}
          <Typography>Status pipeline: {row.pipeline.replace(/_/g, ' ')}</Typography>
          <Typography>Must have skills rate: {row.feedback.customer_feedback_rate_must}</Typography>
          <Typography>Nice to have skills rate: {row.feedback.customer_feedback_rate_nice}</Typography>
          <Typography>Concerns:</Typography>
          {row.feedback.customer_feedback_concerns?.[0]?.value && (
            <ItemListFeedback>- Cultural Compatibility</ItemListFeedback>
          )}
          {row.feedback.customer_feedback_concerns?.[1]?.value && <ItemListFeedback>- Professinalism</ItemListFeedback>}
          {row.feedback.customer_feedback_concerns?.[2]?.value && <ItemListFeedback>- English Proficiency</ItemListFeedback>}
          {row.feedback.customer_feedback_concerns?.[3]?.value && <ItemListFeedback>- None</ItemListFeedback>}
          {row.feedback.customer_feedback_concerns?.[4]?.value && (
            <ItemListFeedback>- {row.feedback.customer_feedback_other_concern}</ItemListFeedback>
          )}
          <Typography sx={{ fontWeight: 900 }}>
            Acepted: {capitalizeString(row.feedback.customer_feedback_accept)}
          </Typography>
          {['maybe', 'no'].includes(row.feedback.customer_feedback_accept) && (
            <ItemListFeedback>- Reason: {capitalizeString(row.feedback.customer_feedback_accept_why)}</ItemListFeedback>
          )}
          {row.feedback.customer_feedback_accept === 'maybe' && row?.feedback.customer_feedback_accept_schedule === true && (
            <ItemListFeedback>- Request Interview: YES</ItemListFeedback>
          )}
        </Box>
      </Popover>
    </Stack>
  );
};

const TabFeedback = ({ candidateId }) => {
  const theme = useTheme();
  const { data: customerFeedback } = useGetCandidateFeedbackQuery(candidateId);
  const [pageSize, setPageSize] = useState(20);
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);

  const CustomContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.variables.inputs.borderRadius,
    borderColor: theme.variables.inputs.borderColor,
    borderWidth: theme.variables.inputs.borderWidth,
    borderStyle: 'solid',
    padding: '15px',
    overflowY: 'auto',
    height: '503px',
  }));

  const columns = [
    {
      field: 'status',
      headerName: 'STATUS',
      width: 80,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.status === 'yes') return <FiberManualRecord color="active" sx={{ mb: -0.7, ml: '7px' }} />;
        if (row.status === 'no') return <FiberManualRecord color="urgent" sx={{ mb: -0.7, ml: '7px' }} />;
        if (row.status === 'maybe') return <FiberManualRecord sx={{ mb: -0.7, ml: '7px', color: amber[500] }} />;
        return <></>;
      },
    },
    {
      field: 'client',
      headerName: 'CUSTOMER',
      minWidth: 175,
      flex: 0.6,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      valueGetter: ({ row }) => row.customer ?? '',
    },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 1,
      minWidth: 300,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      valueGetter: ({ row }) => {
        const position = Object.values(POSITIONS).find((ti) => ti._id === row.position).label ?? '';
        const seniority = PROJECT_SENIORITY.find((ti) => ti._id === row.seniority).label ?? '';
        return `${position} - ${seniority}`;
      },
    },
    {
      field: 'dateFeed',
      headerName: 'DATE FEEDBACK',
      align: 'left',
      headerAlign: 'left',
      width: 150,
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => row.dateFeed,
      valueFormatter: (params) => {
        return moment(new Date(params.value)).format('MMM DD, YYYY HH:mm:ss');
      },
      // renderCell: ({ row }) => {
      //   return moment(new Date(row.dateFeed)).format('MMM DD, YYYY HH:mm:ss');
      // },
      // valueGetter: ({ row }) => moment(new Date(row.dateFeed)).format('MMM DD, YYYY HH:mm:ss'),
    },
    {
      field: 'statusFeed',
      headerName: 'NOTES',
      align: 'left',
      headerAlign: 'left',
      width: 175,
      sortable: false,
      valueGetter: ({ row }) => {
        if (row.feedback.customer_feedback_accept === 'yes') return 'HIRED';
        switch (row.feedback.customer_feedback_accept) {
          case 'yes':
            return 'HIRED';
          case 'no':
            return 'REJECTED';
          case 'maybe':
            if (row.feedback.customer_feedback_accept_schedule === false) return 'MAYBE';
            else return 'REQUESTED INTERVIEW';
          default:
            return '';
        }
      },
    },
    {
      field: 'view',
      headerName: 'DETAILS',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <RenderActions row={row} />;
      },
    },
  ];

  useEffect(() => {
    if (customerFeedback?.length > 0) {
      const feeds = customerFeedback
        .filter((feed) => typeof feed.feedback === 'object')
        .map((feed) => ({
          status: feed.feedback.customer_feedback_accept,
          position: feed.position,
          seniority: feed.seniority,
          dateFeed: new Date(feed.date),
          feedback: feed.feedback,
          pipeline: feed.pipeline_stage,
          customer: feed.customer,
        }));

      setFeeds(feeds);
      setLoading(false);
    } else setLoading(false);
  }, [customerFeedback]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Box sx={{}}>
          <Typography variant="label">CUSTOMER FEEDBACK</Typography>
          <CustomContainer>
            <Box
              sx={{
                minHeight: '360px',
                width: '100%',
                mt: 1,
                '& .skill--cell': {
                  justifyContent: 'center !important',
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent={'flex-start'}
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', my: 4, mb: 2 }}
              >
                <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Status</Typography>

                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord color="active" sx={{ mb: -0.7 }} /> HIRED
                </Typography>

                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} /> REJECTED
                </Typography>

                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} /> MAYBE / REQUESTED INTERVIEW
                </Typography>
              </Stack>
              {loading ? (
                <Stack
                  id="widget-loader"
                  direction={'column'}
                  spacing={2}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{ px: 4, mt: '0px!important', minHeight: '320px', [theme.breakpoints.down('xl')]: { px: 1 } }}
                >
                  <CircularProgress size={80} thickness={6} />
                </Stack>
              ) : (
                <DataGrid
                  rows={feeds}
                  columns={columns}
                  getRowId={(row) => row.position + row.senority + row.dateFeed}
                  autoHeight
                  loading={false}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  disableSelectionOnClick
                  pageSize={pageSize}
                  onPageSizeChange={(newPage) => setPageSize(newPage)}
                  pagination
                  disableColumnMenu
                  components={{
                    ...theme.components.MuiDataGrid.defaultProps.components,
                    ...{
                      NoRowsOverlay: NoData,
                      NoResultsOverlay: NoData,
                    },
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: 'dateFeed',
                          sort: 'desc',
                        },
                      ],
                    },
                  }}
                />
              )}
            </Box>
          </CustomContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabFeedback;
