import { Box, CircularProgress, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import * as React from 'react';

const GrayCircle = styled(CircularProgress)(({ theme }) => ({
  color: 'white',
  position: 'absolute',
}));

function ScoreGauge(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const getColor = (value) => {
    if (value < 60) {
      return theme.palette.error.main;
    } else if (value < 80) {
      return theme.palette.nexxusYellow.main;
    } else {
      return theme.palette.success.main;
    }
  };

  React.useEffect(() => {
    let timeout;
    if (value !== props.value) {
      const delay = props.delay || 0;

      timeout = setTimeout(() => {
        setValue(props.value);
      }, delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [props, value]);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', ...props.sx }}>
      <GrayCircle
        variant="determinate"
        size={props.size || 50}
        value={100}
        sx={{ filter: 'drop-shadow(-2px -3px 3px rgba(0, 0, 0, 0.2))' }}
      />
      <CircularProgress
        variant="determinate"
        value={parseInt(value)}
        size={props.size || 50}
        sx={{
          color: getColor(props.value),
          '&.MuiCircularProgress-root': {
            backgroundColor: 'white',
            borderRadius: '50%',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          color={getColor(props.value)}
          sx={{ fontWeight: 'bold', fontSize: props.size / 2.6 || 22 }}
        >
          {Math.round(value)}
        </Typography>
      </Box>
    </Box>
  );
}

ScoreGauge.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.any,
};

export default ScoreGauge;
