const CANDIDATEPROJECT_STATUS = {
  ARCHIVED: 0,
  PANEL_INTERVIEW: 1,
  PANEL_INTERVIEW_REJECTED: 11,
  SKILLS_ASSESSMENT: 2,
  SKILLS_ASSESSMENT_REJECTED: 21,
  SUBMITTED_CUSTOMER: 3,
  SUBMITTED_CUSTOMER_REJECTED: 31,
  CUSTOMER_INTERVIEW: 4,
  CUSTOMER_INTERVIEW_REJECTED: 41,
  OFFER_EXTENDED: 51,
  OFFER_ACCEPTED: 52,
  OFFER_REJECTION: 53,
  HIRED: 5,
  DROPPED_OUT: 6,
};

const CANDIDATEPROJECT_COUNTERS = {
  ARCHIVED: 0,
  PANEL_INTERVIEW: 0,
  PANEL_INTERVIEW_REJECTED: 0,
  SKILLS_ASSESSMENT: 0,
  SKILLS_ASSESSMENT_REJECTED: 0,
  SUBMITTED_CUSTOMER: 0,
  SUBMITTED_CUSTOMER_REJECTED: 0,
  CUSTOMER_INTERVIEW: 0,
  CUSTOMER_INTERVIEW_REJECTED: 0,
  OFFER_EXTENDED: 0,
  OFFER_ACCEPTED: 0,
  OFFER_REJECTION: 0,
  HIRED: 0,
  DROPPED_OUT: 0,
};

const GROUP_PROJECT_STATUS_REJECTED = [
  CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.DROPPED_OUT,
  CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT_REJECTED,
  CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED,
  CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
];

export { CANDIDATEPROJECT_STATUS, CANDIDATEPROJECT_COUNTERS, GROUP_PROJECT_STATUS_REJECTED };
