import { Grid } from '@mui/material';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { PROJECT_SKILL_YEAR_EXP } from 'constants/project';
import { useCallback } from 'react';

const RowSkill = ({ id, index, skills, schemas, onUpdateSkill, errors, skillsSearch, reset, skillRow }) => {
  const schema = id === 'newSkills' ? schemas.newSkill : schemas.adittionalSkill;
  const labelSkillField = id === 'newSkills' ? 'SKILLS (MUST HAVE)' : 'SKILLS (NICE TO HAVE)';
  const getMarginTop = (index) => 0;

  const handleChange = useCallback(
    (items) => {
      const obj = { target: { name: `${id}_${index}_SkillId`, value: items.target.value } };
      onUpdateSkill(obj, schema.skill);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  const handleOnChangeInput = useCallback(
    (e) => {
      onUpdateSkill(e, schema.year);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  return (
    <>
      <Grid item xs={8} sx={{ marginTop: getMarginTop(index) }}>
        <InputAutoCompleteV2
          id={`${id}_${index}_SkillId`}
          size={'small'}
          opts={skillsSearch.map((sk) => ({ _id: sk._id, label: sk.label, disabled: sk.disabled }))}
          error={errors?.[index] ? errors[index].SkillId?.txt : ''}
          initialValue={skillRow ? Number(skillRow.SkillId) : ''}
          placeholder={'Skills'}
          label={labelSkillField}
          onChangeInput={(e) => handleChange(e)}
          sortOpts={false}
        />
      </Grid>
      <Grid item xs={4} sx={{ marginTop: getMarginTop(index) }}>
        <InputAutoCompleteV2
          id={`${id}_${index}_yearExperience`}
          size={'small'}
          initialValue={skillRow?.yearExperience ?? ''}
          opts={PROJECT_SKILL_YEAR_EXP}
          onChangeInput={handleOnChangeInput}
          error={errors && errors[index] ? errors[index].yearExperience?.txt : ''}
          label="YEARS"
          styleError={{ marginTop: '3px' }}
        />
      </Grid>
    </>
  );
};

export default RowSkill;
