import { Box, Stack } from '@mui/material';
import CandidateCardInfo from './CandidateCardInfo';
import CandidateCardSummary from './CandidateCardSummary';
import CandidateCardWrapper from './CandidateCardWrapper';

import { CANDIDATE_CARD_HEIGHT, CANDIDATE_CARD_WIDTH } from '../../../constants/candidate_card';

export default function CandidateCard(props) {
  const width = CANDIDATE_CARD_WIDTH;
  const height = CANDIDATE_CARD_HEIGHT;

  return (
    <Box
      elevation={0}
      sx={{
        width,
        height,
        m: 0,
        boxShadow: 1,
        borderRadius: '8px',
        pt: 1,
        px: 1.5,
        margin: 'auto',
      }}
    >
      <Stack direction="column" justifyContent="space-between">
        <CandidateCardInfo {...props} />
        <CandidateCardSummary {...props} />
        {/* <CandidateCardInterview {...props} /> */}
      </Stack>
    </Box>
  );
}

export { CandidateCardWrapper };
