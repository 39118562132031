import { getTheme as getNewTheme } from '@nexxus/new-theme/dist/index.js';
import { getTheme as getOldTheme } from '@nexxus/old-theme/dist/index.js';

export function getThemeByName(theme) {
  return themeMap[theme];
}

const themeMap = {
  new: getNewTheme(),
  old: getOldTheme(),
};
