import { Box, Container } from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import FormReport from 'pages/tas/reports/form/FormReport';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import projectService from 'services/projectService';
import { loginExpire } from 'store/auth';
import { useGetCustomersQuery } from 'api/api';

const CreateReport = (props) => {
  let { ProjectId } = useParams();
  const maxWidth = props.maxWidth || 'xl';
  const { data: customers } = useGetCustomersQuery();
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const [project, setProject] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data } = await projectService.getProject(ProjectId);
        setProject(data);
      } catch (e) {
        navigate('/404');
      }
    })();
  }, [ProjectId, navigate]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Reports',
        url: '/tas/reports',
      },
      {
        name: 'New Report',
      },
    ]);
  }, [setBreadcrumbs]);
  return (
    <MainLayout {...props} current="reports">
      <Box>
        <Container maxWidth={maxWidth}>
          {project && <FormReport customers={customers} project={project}></FormReport>}
        </Container>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
