import { ROLE_ADMIN } from 'constants/users';
import LogDashboard from '../pages/Log/LogDashboard';
import { Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';
import LogDetail from '../pages/Log/LogDashboard/LogDetail';

export const AuditTrailRoutes = () => {
  return (
    <Route path="/audittrails" element={<PrivateRoute roles={[ROLE_ADMIN]}>{<LogDashboard />}</PrivateRoute>}>
      <Route path="/audittrails/:id" element={<PrivateRoute roles={[ROLE_ADMIN]}>{<LogDetail />}</PrivateRoute>} />
    </Route>
  );
};
