import {
  Backdrop,
  Box,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MainContainer from './MainContainer';
import React, { useEffect, useContext, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store/auth';
import {
  SideBar,
  TopBar,
  ThemeContext,
  CookiesFooter,
  SonatafyLogoSmall,
  SidebarContext,
  DesktopBackdropCardCandidate,
  PopoverCardCandidateLayout,
  MobilePopoverCardCandidateLayout,
  DashboardIcon,
  PositionIcon,
  SettingsIcon,
} from '@nexxus/components';
import { useNavigate } from 'react-router-dom';
import WrapperCustomer from 'pages/customer/WrapperCustomer';
import { useGetCurrentUserQuery, useSetLastUserActivityMutation } from '../../api/api';
import { useIdleTimer } from 'react-idle-timer';
import useWindowSize from 'hooks/useWindowSize';
import { setCandidateInfo, setCandidateOpen } from 'store/customer';
import useSignedS3Url from 'hooks/useSignedS3Url';
import candidateService from 'services/candidateService';
import { CANDIDATEPROJECT_STATUS, ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from '@nexxus/constants';
import _ from 'lodash';
import usePreventLocation from 'hooks/usePreventLocation';
import customerService from 'services/customerService';
import { toast } from 'react-toastify';
import useCustomerNotifications from 'hooks/customer/useCustomerNotifications';
import HelpIcon from '@mui/icons-material/Help';

export const NAVBAR_WIDTHS = {
  1280: {
    primary: 87,
    secondary: 103,
  },
  1366: {
    primary: 93,
    secondary: 110,
  },
  1920: {
    primary: 126,
    secondary: 149,
  },
};

const CustomNav = styled(Box)(({ theme }) => ({
  zIndex: '100000',
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
  },
  [theme.breakpoints.up(1280)]: {
    width: NAVBAR_WIDTHS[1280].primary + 'px',
  },
  [theme.breakpoints.up(1366)]: {
    width: NAVBAR_WIDTHS[1366].primary + 'px',
  },
  [theme.breakpoints.up(1920)]: {
    width: NAVBAR_WIDTHS[1920].primary + 'px',
  },
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: '100%',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2, 7, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 'unset',
    [theme.breakpoints.up(1280)]: {
      marginLeft: NAVBAR_WIDTHS[1280].secondary + 'px',
    },
    [theme.breakpoints.up(1366)]: {
      marginLeft: NAVBAR_WIDTHS[1366].secondary + 'px',
    },
    [theme.breakpoints.up(1920)]: {
      marginLeft: NAVBAR_WIDTHS[1920].secondary + 'px',
    },
  }),
}));

const SecondaryDrawerNav = styled(
  Drawer,
  {}
)(({ theme, open }) => ({
  ...(open && {
    '& .MuiDrawer-paper': {
      zIndex: '100000',
      background: '#1841A3!important',
      border: 'none!important',
      width: 160 + 'px',
      [theme.breakpoints.down(1280)]: {
        maxWidth: '180px',
      },
      [theme.breakpoints.up(1280)]: {
        width: NAVBAR_WIDTHS[1280].primary + NAVBAR_WIDTHS[1280].secondary + 'px',
      },
      [theme.breakpoints.up(1366)]: {
        width: NAVBAR_WIDTHS[1366].primary + NAVBAR_WIDTHS[1366].secondary + 'px',
      },
      [theme.breakpoints.up(1920)]: {
        width: NAVBAR_WIDTHS[1920].primary + NAVBAR_WIDTHS[1920].secondary + 'px',
      },
    },
  }),
  marginLeft: 0,
}));

const ButtonBarNavigation = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
`;

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  variant: 'caption',
  color: 'primary',
  [theme.breakpoints.up(1280)]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up(1366)]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up(1920)]: {
    fontSize: '16px',
  },
}));

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
  mr: 0.5,
  verticalAlign: 'supper',
  width: '15.22px',
  height: '8.4px',
  [theme.breakpoints.up(1280)]: {
    width: '14.26px',
    height: '7.87px',
  },
  [theme.breakpoints.up(1366)]: {
    width: '15.22px',
    height: '8.4px',
  },
  [theme.breakpoints.up(1920)]: {
    width: '20.61px',
    height: '11.37px',
  },
}));

const MainLayout = (props) => {
  const { current, customerStore, setCandidateOpen, refetchOverview /*, boundaryContactFlag, setBoundaryContactFlag */ } =
    props;
  const { openSecondary, setOpenSecondary, open: mobileOpen, setOpen: setMobileOpen } = useContext(SidebarContext);
  const [modalHubspot, setModalHubspot] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(80);
  const [secondaryDrawerWidth, setSecondaryDrawerWidth] = React.useState(drawerWidth * 3);
  const { auth } = props;
  const lastLogin = auth?.lastLogin;
  const { setThemeName } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 600px
  const isTablet = useMediaQuery(theme.breakpoints.down(1280));
  const isUpTo1280px = useMediaQuery(theme.breakpoints.up(1280));
  const isUpTo1366 = useMediaQuery(theme.breakpoints.up(1366));
  const isUpTo1920px = useMediaQuery(theme.breakpoints.up(1920));
  const history = useNavigate();
  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();
  /*
   * modal candidate card
   */
  const [candidateData, setCandidateData] = useState({});
  const { getSignedDownloadUrl } = useSignedS3Url();
  const [anchorElCandidateBackdrop, setAnchorElCandidateBackdrop] = useState(null);
  const [controlViewer, setControlViewer] = useState({ pdfSrc: '', source: '' });
  const [flagNotInterested, setFlagNotInterested] = useState(false);
  const [tabDefaultOpen, setTabDefaultOpen] = useState(0);
  /*
   * end modal candidate card
   */

  const [setLastUserActivity] = useSetLastUserActivityMutation();

  const timeout_minutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?? '15';
  const session_idle_timeout = 1000 * 60 * parseInt(timeout_minutes);
  const windowSize = useWindowSize();

  useIdleTimer({
    timeout: session_idle_timeout,
    throttle: 1000 * 30,
    onIdle: () => history('/logout'),
    onAction: () => setLastUserActivity(),
  });

  useEffect(() => {
    setThemeName('new');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setMobileOpen(false);
    setOpenSecondary(false);
  }, [setMobileOpen, setOpenSecondary]);

  useEffect(() => {
    if (windowSize[0] < 1279) {
      handleClose();
    }
  }, [handleClose, windowSize]);

  useEffect(() => {
    //order is important
    if (isUpTo1920px) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1920].primary + NAVBAR_WIDTHS[1920].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1920].primary);
    }
    if (isUpTo1366) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1366].primary + NAVBAR_WIDTHS[1366].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1366].primary);
    }
    if (isUpTo1280px) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1280].primary + NAVBAR_WIDTHS[1280].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1280].primary);
    }
  }, [isUpTo1366, isUpTo1280px, isUpTo1920px]);

  const handleLogout = async () => {
    history('/logout');
  };

  const handleGoToNotifications = () => {
    history('/customer/notifications');
  };

  const riseHandleScheduledInterview = async () => {
    await customerService.saveCustomerFeedback({
      candidateId: customerStore.candidate.id,
      projectId: Number(customerStore.candidate.position),
      scheduled: true,
    });
    toast.success('Interview request sent!');
  };

  /**
   *
   *  modal candidate card
   *
   */
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();

  useEffect(() => {
    (async () => {
      if (customerStore.candidateOpen) {
        if (customerStore?.candidate?.tabDefault === 3) setTabDefaultOpen(3);
        setAnchorElCandidateBackdrop(customerStore.candidate.target);
        const { data } = await candidateService.getCandidatesByProjectStatus(Number(customerStore.candidate.position), {
          status: [CANDIDATEPROJECT_STATUS[customerStore.candidate.pipeline]],
          includeLogs: false,
          excludeRelationsCandidate: false,
          candidateId: customerStore.candidate.id,
        });
        if (customerStore?.candidate?.controlViewer) setControlViewer(customerStore?.candidate?.controlViewer);
        setCandidateData(data[0]);
        if (
          customerStore?.candidate?.tabDefault === 3 &&
          [CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW, CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER].includes(
            data[0].Projects[0].Project_Candidate.status
          )
        ) {
          setTabDefaultOpen(3);
        } else setTabDefaultOpen(0);
      } else {
        setAnchorElCandidateBackdrop(null);
        setCandidateData([]);
        setTabDefaultOpen(0);
      }
    })();
  }, [customerStore]);

  const setAnchorActions = useCallback(() => {
    (async () => {
      if (customerStore.candidateOpen && hasChanged) {
        if (!window.confirm(LEAVE_RESTRICTION_MESSAGE)) {
          return;
        }
        setHasChanged(false);
      }
      setCandidateOpen();
    })();
  }, [LEAVE_RESTRICTION_MESSAGE, customerStore.candidateOpen, hasChanged, setCandidateOpen, setHasChanged]);

  const handleControlViewer = useCallback((control) => {
    setControlViewer(control);
  }, []);

  const riseHandleNotInterested = useCallback(() => {
    setFlagNotInterested(true);
    setTabDefaultOpen(3);
  }, []);

  const riseHandleFeedback = useCallback(
    async (feedback, candidate) => {
      await customerService.saveCustomerFeedback({
        feedback: feedback,
        candidateId: candidate,
        projectId: Number(customerStore.candidate.position),
      });
      //
      toast.success('Feedback sent!');
      if (refetchOverview) refetchOverview(candidate, feedback);
    },
    [customerStore.candidate.position, refetchOverview]
  );

  const setCandidateSkills = useMemo(() => {
    return (
      candidateData?.Skills?.map((skill) => {
        return { skill: skill.name, rate: skill.Candidate_Skill?.rate };
      }) || []
    );
  }, [candidateData?.Skills]);

  const setCandidateSoftSkills = useMemo(() => {
    return candidateData?.CandidateGrade
      ? [
          {
            skill: 'Professionalism',
            rate: candidateData?.CandidateGrade?.professionalism ?? 0,
          },
          { skill: 'English Proficiency', rate: candidateData?.CandidateGrade?.english_proficiency ?? 0 },
          { skill: 'Articulation Clarity', rate: candidateData?.CandidateGrade?.articulation ?? 0 },
          { skill: 'Experience/Job History', rate: candidateData?.CandidateGrade?.experience ?? 0 },
          { skill: 'Cultural Match', rate: candidateData?.CandidateGrade?.cultural_match ?? 0 },
        ]
      : [];
  }, [candidateData?.CandidateGrade]);

  const getIndustryExperience = () => {
    return candidateData?.CandidateIndustryExperiences.map((ie) => ({
      name: ie.IndustryExperience?.name,
      years: ie.years,
    }));
  };

  const goTo = (path) => history(path);

  const setLocations = useMemo(() => {
    return customerStore.locations?.map(({ id, name }) => ({ id, name })) ?? [];
  }, [customerStore?.locations]);
  /**
   *
   *  New Widget Topbat
   *
   */

  const { notifications, handleWidgetClick } = useCustomerNotifications(currentUser, currentUserIsSuccess, setCandidateOpen);

  if (!props.auth?.user) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', background: '#ECECEC' }}>
      <CustomNav component="nav" aria-label="navbar box">
        <SideBar
          {...props}
          mobileOpen={mobileOpen}
          handleSidebarOpen={(val) => setMobileOpen(val)}
          current={current}
          setModalHubspot={setModalHubspot}
          drawerWidth={drawerWidth}
          auth={auth}
        />
      </CustomNav>

      <SecondaryDrawerNav
        sx={{
          width: {},
          zIndex: (theme) => theme.zIndex.drawer + 9999,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openSecondary}
      >
        <SideBar
          {...props}
          mobileOpen={openSecondary}
          current={current}
          setModalHubspot={setModalHubspot}
          drawerWidth={secondaryDrawerWidth}
          auth={auth}
          isPrimary={false}
        />
      </SecondaryDrawerNav>

      <Box
        onClick={() => {
          setOpenSecondary(false);
        }}
        width={'100%'}
        sx={{ maxWidth: '100%', overflow: 'hidden' }}
      >
        <TopBar
          onHandleLogOut={handleLogout}
          handleSidebarOpen={() => setMobileOpen(!mobileOpen)}
          lastLogin={lastLogin ?? ''}
          userName={props.auth.user.first_name}
          badgeNumber={notifications?.totalNotRead ?? 0}
          open={openSecondary}
          user={props.auth?.user}
          notifications={notifications?.notifications || []}
          onHandleWidgetClick={handleWidgetClick}
          handleGoToNotifications={handleGoToNotifications}
        />
        <Main open={openSecondary} style={{ minHeight: 'calc(100vh - 86px)', paddingBottom: '71px' }}>
          <MainContainer>{props.children}</MainContainer>
          {[ROLE_CUSTOMER, ROLE_SUPERCUSTOMER].includes(props?.auth?.user.role) && (
            <WrapperCustomer modalHubspot={modalHubspot} setModalHubspot={setModalHubspot} />
          )}

          <Box
            left="0"
            padding={!isTablet ? '23.5px' : isMobile ? '0px' : '17px'}
            position="fixed"
            bottom="0"
            width="100%"
            sx={{ background: '#ECECEC' }}
          >
            <Stack marginLeft={!isTablet ? '92px' : '0px'} direction="row" justifyContent="center" alignItems="center">
              <Stack direction="row" justifyContent="center" alignItems="center" width={isMobile ? '100%' : 'auto'}>
                {isMobile && [ROLE_SUPERCUSTOMER, ROLE_CUSTOMER].includes(props.auth?.user.role) ? (
                  <AppBar position="static" sx={{ width: '100%' }}>
                    <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 10px' }}>
                      <ButtonBarNavigation
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => goTo('/customer/dashboard')}
                      >
                        <DashboardIcon />
                        <Typography>Dashboard</Typography>
                      </ButtonBarNavigation>
                      <ButtonBarNavigation
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => goTo('/customer/projects/archived')}
                      >
                        <PositionIcon />
                        <Typography>Positions</Typography>
                      </ButtonBarNavigation>
                      <ButtonBarNavigation
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => goTo('/admincustomer/users')}
                      >
                        <SettingsIcon />
                        <Typography>Settings</Typography>
                      </ButtonBarNavigation>
                      <ButtonBarNavigation
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => goTo('/customer/contact-form')}
                      >
                        <HelpIcon />
                        <Typography>Help</Typography>
                      </ButtonBarNavigation>
                    </Toolbar>
                  </AppBar>
                ) : (
                  <CustomTypography variant="caption" color="primary">
                    <CustomSvgIcon component={SonatafyLogoSmall} inheritViewBox /> POWERED BY{' '}
                    <strong>SONATAFY TECHNOLOGY</strong>
                  </CustomTypography>
                )}
              </Stack>

              {!isMobile && (
                <>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#004DD3', marginInline: '21.67px' }} />
                  <Typography fontSize={isUpTo1280px ? '15px' : '14.4px'} fontWeight="400" color="#5F6368" align="center">
                    NEXXUS {new Date().getFullYear()} v1.0.0
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
        </Main>
      </Box>
      <CookiesFooter />

      {isTablet && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={mobileOpen}
          onClick={handleClose}
        ></Backdrop>
      )}

      {!isMobile ? (
        <DesktopBackdropCardCandidate anchorEl={anchorElCandidateBackdrop} setAnchorEl={setAnchorActions}>
          {!_.isEmpty(candidateData) ? (
            <PopoverCardCandidateLayout
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              leaveMessage={LEAVE_RESTRICTION_MESSAGE}
              controlViewer={controlViewer}
              onHandleControlViewer={handleControlViewer}
              candidateInfo={candidateData ?? {}}
              candidateSkills={setCandidateSkills}
              candidateSoftSkills={setCandidateSoftSkills}
              onHandleFeedback={riseHandleFeedback}
              statusSelected={customerStore.candidate.pipeline}
              tabDefaultOpen={tabDefaultOpen}
              setAnchorEl={setAnchorActions}
              handleNotInterested={riseHandleNotInterested}
              setTabDefaultOpen={setTabDefaultOpen}
              flagNotInterested={flagNotInterested}
              setFlagNotInterested={setFlagNotInterested}
              getSignedDownloadUrl={getSignedDownloadUrl}
              locations={setLocations}
              candidateIndustryExperience={getIndustryExperience()}
              riseHandleScheduledInterview={riseHandleScheduledInterview}
            />
          ) : (
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
              <CircularProgress color="primary" sx={{ width: '120px!important', height: '120px!important' }} />
            </Stack>
          )}
        </DesktopBackdropCardCandidate>
      ) : (
        <DesktopBackdropCardCandidate
          anchorEl={anchorElCandidateBackdrop}
          setAnchorEl={setAnchorActions}
          TransitionProps={{
            onExited: () => {
              setFlagNotInterested(false);
              setTabDefaultOpen(0);
            },
          }}
          sx={{
            left: '0!important',
            width: '100%',
            top: 'unset!important',
            bottom: '0%',
            minWidth: 'unset!important',
            maxWidth: 'unset!important',
            height: 'auto',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            border: 'none',
            boxShadow: '10px 0px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <MobilePopoverCardCandidateLayout
            hasChanged={hasChanged}
            setHasChanged={setHasChanged}
            leaveMessage={LEAVE_RESTRICTION_MESSAGE}
            candidateInfo={candidateData ?? {}}
            candidateSkills={setCandidateSkills}
            candidateSoftSkills={setCandidateSoftSkills}
            onHandleFeedback={riseHandleFeedback}
            statusSelected={customerStore.candidate.pipeline}
            onHandleNotInterested={riseHandleNotInterested}
            tabDefaultOpen={tabDefaultOpen}
            setTabDefaultOpen={setTabDefaultOpen}
            flagNotInterested={flagNotInterested}
            setFlagNotInterested={setFlagNotInterested}
            getSignedDownloadUrl={getSignedDownloadUrl}
            setAnchorEl={setAnchorActions}
            locations={setLocations}
          />
        </DesktopBackdropCardCandidate>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customerStore: state.customer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    setCandidateOpen: (payload) => {
      dispatch(setCandidateOpen(payload));
    },
    setCandidateInfo: () => {
      dispatch(setCandidateInfo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
