import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import useIsMounted from 'hooks/useIsMounted';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import SkillPills from '../../../components/SkillPills/SkillPills';
import candidateService from '../../../services/candidateService';
import projectService from '../../../services/projectService';
import { PROJECT_SKILL_YEAR_EXP } from 'constants/project';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { useDispatch } from 'react-redux';
import { api } from 'api/api';

const NewTabSkills = ({ candidateId, initialSkills, isDisabled, setHasChanged }) => {
  const [skillsSearch, setSkillsSearch] = useState([]);
  const [saveSkills, setSaveSkills] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    setHasChanged(false);
    setSaveSkills(true);
    setOpen(false);
  };
  useEffect(() => {
    saveSkills && setOpen(false);
  }, [saveSkills]);

  const [skills, setSkills] = useState([
    { SkillId: '', rate: '', year_experience: '' },
    { SkillId: '', rate: '', year_experience: '' },
    { SkillId: '', rate: '', year_experience: '' },
    { SkillId: '', rate: '', year_experience: '' },
    { SkillId: '', rate: '', year_experience: '' },
  ]);
  const [saved, setSaved] = useState(false);
  const theme = useTheme();
  const isMountedRef = useIsMounted();

  useEffect(() => {
    (async () => {
      const { data: skillsDB } = await projectService.getAllSkills();
      const skillOpts = [];
      skillsDB.forEach((el) => skillOpts.push({ _id: el.id, label: el.name, disabled: el.isActive === false }));
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setSkillsSearch(skillOpts);
      }
    })();
  }, [isMountedRef]);

  const options = useMemo(() => {
    const skDB = skills
      .map((sk) => Number(sk.SkillId));
    const newArr = [...skillsSearch].filter((sk) => !sk.disabled || skDB.includes(sk._id));
    return newArr;
  }, [skills, skillsSearch]);

  useEffect(() => {
    const initValues = [];
    if (initialSkills && initialSkills.length > 0) {
      for (let i = 0; i < 5; i++) {
        initValues.push({
          SkillId: initialSkills[i]?.Candidate_Skill?.SkillId || '',
          rate: initialSkills[i]?.Candidate_Skill?.rate || '',
          year_experience: initialSkills[i]?.Candidate_Skill?.year_experience || '',
        });
      }
    }
    setSkills(initValues);
  }, [initialSkills]);

  useMemo(() => {
    (async () => {
      try {
        if (saveSkills) {
          await candidateService.updateCandidateSkills({ skills }, candidateId);
          await candidateService.updateCandidateActivity(candidateId);
          dispatch(api.util.invalidateTags(['Candidates']));
          setSaveSkills(false);
          return toast.success('Candidate skills saved!');
        }
      } catch (error) {
        let msg;
        msg = JSON.parse(JSON.stringify(error.message));
        if (error.response && error.response.data) {
          msg = error.response.data.message;
        }
        return toast.error(msg);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSkills]);
  
  const handleChange = () => {
    setHasChanged(true);
  };
  const handleSave = () => {
    setOpen(true);
  };

  return (
    <Box>
      <ModalFeedback
        variant="info"
        headerText="Update Candidate Skills"
        titleText="Do you want to update the candidate skills?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <Grid container>
        {saved && (
          <Grid item xs={12} sx={{ mb: theme.spacing(2) }}>
            <Alert onClose={() => setSaved(false)}>Candidate skills saved!</Alert>
          </Grid>
        )}
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'left',
              marginBottom: 2,
            }}
          >
            SELECT OR UPDATE CANDIDATE SKILLS OR TECHNOLOGIES
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '305px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  textAlign: 'left',
                  marginBottom: 2,
                }}
              >
                SELECT SKILL LEVEL
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'left',
              marginBottom: 2,
            }}
          >
            YEARS OF EXPERIENCE
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {skills.map((val, i) => {
          return (
            <Fragment key={i}>
              <Grid item xs={5}>
                <InputAutoCompleteV2
                  id={`skill_${i}`}
                  size={'small'}
                  opts={options}
                  // opts={skillsSearch}
                  initialValue={skills[i].SkillId}
                  placeholder={'Skills'}
                  onChangeInput={({ target: item }) => {
                    const newSkills = [...skills];
                    newSkills[i].SkillId = item.value !== ' ' ? item.value : '';
                    newSkills[i].rate = item.value !== ' ' ? 1 : '';
                    newSkills[i].year_experience = item.value !== ' ' ? 1 : '';
                    setSkills(newSkills);
                    handleChange();
                  }}
                  sortOpts={false}
                  isDisabled={isDisabled}
                />
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {skills[i].SkillId !== '' && (
                    <SkillPills
                      rate={skills[i].rate !== '' ? skills[i].rate : 1}
                      onHandleRate={(newRate) => {
                        const newSkills = [...skills];
                        newSkills[i].rate = newRate;
                        setSkills(newSkills);
                        handleChange();
                      }}
                      stylePill={{
                        height: 30,
                        width: 60,
                      }}
                      disabled={isDisabled}
                      labelPill={true}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <InputAutoCompleteV2
                  id={`skill_${i}_exp`}
                  size={'normal'}
                  initialValue={skills[i].year_experience}
                  opts={skills[i].SkillId !== '' ? PROJECT_SKILL_YEAR_EXP : []}
                  onChangeInput={(e) => {
                    const newSkills = [...skills];
                    newSkills[i].year_experience = e.target.value;
                    setSkills(newSkills);
                    handleChange();
                  }}
                  sx={{ width: '140px' }}
                  isDisabled={isDisabled}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                textAlign: 'left',
                color: 'nexxusGray.main',
                marginRight: 2,
              }}
            >
              {"DON'T FORGET TO SAVE YOUR CHANGES"}
            </Typography>
            <Button
              variant="contained"
              type="button"
              startIcon={<CheckCircleIcon />}
              disabled={isDisabled}
              onClick={() => handleSave()}
            >
              save candidate
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewTabSkills;
