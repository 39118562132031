import { Route } from 'react-router-dom';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword';
import TermsAndConditions from 'pages/auth/TermsConditions';

const AuthRoutes = [
  <Route key="auth01" path="/login" element={<Login />} />,
  <Route key="auth02" path="/forgot-password" element={<ForgotPassword />} />,
  <Route key="auth03" path="/reset-password" element={<ResetPassword />} />,
  <Route key="auth04" path="/logout" element={<Logout />} />,
  <Route key="auth05" path="/terms-and-conditions" element={<TermsAndConditions />} />,
];

export default AuthRoutes;
