import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { useState } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import ManageCountryList from './ManagedCountryList';
import CountryAvailabilityForm from './CountryAvailabilityForm';
import { useGetManagedCountriesQuery } from 'api/api';

const ManageCountriesContent = (props) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data: managedCountries, refetch: refetchManagedCountries } = useGetManagedCountriesQuery();

  return (
    <Paper>
      <Box p={3} maxWidth={1400}>
        <Typography
          variant="h2"
          mb={1}
          sx={{
            color: '#656565',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
          }}
        >
          Countries in which we cannot hire
        </Typography>
        <Typography
          variant="h3"
          mb={4}
          sx={{
            color: '#5F6368',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '19px',
            maxWidth: '900px',
          }}
        >
          Below you will find a list of countries that due to their local laws, their availability of shipping goods, or
          their capability of having a stable power or internet grid limit our ability to complete our selection and/or
          onboarding processes.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                color: '#0242D1',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              Countries
            </Typography>
            <ManageCountryList
              managedCountries={managedCountries}
              onSelectCountry={(country) => setSelectedCountry(country)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              mb={3}
              sx={{
                color: '#0242D1',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              {selectedCountry ? 'Edit Entry' : 'Add Country'}
            </Typography>
            <CountryAvailabilityForm
              country={selectedCountry}
              managedCountries={managedCountries}
              onSubmit={() => {
                setSelectedCountry(null);
                refetchManagedCountries();
              }}
              onCancel={() => setSelectedCountry(null)}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountriesContent);
