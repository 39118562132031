import { Edit as EditIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import http from 'services/httpService';

const useInvalidCandidatesByName = () => {
  const [invalidCandidates, setInvalidCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await http.get(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/invalid/group-names');
      setInvalidCandidates(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  }, []);

  const refetch = useCallback(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (!hasFetched) {
      fetch();
      setHasFetched(true);
    }
  }, [fetch, hasFetched]);

  return { invalidCandidates, loading, error, refetch };
};

const DuplicateCandidatesGrid = () => {
  const [pageSize, setPageSize] = useState(10);
  const {
    invalidCandidates,
    loading: loadingInvalidCandidates,
    refetch: refetchInvalidCandidates,
  } = useInvalidCandidatesByName();
  const navigate = useNavigate();

  const editCandidate = (params) => {
    navigate(`/tas/candidates/${params.row.id}/edit`);
  };

  // const deleteCandidate = (params) => {
  //   candidateService
  //     .deleteCandidate(params.id)
  //     .then((res) => {
  //       refetchInvalidCandidates();
  //       toast.success('LinkedIn profile cleared');
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const invalidCandidatesColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: (params) => [
        <GridActionsCellItem
          key="clear-linkedin"
          icon={<EditIcon />}
          onClick={() => editCandidate(params)}
          label="Clear LinkedIn"
        />,
        // <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteCandidate(params)} label="Delete" />,
      ],
    },
  ];

  return (
    <>
      <Typography
        variant="subtitle"
        align="center"
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        Invalid Candidates with Similar Names
      </Typography>
      <DataGrid
        headerHeight={40}
        autoHeight
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        disableSelectionOnClick
        disableColumnMenu
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        rows={invalidCandidates}
        loading={loadingInvalidCandidates}
        columns={invalidCandidatesColumns}
      />
      <Stack mt={5} direction="row" spacing={3} justifyContent="end" mb={4}>
        <LoadingButton
          loading={loadingInvalidCandidates}
          variant="contained"
          onClick={() => {
            refetchInvalidCandidates();
          }}
        >
          Refresh invalid duplicates
        </LoadingButton>
      </Stack>
    </>
  );
};

export default DuplicateCandidatesGrid;
