import { TableCell, TableRow, useTheme, Stack, Avatar, Typography, Link, IconButton, Divider } from '@mui/material';
import { ROLE_TAS } from 'constants/users';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import ActiveProjects from '../ActiveProjects';
import moment from 'moment';

const ManageRenderCompany = ({ client, user, hideArchive }) => {
  const theme = useTheme();

  return (
    <>
      <TableRow
        sx={{
          borderRadius: 0,
          backgroundColor: '#f2f2f2',
          display: hideArchive && client.status === '0' ? 'none' : 'table-row',
        }}
      >
        <TableCell>
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
            <Avatar sx={{ width: 40, height: 40, fontSize: '1rem' }} src={client.logoUrl}>
              <ApartmentRoundedIcon />
            </Avatar>
            <Typography align="left" sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
              {client.companyName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          <Link
            component="a"
            href={client.websiteUrl}
            target={'_blank'}
            sx={{ fontWeight: 'bold', fontSize: '12px', color: 'nexxusBlueExtraLight.main', textAlign: 'center' }}
          >
            COMPANY WEBSITE
          </Link>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
            {client.candidatesCountOfered}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'green', textAlign: 'center' }}>
            {client.candidatesCountHired}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'nexxusRed.main', textAlign: 'center' }}>
            {client.candidatesCountDenied}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: '400', fontSize: '14px', textAlign: 'center' }}>
            {moment(new Date(client.createdAt)).format('MMM DD, YYYY')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: '400', fontSize: '14px', textAlign: 'center' }}>
            {' '}
            {moment(new Date(client.updatedAt)).format('MMM DD, YYYY')}
          </Typography>
        </TableCell>
        <TableCell>
          {user.role === 'tas' ? (
            <IconButton
              component={RouterLink}
              to={`/tas/customer/${client.id}/${![ROLE_TAS].includes(user.role) ? 'edit' : 'view'}`}
            >
              <VisibilityIcon sx={{ fontSize: 20, color: theme.palette.nexxusBlack.main }} />
            </IconButton>
          ) : (
            <Stack direction={'row'}>
              <IconButton
                component={RouterLink}
                to={`/tas/customer/${client.id}/${![ROLE_TAS].includes(user.role) ? 'edit' : 'view'}`}
              >
                <EditIcon sx={{ fontSize: 20, color: theme.palette.nexxusBlack.main }} />
              </IconButton>
              <IconButton component={RouterLink} to={`/tas/customer/${client.id}/view`}>
                <VisibilityIcon sx={{ fontSize: 20, color: theme.palette.nexxusBlack.main }} />
              </IconButton>
            </Stack>
          )}
        </TableCell>
      </TableRow>

      <TableRow sx={{ display: hideArchive && client.status === '0' ? 'none' : 'table-row' }}>
        <TableCell colSpan={8} sx={{ p: 0 }}>
          <ActiveProjects clientId={client.id} activeProjectCount={client.activeProjectCount} client={client} />
          <Divider light sx={{ mb: 4 }} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default ManageRenderCompany;
