import { useState, useMemo, useEffect } from 'react';

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => !!entry && setIsIntersecting(entry.isIntersecting), {
        rootMargin: '-80px 0px 0px 0px',
      }),
    []
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default useIsInViewport;
