import { FormHook } from '../../../hooks/form/Form';
import { schemaClient } from '../../../validations/clients/clientRegister';

export const customerInitialState = {
  companyName: '',
  websiteUrl: '',
  logoUrl: '',
  timezone: '',
  status: '',
  notes: '',
  managers: [{ stakeholder: '', email: '' }],
};

export default function ClientFields(data = customerInitialState) {
  return FormHook(data, schemaClient);
}
