import Grid from '@mui/material/Grid';
import RepeaterControl from 'components/FormElement/RepeaterControl';
import RowSkill from 'pages/tas/projects/EditProject/rowSkill';
import { Fragment } from 'react';

const FieldSkills = ({
  keyField,
  skills,
  errors,
  addMoreSkill,
  reset,
  skillsSearch,
  onUpdateSkill,
  removeMoreSkill,
  schemas,
}) => {
  return (
    <>
      <Grid item container spacing={2}>
        {skills.length &&
          skills.map((skill, index) => {
            return (
              <Fragment key={index}>
                <RowSkill
                  index={index}
                  skillRow={skill}
                  id={keyField}
                  skills={skills}
                  schemas={schemas}
                  onUpdateSkill={onUpdateSkill}
                  errors={errors}
                  skillsSearch={skillsSearch}
                  reset={reset}
                />
              </Fragment>
            );
          })}
        <Grid item xs={12} sx={{ pt: '0!important' }}>
          <RepeaterControl
            elements={skills}
            maxElements={5}
            labelRemove={'REMOVE'}
            labelAdd={'ADD (UP TO 5)'}
            onHandleIncrement={addMoreSkill}
            onHandleDecrement={removeMoreSkill}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FieldSkills;
