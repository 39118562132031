import { connect } from 'react-redux';

import { Box } from '@mui/material';
import ManageProjects from 'pages/accountManager/projects/ManageProjects';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from '../../components/MainLayout/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

const Projects = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Projects',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="projects">
      <Box>
        <ManageProjects></ManageProjects>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
