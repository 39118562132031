import http from 'services/httpService';

function getAllReports(body) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/reports/`, {
    params: body,
  });
}

function getReport(ReportId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/reports/${ReportId}`);
}

function getReportOfWeek(ProjectId, ClientId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/reports/weekly`, { params: { ProjectId, ClientId } });
}

function registerReport({ project, customer, notes, candidates }) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/reports/customer/${customer.id}`, {
    ProjectId: project.id,
    ClientId: customer.id,
    notes,
    candidates,
  });
}

function sendReport(reportId) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/reports/${reportId}/send`);
}

function updateReport(reportId, body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/reports/${reportId}`, body);
}

export default {
  getReport,
  getAllReports,
  registerReport,
  getReportOfWeek,
  sendReport,
  updateReport,
};
