import Joi from 'joi';
import customerService from 'services/customerService';
import { setValidationMessages } from '../../utils/validationMessages';

const asyncCompanyName = async (value, helpers) => {
  try {
    if (helpers.prefs.initialStateRequest.companyName !== value) await customerService.getCompanyNameValid(value);
  } catch (e) {
    throw Object.assign(new Error('Company Name is already taken!'), {
      details: [
        {
          context: {
            label: 'companyName',
            key: 'companyName',
          },
          code: 422,
          type: 'any.invalid',
          path: ['companyName'],
          message: 'Name is already taken!',
        },
      ],
    });
  }
  return true;
};

export const schemaCustomerStakeholder = {
  stakeholder: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaCustomerManager = {
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaClient = {
  companyName: Joi.string()
    .required()
    .external(asyncCompanyName, 'custom validation')
    .messages(setValidationMessages(['string', 'required'])),
  websiteUrl: Joi.string().optional().allow(''),
  logoUrl: Joi.string().optional().allow(''),
  timezone: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  status: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  notes: Joi.string().optional().allow(''),
  managers: Joi.array().items(
    Joi.object().keys({
      ...schemaCustomerStakeholder,
      ...schemaCustomerManager,
    })
  ),
};
