import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import { FiberManualRecord } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SkillsPopover from 'components/Projects/SkillsPopover';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { PROJECT_STATUS } from 'utils/staticData';
import { capitalizeString } from 'utils/utils';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const RenderActions = (props) => {
  const navigate = useNavigate();
  const handleUrlProject = () => {
    const { row: project } = props;
    const url =
      project.status === 15 // DRAFT ID
        ? `/accountmanager/customer/intake/${project.id}`
        : `/accountmanager/projects/${project.id}/view`;
    navigate(url);
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={handleUrlProject} size="small">
        <VisibilityIcon color={'nexxusBlack'} />
      </IconButton>
    </Stack>
  );
};

const ProjectsList = (props) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.status === PROJECT_STATUS.ACTIVE) return <FiberManualRecord color="active" sx={{ mb: -0.7 }} />;
        else if (row.status === PROJECT_STATUS.URGENT) return <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />;
        else if (row.status === PROJECT_STATUS.ON_HOLD) return <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />;
        else return <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />;
      },
    },
    {
      field: 'name',
      headerName: 'POSITION',
      flex: 2,
      valueGetter: ({ row }) => capitalizeString(row.position),
    },
    {
      field: 'seniority',
      headerName: 'SENIORITY',
      flex: 1,
      valueGetter: ({ row }) => row.seniority,
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: ({ row }) => row?.Skills[0]?.name || '',
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 100,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const color = row.hired_count > 0 ? 'success.main' : 'error.main';
        return (
          <Typography variant="cell" color={color} sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}>
            {row.hired_count}/{row.teamsize}
          </Typography>
        );
      },
    },
    {
      field: 'skills',
      headerName: 'SKILLS',
      width: 100,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'skill--cell',
      renderCell: (params) => {
        return <SkillsPopover label="VIEW ALL" skills={params.row.Skills} />;
      },
    },
    {
      field: 'datecreated',
      headerName: 'DATE CREATED',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueGetter: ({ row }) => moment(row.datecreated).format('MMM DD, YYYY'),
    },
    {
      field: 'Recruiter.full_name',
      headerName: 'RECRUITER',
      flex: 1,
      valueGetter: ({ row }) => row.Recruiter?.full_name || '',
    },
    {
      field: 'view',
      headerName: 'ACTIONS',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <RenderActions row={row} userRole={props.auth.user.role} />;
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          minHeight: '360px',
          width: '100%',
          mt: 4,
          '& .skill--cell': {
            justifyContent: 'center !important',
          },
        }}
      >
        <DataGrid
          rows={props.projects.rows}
          columns={columns}
          autoHeight
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          disableColumnMenu
          components={{
            ...theme.components.MuiDataGrid.defaultProps.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay />,
              NoResultsOverlay: () => <NoDataOverlay />,
            },
          }}
          initialState={
            props.initialOrder
              ? {
                  sorting: {
                    sortModel: [{ field: props.initialOrder, sort: 'desc' }],
                  },
                }
              : {}
          }
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
