import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { PdfIcon } from 'assets/icons';
import AudioPlayerButton from '../../../Media/AudioPlayerButton';

export default function CandidateUploads(props) {
  const theme = useTheme();

  const interviewFile = props?.candidate?.interview_file_url;
  const cvFile = props?.candidate?.cv_file_url;
  const audios = props?.candidate?.CandidateAudios;

  return (
    <>
      <Box sx={{ py: 0, mx: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="label-secondary" size="small">
              English Cert
            </Typography>
            <IconButton LinkComponent="a" disableRipple disabled={!interviewFile} target="_blank" href={interviewFile}>
              <PdfIcon
                sx={{
                  fontSize: 48,
                  color: interviewFile ? theme.palette.primary.lighter : theme.palette.grey.light,
                  '&:hover': { color: theme.palette.primary.light },
                }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="label-secondary" size="small">
              CV/Resume
            </Typography>
            <IconButton LinkComponent="a" disableRipple disabled={!cvFile} target="_blank" href={cvFile}>
              <PdfIcon
                sx={{
                  fontSize: 48,
                  color: cvFile ? theme.palette.primary.lighter : theme.palette.grey.light,
                  '&:hover': { color: theme.palette.primary.light },
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="label-secondary" size="small">
          English Audios
        </Typography>
        <Stack direction="row" justifyContent="space-evenly" alignItems="center">
          {audios?.map((audio, index) => (
            <Box key={audio.id}>
              <Typography variant="label-secondary" size="small">
                Q #{index + 1}
              </Typography>
              <AudioPlayerButton src={audio.audio_file_url} />
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
}
