import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PdfIcon } from 'assets/icons';
import useSignedS3Url from '../../hooks/useSignedS3Url';
import ModalPdfViewer from 'components/Generic/Modal/ModalPdfViewer';
import { useState } from 'react';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';

const UploadPdf = ({ label, filename, handleChange, handleDelete, filetype, uploading, fileurl, inputRef, isDisabled }) => {
  const theme = useTheme();
  const [fileViewer, setFileViewer] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { getSignedDownloadUrl } = useSignedS3Url();

  return (
    <>
      <ModalFeedback
        key="confirmDelete"
        variant="warning"
        headerText="Delete PDF"
        titleText={`Delete ${label} permanently?`}
        open={deleteConfirmOpen}
        onCancel={() => setDeleteConfirmOpen(false)}
        onClose={() => setDeleteConfirmOpen(false)}
        onAccept={handleDelete}
      />
      <ModalPdfViewer fileViewer={fileViewer} onClose={() => setFileViewer('')} />
      <Stack alignItems="center">
        <Typography variant="subtitle" marginBottom={2}>
          {label}
        </Typography>
        <IconButton
          disableRipple
          disabled={!fileurl}
          onClick={async () => {
            const signedUrl = await getSignedDownloadUrl(fileurl);
            setFileViewer(signedUrl);
          }}
        >
          <PdfIcon
            sx={{
              fontSize: 64,
              color: fileurl ? theme.palette.primary.lighter : theme.palette.grey.light,
              '&:hover': { color: theme.palette.primary.light },
            }}
          />
        </IconButton>
        <div className="file-input">
          <input
            type="file"
            id={`file-${filetype}`}
            name={`file-${filetype}`}
            accept="application/pdf"
            ref={inputRef}
            className="file"
            onChange={handleChange}
            disabled={isDisabled}
          />
          {!uploading && (
            <label
              className={isDisabled ? 'disabled' : ''}
              style={{ opacity: isDisabled ? '.5' : '1' }}
              htmlFor={`file-${filetype}`}
            >
              {fileurl ? 'UPDATE PDF' : 'UPLOAD PDF'}
            </label>
          )}
          {uploading && <CircularProgress size={20} />}
        </div>
        {fileurl && (
          <Button variant="text" color="error" disabled={isDisabled} onClick={() => setDeleteConfirmOpen(true)}>
            Delete File
          </Button>
        )}
      </Stack>
    </>
  );
};

export default UploadPdf;
