import { Pause as PauseIcon, PlayArrow as PlayArrowIcon, PlayDisabled as PlayDisabledIcon } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import proptypes from 'prop-types';
import { useEffect, useState } from 'react';

const AudioPlayerButton = (props) => {
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, SetAudio] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [canPlay, setCanPlay] = useState(false);
  const [currentAudioBtn, setCurrentAudioBtn] = useState(<></>);

  const size = props.size || 36;

  useEffect(() => {
    if (isPlaying) {
      setCurrentAudioBtn(<PauseIcon />);
      return;
    } else if (!error) {
      setCurrentAudioBtn(<PlayArrowIcon />);
      return;
    }
    setCurrentAudioBtn(<PlayDisabledIcon />);
  }, [isPlaying, error]);

  const onCanPlay = () => {
    setIsLoading(false);
    setCanPlay(true);
  };

  const onTimeUpdate = (event) => {
    setPosition(Math.floor((event.target.currentTime / audio.duration) * 100));
  };

  const onEnded = () => {
    setIsPlaying(false);
    setPosition(0);
  };

  const onError = (error) => {
    setIsLoading(false);
    setError(true);
    console.log('error', error);
  };

  useEffect(() => {
    if (props.src) SetAudio(new Audio(props.src));
  }, [props.src]);

  useEffect(() => {
    if (audio) {
      setIsLoading(true);
      audio.addEventListener('canplay', onCanPlay);
      audio.addEventListener('timeupdate', onTimeUpdate);
      audio.addEventListener('ended', onEnded);
      audio.addEventListener('error', onError);
    }
    return () => {
      if (audio) {
        audio.removeEventListener('canplay', onCanPlay);
        audio.removeEventListener('timeupdate', onTimeUpdate);
        audio.removeEventListener('ended', onEnded);
        audio.removeEventListener('error', onError);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  const handlePlay = () => {
    setIsPlaying(true);
    audio.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    audio.pause();
  };

  return (
    <Box sx={{ bgcolor: theme.palette.grey.light, width: size, height: size, borderRadius: '100%', position: 'relative' }}>
      {isLoading && <CircularProgress size={size} />}
      {audio && !error && !isLoading && <CircularProgress variant="determinate" value={position} size={size} />}
      <IconButton
        disabled={!canPlay}
        sx={{ position: 'absolute', left: 0, width: size, height: size }}
        onClick={isPlaying ? handlePause : handlePlay}
      >
        {currentAudioBtn}
      </IconButton>
    </Box>
  );
};

AudioPlayerButton.propTypes = {
  src: proptypes.string.isRequired,
  size: proptypes.number,
};

export default AudioPlayerButton;
