import AudioFile from './AudioFile';

const CandidateAudios = ({ candidate }) => {
  return (
    <>
      <AudioFile key={1} candidateId={candidate.id} questionId={1} audio={candidate?.CandidateAudios[0]} />
      <AudioFile key={2} candidateId={candidate.id} questionId={2} audio={candidate?.CandidateAudios[1]} />
      <AudioFile key={3} candidateId={candidate.id} questionId={3} audio={candidate?.CandidateAudios[2]} />
    </>
  );
};

export default CandidateAudios;
