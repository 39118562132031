import { Autocomplete, FormHelperText, Grid, TextField } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { PROJECT_SKILL_YEAR_EXP } from 'constants/project';

const SkillFields = ({ index, skills, errors, values, handleValue, disabled }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          id={`intake_skills_skill_${index}`}
          size="medium"
          disablePortal
          options={skills}
          getOptionLabel={(option) => {
            return option['label'] || '';
          }}
          getOptionDisabled={(option) => option.disabled}
          sx={{
            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #FF5050!important',
            },
          }}
          renderInput={(params) => <TextField {...params} label="Skill" error={errors?.[index]?.SkillId ? true : false} />}
          onChange={(_event, newValue) => handleValue(newValue?.id ?? '', index, 'SkillId')}
          value={skills.find((sk) => sk.id === values?.[index]?.SkillId)}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          disabled={disabled}
        ></Autocomplete>
        {errors?.[index]?.SkillId?.message && <FormHelperText error>{errors?.[index]?.SkillId?.message}</FormHelperText>}
      </Grid>
      <Grid item xs={12} md={3}>
        <InputAutoCompleteV2
          id={`intake_skills_yoe_${index}`}
          size={'normal'}
          placeholder="Years of Experience"
          error={errors?.[index]?.yearExperience?.message}
          initialValue={values[index] ? Number(values[index].yearExperience) : ''}
          opts={PROJECT_SKILL_YEAR_EXP}
          onChangeInput={(e) => {
            handleValue(e.target.value, index, 'yearExperience');
          }}
          isDisabled={disabled}
          disableClearable={true}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputAutoCompleteV2
          id={`intake_skills_type_${index}`}
          placeholder="Type Must/Nice"
          size={'normal'}
          error={errors?.[index]?.type?.message}
          initialValue={values[index] ? values[index].type : ''}
          opts={[
            { _id: 'must', label: 'Must Have' },
            { _id: 'nice', label: 'Nice Have' },
          ]}
          onChangeInput={(e) => {
            handleValue(e.target.value, index, 'type');
          }}
          isDisabled={disabled}
          disableClearable={true}
        />
      </Grid>
    </>
  );
};

// @ts-ignore
const SkillsInfo = ({ values, errors, skillsOpts, disabled, onHandleSkills, initialStateRequest }) => {
  const [skills, setSkills] = useState([]);

  useMemo(() => {
    const skillsDB = _.get(values, 'intake_skills');
    setSkills(skillsDB);
  }, [values]);

  const updateState = useCallback(
    (value, index, field, type) => {
      const newFields = [...skills];
      newFields[index][field] = value;
      if (field === 'SkillId' && value === '') {
        newFields[index]['yearExperience'] = '';
        newFields[index]['type'] = '';
      }

      setSkills(newFields);
      onHandleSkills(newFields, type);
    },
    [onHandleSkills, skills]
  );

  const options = useMemo(() => {
    const skDB = initialStateRequest?.intake_skills?.map((sk) => Number(sk.SkillId)).filter((el) => el !== 0);
    const newArr = [...skillsOpts]?.filter((sk) => !sk.disabled || skDB.includes(sk.id));
    return newArr;
  }, [skillsOpts, initialStateRequest]);

  return (
    <Grid container spacing={2}>
      {[...Array(5)].map(
        (
          // @ts-ignore
          el,
          index
        ) => (
          <SkillFields
            key={`${uuidv4()}`}
            index={index}
            skills={options}
            errors={errors?.intake_skills}
            values={skills}
            handleValue={(value, index, field) => {
              updateState(value, index, field, 'must');
            }}
            disabled={disabled}
          ></SkillFields>
        )
      )}
    </Grid>
  );
};

export default SkillsInfo;
