import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, IconButton, Stack, TextField, FormControl, FormLabel } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';

import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { useGetCandidateProjectMetaQuery, useUpdateCandidateProjectMetaMutation } from 'api/api';

const schema = Joi.object({
  ncs_override: Joi.number().integer().min(1).max(100).allow('').label('NCS Override'),
});

const ManageCandidateNCS = ({ candidateId, projectId, disabled = false }) => {
  const theme = useTheme();
  const [previousRequestId, setPreviousRequestId] = useState(null);
  const {
    data: candidateProjectMeta,
    refetch: refetchMeta,
    requestId,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetCandidateProjectMetaQuery(
    {
      candidateId,
      projectId,
    },
    {
      skip: !candidateId || !projectId,
    }
  );

  const [updateCandidateProjectMeta] = useUpdateCandidateProjectMetaMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      ncs_override: candidateProjectMeta?.meta?.ncs_override ?? '',
    },
  });

  const onSubmit = async (data) => {
    updateCandidateProjectMeta({
      candidateId,
      projectId,
      data,
    })
      .unwrap()
      .then(() => {
        toast.success('NCS Override updated successfully');
        refetchMeta();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isSuccess && !isLoading && !isFetching && previousRequestId !== requestId) {
      setPreviousRequestId(requestId);
      resetField('ncs_override', { defaultValue: candidateProjectMeta?.meta?.ncs_override });
    }
  }, [candidateProjectMeta?.meta?.ncs_override, isFetching, isLoading, isSuccess, previousRequestId, requestId, resetField]);

  if (!candidateId || !projectId) return null;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        disabled={disabled}
        name="ncs_override"
        control={control}
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <FormLabel htmlFor="ncs_override">NCS Override</FormLabel>
              <TextField
                type="number"
                InputProps={{
                  endAdornment: (
                    <Stack direction="row" gap={1}>
                      {!!watch().ncs_override && (
                        <IconButton
                          disabled={disabled}
                          sx={{ color: theme.palette.grey[600] }}
                          onClick={() => setValue('ncs_override', '')}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <Button
                        variant="text"
                        type="submit"
                        size="small"
                        disabled={
                          disabled ||
                          (!candidateProjectMeta?.meta?.ncs_override && !watch().ncs_override) ||
                          candidateProjectMeta?.meta?.ncs_override === watch().ncs_override
                        }
                      >
                        Update
                      </Button>
                    </Stack>
                  ),
                }}
                helperText={errors?.ncs_override?.message.toString()}
                error={!!errors?.ncs_override?.message}
                placeholder="Type a value between 1 and 100 to override NCS"
                {...field}
              />
            </FormControl>
          );
        }}
      />
    </form>
  );
};

export default ManageCandidateNCS;
