import Joi from 'joi';
import userService from 'services/userService';
import { setValidationMessages } from 'utils/validationMessages';

const methodOldEmailValid = async (value, helpers) => {
  if (helpers.prefs.initialStateRequest.old_email !== value) {
    throw Object.assign(new Error('Email is already taken!'), {
      details: [
        {
          context: {
            label: 'email',
            key: 'email',
          },
          code: 422,
          type: 'any.invalid',
          path: ['email'],
          message: 'Field must be the current Email',
        },
      ],
    });
  }
  return true;
};

const methodEmailValid = async (value, helpers) => {
  if (helpers.prefs.initialStateRequest.email !== value) {
    try {
      await userService.validateNewEmail(value);
    } catch (e) {
      throw Object.assign(new Error('Email is already taken!'), {
        details: [
          {
            context: {
              label: 'email',
              key: 'email',
            },
            code: 422,
            type: 'any.invalid',
            path: ['email'],
            message: 'Email is already taken!',
          },
        ],
      });
    }
  }
  return true;
};

const methodCurrentPasswordValid = async (value, helpers) => {
  if (helpers.prefs.initialStateRequest.email !== value) {
    try {
      await userService.validateCurrentPassword(value);
    } catch (e) {
      throw Object.assign(new Error('Field must be the current Password'), {
        details: [
          {
            context: {
              label: 'old_password',
              key: 'old_password',
            },
            code: 422,
            type: 'any.invalid',
            path: ['old_password'],
            message: 'Field must be the current Password',
          },
        ],
      });
    }
  }
  return true;
};

export const schemaProfile = {
  first_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  last_name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  avatar: Joi.string().optional().allow(null, ''),
  phone: Joi.string().optional().allow(null, ''),
};

export const schemaProfileEmail = {
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .external(methodEmailValid, 'email validation')
    .messages(setValidationMessages(['string', 'required'])),
  old_email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .external(methodOldEmailValid, 'old email validation')
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaProfilePassword = {
  old_password: Joi.string()
    .required()
    .external(methodCurrentPasswordValid, 'old password validation')
    .messages(setValidationMessages(['string', 'required'])),
  password: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  password_confirm: Joi.any()
    .valid(Joi.ref('password'))
    .required()
    .messages(setValidationMessages(['ref'])),
};
