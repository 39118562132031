import { ROLE_ADMIN } from 'constants/users';
import Dashboard from 'pages/admin/Dashboard';
import UserAccessControl from 'pages/userAccessControl/UserAccessControl';
import { Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';

const adminRoutes = [
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_ADMIN],
  },
  {
    name: 'User Access Control',
    path: '/admin/users',
    element: <UserAccessControl />,
    authorization: [ROLE_ADMIN],
  },
];

export const AdminRoutes = () => {
  return adminRoutes.map((route, index) => {
    return (
      <Route
        key={`admin-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
