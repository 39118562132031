import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MainLayout from 'components/MainLayout/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { useGetCurrentUserQuery, useGetSkillsQuery } from 'api/api';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import AddSkillModal from './addSkillModal';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import administrationService from 'services/administrationService';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import AdministrationService from 'services/administrationService';
import { toast } from 'react-toastify';

const FilterButton = styled(Button)({
  fontSize: 18,
  fontWeight: 'bold',
});

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const AdminSkills = (props) => {
  const [pageSize, setPageSize] = useState(15);
  const theme = useTheme();
  const maxWidth = props.maxWidth || 'xl';
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();
  const initialFilters = {
    skill: '',
    includeInactive: false,
  };
  const [filters, setFilters] = useState(initialFilters);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { data: skills, isLoading, refetch } = useGetSkillsQuery();
  const [skillsDB, setSkillsDB] = useState([]);
  const [skillSelected, setSkillSelected] = useState({});

  useMemo(() => {
    setSkillsDB(skills);
  }, [skills]);

  const handleSearch = useCallback(async () => {
    const { data } = await administrationService.getSkillsQuery({
      value: filters.skill,
      isActive: filters.includeInactive,
    });
    setSkillsDB(data);
  }, [filters]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Admin Skills',
        url: '/tas/admin/skills',
      },
      {
        name: 'Skills',
      },
    ]);
  }, [setBreadcrumbs]);

  const handleEditSkill = useCallback((row, type) => {
    setSkillSelected(row);
    if (type === 'edit') setOpenModal(true);
    else setOpenDeleteModal(true);
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.isActive ? <Chip label="Active" color="primary" /> : <Chip label="Inactive" />;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton onClick={() => handleEditSkill(row, 'edit')} size="small">
              <EditIcon color={'nexxusBlack'} />
            </IconButton>
            {row.isActive ? (
              <IconButton onClick={() => handleEditSkill(row, 'delete')} size="medium" sx={{ mx: 4 }}>
                <HighlightOffIcon color={'error'} />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleEditSkill(row, 'delete')} size="medium" sx={{ mx: 4 }}>
                <CheckCircleOutlineIcon color={'primary'} />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  const handleSaved = useCallback(() => {
    setOpenModal(false);
    refetch();
  }, [refetch]);

  const handleOpenNew = () => setOpenModal(true);

  useMemo(() => {
    if (openModal === false && openDeleteModal === false) {
      setSkillSelected({ name: '' });
    }
  }, [openModal, openDeleteModal]);

  const handleSkillSearch = useCallback(
    async (e) => {
      if (e.target.value !== filters.skill) {
        setFilters((prev) => ({ ...prev, skill: e.target.value }));
      }
    },
    [filters.skill]
  );
  const onHandleDeleteUser = useCallback(async () => {
    await AdministrationService.updateSkill(skillSelected.id, {
      isActive: !skillSelected.isActive,
    });
    setSkillSelected({ name: '' });
    toast.success('Skill saved succesfully');
    handleSaved();
  }, [skillSelected, handleSaved]);

  return (
    <MainLayout {...props} current="administration">
      <AddSkillModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onHandleSaved={handleSaved}
        skillSelected={skillSelected}
      />
      <ModalFeedback
        variant="warning"
        open={openDeleteModal}
        headerText={`${skillSelected?.isActive ? 'Disable' : 'Enable'} Current Skill`}
        titleText={`Do you want to ${skillSelected?.isActive ? 'disable' : 'enable'} this skill?`}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSkillSelected({ name: '' });
        }}
        onAccept={onHandleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSkillSelected({ name: '' });
        }}
      />
      <Box>
        <Container maxWidth={maxWidth}>
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
                Welcome <strong>{currentUser?.first_name}!</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={'auto'} sx={{ marginLeft: 'auto' }}>
              <FormControl sx={{ width: '100%' }}>
                <FilterButton variant="contained" color="info" size="large" onClick={handleOpenNew}>
                  + Add Skill
                </FilterButton>
              </FormControl>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />

          <Grid container spacing={0} mb={3} sx={{ width: '100%' }}>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <FilterButton
                variant="contained"
                color="warning"
                size="small"
                onClick={() => {
                  setFilters(initialFilters);
                  setSkillsDB(skills);
                  setSkillSelected({});
                }}
                sx={{}}
              >
                Clear Filters
              </FilterButton>

              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={filters.includeInactive}
                    onChange={(_e, checked) => setFilters((prev) => ({ ...prev, includeInactive: !prev.includeInactive }))}
                  />
                }
                sx={{ ml: 2 }}
                label={<Typography variant="caption">Exclude Inactive</Typography>}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <InputAutoCompleteV2
                  id={'skillsDB'}
                  size={'normal'}
                  label=""
                  placeholder="Search Skill"
                  error={''}
                  initialValue={filters.skill}
                  opts={skills?.map((sk) => ({ _id: sk.id, label: sk.name })) ?? []}
                  onChangeInput={handleSkillSearch}
                  isDisabled={false}
                  multiple={false}
                  disableClearable
                />
                <FilterButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleSearch}
                  sx={{ maxWidth: '180px' }}
                >
                  FILTER RESULTS
                </FilterButton>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: 'center!important' }}>
            <Grid item xs={'auto'}>
              <Button
                size="small"
                sx={{
                  color: 'rgba(0,0,0,0.6)',
                  fontWeight: '700',
                  textTransform: 'lowercase',
                  cursor: 'default',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
              >
                showing {skillsDB?.length} results
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            sx={{ mt: 4 }}
            headerHeight={50}
            rows={skillsDB || []}
            loading={isLoading}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            components={{
              ...theme.components.MuiDataGrid.defaultProps.components,
              ...{
                NoRowsOverlay: () => <NoDataOverlay />,
                NoResultsOverlay: () => <NoDataOverlay />,
              },
            }}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSkills);
