import { Typography, Box, Container, Stack, SvgIcon, styled, Divider, IconButton } from '@mui/material';
import { ReactComponent as logo } from 'assets/icons/logo_terms_cond.svg';
import { PaperTermsConditions, SonatafyLogoSmall } from '@nexxus/components';

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  variant: 'caption',
  color: 'primary',
  [theme.breakpoints.up(1280)]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up(1366)]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up(1920)]: {
    fontSize: '16px',
  },
}));

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
  mr: 0.5,
  verticalAlign: 'supper',
  width: '15.22px',
  height: '8.4px',
  [theme.breakpoints.up(1280)]: {
    width: '14.26px',
    height: '7.87px',
  },
  [theme.breakpoints.up(1366)]: {
    width: '15.22px',
    height: '8.4px',
  },
  [theme.breakpoints.up(1920)]: {
    width: '20.61px',
    height: '11.37px',
  },
}));

const TermsAndConditions = () => {
  const navigationCallback = () => close();
  return (
    <Box sx={{ background: '#ECECEC' }}>
      <Stack direction={'row'} justifyContent={'center'} alignContent={'center'} sx={{ background: 'white', p: 2 }}>
        <IconButton sx={{ width: '105px' }}>
          <SvgIcon component={logo} inheritViewBox sx={{ fontSize: 22, width: '115px', height: '35px' }}></SvgIcon>
        </IconButton>
      </Stack>
      <Container sx={{ pb: 6 }}>
        <Typography></Typography>
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          mt="20px!important"
          mb="4px!Important"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Terms & Conditions
        </Typography>

        <PaperTermsConditions navigation={navigationCallback} navigationText={'GO BACK TO LOGIN'}></PaperTermsConditions>

        <Box left="0" position="relative" bottom="0" width="100%" sx={{ background: '#ECECEC', mt: 4 }}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CustomTypography variant="caption" color="primary">
                <CustomSvgIcon component={SonatafyLogoSmall} inheritViewBox /> POWERED BY{' '}
                <strong>SONATAFY TECHNOLOGY</strong>
              </CustomTypography>
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#004DD3', marginInline: '21.67px' }} />
            <Typography fontSize={'15px'} fontWeight="400" color="#5F6368" align="center">
              NEXXUS {new Date().getFullYear()} v1.0.0
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
