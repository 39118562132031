import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { CANDIDATEPROJECT_STATUS, CANDIDATEPROJECT_COUNTERS } from 'constants/candidate_project';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';
import projectService from 'services/projectService';
import reportService from 'services/reportService';
import { loginExpire } from 'store/auth';
import { seniority } from 'utils/staticData';
import StatusCardsReport from './statusCardReport';
import { ROLE_SUPERTAS } from '@nexxus/constants';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const FormReport = ({ customers, auth, project, report }) => {
  const navigate = useNavigate();
  const [candidatesCountStatus, setCandidatesCountStatus] = useState({ ...CANDIDATEPROJECT_COUNTERS });
  const [candidatesByStatus, setCandidatesByStatus] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [seniorityShown, setSeniorityShown] = useState('');
  const [totalCandidates, setTotalCandidates] = useState({ pipeline: 0, queue: 0 });
  const [reportData, setReportData] = useState({
    project: {},
    customer: {},
    candidates: Object.assign({}, ...Object.entries({ ...CANDIDATEPROJECT_COUNTERS }).map(([k, v]) => ({ [k]: [] }))),
    notes: '',
  });

  const refreshCandidateData = useCallback(
    async (projectId) => {
      const { data: countsStatus } = await projectService.getProjectCandidatesCount(projectId, {includeBlacklisted: true});
      const arr = Object.values(CANDIDATEPROJECT_STATUS); //filter((key) => key !== 0);

      const { data: newArr } = await candidateService.getCandidatesByProjectStatus(projectId, {
        status: arr,
        sortBy: 'id',
        includeLogs: false,
        includeBlacklisted: true,
        excludeRelationsCandidate: true,
      });
      const candidates = { ...reportData.candidates };

      newArr.forEach((cand) => {
        Object.keys(CANDIDATEPROJECT_STATUS).forEach(function (key, index) {
          if (CANDIDATEPROJECT_STATUS[key] === cand.Projects[0].Project_Candidate.status) {
            candidates[key].push(cand);
          }
        });
      });

      Promise.all([
        setCandidatesCountStatus(countsStatus),
        setCandidatesByStatus(candidates),
        // setCandidatesByStatus([]),
        setReportData((prev) => ({
          ...prev, 
          candidates: candidates,
          // candidates: [],
          project: project,
          customer: project.Client,
        })),
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  useEffect(() => { 
    (async () => {
      if (!_.isEmpty(project)) {
        await refreshCandidateData(project.id);
        if (!_.isEmpty(report)) {
          setReportData((prev) => ({ ...prev, notes: _.unescape(_.unescape(report.notes)) }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useMemo(() => {
    const status = { ...candidatesCountStatus };
    delete status.ARCHIVED;
    const pipeline = Object.values({ ...status }).reduce((acc, val) => acc + val, 0) || 0;
    const queue = Object.values({ ...candidatesCountStatus }).reduce((acc, val) => acc + val, 0) || 0;

    setTotalCandidates({ pipeline, queue });
  }, [candidatesCountStatus]);

  useMemo(() => {
    const projSeniority = project.seniority;
    if (projSeniority) {
      const senior = seniority.find((current) => current._id === projSeniority);
      setSeniorityShown(senior.label);
    }
    if (project?.Client?.companyName) {
      setCompanyName(project.Client.companyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const cleanHTML = (dirtyHTML) =>
    DOMPurify.sanitize(dirtyHTML, {
      USE_PROFILES: { html: true },
    });

  const BoxStyledNotes = styled(Box)(({ theme }) => ({
    '> ol': {
      paddingLeft: 30,
    },
  }));

  // Confirm Draft Modal
  const [openConfirmDraft, setOpenConfirmDraft] = useState(false);
  const onCloseConfirmDraft = (event, reason) => {
    event?.preventDefault();
    setOpenConfirmDraft(false);
  };
  const onAcceptDraft = async (e, reason) => {
    await reportService.registerReport(reportData);
    setOpenConfirmDraft(false);
    toast.success('Report Draft Created!');
    navigate('/tas/reports');
  };

  // Confirm Draft Update Modal
  const [openConfirmUpdateDraft, setOpenConfirmUpdateDraft] = useState(false);
  const onCloseConfirmUpdateDraft = (event, reason) => {
    event?.preventDefault();
    setOpenConfirmUpdateDraft(false);
  };
  const onAcceptUpdateDraft = async (e, reason) => {
    await reportService.updateReport(report.id, { candidates: reportData.candidates, notes: reportData.notes });
    setOpenConfirmUpdateDraft(false);
    toast.success('Report Updated!');
    navigate('/tas/reports');
  };

  // Confirm Send Draft Modal
  const [openConfirmSendEmailReport, setOpenConfirmSendEmailReport] = useState(false);
  const onCloseConfirmSendEmailReport = (event, reason) => {
    event?.preventDefault();
    setOpenConfirmSendEmailReport(false);
  };
  const onAcceptSendEmailReport = async (e, reason) => {
    await reportService.updateReport(report.id, { candidates: reportData.candidates, notes: reportData.notes });
    await reportService.sendReport(report.id);
    setOpenConfirmSendEmailReport(false);
    toast.success('Report Sent!');
    navigate('/tas/reports');
  };

  return (
    <>
      {report && report.status === 'draft' && (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Typography>DRAFT</Typography>
        </Stack>
      )}
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="left" sx={{ textTransform: 'uppercase' }}>
            {report && typeof report.notes !== 'undefined' ? '' : 'Create '}Weekly Report
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={'right'} sx={{ fontWeight: 900, fontSize: 18 }}>
            CANDIDATES IN QUEUE:{' '}
            <span
              style={{
                marginLeft: '.6em',
                width: '2em',
                height: '2em',
                boxShadow: 'rgb(0 0 0 / 18%) -2px 2px 5px',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '2em',
                borderRadius: '50%',
              }}
            >
              {totalCandidates.queue}
            </span>
          </Typography>
          <Typography textAlign={'right'} sx={{ fontWeight: 900, fontSize: 18, mt: 1 }}>
            TOTAL CANDIDATES:{' '}
            <span
              style={{
                marginLeft: '.6em',
                width: '2em',
                height: '2em',
                boxShadow: 'rgb(0 0 0 / 18%) -2px 2px 5px',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '2em',
                borderRadius: '50%',
              }}
            >
              {totalCandidates.pipeline}
            </span>
          </Typography>
        </Grid>
        <Grid item container ml={2} mb={3} sx={{ background: 'rgba(0,0,0,.015)', paddingBottom: 2 }}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="label-secondary"
              color="info"
              sx={{
                fontSize: '12px',
              }}
            >
              Customer:
            </Typography>
            <Typography variant="placeholder" color="black" sx={{ display: 'block', textTransform: 'capitalize' }}>
              {companyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="label-secondary"
              color="info"
              sx={{
                fontSize: '12px',
              }}
            >
              Project:
            </Typography>
            <Typography variant="placeholder" color="black" sx={{ display: 'block', textTransform: 'capitalize' }}>
              {project.position}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="label-secondary"
              color="info"
              sx={{
                fontSize: '12px',
              }}
            >
              Seniority:
            </Typography>
            <Typography variant="placeholder" color="black" sx={{ display: 'block', textTransform: 'capitalize' }}>
              {seniorityShown}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatusCardsReport
            candidatesByStatus={candidatesByStatus}
            candidatesCountStatus={candidatesCountStatus}
          ></StatusCardsReport>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 900, fontSize: 18 }}>NOTES FOR CUSTOMER:</Typography>
        </Grid>
        {report && typeof report.notes !== 'undefined' && report?.status === 'sent' ? (
          <Grid item xs={12}>
            <BoxStyledNotes dangerouslySetInnerHTML={{ __html: cleanHTML(_.unescape(_.unescape(report.notes))) }} />
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ '> .quill > .ql-container': { minHeight: '190px' } }}>
            <ReactQuill
              theme="snow"
              value={reportData.notes}
              onChange={(value) => setReportData((prev) => ({ ...prev, notes: value }))}
              modules={modules}
              formats={formats}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {report?.status !== 'sent' && (
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                onClick={_.isEmpty(report) ? (e) => setOpenConfirmDraft(true) : (e) => setOpenConfirmUpdateDraft(true)}
                disabled={reportData.notes === '' || reportData.notes === '<p><br></p>'}
              >
                {_.isEmpty(report) ? 'CREATE DRAFT' : 'UPDATE DRAFT'}
              </Button>
              {report?.status === 'draft' && [ROLE_SUPERTAS].includes(auth.user.role) && (
                <Button variant="contained" color="success" onClick={() => setOpenConfirmSendEmailReport(true)}>
                  SEND EMAIL
                </Button>
              )}
            </Stack>
          )}
        </Grid>
        <ModalFeedback
          variant="info"
          headerText={'Create Draft Report'}
          titleText={'Do you want create draft report?'}
          open={openConfirmDraft}
          onCancel={onCloseConfirmDraft}
          onClose={onCloseConfirmDraft}
          onAccept={onAcceptDraft}
        />
        <ModalFeedback
          variant="info"
          headerText={'Update Draft Report'}
          titleText={'Do you want update draft report?'}
          open={openConfirmUpdateDraft}
          onCancel={onCloseConfirmUpdateDraft}
          onClose={onCloseConfirmUpdateDraft}
          onAccept={onAcceptUpdateDraft}
        />
        <ModalFeedback
          variant="info"
          headerText={'Send Email Report'}
          titleText={'Do you want send email report?'}
          open={openConfirmSendEmailReport}
          onCancel={onCloseConfirmSendEmailReport}
          onClose={onCloseConfirmSendEmailReport}
          onAccept={onAcceptSendEmailReport}
        />
      </Grid>
      <Divider sx={{ my: 4 }} />
      <Stack direction="row" spacing={2} mt={2}>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={(e) => navigate('/tas/reports')}>
          BACK TO REPORTS
        </Button>
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormReport);
