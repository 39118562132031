import { Grid, useTheme } from '@mui/material';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import _ from 'lodash';
import { UserFields, usersInitialState } from 'pages/userAccessControlCustomer/userFields';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import UsersService from 'services/UsersService';
import usePreventLocation from 'hooks/usePreventLocation';
import { hookFormTrimValues } from 'utils/utils';

const AddUsersModal = ({ openModal, setOpenModal, clientId, onHandleSaved, userSelected, setUserSelected }) => {
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();
  const { fields, handleSave, errors, handleSubmit, validSubmit, resetForm, setValues, setInitialStateRequest } =
    UserFields(usersInitialState);
  const theme = useTheme();
  useEffect(() => {
    if (!openModal) {
      setHasChanged(false);
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        const trimmedFields = hookFormTrimValues(fields);
        if (!_.isEmpty(userSelected)) await UsersService.updateUser(userSelected.id, trimmedFields);
        else await UsersService.createNewUser({ ...trimmedFields, clientId });

        toast.success('User saved succesfully');
        onHandleSaved();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  useMemo(() => {
    if(clientId)
      setValues((prev) => ({ ...prev, clientId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    if (!_.isEmpty(userSelected)) {
      const clientId = userSelected.Clients?.length > 0 ? userSelected.Clients[0].id : '';
      const { first_name, last_name, email, role, phone } = userSelected;
      setValues((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
      }));
      setInitialStateRequest((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const handleCloseModal = useCallback(() => {
    if (hasChanged) {
      if (window.confirm(LEAVE_RESTRICTION_MESSAGE)) {
        Promise.all([setUserSelected({}), setOpenModal(false)]);
        return true;
      } else {
        return false;
      }
    } else {
      setUserSelected({});
      setOpenModal(false);
    }
  }, [LEAVE_RESTRICTION_MESSAGE, hasChanged, setOpenModal, setUserSelected]);

  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value !== fields[e.target.name]) {
        setHasChanged(true);
        handleSave(e);
      }
    },
    [fields, handleSave, setHasChanged]
  );

  const addNewUser = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="first_name"
            label="First Name"
            placeholder=""
            onChangeInput={handleInputChange}
            initialValue={fields?.first_name || ''}
            error={errors.first_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="last_name"
            label="Last Name"
            placeholder=""
            onChangeInput={handleInputChange}
            initialValue={fields?.last_name || ''}
            error={errors?.last_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="email"
            label="Email"
            type="email"
            onChangeInput={handleInputChange}
            error={errors.email?.txt}
            initialValue={fields?.email || ''}
            variant="standard"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalFeedback
      variant="general"
      headerText={'Add New User'}
      headerBarColor={theme.palette.primary.main}
      open={openModal}
      onAccept={() => handleSubmit(fields)}
      onCancel={() => {}}
      onClose={handleCloseModal}
      disableCloseOnAccept={true}
    >
      {addNewUser()}
    </ModalFeedback>
  );
};

export default AddUsersModal;
