import { CANDIDATE_STATUS } from 'constants/candidate';
import { FormHook } from '../../../hooks/form/Form';
import { schemaTAS } from '../../../validations/candidateRegister';

export const candidateCvWorkExperienceInitialState = {
  we_isValid: false,
  we_startDate: '',
  we_endDate: '',
  we_position: '',
  we_location: '',
  we_company: '',
  we_description: '',
};

export const candidateCvInitialState = {
  resumeRequired: false,
  birthdayCV: '',
  yearsOfExperienceCV: '',
  positionCV: '',
  certificationsCV: '',
  industry_experienceCV: [],
  certificationsCV_valid: false,
  skillsCV: [],
  languagesCV: [],
  languagesCV_valid: false,
  workExperienceCV: [{ ...candidateCvWorkExperienceInitialState }],
  tempCV: '',
};

const candidateVisa = {
  visa_issue_date: '',
  visa_expiration_date: '',
  visa_type: '',
  visa_type_other: '',
  visa_nationality: '',
  visa_status: '',
  visa_file: '',
  visa_required: false,
};

export const candidateInitialState = {
  first_name: '',
  last_name: '',
  phone_number: '',
  avatar_url: '',
  availability: '',
  availability_interview: '',
  email: '',
  company_email: '',
  status: CANDIDATE_STATUS.ACTIVE,
  blacklisted: false,
  LocationCountryId: '',
  developer_title: '',
  developer_seniority: '',
  employment_type: '',
  salary_currency: '',
  salary_expectation: '',
  candidate_result_link: '',
  candidate_code_score: '',
  timezone: '',
  linkedin: '',
  github_profile: '',
  portfolio_link: '',
  info_additional: '',
  tags: [],
  staff: false,
  rate_customer: '',
  rate_per_hour: '',
  industry_experienceCV: [],
  ...candidateCvInitialState,
  ...candidateVisa,
};

/*keep it just for demo pruposes (minimal initial data)*/
/*export const candidateInitialState = {
  first_name: 'test',
  last_name: 'test',
  phone_number: '',
  avatar_url: '',
  availability: '2-weeks',
  availability_interview: 'lorem ipsum dolor sit ament',
  email: 'lorem@sitament.com',
  company_email: '',
  status: CANDIDATE_STATUS.ACTIVE,
  blacklisted: false,
  LocationCountryId: 2, // Argentina
  developer_title: 'backend developer',
  developer_seniority: 'junior',
  employment_type: 'contractor',
  salary_currency: 'mxn',
  salary_expectation: '500',
  candidate_result_link: '',
  candidate_code_score: '',
  timezone: 'act',
  linkedin: '',
  github_profile: '',
  portfolio_link: '',
  info_additional: '',
  tags: [],
  staff: false,
  rate_customer: 500,
  rate_per_hour: 50,
  ...candidateCvInitialState,
  ...candidateVisa,
};*/

export default function CandidateFields(data = candidateInitialState) {
  return FormHook(data, schemaTAS, false);
}
