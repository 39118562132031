import http from 'services/httpService';

/**
 *
 *
 */
function getUsers(filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/users`, {
    params: filters,
  });
}

/**
 *
 * @param {Object} body
 * @param {String} body.first_name
 * @param {String} body.last_name
 * @param {String} body.email
 * @param {String} body.role
 * @param {String} body.phone
 * @param {Number} body.clientId
 *
 */
function createNewUser(body) {
  return http.post(process.env.REACT_APP_API_BACKEND + `/v1/users/create`, body);
}

/**
 *
 * @param {Number} userId
 * @param {Object} body
 * @param {String} body.first_name
 * @param {String} body.last_name
 * @param {String} body.email
 * @param {String} body.role
 * @param {String} body.phone
 * @param {Number} body.clientId
 *
 */
function updateUser(userId, body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/users/${userId}`, body);
}

function softDeleteUser(clientId) {
  return http.put(process.env.REACT_APP_API_BACKEND + `/v1/users/delete/${clientId}`);
}

export default { getUsers, createNewUser, softDeleteUser, updateUser };
