import { Avatar, Box, Typography } from '@mui/material';
import LinkMaterial from '@mui/material/Link';
import { stringAvatar } from 'utils/utils';

const ProjectHeaderCustomer = ({ client }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {client && client.companyName && (
        <Avatar {...stringAvatar(client.companyName)} sx={{ bgcolor: 'nexxusGrayLight.main' }} src={client.logoUrl} />
      )}

      <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 2 }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'left',
          }}
        >
          {client && client.companyName}
        </Typography>
        {client && client.companyName && (
          <>
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {client && client.address}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {client && client.phone}
            </Typography>
          </>
        )}
        {client && client.websiteUrl && (
          <LinkMaterial
            component="a"
            href={client.websiteUrl}
            sx={{
              color: 'nexxusBlueExtraLight.main',
              fontSize: 14,
              fontWeight: 'bold',
              textAlign: 'left',
              textDecoration: 'none',
            }}
            target="_blank"
          >
            VISIT WEBSITE
          </LinkMaterial>
        )}
      </Box>
    </Box>
  );
};

export default ProjectHeaderCustomer;
