import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const Title = ({ children, textAlign = 'left', sx = {} }) => {
  // @ts-ignore
  const SectionTitle = styled(Typography)(({ theme }) => ({
    fontSize: '22px',
    fontWeight: 'bold',
    color: theme.palette.nexxusGrayMid.main,
    textAlign: textAlign,
    marginY: theme.spacing(3),
  }));

  return <SectionTitle sx={sx}>{children}</SectionTitle>;
};

export default Title;
