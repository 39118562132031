import { PROJECT_STATUS } from 'constants/project';
import _ from 'lodash';
import projectService from 'services/projectService';

const getActiveProjsAndCustomers = async (clientID, _recruiter) => {
  const client = clientID ?? '';

  const { data: activeProjects } = await projectService.getAllProjects({
    // recruiters: recruiter ? [recruiter] : [],
    excludeStatus: [PROJECT_STATUS.ARCHIVED, PROJECT_STATUS.ON_HOLD, PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE],
    client,
  });

  const customers = _.uniqBy(
    activeProjects.map((pj) => pj.Client),
    'id'
  );

  return { activeProjects, customers };
};

export { getActiveProjsAndCustomers };
