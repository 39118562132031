import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from '../../components/MainLayout/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import DashboardSummary from './dashboard/DashboardSummary';
import { useGetCurrentUserQuery, useGetCustomersQuery, useGetRecruitersQuery, useGetSkillsQuery } from 'api/api';

const Dashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: recruiters } = useGetRecruitersQuery();
  const { data: customers } = useGetCustomersQuery();
  const { data: skills } = useGetSkillsQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="dashboard">
      <Box pt={2} pb={2}>
        {currentUser && <DashboardSummary client={currentUser.Client} {...{ recruiters, skills, customers }} />}
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
