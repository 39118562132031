import { Box, Switch } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Edit as EditIcon } from '@mui/icons-material';
import { useUpdateManagedCountryMutation, useUpdatePhoneAvailabilityMutation } from 'api/api';

const dataGridStyles = {
  '&': {
    padding: '0 !important',
    margin: '0 !important',
    border: 'none !important',
  },
  '& .MuiDataGrid-main': {
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#656565 !important',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0', // color for odd rows
  },
};

const ManagedCountryList = ({ managedCountries, onSelectCountry }) => {
  const [updateManagedCountryMutation] = useUpdateManagedCountryMutation();
  const [updatePhoneAvailability] = useUpdatePhoneAvailabilityMutation();

  const handleIsHireAvailableUpdate = (id, checked) => {
    updateManagedCountryMutation({
      CountryId: id,
      data: {
        isHireAvailable: checked,
      },
    });
  };

  const onUpdatePhoneAvailability = (id, checked) => {
    updatePhoneAvailability({
      CountryId: id,
      data: {
        isActive: checked,
      },
    });
  };

  const columns = [
    { field: 'name', headerName: 'Country', width: 130, headerAlign: 'left' },
    {
      headerName: 'Phone Blacklisted',
      width: 140,
      renderCell: ({ row }) => {
        return (
          <Switch
            defaultChecked={!row.CountryAvailability.isPhoneAvailable}
            onChange={(_, checked) => onUpdatePhoneAvailability(row.id, !checked)}
          />
        );
      },
      headerAlign: 'left'
    },
    {
      field: 'CountryAvailability.isHireAvailable',
      headerName: 'Blacklisted',
      renderCell: ({ row }) => {
        return (
          <Switch
            defaultChecked={!row.CountryAvailability.isHireAvailable}
            onChange={(_, checked) => handleIsHireAvailableUpdate(row.id, !checked)}
          />
        );
      },
      width: 100,
    },
    {
      field: 'CountryAvailability.comments',
      headerName: 'Comments',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {row.CountryAvailability.comments}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          onClick={() => {
            onSelectCountry(params.row);
          }}
          label="Edit"
        />,
      ],
    },
  ];

  return (
    <Box minHeight={400} mt={2}>
      {managedCountries ? (
        <DataGrid
          sx={dataGridStyles}
          disableColumnMenu
          disableSelectionOnClick
          density="compact"
          rows={managedCountries}
          rowsPerPageOptions={[]}
          columns={columns}
          hideFooterPagination={true}
          autoHeight={true}
        />
      ) : null}
    </Box>
  );
};

export default ManagedCountryList;
