import { CANDIDATE_EMPLOYMENT, CANDIDATE_LOCATION, CANDIDATE_TIMEZONES } from 'constants/candidate';
import { orderZones } from 'utils/utils';

export const availabilityOptions = [
  { _id: 'immediately', label: 'Immediately' },
  { _id: '2-weeks', label: '2-Weeks' },
  { _id: '3-weeks', label: '3-Weeks' },
  { _id: '4-weeks', label: '4-Weeks' },
  { _id: '5-weeks', label: '5-Weeks' },
  { _id: '6-weeks', label: '6-Weeks' },
];

export const seniority = [
  { _id: 'junior', label: 'Junior (1+ Years)', shortLabel: 'Junior' },
  { _id: 'mid-level', label: 'Mid-Level (3+ Years)', shortLabel: 'Mid-Level' },
  { _id: 'senior', label: 'Senior (5+ Years)', shortLabel: 'Senior' },
  { _id: 'principal', label: 'Principal Engineer (8+ Years)', shortLabel: 'Principal Engineer' },
];

export const employmentType = [
  { _id: CANDIDATE_EMPLOYMENT.FTE, label: 'FTE - 100% Employee' },
  { _id: CANDIDATE_EMPLOYMENT.CONT, label: 'Contractor - DEEL' },
  { _id: CANDIDATE_EMPLOYMENT.OTHER, label: 'Contractor - Other' },
  { _id: CANDIDATE_EMPLOYMENT.VENDOR, label: 'Vendor' },
];

export const salaryCurrency = [
  { _id: 'usd', label: 'USD' },
  { _id: 'mxn', label: 'MXN' },
];

export const technolgy = [
  { _id: '1', label: 'FIGMA' },
  { _id: '2', label: 'JAVA' },
  { _id: '3', label: 'PHP' },
  { _id: '4', label: '.NET' },
  { _id: '5', label: 'REACT' },
];

export const timezoneOpts = [
  { _id: CANDIDATE_TIMEZONES.MST, label: 'MST - Mountain Time Zone', timeRef: -7 },
  { _id: CANDIDATE_TIMEZONES.CST, label: 'CST - Central Time Zone', timeRef: -6 },
  { _id: CANDIDATE_TIMEZONES.EST, label: 'EST - Eastern Time Zone', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.PST, label: 'PST - Pacific Time Zone', timeRef: -8 },
];

export const timezoneOptsCand = [
  { _id: CANDIDATE_TIMEZONES.ART, label: 'ART - Argentina Time', timeRef: -3 },
  { _id: CANDIDATE_TIMEZONES.ACT, label: 'ACT - Brazil Acre Time', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.AMT, label: 'AMT - Brazil Amazon Time', timeRef: -4 },
  { _id: CANDIDATE_TIMEZONES.BOT, label: 'BOT - Bolivia Time', timeRef: -4 },
  { _id: CANDIDATE_TIMEZONES.BRT, label: 'BRT - Brazil Brasília Time', timeRef: -3 },
  { _id: CANDIDATE_TIMEZONES.CLT, label: 'CLT - Chile Standard Time', timeRef: -3 },
  { _id: CANDIDATE_TIMEZONES.EASST, label: 'EASST - Chile Easter Island Summer Time', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.ECT, label: 'ECT - Ecuador Time', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.FNT, label: 'FNT - Brazil Fernando de Noronha Time', timeRef: -2 },
  { _id: CANDIDATE_TIMEZONES.GALT, label: 'GALT - Ecuador Galapagos Time', timeRef: -6 },
  { _id: CANDIDATE_TIMEZONES.GYT, label: 'GYT - Guyana Time', timeRef: -4 },
  { _id: CANDIDATE_TIMEZONES.PET, label: 'PET - Peru Time', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.PYST, label: 'PYST - Paraguay Time', timeRef: -3 },
  { _id: CANDIDATE_TIMEZONES.UYT, label: 'UYT - Uruguay Time', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.VET, label: 'VET - Venezuelan Standard Time', timeRef: -4 },
  { _id: CANDIDATE_TIMEZONES.AST, label: 'AST - Atlantic Standard Time', timeRef: -4 },
  ...timezoneOpts,
];

export const englishProficiency = [
  { _id: 'professional', label: 'Professional' },
  { _id: 'conversational', label: 'Conversational' },
  { _id: 'bi-lingual', label: 'Bi-Lingual' },
  { _id: 'native', label: 'Native' },
  { _id: 'minimal', label: 'Minimal' },
];

export const CLIENTSTATUS = {
  ARCHIVED: 0,
  ACTIVE: 1,
};

export const clientStatusOptions = [
  { _id: CLIENTSTATUS.ACTIVE.toString(), label: 'ACTIVE' },
  { _id: CLIENTSTATUS.ARCHIVED.toString(), label: 'ARCHIVED' },
];

export const PROJECT_STATUS = {
  ARCHIVED: 0,
  ON_HOLD: 5,
  ACTIVE: 10,
  URGENT: 20,
};

export const SKILL_TYPE = {
  MUST_HAVE: 0,
  NICE_HAVE: 1,
  MAIN_TECH: 3,
};

export const locationOptions = orderZones([
  {
    _id: CANDIDATE_LOCATION.MEXICO,
    label: 'Mexico',
  },
  {
    _id: CANDIDATE_LOCATION.BRAZIL,
    label: 'Brazil',
  },
  {
    _id: CANDIDATE_LOCATION.COLOMBIA,
    label: 'Colombia',
  },
  {
    _id: CANDIDATE_LOCATION.VENEZUELA,
    label: 'Venezuela',
  },
  {
    _id: CANDIDATE_LOCATION.URUGUAY,
    label: 'Uruguay',
  },
  {
    _id: CANDIDATE_LOCATION.ARGENTINA,
    label: 'Argentina',
  },
  {
    _id: CANDIDATE_LOCATION.DOMINICAN_REPUBLIC,
    label: 'Dominican Republic',
  },
  {
    _id: CANDIDATE_LOCATION.COSTA_RICA,
    label: 'Costa Rica',
  },
  {
    _id: CANDIDATE_LOCATION.PERU,
    label: 'Peru',
  },
  {
    _id: CANDIDATE_LOCATION.BAHAMAS,
    label: 'Bahamas',
  },
  {
    _id: CANDIDATE_LOCATION.BARBADOS,
    label: 'Barbados',
  },
  {
    _id: CANDIDATE_LOCATION.BELIZE,
    label: 'Belize',
  },
  {
    _id: CANDIDATE_LOCATION.BOLIVIA,
    label: 'Bolivia',
  },
  {
    _id: CANDIDATE_LOCATION.CUBA,
    label: 'Cuba',
  },
  {
    _id: CANDIDATE_LOCATION.ECUADOR,
    label: 'Ecuador',
  },
  {
    _id: CANDIDATE_LOCATION.CHILE,
    label: 'Chile',
  },
  {
    _id: CANDIDATE_LOCATION.SALVADOR,
    label: 'El Salvador',
  },
  {
    _id: CANDIDATE_LOCATION.GRENADA,
    label: 'Grenada',
  },
  {
    _id: CANDIDATE_LOCATION.GUATEMALA,
    label: 'Guatemala',
  },
  {
    _id: CANDIDATE_LOCATION.GUYANA,
    label: 'Guyana',
  },
  {
    _id: CANDIDATE_LOCATION.HAITI,
    label: 'Haiti',
  },
  {
    _id: CANDIDATE_LOCATION.HONDURAS,
    label: 'Honduras',
  },
  {
    _id: CANDIDATE_LOCATION.JAMAICA,
    label: 'Jamaica',
  },
  {
    _id: CANDIDATE_LOCATION.NICARAGUA,
    label: 'Nicaragua',
  },
  {
    _id: CANDIDATE_LOCATION.PANAMA,
    label: 'Panama',
  },
  {
    _id: CANDIDATE_LOCATION.PARAGUAY,
    label: 'Paraguay',
  },
  {
    _id: CANDIDATE_LOCATION.TRINIDAD_TOBAGO,
    label: 'Trinidad y Tobago',
  },
]);

export const LOG_TYPE = {
  PROJECT: 'project',
  PROJECT_CANDIDATE: 'project_candidate',
  CANDIDATE: 'candidate',
  CUSTOMER: 'customer',
  PROJECT_CUSTOMER: 'project_customer',
};

export const LOG_ACTION = {
  PROJECT_CANDIDATE: {
    STATUS_CHANGE: 'status_change',
    GRADE_CHANGE: 'grade_change',
  },
};

export const CANDIDATE_NOTE_SCOPE = {
  GLOBAL: 'global',
  PROJECT: 'project',
  CUSTOMER: 'customer',
};
