import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Link, Stack, Typography } from '@mui/material';

import { Article, Search, HighlightOff, PriorityHigh, ChatBubbleOutline } from '@mui/icons-material';

import DashboardCard from '../../../components/Dashboard/DashboardCard';

import ModalConfirmation, {
  useModalState,
  ModalHeader,
  ModalBody,
  ModalConfirmButton,
  ModalCancelButton,
} from '../../../components/Generic/Modal/ModalConfirmation';

function createData(name, test_score, ncs, stage, tags, last_edit, notes) {
  return { name, test_score, ncs, stage, tags, last_edit, notes };
}

const rows = [
  createData('ERIK BIRKFELD', '100%', '100%', 'PANEL INTERVIEW', 'VIEW ALL', '3/2/2022', 'VIEW'),
  createData('ERIK BIRKFELD', '100%', '100%', 'PANEL INTERVIEW', 'VIEW ALL', '3/2/2022', 'VIEW'),
  createData('ERIK BIRKFELD', '100%', '100%', 'PANEL INTERVIEW', 'VIEW ALL', '3/2/2022', 'VIEW'),
  createData('ERIK BIRKFELD', '100%', '100%', 'PANEL INTERVIEW', 'VIEW ALL', '3/2/2022', 'VIEW'),
  createData('ERIK BIRKFELD', '100%', '100%', 'PANEL INTERVIEW', 'VIEW ALL', '3/2/2022', 'VIEW'),
];

export default function CandidatePipeline() {
  const modalState = useModalState();

  const handleRemoveCandidate = (ev) => {
    ev.preventDefault();
    modalState.handleOpen();
  };

  const ConfirmRemoveCandidate = () => {
    return (
      <ModalConfirmation open={modalState.open} onClose={modalState.handleClose}>
        <ModalHeader>
          <Typography variant="span">
            <PriorityHigh />
          </Typography>
          <Typography variant="span">REMOVE CANDIDATE</Typography>
        </ModalHeader>
        <ModalBody>
          <Typography variant="span">ARE YOU SURE YOU WANT TO REMOVE</Typography>
          <Link
            href="#"
            color="#fafafa"
            sx={{ fontSize: 50 }}
            onClick={(ev) => {
              ev.preventDefault();
              modalState.handleClose();
            }}
          >
            ERIK BIRKFELD
          </Link>
          <Typography variant="span">FROM THIS PROJECT?</Typography>
          <ModalConfirmButton onClick={handleRemoveCandidate}>YES, REMOVE</ModalConfirmButton>
          <ModalCancelButton onClose={modalState.handleClose}>NO, THANKS</ModalCancelButton>
        </ModalBody>
      </ModalConfirmation>
    );
  };

  return (
    <>
      <Typography>%PROJECT NAME% CANDIDATE PIPELINE</Typography>
      <Stack direction="row" spacing={1}>
        <DashboardCard reverse size={38} bgcolor="project-card-1" justifyContent="flex-end" total={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>PANEL</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>INTERVIEW</Typography>
        </DashboardCard>
        <DashboardCard reverse size={38} bgcolor="project-card-2" justifyContent="flex-end" total={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>SKILLS</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>ASSESSMENT</Typography>
        </DashboardCard>
        <DashboardCard reverse size={38} bgcolor="project-card-3" justifyContent="flex-end" total={14}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>SUBMITTED</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>TO CUSTOMER</Typography>
        </DashboardCard>
        <DashboardCard reverse size={38} bgcolor="project-card-4" justifyContent="flex-end" total={4}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>CUSTOMER</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>INTERVIEW</Typography>
        </DashboardCard>
        <DashboardCard reverse size={38} bgcolor="project-card-5" justifyContent="flex-end" total={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>HIRED</Typography>
        </DashboardCard>
        <DashboardCard reverse size={38} bgcolor="project-card-6" justifyContent="flex-end" total={12}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>REJECTED</Typography>
        </DashboardCard>
      </Stack>

      <TableContainer sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>NAME</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>TEST SCORE</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>NCS</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>STAGE</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>TAGS</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>LAST EDIT</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>NOdTES</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>DETAILS</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'gray' }}>REMOVE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.customer} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Typography sx={{ fontWeight: 'bold' }}>{row.name}</Typography>
                </TableCell>
                <TableCell>{row.test_score}</TableCell>
                <TableCell>{row.ncs}</TableCell>
                <TableCell>{row.stage}</TableCell>
                <TableCell>{row.tags}</TableCell>
                <TableCell>{row.last_edit}</TableCell>
                <TableCell>{row.notes}</TableCell>
                <TableCell component="th" scope="row">
                  <Link href="#">
                    <Article />
                  </Link>
                  &nbsp;
                  <Link href="#">
                    <ChatBubbleOutline />
                  </Link>
                  &nbsp;
                  <Link href="#">
                    <Search />
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link href="#" sx={{ color: 'tomato' }} onClick={handleRemoveCandidate}>
                    <HighlightOff />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmRemoveCandidate />
    </>
  );
}
