import _ from 'lodash';
import { useEffect, useState } from 'react';
import candidateService from 'services/candidateService';
import useSignedS3Url from './useSignedS3Url';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';

const useCvParser = () => {
  const { handleFileUpload } = useSignedS3Url();
  const [cvData, setCvData] = useState({
    first_name: '',
    last_name: '',
    birthday: '',
    phone_number: '',
    yearsOfExperience: '',
    email: '',
    linkedin: '',
    position: '',
    languages: '',
    industry_experience: [],
    github_profile: '',
    skills: '',
    certifications: '',
    workExperience: [],
    avatar_url: '',
    education: '',
    tempCV: '',
  });
  const [fileCv, setFileCv] = useState('');
  const [fileType, setFileType] = useState('');
  const [errorParser, setErrorParser] = useState('');

  useEffect(() => {
    (async () => {
      try {
        if (fileCv === '' && fileType !== 'pdf') return;
        const {
          certifications,
          dateOfBirth,
          education,
          name,
          phoneNumbers,
          totalYearsExperience,
          profession,
          github,
          skills,
          workExperience,
          languages,
          temp_cv,
          emails,
        } = await handleSubmission();
        const getDataValue = (dataReference) => dataReference ?? '';
        setCvData({
          certifications: certifications.join(', '),
          birthday: getDataValue(dateOfBirth),
          education: education,
          avatar_url: '',
          tempCV: getDataValue(temp_cv) || '',
          email: getDataValue(emails?.[0]),
          // avatar_url: headShot ? `data:image/png;base64,${headShot}` : '',
          languages: languages,
          first_name: getDataValue(name.first),
          last_name: getDataValue(name.last),
          phone_number: getDataValue(phoneNumbers?.[0]),
          yearsOfExperience: totalYearsExperience,
          linkedin: getDataValue(name.linkedin),
          position: profession,
          github_profile: getDataValue(github),
          skills: skills,
          workExperience:
            workExperience?.length > 0
              ? _.map(workExperience, (obj) => _.mapValues(obj, (value) => (value === null ? '' : value)))
              : [],
        });
      } catch (error) {
        setErrorParser(error);
        return;
      }
    })();
  }, [fileCv, fileType]);

  const handleSubmission = async () => {
    const s3File = await handleFileUpload({
      file: fileCv,
      contentType: 'application/pdf',
      path: UPLOAD_LOCATIONS.CANDIDATE_CV_TEMP,
    });
    if (!s3File) {
      return new Error(s3File);
    }

    const body = {
      cv_file_name: s3File?.originalName,
      cv_file_url: s3File?.object_url,
    };

    const {
      data: { data },
    } = await candidateService.uploadCandidateCvExtraData(body);

    return data;
  };

  return {
    setFileCv,
    setFileType,
    cvData,
    errorParser,
    setErrorParser,
  };
};

export default useCvParser;
