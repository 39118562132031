import { schemaIntakeForm } from 'validations/projects/intakeForm';
import { ExtendsValidationHandler } from '@nexxus/components';

export const intakeInitialStateRequest = {
  ClientId: '',
  intake_position_title: '',
  intake_seniority: '',
  intake_headcount: '',
  intake_maxrate_usd: '',
  intake_description: '',
  intake_timezone: '',
  intake_holidays: '',
  intake_working_hours_open: '',
  intake_working_hours_close: '',
  intake_interview_process: '',
  intake_job_description: '',
  intake_codetest_link: '',
  intake_codetest_file: '',
  intake_hardware_needs: '',
  intake_status: 'DRAFT',
  industry_experienceCV: [],
  intake_skills: [
    { SkillId: '', yearExperience: '', type: '' },
    { SkillId: '', yearExperience: '', type: '' },
    { SkillId: '', yearExperience: '', type: '' },
    { SkillId: '', yearExperience: '', type: '' },
    { SkillId: '', yearExperience: '', type: '' },
  ],
};

export default function IntakeFormFields(data = intakeInitialStateRequest) {
  const {
    alertMessage,
    setAlertMessage,
    alertFormNotification, //   handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    handleSubmit,
  } = ExtendsValidationHandler({ schemaData: schemaIntakeForm, defaultValues: data });

  return {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    alertFormNotification,
    alertMessage,
    setAlertMessage,
  };
}
