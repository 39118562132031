import { Box, Typography } from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import InvalidCandidatesGrid from 'pages/import/InvalidCandidates/InvalidCandidatesGrid';

const InvalidCandidates = (props) => {
  return (
    <MainLayout {...props} current="import">
      <Box pt={2} pb={2}>
        <Typography variant="h4" textTransform="uppercase">
          Invalid <strong>Candidates</strong>
        </Typography>
      </Box>

      <InvalidCandidatesGrid />
    </MainLayout>
  );
};

export default InvalidCandidates;
