import React from 'react';
import { Box, Container } from '@mui/material';

export default function ContainerView(props) {
  const maxWidth = props.maxWidth || 'xl';

  return (
    <Box
      sx={{
        padding: '1rem .5rem',
        marginTop: '10px',
        borderRadius: '4px',
        height: '100%',
      }}
    >
      <Container maxWidth={maxWidth}>{props.children}</Container>
    </Box>
  );
}
