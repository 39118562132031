import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useState } from 'react';
import TagService from 'services/tagService';
import { toast } from 'react-toastify';

const TagsDataGrid = ({ tags, onDelete }) => {
  const [openDeleteTag, setOpenDeleteTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(null);

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpenDeleteTag(false);
  };

  const submitDeleteTag = () => {
    console.log('deleteTag', deleteTag);
    onCloseModal();
    TagService.deleteTag(deleteTag.row.id)
      .then((response) => {
        console.log('response', response);
        toast.success('Tag deleted');
        onDelete();
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Error deleting tag');
      });
  };

  const handleRemoveTag = (params) => {
    setOpenDeleteTag(true);
    setDeleteTag(params);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'candidateUseCount', headerName: 'Candidate Use Count', width: 200, align: 'center', headerAlign: 'center' },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: (params) => [
        <GridActionsCellItem
          disabled={params?.row?.candidateUseCount > 0}
          key={1}
          icon={<DeleteIcon />}
          onClick={() => handleRemoveTag(params)}
          label="Delete"
        />,
      ],
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={tags} columns={columns} pageSize={20} disableSelectionOnClick />
      <ModalFeedback
        variant="info"
        headerText="Tag"
        titleText={`Are you sure you want to thelete "${deleteTag?.row.name}" Tag ?`}
        open={openDeleteTag}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={submitDeleteTag}
      />
    </div>
  );
};

export default TagsDataGrid;
