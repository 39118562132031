import { combineReducers } from 'redux';
import authReducer from './auth';
import customerReducer from './customer';
import { api } from '../api/api';

export default combineReducers({
  auth: authReducer,
  customer: customerReducer,
  [api.reducerPath]: api.reducer,
});
