import { useLocation } from 'react-router-dom';
import ComplexListItem from './ComplexListItem';
import SingleListItem from './SingleListItem';

const ListItemSidebar = ({ item, expanded, handleChangeExpanded }) => {
  const location = useLocation();
  return (
    <>
      {item.submenuItems ? (
        <ComplexListItem
          item={item}
          expanded={expanded}
          handleChangeExpanded={handleChangeExpanded}
          pathname={location.pathname}
        />
      ) : (
        <SingleListItem item={item} pathname={location.pathname} />
      )}
    </>
  );
};

export default ListItemSidebar;
