import { Stack, Typography } from '@mui/material';
import facebook from '../../assets/img/facebook-icon.png';
import glassdoor from '../../assets/img/glassdoor-icon.png';
import instagram from '../../assets/img/instagram-icon.png';
import linkedin from '../../assets/img/linkedin-icon.png';
import twitter from '../../assets/img/twitter-icon.png';
import youtube from '../../assets/img/youtube-icon.png';

const SocialMediaLinks = () => {
  return (
    <>
      <Typography variant="body2" color="white" textTransform="uppercase" align="center">
        VISIT OUR SONATAFY SOCIAL MEDIA PROFILES:
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={3} my={1}>
        <a target="_blank" href="https://www.linkedin.com/company/6406179" rel="noreferrer">
          <img src={linkedin} width={30} alt="" />
        </a>
        <a target="_blank" href="https://twitter.com/sonatafy" rel="noreferrer">
          <img src={twitter} width={30} alt="" />
        </a>
        <a target="_blank" href="https://www.facebook.com/sonatafytechnology" rel="noreferrer">
          <img src={facebook} width={30} alt="" />
        </a>
        <a target="_blank" href="https://www.instagram.com/sonatafytechnology/" rel="noreferrer">
          <img src={instagram} width={30} alt="" />
        </a>
        <a target="_blank" href="https://www.youtube.com/c/SonatafyTechnologyNearshoreSoftwareDevelopment" rel="noreferrer">
          <img src={youtube} width={30} alt="" />
        </a>
        <a
          target="_blank"
          href="https://www.glassdoor.com.mx/Descripci%C3%B3n-general/Trabajar-en-Sonatafy-Technology-EI_IE4368020.12,31.htm"
          rel="noreferrer"
        >
          <img src={glassdoor} width={30} alt="" />
        </a>
      </Stack>
    </>
  );
};

export default SocialMediaLinks;
