import {
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import { ROLE_CUSTOMER } from 'constants/users';
import AddUsersModal from 'pages/userAccessControlCustomer/AddUsersModal';
import RenderActions from 'pages/userAccessControlCustomer/RenderActions';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { loginExpire } from 'store/auth';
import {
  api,
  useGetCurrentUserQuery,
  useResetPasswordMutation,
  useRestoreUserMutation,
} from 'api/api';
import useUsersByCustomerFilter from 'hooks/useUsersByCustomerFilter';

import { CustomButton, InputTextOutlined, TooltipHelper } from '@nexxus/components';
import UsersService from 'services/UsersService';
import { toast } from 'react-toastify';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

const getClients = () => {
  return [
    { _id: 1, label: 'client 1' },
    { _id: 2, label: 'client 2' },
  ];
};

const UserAccessControlCustomer = (props) => {
  const [pageSize, setPageSize] = useState(20);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = props.maxWidth || 'xl';
  const { data: currentUser } = useGetCurrentUserQuery();
  const initialFilters = {
    first_name: '',
    last_name: '',
    email: '',
    archived_only: false,
    excludeStaff: true
  };

  const dispatch = useDispatch();
  const [restoreUser] = useRestoreUserMutation();
  const [resetPassword] = useResetPasswordMutation();

  const handleRestoreUser = async (user) => {
    restoreUser({
      userId: user.id,
    });
    toast.success('User restored');
  };

  const [filters, setFilters] = useState(initialFilters);
  const [openModal, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const {
    handleFilterUsers,
    users,
    usersIsLoading,
    refetch: userRefetch,
  } = useUsersByCustomerFilter({ clientId: currentUser?.Client?.id });

  const columns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 2,
    },
    {
      field: 'role',
      headerName: 'Role',
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return <>{row.role === ROLE_CUSTOMER ? 'Read Only' : 'Admin'}</>;
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,

      renderCell: ({ row }) => {
        return (
          <RenderActions
            onHandleDeleteUser={() => handleDeleteUser(row)}
            onHandleRestoreUser={() => handleRestoreUser(row)}
            onHandleResetPassword={() => onHandleResetPassword(row)}
            clients={getClients()}
            setOpenModalUser={() => setOpenModal(true)}
            row={row}
            users={users}
            setUserSelected={setUserSelected}
          />
        );
      },
    },
  ];

  const handleOpenNew = () => {
    Promise.all([setUserSelected({}), setOpenModal(true)]);
  };

  const handleSaved = () => {
    setOpenModal(false);
    userRefetch();
  };

  const handleDeleteUser = async (user) => {
    await UsersService.softDeleteUser(user.id);
    toast.success('User deleted from Nexxus');
    dispatch(api.util.invalidateTags(['Users']));
    userRefetch();
  };

  const onHandleResetPassword = (user) => {
    resetPassword({
      email: user.email,
    });
    toast.success('Password reset');
  };

  return (
    <>
      <AddUsersModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onHandleSaved={handleSaved}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
        clientId={currentUser?.Client?.id}
      />

      <MainLayout
        {...props}
        current="users"
        avatarTopBar={currentUser?.Client?.logoUrl ?? ''}
      >
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          my="10px"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Users administration{' '}
          <TooltipHelper
            textTip={
              'This section allows you to Add Users to the system and assign their Role permissions for the Sonatafy NEXXUS software.'
            }
            placement={isMobile ? 'top' : 'top-end'}
          >
            <QuestionMarkOutlinedIcon sx={{ color: '#fff', width: '0.5em', height: '0.5em', fontSize: '1.6rem' }} />
          </TooltipHelper>
        </Typography>
        <Box sx={{ padding: '16px', background: 'white', borderRadius: '5px', mt: 3 }}>
          <Container maxWidth={maxWidth}>
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
                  Welcome <strong>{currentUser?.first_name}!</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={'auto'} sx={{ marginLeft: 'auto' }}>
                <FormControl sx={{ width: '100%' }}>
                  <CustomButton
                    sx={{
                      height: '36px',
                      fontSize: '16px',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                      [theme.breakpoints.down('sm')]: { margin: '0px!important' },
                      [theme.breakpoints.down('md')]: { ml: '0px!important' },
                    }}
                    colorfull={true}
                    type="button"
                    onClick={handleOpenNew}
                  >
                    + Add User
                  </CustomButton>
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />

            <Grid container columns={20} spacing={3} mb={3}>
              <Grid item xs={20} lg={4}>
                <InputTextOutlined
                  id="first_name"
                  label="First Name"
                  placeholder="Filter by first name"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, first_name: e.target.value }))}
                  initialValue={filters.first_name}
                  size="small"
                  type="text"
                  error={''}
                />
              </Grid>
              <Grid item xs={20} lg={4}>
                <InputTextOutlined
                  id="last_name"
                  label="Last Name"
                  placeholder="Filter by last name"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, last_name: e.target.value }))}
                  initialValue={filters.last_name}
                  size="small"
                  type="text"
                  error={''}
                />
              </Grid>
              <Grid item xs={20} lg={4}>
                <InputTextOutlined
                  id="email"
                  label="Email"
                  placeholder="Filter by email"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, email: e.target.value }))}
                  initialValue={filters.email}
                  size="small"
                  type="text"
                  error={''}
                />
              </Grid>

              <Grid item xs={20} lg={4}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => {
                        setFilters((prev) => ({ ...prev, archived_only: e.target.checked }));
                      }}
                      checked={filters.archived_only}
                    />
                  }
                  label="Show Deleted"
                  data-cy="show-deleted"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={20} lg={4}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>&nbsp;</FormLabel>
                  <CustomButton
                    sx={{
                      height: '38px',
                      fontSize: '16px',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                      [theme.breakpoints.down('md')]: { ml: '0px!important' },
                    }}
                    colorfull={false}
                    type="button"
                    onClick={() => handleFilterUsers(filters)}
                  >
                    FILTER RESULTS
                  </CustomButton>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container sx={{ justifyContent: 'center!important' }}>
              <Grid item xs={'auto'}></Grid>
            </Grid>
            <DataGrid
              sx={{ mt: 4 }}
              headerHeight={50}
              rows={users || []}
              loading={usersIsLoading}
              columns={columns}
              autoHeight
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              disableSelectionOnClick
              disableColumnMenu
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
            />
          </Container>
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessControlCustomer);
