import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardCard from '../../../../components/Dashboard/DashboardCard';

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const FormCounters = ({ counters, mode }) => {
  return (
    <Stack direction="row" spacing={2}>
      <DashboardCard
        bgcolor="blue-card"
        totalSize={50}
        width={170}
        total={typeof mode === 'undefined' ? 'NO DATA' : counters?.candidatesCountOffered}
        justifyContent="flex-end"
        fontSize={typeof mode === 'undefined' ? '8px' : null}
        sx={{}}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AutorenewOutlinedIcon />
        </Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>CANDIDATES</Typography>
        <Typography sx={{ fontSize: 11 }}>IN PIPELINE</Typography>
      </DashboardCard>
      <DashboardCard
        bgcolor="green-card"
        totalSize={50}
        width={170}
        total={typeof mode === 'undefined' ? 'NO DATA' : counters?.candidatesCountHired}
        justifyContent="flex-end"
        fontSize={typeof mode === 'undefined' ? '8px' : null}
        sx={{}}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ThumbUpIcon />
        </Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>CANDIDATES</Typography>
        <Typography sx={{ fontSize: 11 }}>HIRED</Typography>
      </DashboardCard>
      <DashboardCard
        bgcolor="red-card"
        totalSize={50}
        width={170}
        total={typeof mode === 'undefined' ? 'NO DATA' : counters?.candidatesCountDenied}
        justifyContent="flex-end"
        fontSize={typeof mode === 'undefined' ? '8px' : null}
        sx={{}}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BlockOutlinedIcon />
        </Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>CANDIDATES</Typography>
        <Typography sx={{ fontSize: 11 }}>REJECTED</Typography>
      </DashboardCard>
    </Stack>
  );
};

export default FormCounters;
