import { connect } from 'react-redux';

import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

import { useGetCurrentUserQuery, useGetProjectsQuery } from 'api/api';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { capitalizeString } from 'utils/utils';
import moment from 'moment';

import { ModalConfirmation, SkillsPopover, TooltipHelper } from '@nexxus/components';
import { FiberManualRecord } from '@mui/icons-material';
import { blue, amber } from '@mui/material/colors';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import customerService from 'services/customerService';
import { toast } from 'react-toastify';
import { PROJECT_SENIORITY, PROJECT_STATUS } from 'constants/project';
import { POSITIONS } from '@nexxus/constants';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

const ProjectArchived = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageSize, setPageSize] = useState(10);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [projectSelected, setProjectSelected] = useState('');
  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();

  const [filterArchived] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: [PROJECT_STATUS.ARCHIVED, PROJECT_STATUS.RE_ENABLE],
    excludeStatus: '',
    page: '',
    limit: '',
  });
  const { data: projectsArchived, refetch: refetchProjectsArchived } = useGetProjectsQuery(
    { ...filterArchived, client: currentUser?.Client?.id },
    {
      skip: !currentUserIsSuccess,
    }
  );

  const NoDataOverlay = useCallback(() => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
          No Data
        </Typography>
        <Typography variant="h6" color="nexxusGrayLight.main">
          0 Positions Archived
        </Typography>
      </Stack>
    );
  }, []);

  const columns = [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <FiberManualRecord
            sx={{ mb: -0.7, color: row.status === 0 ? blue[400] : amber[500], position: 'relative', top: '-5px' }}
          />
        );
      },
    },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 1,
      valueGetter: ({ row }) => {
        const position = Object.values(POSITIONS).find((ti) => ti._id === row.position).label || '';
        return capitalizeString(position);
      },
    },
    {
      field: 'seniority',
      headerName: 'SENIORITY',
      flex: 0.5,
      valueGetter: ({ row }) => {
        const seniority = PROJECT_SENIORITY.find((se) => se._id === row.seniority).shortLabel || '';
        return capitalizeString(seniority);
      },
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: ({ row }) => {
        const skill = row?.Skills.filter((sk) => sk.Project_Skill.skill_type === 3);
        return skill[0]?.name || '';
      },
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 100,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const color = row.hired_count > 0 ? 'success.main' : 'error.main';
        return (
          <Typography color={color} sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}>
            {row.hired_count ?? 0}/{row.teamsize}
          </Typography>
        );
      },
    },
    {
      field: 'skills',
      headerName: 'SKILLS',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return <SkillsPopover label="VIEW ALL" skills={params.row.Skills} />;
      },
    },
    {
      field: 'datecreated',
      headerName: 'DATE CREATED',
      align: 'center',
      headerAlign: 'center',
      width: 120,
      valueGetter: ({ row }) => moment(row.datecreated).format('MMM DD, YYYY'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key="merge"
            disabled={row.status === PROJECT_STATUS.RE_ENABLE}
            icon={<SettingsBackupRestoreIcon />}
            onClick={() => {
              setProjectSelected(row.id);
              setOpenConfirm(true);
            }}
            label="Request re-enable"
            showInMenu
          />,
        ];
      },
    },
  ];

  const onCloseConfirm = (event, _reason) => {
    event?.preventDefault();
    setProjectSelected('');
    setOpenConfirm(false);
  };
  const onAccept = async (e, _reason) => {
    e?.preventDefault();
    await customerService.requestEnablePosition({ ProjectId: Number(projectSelected) });
    onCloseConfirm();
    toast.success('Request re-enable position sent!');
    refetchProjectsArchived();
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  const getStatusIndicator = (status) => {
    if (status === 1) {
      return <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} />;
    }
    return <FiberManualRecord sx={{ mb: -0.7, color: blue[400] }} />;
  };

  useEffect(() => {
    if (currentUser) refetchProjectsArchived();
  }, [currentUser, refetchProjectsArchived]);

  return (
    <MainLayout {...props} current="projects" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      <Stack spacing={0}>
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          my="20px!important"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Archived positions{' '}
          <TooltipHelper
            textTip={
              'This section summarizes all of the positions archived - which can be due to the Position being filled or canceled.'
            }
            placement={isMobile ? 'top' : 'top-end'}
          >
            <QuestionMarkOutlinedIcon sx={{ color: '#fff', width: '0.5em', height: '0.5em', fontSize: '1.6rem' }} />
          </TooltipHelper>
        </Typography>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: '16px', background: 'white', borderRadius: '5px' }}
        >
          <Stack direction="row" justifyContent={'flex-start'} alignItems="center" spacing={2} sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Status</Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              {getStatusIndicator(0)}
              ARCHIVED
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              {getStatusIndicator(1)}
              PENDING
            </Typography>
          </Stack>
          <Box sx={{ width: '100%', mt: 4 }}>
            {isMobile ? (
              <Box>
                {projectsArchived?.map((pos, index) => (
                  <Stack
                    key={JSON.stringify(pos)}
                    spacing="10px"
                    mt="14px"
                    padding="8px 16px"
                    borderRadius="4px"
                    sx={{ background: index % 2 ? 'ffffff' : '#F2F2F2' }}
                  >
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <Box>
                        <b>{capitalizeString(pos.position)}</b>
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <b>Seniority</b>
                        <span color="#979797">{capitalizeString(pos.seniority)}</span>
                      </Stack>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <Box>
                        <span>{moment(pos.datecreated).format('MMM DD, YYYY') || ''}</span>
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <b>Headcount</b>
                        <Typography
                          color={pos.hired_count > 0 ? 'success.main' : 'error.main'}
                          sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}
                        >
                          {pos.hired_count ?? 0}/{pos.teamsize}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack justifyContent="space-between" alignItems="center" direction="row">
                      <Stack direction="row" alignItems="center">
                        <b>Status</b>
                        {getStatusIndicator(Number(pos.status))}
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <b>Skills:</b>
                        <SkillsPopover label="VIEW ALL" skills={pos.Skills} />
                      </Stack>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" direction="row">
                      <GridActionsCellItem
                        key="merge"
                        disabled={pos.status === PROJECT_STATUS.RE_ENABLE}
                        icon={<SettingsBackupRestoreIcon />}
                        onClick={() => {
                          setProjectSelected(pos.id);
                          setOpenConfirm(true);
                        }}
                        label="Request re-enable"
                        showInMenu
                      />
                    </Stack>
                  </Stack>
                ))}
              </Box>
            ) : (
              <DataGrid
                rows={projectsArchived || []}
                columns={columns}
                autoHeight
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                disableColumnMenu
                components={{
                  ...{
                    NoRowsOverlay: () => NoDataOverlay(),
                    NoResultsOverlay: () => NoDataOverlay(),
                  },
                }}
              />
            )}
          </Box>
        </Stack>
      </Stack>
      <ModalConfirmation
        variant="info"
        headerText={'Re-enable Position'}
        titleText={'Do you want re-enable this position?'}
        open={openConfirm}
        onCancel={onCloseConfirm}
        onClose={onCloseConfirm}
        onAccept={onAccept}
        iconVariant={'info'}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectArchived);
