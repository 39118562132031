import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { WidgetHelp } from '@nexxus/components';
import { useGetCurrentUserQuery } from 'api/api';

const Help = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Help',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="Help" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      <Box>
        <Typography my="20px" variant="h1" color="primary">
          Help
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <WidgetHelp />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
