import { useCallback, useState } from 'react';
import { useGetUsersByCustomerQuery } from 'api/api';

const useUsersByCustomerFilter = ({ clientId }) => {
  const initialFilters = {
    first_name: '',
    last_name: '',
    email: '',
    archived_only: false,
    excludeStaff: true
  };
  const [filters, setFilters] = useState(initialFilters);
  const {
    data: users,
    isLoading: usersIsLoading,
    isSuccess: usersIsSuccess,
    error: usersError,
    refetch,
  } = useGetUsersByCustomerQuery({ clientId, ...filters }, { skip: !clientId });

  const handleFilterUsers = useCallback((filters) => {
    setFilters(filters);
  }, []);

  return {
    filters,
    handleFilterUsers,
    refetch,
    usersIsLoading,
    usersIsSuccess,
    usersError,
    users,
  };
};

export default useUsersByCustomerFilter;
