import { Stack, Typography, Button, Box, Link as LinkMaterial, styled } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState } from 'react';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const ModalPdfViewer = ({ fileViewer, onClose = () => {} }) => {
  const [open, setOpen] = useState(false);

  const onCloseModal = async (event, reason) => {
    event?.preventDefault();
    await Promise.all([setOpen(false), setPageNumber(1)]);
    onClose();
  };

  useEffect(() => {
    if (fileViewer !== '') setOpen(true);
  }, [fileViewer]);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const PdfViewerControls = styled(Box)({
    display: numPages ? 'flex' : 'none',
    position: 'relative',
    minWidth: 'min-content',
    minHeight: 'min-content',
    margin: '10px 0',
    justifyContent: 'center',
  });

  const PdfControl = styled(Button)({
    width: '34px',
    height: '34px',
    background: `white`,
    border: '0',
    font: 'inherit',
    fontSize: '2em',
  });

  return (
    <ModalFeedback
      variant="general"
      headerText="PDF Viewer"
      titleText=""
      open={open}
      onCancel={onCloseModal}
      onClose={onCloseModal}
      modalWidth={1200}
      disableBtnConfirm={true}
      btnCancelText="Close"
    >
      <Box sx={{ p: '0 0 20px' }}>
        <LinkMaterial
          component="a"
          href={fileViewer}
          sx={{
            color: 'nexxusBlueExtraLight.main',
            fontWeight: 'bold',
          }}
          target="_blank"
        >
          DOWNLOAD
        </LinkMaterial>
      </Box>

      <Box
        sx={{
          position: 'relative',
          textAlign: 'center',
          '& canvas': {
            margin: '0 auto',
          },
          display: 'block',
        }}
      >
        <Document file={fileViewer} renderMode="canvas" renderTextLayer={false} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <PdfViewerControls>
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <PdfControl
              sx={{
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              }}
              type="button"
              disabled={pageNumber <= 1}
              onClick={() => changePage(-1)}
            >
              ‹
            </PdfControl>
            <Typography variant="h6" sx={{ px: 2 }}>
              {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </Typography>
            <PdfControl
              sx={{
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
              }}
              type="button"
              disabled={pageNumber >= numPages}
              onClick={() => changePage(1)}
            >
              ›
            </PdfControl>
          </Stack>
        </PdfViewerControls>
      </Box>
    </ModalFeedback>
  );
};

export default ModalPdfViewer;
