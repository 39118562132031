import { FormHook } from 'hooks/form/Form';
import { schemaProfile, schemaProfileEmail, schemaProfilePassword } from 'validations/me/schemaProfile';

export const profileInitialState = {
  first_name: '',
  last_name: '',
  phone: '',
  avatar: '',
};

export const emailInitialState = {
  email: '',
  old_email: '',
};

export const passwordInitialState = {
  old_password: '',
  password: '',
  password_confirm: '',
};

export function PasswordFields(data = passwordInitialState) {
  return FormHook(data, schemaProfilePassword);
}

export function EmailFields(data = emailInitialState) {
  return FormHook(data, schemaProfileEmail);
}

export default function ProfileFields(data = profileInitialState) {
  return FormHook(data, schemaProfile);
}
