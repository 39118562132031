'use client';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { loginExpire } from 'store/auth';
import { BreadcrumbsProvider } from './contexts/BreadcrumbsContext';
import AppRouter from './routers/appRouter';
import ThemeProvider from './theme';
import { SidebarProvider } from '@nexxus/components';
import { styled } from '@mui/material';

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width: 100%
    @media screen and (min-width: 600px){
      width: auto;
      max-width: 600px;
    }

  }
  .Toastify__toast.Toastify__toast--success {
    background: #dffdea !important;
    color: #00bf0c !important;
    border: 1px solid #00bf0c !important;
    font-weight: 900;
  }
  .Toastify__toast.Toastify__toast--error {
    background: #ffe5e5 !important;
    color: #ff5050 !important;
    border: 1px solid #ff5050 !important;
    font-weight: 900;
  }
  .Toastify__toast.Toastify__toast--success > .Toastify__close-button {
    color: #00bf0c !important;
    opacity: 1;
  }
  .Toastify__toast.Toastify__toast--error > .Toastify__close-button {
    color: #ff5050 !important;
    opacity: 1;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const App = (props) => {
  return (
    <BreadcrumbsProvider>
      <SidebarProvider>
        <ThemeProvider>
          <div className="App">
            <StyledToastContainer
              position="top-right"
              autoClose={10000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
              pauseOnHover={false}
            />
            <AppRouter />
          </div>
        </ThemeProvider>
      </SidebarProvider>
    </BreadcrumbsProvider>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
