import MainLayout from 'components/MainLayout/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import { Box, Typography } from '@mui/material';

const Dashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="dashboard">
      <Box pt={2} pb={2}>
        <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
          Welcome <strong>{props.auth?.user?.first_name}!</strong>
        </Typography>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
