import { ROLE_ADMIN } from '@nexxus/constants';
import { ROLE_SUPERCUSTOMER, ROLE_SUPERTAS, ROLE_CUSTOMER, ROLE_TAS, ROLE_HUMAN_RESOURCES } from 'constants/users';
import moment from 'moment';
import { useEffect, useRef } from 'react';

const isEmptyString = (value) => value === '' || value === null || value === undefined;

const capitalizeString = (sentence) => {
  if (isEmptyString(sentence)) {
    return '';
  }
  const words = sentence.split(' ');
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

const isEmptyCheck = (_string = '') => {
  return _string.replace(/\s/g, '') === '';
};

const stringToColor = (str) => {
  if (isEmptyCheck(str)) {
    return '#fff';
  }
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (name) => {
  if (isEmptyCheck(name)) {
    return { sx: {} };
  }

  const words = name.split(' ');
  const initials = words.map((word) => word[0]).join('');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const objectToQueryString = (obj) => {
  const filteredObj = Object.keys(obj)
    .filter((key) => obj[key] !== '' && obj[key] !== undefined)
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

  return Object.keys(filteredObj)
    .map((key) => {
      if (Array.isArray(filteredObj[key])) {
        return filteredObj[key].map((value) => `${key}[]=${value}`);
      }
      return `${key}=${filteredObj[key]}`;
    })
    .flat()
    .join('&');
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

const mapRangeValue = (value, x1, y1, x2, y2) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

const getPathDashboard = (user) => {
  let pathname = '';
  switch (user.role) {
    case ROLE_ADMIN:
      pathname = '/admin/dashboard';
      break;
    case ROLE_SUPERTAS:
    case ROLE_TAS:
      pathname = '/tas/dashboard';
      break;
    case ROLE_SUPERCUSTOMER:
    case ROLE_CUSTOMER:
      pathname = '/customer/dashboard';
      break;
    case ROLE_HUMAN_RESOURCES:
      pathname = '/hr/dashboard';
      break;
    default:
      pathname = '/logout';
      break;
  }

  return pathname;
};

const orderTimeZone = (timezones, descendingOrder = true, propOrder = 'timeRef') => {
  timezones.sort((firstElement, secondElement) => {
    if (descendingOrder) {
      return secondElement[propOrder] - firstElement[propOrder];
    } else {
      return firstElement[propOrder] - secondElement[propOrder];
    }
  });
  return timezones;
};

const orderZones = (zones, descendingOrder = true) => {
  zones.sort((firstElement, secondElement) => {
    const firstEl = firstElement.label.toLowerCase();
    const secEl = secondElement.label.toLowerCase();
    if (descendingOrder) {
      return firstEl < secEl ? -1 : 1;
    } else {
      return secEl < firstEl ? -1 : 1;
    }
  });
  return zones;
};

const getFormattedDate = (date) => moment(date).format('MM/DD/YYYY');

const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const makeSecondNameInitial = (name = '') => {
  name = name.replace(/\s\s+/g, ' ').trim();
  const nameParts = name.split(' ');

  if (nameParts.length >= 2) {
    return `${nameParts[0]} ${nameParts[1].substring(0, 1)}.`;
  }

  return name;
};

const getCompactName = (first_name = '', last_name = '') => {
  return `${makeSecondNameInitial(first_name)} ${makeSecondNameInitial(last_name)}`;
};

const hookFormTrimValues = (data) => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(hookFormTrimValues);
  }

  return Object.keys(data).reduce((acc, key) => {
    if (typeof data[key] === 'string') {
      acc[key] = data[key].trim();
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      // Recursively trim values of the nested object
      acc[key] = hookFormTrimValues(data[key]);
    } else {
      acc[key] = data[key];
    }
    return acc;
  }, {});
};

function displayObjectAsJSX(obj) {
  // Check if the object is an array
  if (Array.isArray(obj)) {
    return (
      <ul>
        {obj.map((item, index) => (
          <li key={index}>{displayObjectAsJSX(item)}</li>
        ))}
      </ul>
    );
  }

  // Check if the object is a nested object
  if (typeof obj === 'object' && obj !== null) {
    return (
      <ul>
        {Object.entries(obj).map(([key, value], index) => (
          <li key={index}>
            <strong>{key}: </strong>
            {displayObjectAsJSX(value)}
          </li>
        ))}
      </ul>
    );
  }

  // If it's neither an array nor an object, display it as text
  return <span>{obj}</span>;
}

const setNumberFormatted = (field, editing) => {
  if (editing) {
    return currencyFormat.format(field);
  } else {
    return field !== '' ? field : '';
  }
};

export {
  getPathDashboard,
  capitalizeString,
  isEmptyCheck,
  usePrevious,
  stringToColor,
  stringAvatar,
  objectToQueryString,
  getRandomInt,
  mapRangeValue,
  isEmptyString,
  orderTimeZone,
  orderZones,
  getFormattedDate,
  currencyFormat,
  makeSecondNameInitial,
  getCompactName,
  hookFormTrimValues,
  displayObjectAsJSX,
  setNumberFormatted,
};
