import http from './httpService';

function getTags() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/tags/all');
}

function getTagsWithCount() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/tags/all-with-use-count');
}

function createTag(tag) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/tags', tag);
}

function deleteTag(tagId) {
  return http.delete(process.env.REACT_APP_API_BACKEND + '/v1/tags/' + tagId);
}

const TagService = {
  getTags,
  createTag,
  deleteTag,
  getTagsWithCount,
};

export default TagService;
