import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/system';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';
import { capitalizeString } from 'utils/utils';
import projectService from '../../../services/projectService';
import { CLIENTSTATUS, seniority, PROJECT_STATUS } from '../../../utils/staticData';
import { POSITIONS } from '@nexxus/constants';
import BodyProjectsList from './tabProjects/bodyProjectsList';
import HeaderProjectsList from './tabProjects/headerProjectsList';
import { useGetCustomersQuery } from 'api/api';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { FormHook } from 'hooks/form/Form';
import { ModalConfirmation } from '@nexxus/components';
import OnboardingForm from '../candidateForm/onboarding/OnboardingForm';
import _ from 'lodash';

const LabelText = styled(Typography)({
  textAlign: 'left',
});

const NewTabProjects = ({ candidateId, activeCandidate }) => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [clientId, setClientId] = useState('');
  const [editing, setEditing] = useState(false);

  const [projectId, setProjectId] = useState('');
  const [projects, setProjects] = useState([]);

  const [projectStatus, setProjectStatus] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [statusOpts, setStatusOpts] = useState([]);
  const [candidateProjects, setCandidateProjects] = useState({ HIRED: [], ARCHIVED: [], CONSIDERATION: [] });

  const [saveProject, setSaveProject] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOnboarding, setModalOnboarding] = useState(false);

  const timer = useRef();
  const theme = useTheme();

  const { data: customers } = useGetCustomersQuery({ excludeStatus: [CLIENTSTATUS.ARCHIVED] });

  const titles = [
    { title: 'COMPANY', textAlign: 'left' },
    { title: 'POSITION', textAlign: 'left' },
    { title: 'SENIORITY', textAlign: 'left' },
    { title: 'SKILL', textAlign: 'left' },
    { title: 'NCS', textAlign: 'left' },
    { title: 'PIPELINE STAGE', textAlign: 'left' },
  ];
  const titlesArchived = [...titles, { title: 'LAST DAY', textAlign: 'left' }];
  const [enableAddToProjBtn, setEnableAddToProjBtn] = useState(false);

  const {
    PANEL_INTERVIEW,
    SKILLS_ASSESSMENT,
    SUBMITTED_CUSTOMER,
    CUSTOMER_INTERVIEW,
    HIRED,
    OFFER_EXTENDED,
    OFFER_ACCEPTED,
    ARCHIVED,
  } = CANDIDATEPROJECT_STATUS;

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    setSaveProject(true);
    setOpen(false);
  };

  const updateCandidateProjectsBox = async () => {
    const { data: allStatus } = await projectService.getCandidateAllProjectsByStatus(candidateId);

    const pjConsiderations = allStatus.filter((pj) =>
      [PANEL_INTERVIEW, SKILLS_ASSESSMENT, SUBMITTED_CUSTOMER, CUSTOMER_INTERVIEW, OFFER_EXTENDED, OFFER_ACCEPTED].includes(
        pj.status
      )
    );
    const pjHired = allStatus.filter((pj) => [HIRED].includes(pj.status));
    const pjArchived = allStatus.filter((pj) => [ARCHIVED, HIRED].includes(pj.status));

    setCandidateProjects((prev) => ({
      ...prev,
      HIRED: formatProjectsCandidate(pjHired),
      ARCHIVED: formatProjectsCandidate(pjArchived),
      CONSIDERATION: formatProjectsCandidate(pjConsiderations),
    }));
  };

  useMemo(() => {
    (async () => {
      await updateCandidateProjectsBox();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCandidate]);

  useEffect(() => {
    saveProject && setOpen(false);
  }, [saveProject]);

  useEffect(() => {
    (async () => {
      const allStatus = Object.keys(CANDIDATEPROJECT_STATUS).map((key, value) => {
        return { _id: key, label: capitalizeString(key.toLowerCase().replaceAll('_', ' ')) };
      });
      setStatusOpts(allStatus);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatProjectsCandidate = (projects) => {
    let formattedPjs = [...projects];

    function getStatus(value) {
      return Object.keys(CANDIDATEPROJECT_STATUS).find((key) => CANDIDATEPROJECT_STATUS[key] === value);
    }

    formattedPjs.forEach((pj, index) => {
      const position = Object.values(POSITIONS).find((pos) => pos._id === pj.Project.position);
      const senior = seniority.find((sen) => sen._id === pj.Project.seniority);
      if (position && senior) {
        formattedPjs[index].Project.position = position.label;
        formattedPjs[index].Project.seniority = senior.label;
        if (formattedPjs[index].Project.Skills.length > 0)
          formattedPjs[index].Project.mainTech = formattedPjs[index].Project.Skills[0].name;
        else formattedPjs[index].Project.mainTech = '';
        formattedPjs[index].status = getStatus(pj.status).replace('_', ' ');
      }
    });
    return formattedPjs;
  };

  useEffect(() => {
    (async () => {
      if (clientId !== '') {
        const { data } = await projectService.getProjectsByClient(clientId, {
          status: [PROJECT_STATUS.ACTIVE, PROJECT_STATUS.URGENT],
        });
        const projects = [];
        data.forEach((el) =>
          projects.push({ _id: el.id, label: `${capitalizeString(el.position)} - ${capitalizeString(el.seniority)}` })
        );

        Promise.all([
          setProjects(projects),
          setProjectId(''),
          setProjectStatus(''),
          () => {
            if (saved === true) setSaved(false);
          },
        ]);
      } else {
        setProjects([]);
        setProjectId('');
        setProjectStatus('');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        let status;
        const { data } = await projectService.isCandidateEnrolled(candidateId, clientId, projectId);
        if (data) status = data.status;
        setProjectStatus(status || '');
        setCurrentStatus(data);
        if (saved === true) setSaved(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useMemo(() => {
    (async () => {
      if (candidateId && projectId && projectStatus && saveProject === true) {
        if (!loading) {
          setSaved(false);
          setLoading(true);
          try {
            await projectService.candidateEnrollment(candidateId, projectId, projectStatus, '');
            await candidateService.updateCandidateActivity(candidateId);
            setSaveProject(false);
            setAlertMessage('success');
            // @ts-ignore
            setCurrentStatus((prev) => ({ ...prev, status: projectStatus }));
            // @ts-ignore
            timer.current = window.setTimeout(async () => {
              Promise.all([await updateCandidateProjectsBox(), setSaved(true), setLoading(false)]);
            }, 200);
          } catch (e) {
            toast.error(e.message);
            setLoading(false);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveProject]);

  useEffect(() => {
    if (!!clientId && !!projectId && !!projectStatus) {
      setEnableAddToProjBtn(true);
    } else {
      setEnableAddToProjBtn(false);
    }
  }, [clientId, projectId, projectStatus]);

  const borderBoxStyle = {
    borderRadius: theme.variables.inputs.borderRadius,
    borderColor: theme.variables.inputs.borderColor,
    borderWidth: theme.variables.inputs.borderWidth,
    borderStyle: 'solid',
  };

  const { setAlertMessage, alertFormNotification } = FormHook({}, {}, false);

  const showOnboarding = useMemo(() => {
    if (projectStatus !== '') {
      const exists = Object.entries(CANDIDATEPROJECT_STATUS).find(
        ([key, value]) => key === currentStatus.status && value === CANDIDATEPROJECT_STATUS.OFFER_ACCEPTED
      );
      return !!exists && !currentStatus?.dataValues?.onboarding;
    }
    return false;
  }, [currentStatus, projectStatus]);

  const successOnboarding = useCallback(
    (data) => {
      const updateCurrentStatus = _.cloneDeep(currentStatus);
      updateCurrentStatus.dataValues.onboarding = data.onboarding;
      setCurrentStatus(updateCurrentStatus);
      setModalOnboarding(false);
    },
    [currentStatus]
  );

  return (
    <Box>
      <ModalFeedback
        variant="info"
        headerText="New Project"
        titleText="Do you want to add a new candidate's project?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <ModalConfirmation
        open={modalOnboarding}
        variant={'general'}
        headerText={''}
        titleText={''}
        onAccept={() => setModalOnboarding(false)}
        onClose={() => setModalOnboarding(false)}
        headerBarColor={'#fff'}
        modalWidth={1200}
        disableBtnConfirm={true}
        disableBtnCancel={true}
      >
        <OnboardingForm
          projectId={projectId}
          customerId={clientId}
          candidateId={candidateId ?? ''}
          onCancelOnboarding={() => setModalOnboarding(false)}
          onSuccesSavedOnboarding={successOnboarding}
        ></OnboardingForm>
      </ModalConfirmation>
      <Grid container>
        {saved && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            {alertFormNotification('success')}
          </Grid>
        )}

        {/* ENROLLMENT */}
        <Grid item xs={12} lg={4} sx={{ padding: 6 }}>
          {!activeCandidate && (
            <Typography variant="subtitle" align="center">
              Cannot enroll an archived or blacklisted candidate
            </Typography>
          )}
          {!editing && activeCandidate && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Button
                variant="contained"
                color="error"
                startIcon={<SvgIcon component={EditIcon} inheritViewBox />}
                onClick={() => setEditing(true)}
              >
                edit candidate
              </Button>
            </Box>
          )}
          {activeCandidate && editing && (
            <>
              <Typography
                sx={{
                  fontSize: '20px',
                  textAlign: 'left',
                  marginBottom: 2,
                  fontWeight: 'bold',
                }}
              >
                CANDIDATE - PROJECT
              </Typography>
              <Box sx={{ marginBottom: 2 }}>
                <InputAutoCompleteV2
                  id={'ClientId'}
                  label={'SELECT A CUSTOMER'}
                  opts={customers?.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
                  error={''}
                  initialValue={clientId}
                  placeholder={'Choose'}
                  onChangeInput={(e) => {
                    setClientId(e.target.value);
                  }}
                  sortOpts={false}
                />
              </Box>
              <Box sx={{ marginBottom: 2 }}>
                <InputAutoCompleteV2
                  id={'ProjectId'}
                  label={'ASSIGN A PROJECT'}
                  opts={projects}
                  error={''}
                  initialValue={projectId}
                  placeholder={'Choose'}
                  onChangeInput={(e) => {
                    setProjectId(e.target.value);
                  }}
                  sortOpts={false}
                />
              </Box>
              <Box sx={{ marginBottom: 2 }}>
                <InputAutoCompleteV2
                  id="ProjectStatus"
                  label="Project Stage"
                  size={'normal'}
                  opts={statusOpts}
                  error={''}
                  initialValue={projectStatus}
                  placeholder={'Choose'}
                  onChangeInput={(e) => {
                    setProjectStatus(e.target.value);
                  }}
                  sortOpts={false}
                  isDisabled={!(clientId !== '' && projectId !== '')}
                />
              </Box>
              <Button
                variant="contained"
                sx={{ height: '30px', borderRadius: 0, paddingX: 1, marginTop: 2, width: '100%' }}
                color="nexxusBlue"
                type="button"
                onClick={() => setOpen(true)}
                startIcon={<CheckCircleIcon sx={{ color: 'white', marginLeft: 2 }} />}
                disabled={!enableAddToProjBtn}
              >
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    paddingRight: 1,
                    color: 'white',
                    marginTop: '4px',
                  }}
                >
                  save status
                </Typography>
              </Button>
              {clientId && projectId && showOnboarding && (
                <Button
                  variant="contained"
                  sx={{ height: '30px', borderRadius: 0, paddingX: 1, marginTop: 2, width: '100%', color: '#fff' }}
                  startIcon={<SvgIcon component={EditIcon} inheritViewBox sx={{ color: 'white', marginLeft: 2 }} />}
                  onClick={() => setModalOnboarding(true)}
                >
                  Onboarding Format
                </Button>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <LabelText variant="label">CURRENT EMPLOYMENT (IF RE-ASSIGNED)</LabelText>
          <Box
            sx={{
              minHeight: '130px',
              padding: 1,
              marginBottom: 3,
              ...borderBoxStyle,
            }}
          >
            <Grid container columns={7}>
              <HeaderProjectsList items={titles} />
              {loading && <CircularProgress size={60} thickness={4} sx={{ mx: 'auto', my: 4, color: 'nexxusBlue.main' }} />}
              {!loading && candidateProjects.HIRED.length > 0 && (
                <BodyProjectsList items={candidateProjects.HIRED} type="" />
              )}
            </Grid>
          </Box>
          <LabelText variant="label">PROJECT CONSIDERATIONS</LabelText>
          <Box sx={{ ...borderBoxStyle, minHeight: '130px', padding: 1, marginBottom: 3 }}>
            <Grid container columns={7}>
              <HeaderProjectsList items={titles} />
              {loading && <CircularProgress size={60} thickness={4} sx={{ mx: 'auto', my: 4, color: 'nexxusBlue.main' }} />}
              {!loading && candidateProjects.CONSIDERATION.length > 0 && (
                <BodyProjectsList items={candidateProjects.CONSIDERATION} type="" />
              )}
            </Grid>
          </Box>
          <LabelText variant="label">PAST SONATAFY PROJECTS</LabelText>
          <Box sx={{ ...borderBoxStyle, minHeight: '130px', padding: 1, marginBottom: 3 }}>
            <Grid container columns={7}>
              <HeaderProjectsList items={titlesArchived} />
              {loading && <CircularProgress size={60} thickness={4} sx={{ mx: 'auto', my: 4, color: 'nexxusBlue.main' }} />}
              {!loading && candidateProjects.ARCHIVED.length > 0 && (
                <BodyProjectsList items={candidateProjects.ARCHIVED} type="ARCHIVED" />
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewTabProjects;
