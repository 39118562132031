import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const NoDataOverlay = ({ title = '', subtitle = '' }) => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        {subtitle}
      </Typography>
    </Stack>
  );
};

NoDataOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default NoDataOverlay;
