import { useEffect } from 'react';
import MainLayout from '../../../components/MainLayout/MainLayout';

import { connect } from 'react-redux';
import { loginExpire } from '../../../store/auth';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';

import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import DashboardSummary from './DashboardSummary';
import ActiveProjects from './ActiveProjects';

const Dashboard = (props) => {
  const { customerId } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="dashboard">
      <Box pt={2} pb={2}>
        {!customerId && <DashboardSummary />}
        {customerId && <ActiveProjects customerId={customerId} />}
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
