import { Avatar, Card, Stack } from '@mui/material';
import useBackgroundColor from '../../hooks/useBackgroundColor';
export default function DashboardCard(props) {
  const bgcolors = useBackgroundColor();
  const defaultSize = props.size || 80;
  const direction = props.reverse ? 'row-reverse' : 'row';
  const alignItems = props.reverse ? 'left' : 'right';
  const total = props.total || 0;
  const totalSize = props.totalSize || defaultSize;
  const borderSize = totalSize >= defaultSize ? '3' : '2';
  const fontSize = props.fontSize ? props.fontSize : totalSize / 2;
  const width = props.width || '100%';
  const justifyContent = props.justifyContent || 'space-between';
  let bgcolor = props.bgcolor || 'blue-card';
  if (bgcolor === 'red-card') {
    bgcolor = bgcolors.error;
  }

  return (
    <>
      <Stack
        component={Card}
        justifyContent="center"
        className={bgcolor}
        sx={{ p: 1, width, color: '#fafafa', borderRadius: 0, ...props.sx }}
      >
        <Stack direction={direction} alignItems="stretch" justifyContent="space-between">
          <Stack justifyContent="center">
            <Avatar
              sx={{
                border: `${borderSize}px solid`,
                bgcolor: 'transparent',
                width: totalSize,
                height: totalSize,
                fontSize,
                pt: 0.35,
                fontWeight: 'bold',
              }}
            >
              {total}
            </Avatar>
          </Stack>
          <Stack justifyContent={justifyContent} align={alignItems}>
            {props.children}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
