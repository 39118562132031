import { ROLE_CANDIDATE } from 'constants/users';
import { Route } from 'react-router-dom';
import Dashboard from '../pages/customer/Dashboard';
import PrivateRoute from './privateRouter';

const candidateRoutes = [
  {
    name: 'Dashboard Customer',
    path: '/candidate/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_CANDIDATE],
  },
];

export const CandidateRoutes = () => {
  return candidateRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
