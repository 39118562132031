import { ROLE_LABELS } from 'constants/users';
import Profile from 'pages/Profile';
import { Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';

const meRoutes = [
  {
    name: 'Profile',
    path: '/profile',
    element: <Profile />,
    authorization: Object.keys(ROLE_LABELS),
  },
];

export const MeRoutes = () => {
  return meRoutes.map((route, index) => {
    return (
      <Route
        key={`me-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
