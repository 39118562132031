import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const SubTitle = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 35, color: 'nexxusGray.main' }}>{props.subtitle}&nbsp;</Typography>
        <Typography
          sx={{
            fontSize: 34,
            fontWeight: 'bold',
            color: 'nexxusGray.main',
          }}
        >
          {props.subtitleFocus}
        </Typography>
      </Box>
      <Divider sx={{ flexGrow: 1, marginLeft: 2, borderBottomWidth: '2px' }} />
    </Box>
  );
};

export default SubTitle;
