import { FormHook } from 'hooks/form/Form';
import { schemaUser } from 'validations/users/schemaUser';
export const usersInitialState = {
  clientId: '',
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  phone: '',
  isStaff: false,
};

export function UserFields(data = usersInitialState) {
  return FormHook(data, schemaUser, false);
}
