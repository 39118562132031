import { useEffect, useMemo, useState } from 'react';

import { FiberManualRecord } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CLIENTSTATUS } from 'utils/staticData';
import { useGetCustomersQuery, useGetSkillsQuery, useGetRecruitersQuery } from 'api/api';
import { amber } from '@mui/material/colors';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { POSITIONS } from '@nexxus/constants';

export default function RefineProjectResults(props) {
  const hideArchived = useMemo(() => props.projectStatus.hide_archived, [props.projectStatus.hide_archived]);
  const useCustomersParams = hideArchived ? { status: CLIENTSTATUS.ACTIVE } : {};
  const { data: customers, refetch } = useGetCustomersQuery(useCustomersParams);
  const { data: recruiters } = useGetRecruitersQuery();
  const { data: skills, isSuccess: skillIsSuccess } = useGetSkillsQuery();
  const [skillValue, setSkillValue] = useState(null);

  useEffect(() => {
    props.setFilters((prev) => ({
      ...prev,
      hide_archived: hideArchived,
      client: '',
    }));
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideArchived]);

  return (
    <>
      <Grid container>
        <Grid xs={10} item>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Refine Results</Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />
              ON HOLD
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="archived" sx={{ mb: -0.7 }} />
              ARCHIVED
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} /> RE ENABLE
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="active" sx={{ mb: -0.7 }} />
              ACTIVE
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />
              URGENT
            </Typography>

            <FormGroup>
              <FormControlLabel
                sx={{ mt: -0.4 }}
                control={<Checkbox checked={props.projectStatus.urgent_only} onChange={props.toggleUrgentOnly} />}
                label={<Typography sx={{ fontWeight: 'bold', mb: -0.4 }}>SHOW URGENT (ONLY)</Typography>}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                sx={{ mt: -0.4 }}
                control={<Checkbox checked={props.projectStatus.hide_archived} onChange={props.toggleHideArchived} />}
                label={<Typography sx={{ fontWeight: 'bold', mb: -0.4 }}>HIDE ARCHIVED</Typography>}
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            variant="contained"
            color={'warning'}
            disabled={false}
            onClick={() => {
              setSkillValue(null);
              props.setProjectStatus(() => ({
                urgent_only: false,
                hide_archived: false,
              }));
              const initialValues = {
                client: '',
                recruiters: [],
                position: '',
                technology: '',
                status: '',
                excludeStatus: [],
                page: '',
                limit: '',
              };

              props.setFilters((prev) => ({
                ...prev,
                ...initialValues,
              }));
              props.handleReset(initialValues);
            }}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <InputAutoCompleteV2
          id="client"
          size={'small'}
          opts={customers?.map((customer) => ({ _id: customer.id, label: customer.companyName })) || []}
          initialValue={props.filters.client}
          placeholder="No Selection"
          label="Customer"
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, client: ev.target.value }));
          }}
          sortOpts={false}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id="recruiters"
          size={'small'}
          opts={recruiters?.map((recruiter) => ({ _id: recruiter.id, label: recruiter.full_name })) || []}
          initialValue={props.filters.recruiters ?? []}
          placeholder="No Selection"
          label="Recruiter"
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, recruiters: ev.target.value }));
          }}
          sortOpts={false}
          checkbox={true}
          multiple={true}
          disableClearable={true}
        />
        <InputAutoCompleteV2
          id="position"
          size={'small'}
          opts={Object.values(POSITIONS)}
          initialValue={props.filters.position}
          placeholder="No Selection"
          label="Title"
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, position: ev.target.value }));
          }}
          sortOpts={false}
          disableClearable={true}
        />
        <FormControl fullWidth>
          <FormLabel>Main Technology</FormLabel>
          {skillIsSuccess && (
            <Autocomplete
              id="skill"
              name="skill"
              sx={{ '& .MuiTextField-root > div': { paddingLeft: '14px' } }}
              placeholder="Search Skills"
              onChange={(ev, item) => {
                setSkillValue(item);
                props.setFilters((prev) => ({ ...prev, technology: item?.id || '' }));
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={skills}
              renderInput={(params) => <TextField {...params} placeholder="No Selection" />}
              value={skillValue}
              disableClearable={true}
            />
          )}
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <FormLabel>&nbsp;</FormLabel>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.handleFilterProjects}
            sx={{
              height: '54px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: 17,
              pt: 1,
              borderRadius: 0,
            }}
          >
            FILTER RESULTS
          </Button>
        </FormControl>
      </Stack>
    </>
  );
}
