import { Container } from '@mui/material';
import { styled } from '@mui/system';

const MainContainerStyled = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '20px 10px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '50px 60px',
  },
  border: 1,
  borderColor: '#E6E6E6',
  borderRadius: theme.spacing(3),
  borderStyle: 'solid',
}));

const MainContainer = ({ children }) => {
  return (
    <MainContainerStyled maxWidth="lg2" disableGutters>
      {children}
    </MainContainerStyled>
  );
};

export default MainContainer;
