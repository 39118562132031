import { Button, Grid, Stack, useTheme, Typography } from '@mui/material';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import _ from 'lodash';
import { PROJECT_SKILL_YEAR_EXP } from 'constants/project';
import { useGetIndustriesExperienceQuery } from 'api/api';
const industryExperienceModel = { option: '', years: '' };

const IndustryExperienceOptions = ({ handleSave, editingFields, rowOptions, useTargeFormat = true }) => {
  const theme = useTheme();
  const { data = [] } = useGetIndustriesExperienceQuery();
  const addRowOption = () =>
    handleSave(
      useTargeFormat
        ? {
            target: {
              value: [...rowOptions, { ...industryExperienceModel }],
              name: 'industry_experienceCV',
            },
          }
        : [...rowOptions, { ...industryExperienceModel }]
    );

  const deleteRowOptios = (index) => {
    const filerArray = [...rowOptions].filter((v, idx) => idx !== index);
    handleSave(
      useTargeFormat
        ? {
            target: {
              value: filerArray,
              name: 'industry_experienceCV',
            },
          }
        : filerArray
    );
  };

  const handleUpdate = (index, option, value) => {
    const updateArray = [...rowOptions];
    updateArray[index] = {
      ...updateArray[index],
      [option]: value,
    };
    handleSave(
      useTargeFormat
        ? {
            target: {
              value: updateArray,
              name: 'industry_experienceCV',
            },
          }
        : updateArray
    );
  };

	return (
    <Grid container spacing={2} sx={{ paddingLeft: '16px', marginTop: 2 }}>
      <Grid item xs={12} xl={8}>
        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent="flex-end"
          sx={{ width: '100%' }}
          flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' }}
        >
          <Button
            variant="outlined"
            sx={{
              minWidth: '231px',
              [theme.breakpoints.up('lg')]: {
                marginTop: '10px !important',
                width: '30%',
              },
            }}
            onClick={addRowOption}
            disabled={editingFields || rowOptions.length >= 5}
          >
            Add Industry Experience
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={8}>
            {(rowOptions || [])?.map((ie, index) => (
              <Stack
                direction="row"
                spacing={2}
                marginTop={2}
                key={`ie-${index}`}
                alignItems={'center'}
                justifyContent="center"
                sx={{ width: '100%' }}
                flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' }}
              >
                <Stack
                  height="100%"
                  justifyContent={'flex-end'}
                  sx={{
                    flexGrow: 1,
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                >
                  <InputAutoCompleteV2
                    id="industry_experienceCV"
                    size={'normal'}
                    label="Industry Experience"
                    autocomplete={false}
                    value={rowOptions[index]?.option}
                    initialValue={rowOptions[index]?.option}
                    opts={data?.map((i) => ({ _id: i.id, label: i.name }))}
                    onChangeInput={(e) => {
                      handleUpdate(index, 'option', e.target.value);
                    }}
                    placeholder="Industry Experience"
                    isDisabled={editingFields}
                  />
                </Stack>
                <Stack
                  height="100%"
                  justifyContent={'flex-end'}
                  sx={{
                    flexGrow: 1,
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                >
                  <InputAutoCompleteV2
                    id="years_industry_experienceCV"
                    size={'normal'}
                    label="Years of experience"
                    opts={PROJECT_SKILL_YEAR_EXP}
                    value={rowOptions[index]?.years}
                    initialValue={rowOptions[index]?.years}
                    onChangeInput={(e) => {
                      handleUpdate(index, 'years', e.target.value);
                    }}
                    placeholder="Years of experience"
                    isDisabled={editingFields}
                  />
                </Stack>
                <Stack height="100%" justifyContent={'center'} alignItems={'center'}>
                  <Button color="warning" disabled={editingFields} onClick={() => deleteRowOptios(index)}>
                    <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      Delete
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndustryExperienceOptions;
