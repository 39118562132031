import { connect } from 'react-redux';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { ApplicantsWidget, CardsWidget, PositionsWidget, NCSscoreWidget } from '@nexxus/components';
import customerService from 'services/customerService';

import { excludeForTotalCounters } from 'constants/customer';
import { useTheme } from '@emotion/react';

import { useGetCurrentUserQuery } from 'api/api';
import { PROJECT_STATUS } from 'constants/project';
import { createSearchParams, useNavigate } from 'react-router-dom';
import DashboardWalkthrough from 'components/Customer/DashboardWalkthrough';

const Dashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const [counters, setCounters] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [applicants, setApplicants] = useState([]);
  const [latestProjects, setLatestProjects] = useState([]);
  const [isLoadingPositions, setIsLoadingPositions] = useState(false);
  const [isLoadingApplicants, setIsLoadingApplicants] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const excludeForLatestApplicants = [...excludeForTotalCounters];

  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  useMemo(() => {
    (async () => {
      if (currentUser && currentUserIsSuccess) {
        try {
          let { data } = await customerService.getCustomerCounters(
            currentUser?.Client?.id,
            null,
            {
              excludeForTotal: excludeForTotalCounters,
            },
            true
          );
          if (data.length === 0) {
            data = {
              counters: [
                {
                  COUNTER_ARCHIVED: '0',
                  COUNTER_CUSTOMER_INTERVIEW: '0',
                  COUNTER_CUSTOMER_INTERVIEW_REJECTED: '0',
                  COUNTER_DROPPED_OUT: '0',
                  COUNTER_HIRED: '0',
                  COUNTER_OFFER_ACCEPTED: '0',
                  COUNTER_OFFER_EXTENDED: '0',
                  COUNTER_OFFER_REJECTION: '0',
                  COUNTER_PANEL_INTERVIEW: '0',
                  COUNTER_PANEL_INTERVIEW_REJECTED: '0',
                  COUNTER_SKILLS_ASSESSMENT: '0',
                  COUNTER_SKILLS_ASSESSMENT_REJECTED: '0',
                  COUNTER_SUBMITTED_CUSTOMER: '0',
                  COUNTER_SUBMITTED_CUSTOMER_REJECTED: '0',
                  id: '',
                  position: '',
                  seniority: '',
                  total_candidates: '0',
                },
              ],
            };
          }

          setIsLoadingApplicants(true);
          const { data: latestCandidates } = await customerService.getCustomerLatestCandidates(currentUser.Client.id, {
            excludeForApplicants: excludeForLatestApplicants,
          });
          setIsLoadingPositions(true);
          const { data: latestProjects } = await customerService.getCustomerLatestProjects(currentUser.Client.id, {
            excludeForProjects: PROJECT_STATUS.ARCHIVED,
          });

          Promise.all([
            setCounters(data?.counters || null),
            setProjectId(data.counters?.[0]?.id ?? ''),
            setApplicants(latestCandidates),
            setLatestProjects(latestProjects),
          ]);
        } catch (error) {
          console.log('%0', error.response);
          if (error.response.status === 401) props.loginExpire();
        } finally {
          setIsLoadingPositions(false);
          setIsLoadingApplicants(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleCallbackPipelineCard = useCallback(
    (statusSelected) => {
      if (projectId !== '' && Number(projectId) !== 0 && statusSelected !== '') {
        navigate({
          pathname: '/customer/projects/overview',
          search: createSearchParams({ ProjectId: projectId, Tab: statusSelected }).toString(),
        });
      }
    },
    [navigate, projectId]
  );

  return (
    <>
      <MainLayout
        {...props}
        current="dashboard"
        avatarTopBar={currentUser?.Client?.logoUrl ?? ''}
      >
        <Box>
          <Typography my="20px" variant="h1" color="primary">
            Dashboard
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardsWidget
                counters={counters ?? null}
                handleCallbackPipelineCard={handleCallbackPipelineCard}
                setProjectId={setProjectId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ApplicantsWidget applicants={applicants} isLoading={isLoadingApplicants} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PositionsWidget title="Latest Positions" positions={latestProjects} isLoading={isLoadingPositions} />
            </Grid>
            <Grid item xs={12} md={6}>
              <NCSscoreWidget random={92} extendedVersion={!isMobile} />
            </Grid>
          </Grid>
        </Box>
      </MainLayout>
      <DashboardWalkthrough />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
