import { CANDIDATE_TIMEZONES } from './candidate';
import moment from 'moment';

export const PROJECT_STATUS = {
  ARCHIVED: 0,
  RE_ENABLE: 1, // request enable from customer
  ON_HOLD: 5,
  ACTIVE: 10,
  INTAKE: 11,
  DRAFT: 15,
  URGENT: 20,
};

export const PROJECT_SENIORITY = [
  { _id: 'junior', label: 'Junior (1+ Years)', shortLabel: 'Junior' },
  { _id: 'mid-level', label: 'Mid-Level (3+ Years)', shortLabel: 'Mid-Level' },
  { _id: 'senior', label: 'Senior (5+ Years)', shortLabel: 'Senior' },
  { _id: 'principal', label: 'Principal Engineer (8+ Years)', shortLabel: 'Principal Engineer' },
];

export const PROJECT_TIMEZONES = [
  { _id: CANDIDATE_TIMEZONES.CST, label: 'CST - Central Time Zone', timeRef: -6 },
  { _id: CANDIDATE_TIMEZONES.EST, label: 'EST - Eastern Time Zone', timeRef: -5 },
  { _id: CANDIDATE_TIMEZONES.MST, label: 'MST - Mountain Time Zone', timeRef: -7 },
  { _id: CANDIDATE_TIMEZONES.PST, label: 'PST - Pacific Time Zone', timeRef: -8 },
];

export const HOLIDAYS = {
  MX: 'mx',
  US: 'us',
};

export const PROJECT_HARDWARE = [
  { _id: 1, label: 'Sonatafy PC' },
  { _id: 2, label: 'Sonatafy MAC' },
  { _id: 3, label: 'Client PC' },
  { _id: 4, label: 'Client MAC' },
];

/**
 * Retrieve array of hours in options format
 * @returns {Array.<{_id: String, label: String,}>}
 */
export const PROJECT_WORKING_HOURS = () => {
  const initialHour = '06:00';
  const arrHours = [];
  for (let i = 0; i <= 14; i++) {
    const time = moment(initialHour, 'hh').add(i, 'hours');
    arrHours.push({ _id: time.format('HH'), label: time.format('LT') });
  }
  return arrHours;
};

export const PROJECT_HOLIDAYS = [
  {
    _id: HOLIDAYS.MX,
    label: 'MX',
  },
  {
    _id: HOLIDAYS.US,
    label: 'US',
  },
];

export const PROJECT_SKILL_YEAR_EXP = [
  { _id: 1, label: '< 3' },
  { _id: 2, label: '3 - 5' },
  { _id: 3, label: '6 - 8' },
  { _id: 4, label: '8 +' },
];
