import { useRef, useEffect } from 'react';

const useConstructor = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false);
  useEffect(() => {
    hasBeenCalled.current = true;
  });

  if (hasBeenCalled.current) return;
  else return callBack();
};

export { useConstructor };
