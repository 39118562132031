import http from './httpService';
import axios from 'api/axios';

function getAllCandidates() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/candidates');
}

function getCandidatesByProjectStatus(projectId, params) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/projects/${projectId}/candidates/status`, {
    params,
  });
}

function getCandidatesByProject(projectId, status) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/projects/${projectId}/candidates/`);
}

function getCandidates(paginate, only_blacklisted = false, only_staff = false) {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/candidates', {
    params: { ...paginate, only_blacklisted, only_staff },
  });
}

function getCandidateNotes(CandidateId, options) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${CandidateId}/notes`, { params: options });
}

function addCandidateNote(CandidateId, options) {
  return http.post(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${CandidateId}/notes`, options);
}

function updateCandidateNotes(NoteId, body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/candidates/notes/${NoteId}`, body);
}

function getCandidateFeedback(candidateId, ProjectId) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/feedback`);
}

function getFeaturedCandidates(paginate) {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/candidates', { params: paginate });
}

function updateCandidateFile(candidateId, file_type, body) {
  const formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }

  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/${file_type}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function updateCandidateSignedFile(candidateId, file_type, body) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/${file_type}/`, body);
}

function deleteCandidateFile(candidateId, file_type) {
  return http.delete(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/${file_type}/`);
}

function deleteCandidateAudio(audioId) {
  return http.delete(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/audios/${audioId}`);
}

function getCandidate(CandidateId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${CandidateId}`);
}

function getTags() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/tags/all');
}

function patchCandidate(body, candidateId) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}`, body);
}

function registerCandidate(body) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/candidates', body);
}

function updateCandidateActivity(candidateId) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/activity`);
}

function uploadCandidateFile(candidateId, formData) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function uploadCandidateSignedFile(candidateId, data) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/attachments`, data);
}

function uploadCandidateCvExtraData(formData) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/cv-extra-info`, formData);
}

/**
 * @param {number|string} candidateId
 * @param {Object} body
 * @param {"certificates" | "attachments"} body.type
 * @param {Array.<Object>} body.attachments
 */
function updateCandidateAttachment(candidateId, body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/attachments`, body);
}

function uploadCandidateImage(formData, key) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/candidates/profile-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function saveCandidateUploads(formData) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/candidates/profile-upload', formData);
}

function getCandidateAttachments(candidateId) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/attachments`);
}
function getCandidateCodeTests(candidateId) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/codetests`);
}

function addCandidateCodeTest(candidateId, body) {
  return http.post(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/codetests`, body);
}

function removeCandidateCodeTest(candidateId, codeTestId) {
  return http.delete(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/codetests/${codeTestId}`);
}

function getCandidateGrade(candidateId) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/grade`);
}

function updateCandidateGrade(candidateId, body) {
  return http.post(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/grade`, body);
}

function updateCandidateSkills(body, candidateId) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${candidateId}/skills`, body);
}

function getProjectMeta(candidateId, projectId) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/project-meta/${projectId}`);
}

function updateProjectMeta(candidateId, projectId, body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/project-meta/${projectId}`, body);
}

function getEmailValid(email) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/valid-email/${email}`);
}

const clearLinkedInProfile = (id) => {
  return http.patch(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/actions/clear-linkedin/' + id);
};

const deleteCandidate = (id) => {
  return http.delete(process.env.REACT_APP_API_BACKEND + '/v1/candidates/' + id);
};

const getDuplicatesByLinkedinHandle = (slug) => {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/duplicates-by-linkedin/' + slug);
};

const getCandidateProjectNcs = (candidateId, projectId) => {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/candidates/${candidateId}/project/${projectId}`);
};

export default {
  getProjectMeta,
  updateProjectMeta,
  getAllCandidates,
  getCandidates,
  getFeaturedCandidates,
  patchCandidate,
  getCandidate,
  registerCandidate,
  uploadCandidateImage,
  getCandidateCodeTests,
  addCandidateCodeTest,
  removeCandidateCodeTest,
  getTags,
  getCandidateGrade,
  updateCandidateGrade,
  updateCandidateSkills,
  updateCandidateFile,
  deleteCandidateFile,
  deleteCandidateAudio,
  getCandidatesByProjectStatus,
  getCandidatesByProject,
  getCandidateNotes,
  addCandidateNote,
  getCandidateFeedback,
  getEmailValid,
  uploadCandidateFile,
  uploadCandidateSignedFile,
  getCandidateAttachments,
  updateCandidateAttachment,
  clearLinkedInProfile,
  deleteCandidate,
  getDuplicatesByLinkedinHandle,
  updateCandidateActivity,
  updateCandidateNotes,
  updateCandidateSignedFile,
  uploadCandidateCvExtraData,
  saveCandidateUploads,
  getCandidateProjectNcs,
};
