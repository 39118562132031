import { Avatar, Box, FormControl, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import { FormHook } from 'hooks/form/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CLIENTSTATUS } from 'utils/staticData';

import { usePrevious } from 'utils/utils';
import { schemaProjectHeader } from 'validations/projects/projectRegister';
import { useGetCustomersQuery } from 'api/api';
import { PROJECT_STATUS } from 'constants/project';
import _ from 'lodash';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';

const CustomerForm = ({ project, validateForm, onValidForm, setStopNavigation }) => {
  const { ProjectId, ClientId } = useParams();
  let elementRef = useRef();
  const [clientSelected, setClientSelected] = useState({});
  const theme = useTheme();

  const { fields, handleSave, errors, handleSubmit, validSubmit, setErrors, initialState, setValues } = FormHook(
    { ClientId: '', status: '' },
    schemaProjectHeader,
    false
  );

  const prevProjectId = usePrevious(ProjectId);
  const {
    data: customers,
    refetch: refetchCustomers,
    isSuccess: customersIsSuccess,
  } = useGetCustomersQuery(ProjectId ? { id: ProjectId } : { status: CLIENTSTATUS.ACTIVE });

  useEffect(() => {
    if (ProjectId !== prevProjectId) {
      refetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProjectId, refetchCustomers]);

  useEffect(() => {
    if (customersIsSuccess && project.ClientId && project.ClientId !== '') {
      const client = customers.find((client) => client.id === project.ClientId);
      const statusDB = Object.keys(PROJECT_STATUS).find((key) => PROJECT_STATUS[key] === project.status);

      setClientSelected(client);
      setValues((prev) => ({ ...prev, ClientId: project.ClientId, status: statusDB }));
    }
  }, [customers, customersIsSuccess, project, setValues]);

  useEffect(() => {
    if (validateForm === true) {
      elementRef.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }, [validateForm]);

  useEffect(() => {
    (async () => {
      if (validSubmit === null) return;
      else if (validSubmit === false) {
        onValidForm(false);
        return;
      }
      if (validSubmit) {
        try {
          setErrors({ ...initialState });
          onValidForm(fields);
        } catch (error) {
          if (error.response && error.response.data) {
            return toast.error(error.response.data.message);
          }
        }
      }
    })();
  }, [validSubmit]); // eslint-disable-line

  const handleChangeLogo = (idClient) => {
    const client = customers.find((client) => client.id === idClient);
    if (clientSelected !== client) {
      setClientSelected(client);
    }
  };

  useEffect(() => {
    if (ClientId && customersIsSuccess) {
      setClientSelected(customers.find((client) => client.id === ClientId));
      setValues((prev) => ({ ...prev, ClientId }));
    }
  }, [ClientId, customers, customersIsSuccess, setValues]);

  const optionsStatusProject = useMemo(() => {
    const excludingStatus = [PROJECT_STATUS.DRAFT];
    if (_.isEmpty(project) || !project.id) excludingStatus.push(PROJECT_STATUS.RE_ENABLE, PROJECT_STATUS.INTAKE);
    return _.omitBy(PROJECT_STATUS, (value) => excludingStatus.includes(value));
  }, [project]);

  const handleOnChangeInputSelect = (evt) => {
    if (evt.target.value !== fields[evt.target.name]) {
      handleSave(evt);
      if (evt.target.name === 'ClientId') handleChangeLogo(evt.target.value);
      setStopNavigation(true);
    }
  };

  return (
    <Grid container sx={{ marginBottom: 2, [theme.breakpoints.down('sm')]: { flexDirection: 'column-reverse' } }}>
      <Grid
        item
        xs={12}
        sm={6}
        ref={(ref) => (elementRef = ref)}
        component="form"
        onSubmit={(e) => handleSubmit(e, schemaProjectHeader)}
      >
        <Stack spacing={2} direction={'row'} justifyContent={'center'} alignItems={'flex-start'}>
          {/* Client  */}
          <FormControl fullWidth sx={{ paddingRight: 1 }}>
            {customers && (
              <InputAutoCompleteV2
                placeholder={'Customer'}
                id={'ClientId'}
                opts={customers.map((client) => ({ _id: client.id, label: client.companyName }))}
                error={errors.ClientId && errors.ClientId.txt}
                initialValue={fields.ClientId}
                onChangeInput={handleOnChangeInputSelect}
                size={'normal'}
                isDisabled={!!ProjectId ?? !!ClientId}
                defaultOption={false}
              />
            )}
          </FormControl>
          {/* Status Project */}
          <FormControl fullWidth sx={{ paddingLeft: 1 }}>
            <InputAutoCompleteV2
              placeholder={'Status'}
              id="status"
              opts={Object.entries(optionsStatusProject).map(([key, value]) => {
                return {
                  _id: key,
                  label: key.replace('_', ' '),
                  disabled: [PROJECT_STATUS.INTAKE, PROJECT_STATUS.RE_ENABLE].includes(value),
                };
              })}
              error={errors.status && errors.status.txt}
              initialValue={fields.status}
              onChangeInput={handleOnChangeInputSelect}
              size={'normal'}
            />
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-end">
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {clientSelected && clientSelected.label}
            </Typography>
            {clientSelected && clientSelected.label && (
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {clientSelected.address}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {clientSelected.phone}
                </Typography>
              </>
            )}

            {clientSelected && clientSelected.websiteUrl && (
              <Link
                component="a"
                href={clientSelected.websiteUrl}
                target="_blank"
                sx={{
                  color: 'nexxusBlueExtraLight.main',
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'left',
                  textDecoration: 'none',
                }}
              >
                VISIT WEBSITE
              </Link>
            )}
          </Box>
          <Box
            sx={{
              width: 86,
              height: 86,
              border: 1,
              borderColor: 'grey.light',
              overflow: 'hidden',
              borderRadius: '100%',
            }}
          >
            <Avatar
              sx={{
                width: '100%',
                height: '100%',
                fontSize: '1.3rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
              src={clientSelected?.logoUrl}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
