import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { EmailFields, emailInitialState } from 'pages/me/profileFields';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import userService from 'services/userService';
import { loginSuccess } from 'store/auth';

const MeEmailForm = (props) => {
  const [open, setOpen] = useState(false);
  const { setInitialStateRequest, fields, handleSave, errors, handleSubmit, validSubmit } = EmailFields(emailInitialState);

  const CustomButton = styled(Button)({
    padding: '10px 30px',
  });

  useEffect(() => {
    setInitialStateRequest({ ...emailInitialState, old_email: props.auth.user.email });
  }, [props.auth.user.email, setInitialStateRequest]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        const { data } = await userService.updateMeEmail(Object.assign({}, { email: fields.email }));
        props.loginSuccess({ ...props.auth, user: data });
        localStorage.setItem('currentUser', JSON.stringify(data));
        setInitialStateRequest({ ...emailInitialState, old_email: data.email });
        setOpen(false);
        toast.success('User email updated');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  return (
    <>
      <CustomButton variant="contained" onClick={() => setOpen(true)} sx={{ padding: '10px 30px' }}>
        Change Email
      </CustomButton>
      <ModalFeedback
        variant="general"
        headerText={'Change Email'}
        headerBarColor="#0288D1"
        open={open}
        onAccept={() => handleSubmit(fields)}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        disableCloseOnAccept={true}
      >
        <Box>
          <InputTextOutlinedV2
            id="old_email"
            label="Current Email"
            type="old_email"
            onChangeInput={(e) => handleSave(e, props.auth.user)}
            error={errors.old_email?.txt}
            initialValue={''}
            variant="standard"
            styleControl={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          />
          <InputTextOutlinedV2
            id="email"
            label="New Email"
            type="email"
            variant="standard"
            onChangeInput={handleSave}
            initialValue={''}
            error={errors.email?.txt}
            styleControl={{ marginTop: '1.5em' }}
          />
        </Box>
      </ModalFeedback>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (user, tokens) => {
      dispatch(loginSuccess(user, tokens));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeEmailForm);
