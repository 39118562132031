import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { Typography, Modal, Paper, Stack, styled, useTheme, useMediaQuery } from '@mui/material';
import { SidebarContext, CustomButton } from '@nexxus/components';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

const ModalPaper = styled(Paper)(({ theme }) => ({
  '&:focus-visible': {
    outline: 'none',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 480,
  width: '90%',
  backgroundColor: '#ffffff',
  padding: '24px',
  zIndex: 100001,
}));

const steps = [
  {
    element: '#menu-item2',
    popover: {
      description:
        'This is your Dashboard, which gives you an overall candidate and position status. Additionally it quickly shows you how many candidates you have in the pipeline.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#menu-item3',
    popover: {
      description:
        'This is the Positions section, where you can view positions pipelines, archived positions, and add new positions by filling in the Customer Intake Form.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#menu-item7',
    popover: {
      description:
        'This is your User Access section, here you can add Read Only users that are part of your Candidate Sourcing Team.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#menu-item5',
    popover: {
      description: 'This is your Help section, here you can select from different topics in our submenu.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#candidate-pipeline-widget',
    popover: {
      description:
        'This section of your Dashboard is called the Candidate Pipeline and here you can see an overview of the pipeline status per each role.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#latest-applicants-widget',
    popover: {
      description:
        'This section of your Dashboard is called Latest Applicants, here you can view the latest applicants sorted by the date on which they applied to the position.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#latest-positions-widget',
    popover: {
      description: 'This section allows you to see and monitor the latest positions and their status.',
      side: 'left',
      align: 'start',
    },
  },
  {
    element: '#ncs-score-widget',
    popover: {
      description:
        'This section of your Dashboard explains how we score each candidate based on how well their capabilities match your needs, we call it NCS.',
      side: 'left',
      align: 'start',
    },
  },
];

const DashboardWalkthrough = (props) => {
  const { open, setOpen, setOpenSecondary } = useContext(SidebarContext);
  const theme = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobileMenu = useMediaQuery('(max-width:1279px)');

  const driverObj = driver({
    showProgress: true,
    progressText: '{{current}}/{{total}}',
    nextBtnText: 'NEXT',
    prevBtnText: 'GO BACK',
    doneBtnText: 'NEXT',
    // allowClose: false,
    steps,
    onNextClick: () => {
      const index = driverObj.getActiveIndex();
      // close menu after Help section
      if (index >= 3) {
        setOpen(false);
        setOpenSecondary(false);
      }

      if (!driverObj.isLastStep()) driverObj.moveNext();
      else {
        driverObj.destroy();
        navigate({
          pathname: location.pathname,
          search: '?walkthrough=end',
        });
      }
    },
    onPopoverRender: (popover) => {
      const width = ((driverObj.getActiveIndex() + 1) / steps.length) * 100;
      const progressBar = document.createElement('div');
      progressBar.className = 'progress-bar';
      progressBar.style.width = `${width}%`;
      popover.footer.querySelector('.driver-popover-progress-text').prepend(progressBar);
    },
    onDestroyed: () => {
      if (walkthrough === 'end') return;
      localStorage.setItem('isWalkthroughCompleted', 'true');
      navigate({
        pathname: location.pathname,
      });
    },
    onCloseClick: () => {
      setOpen(false);
      driverObj.destroy();
    },
  });

  const walkthrough = searchParams.get('walkthrough');
  useEffect(() => {
    if (walkthrough === 'active' && !driverObj.isActive()) {
      setOpen(true);
      setOpenSecondary(false);
      setTimeout(
        () => {
          driverObj.drive();
        },
        isMobileMenu ? 400 : 0
      );
    }

    if (walkthrough === 'end') {
      driverObj.destroy();
    }
    if (walkthrough === 'start') setOpen(false);
  }, [driverObj, isMobileMenu, open, setOpen, setOpenSecondary, walkthrough]);

  const isWalkthroughCompleted = localStorage.getItem('isWalkthroughCompleted');
  const isCookieAccepted = localStorage.getItem('cookieAccepted');

  useEffect(() => {
    if (isCookieAccepted && !isWalkthroughCompleted && walkthrough !== 'active') {
      navigate({
        pathname: location.pathname,
        search: '?walkthrough=start',
      });
    }
  }, [isWalkthroughCompleted, navigate, location.pathname, walkthrough, isCookieAccepted]);

  return (
    <>
      <Modal open={walkthrough === 'start'} sx={{ zIndex: 13000 }}>
        <ModalPaper>
          <Typography variant="h1" textAlign="center" mb={2} sx={{ color: theme.palette.primary.main }}>
            Welcome {props.auth.user.first_name} to your NEXXUS Dashboard!
          </Typography>

          <Typography variant="body4" mb={2} textAlign="center">
            Whether you&apos;re a first-time visitor or a long-time user, this walkthrough will guide you through the ins and
            outs of our site, ensuring you get the most out of your experience.
          </Typography>

          <Typography variant="body4" mb={2} textAlign="center">
            We will show you how to navigate our user-friendly interface, discover valuable features, and find exactly what
            you need quickly and effortlessly.
          </Typography>

          <Typography variant="body4" mb={2} textAlign="center">
            Let&apos;s get started on this journey to make your talent acquisition tasks simpler and more efficient. Click
            &apos;<strong style={{ whiteSpace: 'nowrap' }}>Get Started</strong>&apos; to begin your Nexxus experience!
          </Typography>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <CustomButton
              variant="outlined"
              sx={{ width: '47%' }}
              onClick={() => {
                navigate({
                  pathname: location.pathname,
                });
                localStorage.setItem('isWalkthroughCompleted', 'true');
              }}
            >
              SKIP
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ width: '47%' }}
              onClick={() =>
                navigate({
                  pathname: location.pathname,
                  search: '?walkthrough=active',
                })
              }
            >
              GET STARTED!
            </CustomButton>
          </Stack>
        </ModalPaper>
      </Modal>

      <Modal open={walkthrough === 'end'} sx={{ zIndex: 13000 }}>
        <ModalPaper>
          <Typography variant="h1" textAlign="center" mb={2} sx={{ color: theme.palette.primary.main }}>
            Congratulations!
          </Typography>

          <Typography variant="body4" mb={2} textAlign="center" width="100%">
            You&apos;ve got the basics.
          </Typography>

          <Typography variant="body4" mb={2} textAlign="center" width="100%">
            You can find these tips any time using the Help section.
          </Typography>

          <Stack direction="row" justifyContent="center" mt={2}>
            <CustomButton
              variant="contained"
              sx={{ width: '47%' }}
              onClick={() =>
                navigate({
                  pathname: location.pathname,
                })
              }
            >
              GOT IT
            </CustomButton>
          </Stack>
        </ModalPaper>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(DashboardWalkthrough);
