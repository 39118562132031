import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import _ from 'lodash';

import { PROJECT_SENIORITY } from 'constants/project';
import { POSITIONS } from '@nexxus/constants';
import projectService from 'services/projectService';
import { DownloadIcon } from 'assets/icons';
import { availabilityOptions, timezoneOpts, timezoneOptsCand } from 'utils/staticData';
import { utils, writeFileXLSX } from 'xlsx';
import { capitalizeString } from 'utils/utils';

import { useGetCountriesQuery } from 'api/api';

const ReportXls = ({ project, color }) => {
  const { data: countries } = useGetCountriesQuery();
  const getCandidates = async (projectId) => {
    const Heading = [
      [
        'Position',
        'Seniority',
        'Description',
        'Project Skills',
        'Candidate',
        'Status',
        'Candidate Skills',
        'Email',
        'Location',
        'Availaility',
        'Linkedin',
        'Candidate Position',
        'Candidate Seniority',
        'Salary Expectation',
        'Candidate NCS',
        'Candidate Timezone',
      ],
    ];
    const { data } = await projectService.getProjectCandidates(projectId);
    const position = Object.values(POSITIONS).find((pos) => pos._id === data.position);
    const senior = PROJECT_SENIORITY.find((pos) => pos._id === data.seniority);
    const pjSkills = data?.Skills?.map((skill) => skill.name);
    console.log(data);
    const dataPj = {
      position: capitalizeString(position?.label || ''),
      seniority: capitalizeString(senior?.label || ''),
      description: data.description.replaceAll('"', '').replaceAll('\\r\\n|\\r|\\n', ' '),
      projectSkills: pjSkills?.join(','),
    };

    let rows;
    if (data.Candidates.length > 0) {
      rows = data.Candidates.map((cand) => {
        const status = _.findKey(CANDIDATEPROJECT_STATUS, function (el) {
          return el === cand.Project_Candidate.status;
        });
        const timezone = [...timezoneOpts, ...timezoneOptsCand].find((time) => time._id === cand.timezone);
        const senior = PROJECT_SENIORITY.find((sen) => sen._id === cand.developer_seniority);
        const title = Object.values(POSITIONS).find((title) => title._id === cand.developer_title);
        const availability = availabilityOptions.find((av) => av._id === cand.availability);
        const candSkills = cand.Skills.map((skill) => skill.name);
        const candLocation = countries.find((loc) => loc.id === cand.LocationCountryId);
        return {
          ...dataPj,
          candFullname: cand.full_name,
          candStatus: status.replace(/_/g, ' ') || ' ',
          candSkills: candSkills.join(','),
          candEmail: cand.email,
          candLocation: candLocation?.name || ' ',
          candAvailability: availability?.label || ' ',
          candLinkedin: cand.linkedin,
          candTitle: title?.label || ' ',
          candSeniority: senior?.label || ' ',
          candSalaryExp: cand.salary_expectation,
          candNcs: cand.candidate_ncs > 0 ? cand.candidate_ncs : ' ',
          candTimezone: timezone?.label || ' ',
        };
      });
    } else {
      rows = [{ ...dataPj }];
    }
    const ws = utils.json_to_sheet(rows);
    const wb = utils.book_new();
    utils.sheet_add_aoa(ws, Heading);
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `${project.Client.companyName.replaceAll(' ', '')}-${project.position.replaceAll(' ', '')}.xlsx`);
  };

  return <DownloadIcon sx={{ color: color }} onClick={() => getCandidates(project.id)}></DownloadIcon>;
};

export default ReportXls;
