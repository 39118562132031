import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { logout } from '../../store/auth';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const Logout = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    const logUserOut = async () => {
      if (user) {
        await authService.logout();
        props.logout();
      }
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 1000);
    };
    logUserOut();
  }, [navigate, props, user]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        background: 'linear-gradient(0deg, #004dd3 0%, #011c2e 100%)',
      }}
    >
      <CircularProgress />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
