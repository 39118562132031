import { useEffect, useState } from 'react';
import { useGetUsersQuery } from 'api/api';

const useUsersFilter = () => {
  const [filters, setFilters] = useState({});
  const { data: users, isLoading: usersIsLoading, error: usersError, refetch } = useGetUsersQuery({ ...filters });

  const handleFilterUsers = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    console.log('filters: ', filters);
    refetch();
  }, [filters, refetch]);

  return {
    filters,
    handleFilterUsers,
    refetch,
    usersIsLoading,
    usersError,
    users,
  };
};

export default useUsersFilter;
