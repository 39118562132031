import './style.css';
import { Dialog, Box, Typography, Chip, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAuditTrailQuery, useGetCandidateByIdQuery, useGetSkillsResumeQuery } from 'api/api';
import { POSITIONS } from '@nexxus/constants';
import { isEqual } from 'lodash';
import { camelCaseToCapitalCase } from '@nexxus/utils';
import { LOG_ACTIVITY } from '@nexxus/constants';

const displayObjectAsJSX = (obj) => {
  // Check if the object is an array
  if (Array.isArray(obj)) {
    return (
      <ul>
        {obj.map((item, index) => (
          <li key={index}>{displayObjectAsJSX(item)}</li>
        ))}
      </ul>
    );
  }

  // Check if the object is a nested object
  if (typeof obj === 'object' && obj !== null) {
    const isAttributeWrapper = isEqual(Object.keys(obj).sort(), ['current', 'previous']);
    const AttributeWrapper = ({ children }) => {
      return (
        <Stack direction="row" spacing={2} gap={6}>
          {children}
        </Stack>
      );
    };
    const AttributeContent = () => {
      return isAttributeWrapper ? (
        <>
          {Object.entries(obj).map(([key, value], index) =>
            ['createdAt', 'updatedAt'].includes(key) ? null : (
              <div key={index}>
                <strong>{camelCaseToCapitalCase(key)}: </strong>
                {displayObjectAsJSX(value)}
              </div>
            )
          )}
        </>
      ) : (
        <ul>
          {Object.entries(obj).map(([key, value], index) =>
            ['createdAt', 'updatedAt'].includes(key) ? null : (
              <li key={index}>
                <strong>{key}: </strong>
                {displayObjectAsJSX(value)}
              </li>
            )
          )}
        </ul>
      );
    };

    return isAttributeWrapper ? (
      <AttributeWrapper>
        <AttributeContent />
      </AttributeWrapper>
    ) : (
      <AttributeContent />
    );
  }

  // If it's neither an array nor an object, display it as text
  return <span>{obj}</span>;
};

const LogDetail = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: auditTrail, isSuccess } = useGetAuditTrailQuery(id, {
    skip: !id,
  });
  const { data: candidate } = useGetCandidateByIdQuery(auditTrail?.meta?.candidateId, {
    skip: !auditTrail?.meta?.candidateId,
  });
  const { data: resumeSkills } = useGetSkillsResumeQuery();
  const [dataUpdated, setDataUpdated] = useState({});

  const handleClose = () => {
    setOpen(false);
    navigate('/audittrails');
  };

  useEffect(() => {
    setDataUpdated(auditTrail?.meta?.dataUpdated);
  }, [auditTrail]);

  useEffect(() => {
    if (isSuccess && auditTrail?.meta.dataUpdated?.CandidateResumeSkills?.current.lenth) {
      const previousSkills = auditTrail.meta.dataUpdated.CandidateResumeSkills?.previous.map((skillId) => {
        return resumeSkills.find((resumeSkill) => resumeSkill.id === skillId).name;
      });

      const currentSkills = auditTrail.meta.dataUpdated.CandidateResumeSkills?.current.map((skillId) => {
        return resumeSkills.find((resumeSkill) => resumeSkill.id === skillId).name;
      });

      const newDataUpdated = {
        ...auditTrail.meta.dataUpdated,
        CandidateResumeSkills: {
          previous: previousSkills,
          current: currentSkills,
        },
      };
      setDataUpdated(newDataUpdated);
    }
  }, [auditTrail, resumeSkills, isSuccess]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        {auditTrail ? (
          <Box padding={4} sx={{ width: 1024, height: 640 }}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Box>
                <Typography variant="h4">
                  {auditTrail?.User.full_name} <Chip size="small" label={auditTrail?.User.role} />
                </Typography>
                <Typography variant="body2" color="gray">
                  {auditTrail?.User.email}
                </Typography>
                <Typography variant="body2" color="gray">
                  {auditTrail?.meta?.ipAddress}
                </Typography>
              </Box>
              <Box textAlign="right">
                <Typography pt={0.5} variant="h5" textTransform="uppercase">
                  {auditTrail?.activity}
                </Typography>
                <Typography variant="body2" color="gray">
                  {auditTrail?.meta?.model}
                </Typography>
                <Typography variant="body2" color="gray">
                  {moment(auditTrail?.createdAt).format('MM/DD/YYYY @ hh:mm A')}
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{ mt: 2, mb: 4 }} />
            {auditTrail.status ? (
              <>
                <Typography variant="h6">STATUS</Typography>
                <Typography variant="h6">{auditTrail.status}</Typography>
              </>
            ) : null}
            {candidate ? (
              <>
                <Typography variant="body2" color="gray">
                  CANDIDATE
                </Typography>
                <Typography variant="h5" fontWeight={700}>
                  {candidate.full_name} <Chip sx={{ borderRadius: 0 }} size="small" label={`ID: ${candidate.id}`} />
                </Typography>
                <Typography variant="body2" color="gray" mb={3}>
                  {Object.values(POSITIONS).find((pos) => pos._id === candidate.developer_title).label}
                </Typography>
              </>
            ) : null}

            {auditTrail.activity === LOG_ACTIVITY.CANDIDATE_PIPELINE_UPDATE ||
            (auditTrail?.meta?.position && auditTrail?.meta?.customerName && auditTrail?.meta?.seniority) ? (
              <>
                <Typography variant="h5" color="gray" mb={2}>
                  {auditTrail?.meta?.customerName} : {auditTrail?.meta?.position} - {auditTrail?.meta?.seniority}
                </Typography>
              </>
            ) : null}

            {auditTrail?.meta?.subAction ? (
              <Typography variant="body2" color="gray" textTransform="uppercase" mb={2}>
                SUB ACTION: <strong>{auditTrail?.meta?.subAction}</strong>
              </Typography>
            ) : null}
            {dataUpdated ? displayObjectAsJSX(dataUpdated) : null}
          </Box>
        ) : null}
      </Dialog>
    </div>
  );
};

export default LogDetail;
