import React from 'react';

import { Link, Stack, Avatar, Typography } from '@mui/material';

export default function ClientInfo(props) {
  const direction = props.reverse ? 'row-reverse' : 'row';
  const alignItems = props.reverse ? 'right' : 'left';
  const totalSize = 100;

  return (
    <>
      <Stack direction={direction} alignItems="stretch" justifyContent="flex-start" spacing={2}>
        <Stack justifyContent="center">
          <Avatar sx={{ width: totalSize, height: totalSize }}>TG</Avatar>
        </Stack>
        <Stack justifyContent="center" align={alignItems}>
          <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>TAYLORDMADE GOLF</Typography>
          <Typography sx={{ fontSize: 15 }}>5545 Fermi Ct, Carlsbad CA 92008</Typography>
          <Typography sx={{ fontSize: 15 }}>(760) 918-6000</Typography>
          <Link href="#" sx={{ fontSize: 12 }}>
            VISIT WEBSITE
          </Link>
        </Stack>
      </Stack>
    </>
  );
}
