import { Autocomplete, Button, Grid, Stack, TextField, createFilterOptions } from '@mui/material';
import { useState, useMemo, useCallback, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const RowSkillCv = (props) => {
  const [skill, setSkill] = useState({ value: '', hint: '', validated: false, isEditing: false });
  const [skillInitial, setSkillInitial] = useState('');
  useMemo(() => {
    if (props.skill !== '') {
      setSkill({
        value: props.skill,
        hint: props.hint,
        validated: props.validated,
        isEditing: false,
      });
      setSkillInitial(props.skill);
    }
  }, [props.hint, props.skill, props.validated]);

  const handleEditRow = () => {
    setSkill((prev) => ({ ...prev, isEditing: !prev.isEditing }));
  };

  const handleNewValueInput = useCallback(() => {
    setSkill((prev) => ({ ...prev, value: ref.current, isEditing: false, validated: false }));
  }, []);

  const handleValidateRow = useCallback(() => {
    setSkill((prev) => ({ ...prev, validated: true }));
    props.onHandleValidate(skillInitial, { ...skill, validated: true });
  }, [props, skill, skillInitial]);

  const ref = useRef('');
  const filter = createFilterOptions();

  return (
    <>
      <Grid
        item
        xs={4}
        sx={{
          display: 'inline-flex',
          width: '100%',
          alignItems: 'center',
          textAlign: 'left',
          py: '7px!important',
          minHeight: '72px',
        }}
      >
        {skill.isEditing ? (
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <Autocomplete
              disablePortal
              value={ref.current}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  ref.current = newValue;
                  // @ts-ignore
                } else if (newValue?.value) {
                  // @ts-ignore
                  ref.current = newValue.value;
                } else {
                  ref.current = newValue;
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="combo-box-demo"
              options={props.skillsOptions.map((skill) => ({ value: skill.label, title: skill.label }))}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              renderInput={(params) => <TextField {...params} placeholder="Skill" />}
              sx={{ minWidth: '250px' }}
              disableClearable={true}
            />
            <IconButton
              aria-label="delete"
              onClick={handleEditRow}
              sx={{
                color: '#d32f2f',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <HighlightOffIcon sx={{ width: '30px', height: '30px' }} />
            </IconButton>
          </Stack>
        ) : (
          <>{skill.value}</>
        )}
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ display: 'inline-flex', alignItems: 'center', textAlign: 'left', py: '7px!important', minHeight: '72px' }}
      >
        <>{skill.hint}</>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ display: 'inline-flex', alignItems: 'center', textAlign: 'left', py: '7px!important', minHeight: '72px' }}
      >
        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
          <Button
            variant="contained"
            disabled={skill.isEditing || (skill.isEditing === false && skill.validated === true)}
            sx={{ minWidth: '100px' }}
            onClick={handleValidateRow}
          >
            Validate
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: '100px' }}
            onClick={skill.isEditing ? handleNewValueInput : handleEditRow}
          >
            {skill.isEditing ? 'Update' : 'Edit'}
          </Button>
          <Button variant="contained" sx={{ minWidth: '100px' }} onClick={props.deleteSkillCV}>
            Delete
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default RowSkillCv;
