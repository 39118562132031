import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Stack, Menu, MenuItem } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useState } from 'react';
import { ROLE_SUPERCUSTOMER } from 'constants/users';

const RenderActions = (props) => {
  const { row, setOpenModalUser, setUserSelected } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [openModalResetPassword, setOpenModalResetPassword] = useState(false);

  const handleEdit = (ev) => {
    ev.preventDefault();
    console.log('row.id', row.id);
    Promise.all([setUserSelected(row), setOpenModalUser(true)]);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ModalFeedback
        variant="warning"
        open={openDeleteModal}
        headerText={'Delete Current User'}
        titleText={'Do you want to delete this user?'}
        onCancel={() => setOpenDeleteModal(false)}
        onAccept={() => props.onHandleDeleteUser()}
        onClose={() => setOpenDeleteModal(false)}
      />

      <ModalFeedback
        variant="warning"
        open={openRestoreModal}
        headerText={'Restore Current User'}
        titleText={'Do you want to restore this user?'}
        onCancel={() => setOpenRestoreModal(false)}
        onAccept={() => props.onHandleRestoreUser()}
        onClose={() => setOpenRestoreModal(false)}
      />

      <ModalFeedback
        variant="warning"
        open={openModalResetPassword}
        headerText={'Reset Password'}
        titleText={'Do you want to reset this user password?'}
        onCancel={() => setOpenModalResetPassword(false)}
        onAccept={() => props.onHandleResetPassword()}
        onClose={() => setOpenModalResetPassword(false)}
      />

      <Stack direction="row" spacing={1}>
        <IconButton disabled={row.role === ROLE_SUPERCUSTOMER} onClick={handleEdit} size="small">
          <EditIcon color={'nexxusBlack'} />
        </IconButton>
        {row.status_user === 1 && (
          <IconButton
            disabled={row.role === ROLE_SUPERCUSTOMER}
            onClick={() => setOpenDeleteModal(true)}
            size="medium"
            sx={{ mx: 4 }}
          >
            <HighlightOffIcon color={'error'} sx={{ opacity: row.role === ROLE_SUPERCUSTOMER ? 0.5 : 1 }} />
          </IconButton>
        )}
        {row.status_user === 0 && (
          <IconButton disabled={row.role === ROLE_SUPERCUSTOMER} onClick={() => setOpenRestoreModal(true)} size="medium">
            <RestoreFromTrashIcon />
          </IconButton>
        )}
        {row.status_user === 1 && (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              disabled={row.role === ROLE_SUPERCUSTOMER}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setOpenModalResetPassword(true);
                  handleClose();
                }}
              >
                Reset Password
              </MenuItem>
            </Menu>
          </div>
        )}
      </Stack>
    </>
  );
};

export default RenderActions;
